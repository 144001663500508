<angular-window style="background-color: white" [dragHolder]="draggableHandle">
  <div #draggableHandle class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "admin.add_comparison" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="height: 300px">
      <div class="col-md-12 d-flex align-items-center">
        <p>{{ "admin.signal_to_compare" | translate }}</p>
      </div>
      <div class="col-md-12">
        <form [formGroup]="form">
          <ng-select
            #sourceSelect
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [items]="biosignals"
            bindValue="_id"
            [bindLabel]="'dataLabel'"
            labelForId="source_switch_id"
            formControlName="source_switch_id"
            (change)="onBiosignalChange($event)"
          >
          </ng-select>
        </form>
      </div>
    </div>
  </div>
</angular-window>
<ng-container *ngFor="let item of oscilogramB">
  <angular-window
    *ngIf="form.get('source_switch_id').value.includes(item._id)"
    style="background-color: white"
    [dragHolder]="draggableHandle2"
  >
    <div #draggableHandle2 class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="deleteOsc(item._id)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          #{{ item.item.dataLabel }};&nbsp; ({{
            "calculation.chss" | translate
          }}: {{ item.item.TimeAnalysis.HeartRate }};&nbsp;
          {{ "calculation.ats" | translate }}:
          {{ item.item.TimeAnalysis.Systola }};&nbsp;
          {{ "calculation.atd" | translate }}:
          {{ item.item.TimeAnalysis.Diastola }};)
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="signal-wrap">
            <app-raw-signal-chart
              [data]="item.oscilogramData"
            ></app-raw-signal-chart>
          </div>
        </div>
      </div>
    </div> </angular-window
></ng-container>
