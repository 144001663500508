import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  demoSignIn(username, password) {
    const body = {
      username: username,
      password: password,
    };

    this.authService.authUser(body).subscribe(
      (res) => {
        this.authService.setUser(res.token, res.user);
        this.router.navigate(['/home']);
      },
      (err) => {
        console.log(err.error.message);
      }
    );
  }
}
