import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService {
  constructor() {}

  document_type = [
    {
      value: 'PASSPORT',
      ua: 'Паспорт громадянина України',
      en: 'Passport',
      pl: 'Paszport',
    },
    {
      value: 'COMPLEMENTARY_PROTECTION_CERTIFICATE',
      ua: 'Посвідчення особи, яка потребує додаткового захисту',
      en: 'ID of a person in need of additional protection',
      pl: 'ID',
    },
    {
      value: 'PERMANENT_RESIDENCE_PERMIT',
      ua: 'Посвідка на постійне проживання в Україні',
      en: 'Residence permit in Ukraine',
      pl: 'Zezwolenie na pobyt stały',
    },
    {
      value: 'REFUGEE_CERTIFICATE',
      ua: 'Посвідка біженця',
      en: 'Refugee ID',
      pl: 'Karta uchodźcy',
    },
    {
      value: 'TEMPORARY_CERTIFICATE',
      ua: 'Посвідка на проживання',
      en: 'Residence permit',
      pl: 'zezwolenie na pobyt',
    },
    {
      value: 'BIRTH_CERTIFICATE',
      ua: 'Свідоцтво про народження (для осіб, які не досягли 14-річного віку)',
      en: 'Birth certificate (for persons under 14 years of age)',
      pl: 'Akt urodzenia (dla osób poniżej 14 roku życia)',
    },
    {
      value: 'TEMPORARY_PASSPORT',
      ua: 'Тимчасове посвідчення громадянина України',
      en: 'Temporary certificate of Ukrainian citizenship',
      pl: 'Świadectwo tymczasowe',
    },
    {
      value: 'NATIONAL_ID',
      ua: 'Біометричний паспорт громадянина України',
      en: 'Passport',
      pl: 'Paszport',
    },
    {
      value: 'BIRTH_CERTIFICATE_FOREIGN',
      ua: 'Свідоцтво про народження іноземного зразку',
      en: 'Foreign birth certificate',
      pl: 'Akt urodzenia',
    },
  ];
}
