<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="main-wrapper">
    <div class="container">
      <div class="row">
        <div class="col">
          <form [formGroup]="myForm">
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="col">
                    <div class="photo">
                      <label class="" for="fileInput">
                        <img
                          [src]="
                            (photoEmitter | async)
                              ? (photoEmitter | async)
                              : '/assets/img/noavatar.png'
                          "
                        />
                        <div class="overlay" *ngIf="isEdit">
                          <i class="bi bi-camera"></i>
                          <p>{{ "form.drag_select" | translate }}</p>
                        </div>
                      </label>
                      <input
                        *ngIf="isEdit"
                        id="fileInput"
                        [readonly]="!isEdit"
                        hidden
                        type="file"
                        (change)="onSelectFiles($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mt-3" *ngIf="isEdit">
                  <div class="col-auto">
                    <button
                      type="submit"
                      [disabled]="!isDisabled"
                      class="btn btn-primary"
                      (click)="photoUpdate()"
                    >
                      {{ "form.photo_update" | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-9">
                <div class="row" *ngIf="!isAdmin">
                  <div class="col">
                    <h2 class="mb-3 title-block">
                      {{ "form.personal_info" | translate }}
                      <span
                        class="addBtn"
                        (click)="edit()"
                        *ngIf="!isPatient"
                        ngbTooltip="{{ 'form.edit' | translate }}"
                        placement="top"
                      >
                        <i class="bi bi-pencil"></i>
                      </span>
                    </h2>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-11">
                    <div class="row mt-3">
                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.surname" | translate }}
                        </mat-label>
                        <input
                          matInput
                          required
                          type="text"
                          [readonly]="!isEdit"
                          formControlName="last_name"
                        />
                      </mat-form-field>

                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.name" | translate }}
                        </mat-label>
                        <input
                          matInput
                          required
                          type="text"
                          [readonly]="!isEdit"
                          formControlName="first_name"
                        />
                      </mat-form-field>

                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.secondName" | translate }}
                        </mat-label>
                        <input
                          matInput
                          type="text"
                          [readonly]="!isEdit"
                          formControlName="second_name"
                        />
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.birthDate" | translate }}
                        </mat-label>
                        <input
                          required
                          matInput
                          [readonly]="!isEdit"
                          [matDatepicker]="birthDate"
                          formControlName="birth_date"
                        />
                        <mat-datepicker-toggle
                          [disabled]="!isEdit"
                          matSuffix
                          [for]="birthDate"
                        >
                        </mat-datepicker-toggle>
                        <mat-datepicker #birthDate touchUi></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "form.age" | translate }}
                        </mat-label>
                        <input
                          matInput
                          type="text"
                          [readonly]="!isEdit"
                          formControlName="age"
                        />
                      </mat-form-field>

                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.sex" | translate }}
                        </mat-label>
                        <mat-select
                          required
                          formControlName="gender"
                          [disabled]="!isEdit"
                        >
                          <mat-option>-</mat-option>
                          <mat-option
                            *ngFor="let item of genderService.gender"
                            [value]="item.value"
                          >
                            <ng-container *ngIf="!(reloading$ | async)">
                              {{ item | lang }}
                            </ng-container>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.email" | translate }}
                        </mat-label>
                        <input
                          matInput
                          type="text"
                          [readonly]="!isEdit"
                          formControlName="email"
                        />
                      </mat-form-field>

                      <ng-container formArrayName="phones">
                        <mat-form-field
                          class="col-md-4"
                          *ngFor="
                            let item of phones.controls;
                            let phonesIndex = index
                          "
                          [formGroupName]="phonesIndex"
                        >
                          <mat-label>
                            {{ "registration.telephone" | translate }}
                          </mat-label>
                          <input
                            matInput
                            type="text"
                            [readonly]="!isEdit"
                            formControlName="number"
                          />
                        </mat-form-field>
                      </ng-container>

                      <div class="col-md-4 mt-3">
                        <mat-checkbox
                          formControlName="withings"
                          [disabled]="!isEdit"
                        >
                          <div>
                            <img
                              style="width: 7vw; height: auto"
                              src="/assets/img/withings.png"
                              alt="withings logo"
                              class="logo-withings-sidebar ml-2"
                            />
                          </div>
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h2 class="mt-3 mb-4">
                          {{ "form.med_info" | translate }}
                        </h2>
                      </div>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.medOrganization" | translate }}
                        </mat-label>
                        <mat-select
                          [disabled]="!isEdit"
                          formControlName="medicalInstitution"
                        >
                          <mat-option>-</mat-option>
                          <mat-option
                            *ngFor="let item of medicalInstitutions"
                            [value]="item._id"
                          >
                            {{ item.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-md-4">
                        <mat-label>
                          {{ "registration.doctor" | translate }}
                        </mat-label>
                        <mat-select
                          [disabled]="!isEdit"
                          formControlName="doctorId"
                        >
                          <mat-option>-</mat-option>
                          <mat-option
                            *ngFor="let doctor of doctors"
                            [value]="doctor._id"
                          >
                            {{ doctor.first_name }}
                            {{ doctor.last_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-12">
                        <mat-label>
                          {{ "registration.expert" | translate }}
                        </mat-label>
                        <mat-select
                          [disabled]="!isEdit"
                          formControlName="consultantId"
                        >
                          <mat-option>-</mat-option>
                          <mat-option
                            *ngFor="let consultant of consultants"
                            [value]="consultant._id"
                          >
                            {{ consultant.first_name }}
                            {{ consultant.last_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <div class="col">
                        <h2 class="mt-3 mb-4">
                          {{ "form.data_personal_account" | translate }}
                        </h2>
                      </div>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4">
                        <mat-label>{{ "login.login" | translate }}</mat-label>
                        <input
                          matInput
                          formControlName="login"
                          type="text"
                          [readonly]="!isEdit"
                          required
                        />
                      </mat-form-field>
                      <mat-form-field class="col-md-4">
                        <mat-label>{{
                          "login.password" | translate
                        }}</mat-label>
                        <input
                          matInput
                          formControlName="password"
                          type="text"
                          [readonly]="true"
                          required
                        />
                      </mat-form-field>
                      <div
                        class="col-md-4 d-flex align-items-center"
                        *ngIf="isEdit && !isAdmin"
                      >
                        <button
                          class="btn btn-outline-primary btn-sm"
                          (click)="changePassword()"
                        >
                          {{ "form.change_password" | translate }}
                        </button>
                      </div>
                    </div>
                    <div
                      class="row d-flex justify-content-between"
                      *ngIf="isEdit && !isAdmin"
                    >
                      <div class="col-auto">
                        <button
                          class="btn btn-primary"
                          (click)="isEdit = false"
                        >
                          {{ "login.cancel" | translate }}
                        </button>
                      </div>
                      <div class="col-auto">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          (click)="save()"
                        >
                          {{ "form.save" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
