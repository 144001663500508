import { Component, Input, OnInit } from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import { ComplexValueCard } from './complex-value-card-ao.js';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-complex-values-cards-ao',
  templateUrl: './complex-values-cards-ao.component.html',
  styleUrls: [
    './complex-values-cards-ao.component.scss',
    '../../main.component.scss',
  ],
})
export class ComplexValuesCardsAoComponent
  extends OnDestroyMixin
  implements OnInit
{
  readonly GREEN = '#66D850';
  readonly YELLOW = '#EDB900';
  readonly RED = '#FF3300';

  _currentBiosignal: Biosignal;
  PARS: ComplexValueCard;
  IFSP: ComplexValueCard;
  _biosignals;

  ats: any;
  atd: any;
  chss: any;

  @Input() public set biosignals(v) {
    console.log(v);

    this._biosignals = v;
    this.calcAverageValue();
  }
  public get biosignals(): any {
    return this._biosignals;
  }

  @Input() public set currentBiosignal(v) {
    console.log(v);

    this._currentBiosignal = v;
    const PARS_value = this.currentBiosignal?.TimeAnalysis
      ? this.currentBiosignal.TimeAnalysis.PARSint
      : 0;
    const IFSP_value = this.currentBiosignal?.TimeAnalysis
      ? this.currentBiosignal.TimeAnalysis.IFSP
      : 0;
    this.PARS = this.getResultPARS(PARS_value);
    this.IFSP = this.getResultIFSP(IFSP_value);
  }
  public get currentBiosignal(): any {
    return this._currentBiosignal;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.PARS = new ComplexValueCard();
    this.IFSP = new ComplexValueCard();
  }

  calcAverageValue() {
    const PARS_value = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'PARSint')
    );
    const IFSP_value = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'IFSP'
    );
    this.PARS = this.getResultPARS(PARS_value);
    this.IFSP = this.getResultIFSP(+IFSP_value);
    this.atd = this.calculateBiosignalsAverageValue('TimeAnalysis', 'Diastola');
    this.ats = this.calculateBiosignalsAverageValue('TimeAnalysis', 'Systola');
    this.chss = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'HeartRate'
    );
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    if (this.biosignals && this.biosignals.length) {
      let sum = 0,
        count = 0;
      this.biosignals.forEach((biosignal) => {
        if (biosignal[object]) {
          sum += biosignal[object][property] || 0;
          count += 1;
        }
      });
      return sum ? (sum / count).toFixed(2) : '0';
    }
  }

  getResultPARS(PARS_value) {
    switch (PARS_value) {
      // case 0:
      case 1:
      case 2:
        return new ComplexValueCard(PARS_value, this.GREEN, 'norma');
      case 3:
      case 4:
        return new ComplexValueCard(
          PARS_value,
          this.YELLOW,
          'moderate_functional_stress'
        );
      case 5:
      case 6:
        return new ComplexValueCard(
          PARS_value,
          this.YELLOW,
          'expressed_functional_stress'
        );
      case 7:
      case 8:
        return new ComplexValueCard(
          PARS_value,
          this.RED,
          'severe_functional_stress'
        );
      case 9:
      case 10:
        return new ComplexValueCard(PARS_value, this.RED, 'asthenization');
      case 11:
      case 12:
        return new ComplexValueCard(PARS_value, this.RED, 'adaptation_failure');
    }
  }

  getResultIFSP(IFSP_value) {
    if (IFSP_value >= 1 && IFSP_value <= 1.49) {
      return new ComplexValueCard(
        IFSP_value,
        this.GREEN,
        'state_physiological_norm'
      );
    } else if (IFSP_value >= 1.5 && IFSP_value <= 2.49) {
      return new ComplexValueCard(
        IFSP_value,
        this.YELLOW,
        'moderate_functional_stress'
      );
    } else if (IFSP_value >= 2.5 && IFSP_value <= 3.49) {
      return new ComplexValueCard(
        IFSP_value,
        this.YELLOW,
        'expressed_functional_stress'
      );
    } else if (IFSP_value >= 3.5 && IFSP_value <= 4.49) {
      return new ComplexValueCard(IFSP_value, this.RED, 'premorbid_state');
    } else if (IFSP_value >= 4.5 && IFSP_value <= 50) {
      return new ComplexValueCard(
        IFSP_value,
        this.RED,
        'probable_presence_of_pathology'
      );
    }
  }
}
