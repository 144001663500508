import {AuthService} from './auth/auth.service';
import {RoleService} from './role.service';
import {AuthGuard} from './auth/auth.guard';

export const SERVICES = [
  AuthService,
  RoleService
];

export const GUARDS = [
  AuthGuard
];

