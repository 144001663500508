<ng-container *ngIf="oscillogramDataFirst">
  <app-ao-raw-signal-chart
    id="graph"
    *ngIf="ACTIVE_GRAPH == GraphType.AO || !ACTIVE_GRAPH"
    [setOscillogramData]="oscillogramDataFirst"
  ></app-ao-raw-signal-chart>
  <app-ecg-raw-signal-chart
    [initData]="initData"
    [setOscillogramData]="oscillogramData"
    id="graph"
    *ngIf="ACTIVE_GRAPH == GraphType.ECG"
  ></app-ecg-raw-signal-chart>
  <app-wp-raw-signal-chart
    [setOscillogramData]="oscillogramDataFirst"
    id="graph"
    *ngIf="ACTIVE_GRAPH == GraphType.WP || ACTIVE_GRAPH == GraphType.CP "
  ></app-wp-raw-signal-chart>
</ng-container>
