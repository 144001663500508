import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-vessels-tab',
  templateUrl: './vessels-tab.component.html',
  styleUrls: ['./vessels-tab.component.scss']
})
export class VesselsTabComponent implements OnInit {
  @Input() set initData(value) {
    if (value) {
      this.isInit = true;
    }
  }
  isInit: boolean = false;
  @Input() biosignalsData = [];
  constructor() {
  }

  ngOnInit(): void { }
}
