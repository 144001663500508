<form [formGroup]="myForm" (ngSubmit)="onSubmitForm()">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "registration.surname" | translate }}</mat-label>
        <input matInput formControlName="last_name" required />
      </mat-form-field>

      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "registration.name" | translate }}</mat-label>
        <input matInput formControlName="first_name" required />
      </mat-form-field>

      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "registration.secondName" | translate }}</mat-label>
        <input matInput formControlName="second_name" />
      </mat-form-field>

      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "registration.birthDate" | translate }}</mat-label>
        <input
          required
          matInput
          [matDatepicker]="birthDate"
          formControlName="birth_date"
        />
        <mat-datepicker-toggle matSuffix [for]="birthDate">
        </mat-datepicker-toggle>
        <mat-datepicker #birthDate touchUi></mat-datepicker>
      </mat-form-field>

      <div class="col mt-1 p-0">
        <mat-label>
          {{ "registration.sex" | translate }}
          <span class="mark">*</span>
        </mat-label>
        <mat-radio-group class="row mt-1" (change)="setGender($event.value)">
          <mat-radio-button value="MALE" class="col-md">
            {{ "registration.man" | translate }}
          </mat-radio-button>
          <mat-radio-button value="FEMALE" class="col-md">
            {{ "registration.woman" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-4">
      <mat-form-field
        class="col mt-1 p-0"
        *ngIf="!medOrganisationInfoNoAvailable"
      >
        <mat-label>
          {{ "registration.medOrganization" | translate }}
        </mat-label>
        <mat-select
          formControlName="medicalInstitution"
          (selectionChange)="selectMedOrganization($event.value)"
        >
          <mat-option>-</mat-option>
          <mat-option
            *ngFor="let item of medicalInstitutions"
            [value]="item._id"
          >
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container formArrayName="educations">
        <mat-form-field
          class="col mt-1 p-0"
          *ngFor="let item of educations.controls; let educationIndex = index"
          [formGroupName]="educationIndex"
        >
          <mat-label>
            {{ "form.education" | translate }}
          </mat-label>
          <input matInput formControlName="institution_name" />
        </mat-form-field>
      </ng-container>

      <ng-container formArrayName="qualifications">
        <mat-form-field
          class="col mt-1 p-0"
          *ngFor="
            let item of qualifications.controls;
            let qualificationIndex = index
          "
          [formGroupName]="qualificationIndex"
        >
          <mat-label>
            {{ "form.qualification" | translate }}
          </mat-label>
          <mat-select formControlName="type">
            <mat-option>-</mat-option>
            <mat-option
              *ngFor="let item of qualificationTypeService.qualification_type"
              [value]="item.value"
            >
              {{ item | lang }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container formArrayName="specialities">
        <mat-form-field
          class="col mt-1 p-0"
          *ngFor="
            let item of specialities.controls;
            let specialitieIndex = index
          "
          [formGroupName]="specialitieIndex"
        >
          <mat-label>
            {{ "form.speciality" | translate }}
          </mat-label>
          <mat-select formControlName="speciality">
            <mat-option>-</mat-option>
            <mat-option
              *ngFor="let item of specialtyLevelService.specialty_level"
              [value]="item.value"
            >
              {{ item | lang }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container formArrayName="science_degree">
        <mat-form-field
          class="col mt-1 p-0"
          *ngFor="
            let item of science_degree.controls;
            let scienceDegreeIndex = index
          "
          [formGroupName]="scienceDegreeIndex"
        >
          <mat-label>
            {{ "form.degree" | translate }}
          </mat-label>
          <mat-select formControlName="degree">
            <mat-option>-</mat-option>
            <mat-option
              *ngFor="let item of degreeService.degree"
              [value]="item.value"
            >
              {{ item | lang }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="col-md-4">
      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "registration.email" | translate }}</mat-label>
        <input matInput formControlName="email" required type="email" />
      </mat-form-field>
      <ng-container formArrayName="phones">
        <mat-form-field
          class="col mt-1 p-0"
          *ngFor="let item of phones.controls; let phonesIndex = index"
          [formGroupName]="phonesIndex"
        >
          <mat-label>
            {{ "registration.telephone" | translate }}
          </mat-label>
          <input matInput formControlName="number" />
        </mat-form-field>
      </ng-container>

      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "login.login" | translate }}</mat-label>
        <input matInput formControlName="login" required />
        <mat-icon matPrefix svgIcon="icon-person" class="icon mr-1"> </mat-icon>
      </mat-form-field>

      <mat-form-field class="col mt-1 p-0">
        <mat-label>{{ "login.password" | translate }}</mat-label>
        <input
          matInput
          formControlName="password"
          required
          [type]="isVisiblePassword ? 'text' : 'password'"
        />
        <mat-icon matPrefix svgIcon="icon-lock" class="icon mr-1"> </mat-icon>
        <mat-icon
          (click)="isVisiblePassword = !isVisiblePassword"
          matSuffix
          class="icon mr-1"
          fontIcon="{{ isVisiblePassword ? 'bi-eye' : 'bi-eye-slash' }}"
        >
        </mat-icon>
        <mat-hint *ngIf="myForm.get('password').hasError('required')">
          {{ "registration.password_validation" | translate }}
        </mat-hint>
        <mat-error *ngIf="myForm.get('password').hasError('passwordStrength')">
          {{ myForm.get("password").errors["passwordStrength"] | lang }}
        </mat-error>
      </mat-form-field>
      <div class="col p-0 mt-4">
        <button class="btn btn-primary fill w-100" type="submit">
          {{ "login.register" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
