<div class="lang-container lang-secondary">
  <button
    class="lang-item"
    [ngClass]="{ 'lang-item-active': currentLang == Langs.EN }"
    (click)="changeLang(Langs.EN)"
  >
    EN
  </button>
  <div class="lang-divider"></div>
  <button
    class="lang-item"
    [ngClass]="{ 'lang-item-active': currentLang == Langs.UA }"
    (click)="changeLang(Langs.UA)"
  >
    UA
  </button>
  <div class="lang-divider"></div>
  <button
    class="lang-item"
    [ngClass]="{ 'lang-item-active': currentLang == Langs.PL }"
    (click)="changeLang(Langs.PL)"
  >
    PL
  </button>
</div>
