import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { EquipmentService } from 'src/app/services/equipment.service';
import { SortEvent } from '../../measurements-settings/interfaces/event.interface';
import { SortableHeader } from 'src/app/directives/sortable-header/sortable-header.directive';
import { EquipmentName } from 'src/app/enums/equipment.enum';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-device-current-settings',
  templateUrl: './device-current-settings.component.html',
  styleUrls: ['./device-current-settings.component.scss'],
})
export class DeviceCurrentSettingsComponent
  extends OnDestroyMixin
  implements OnInit
{
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  tableData = [];
  defaultTable = [];

  allCheck: boolean = false;
  isDisabled: boolean = false;
  tableBusy: boolean = false;

  equipmentList: EquipmentName[] = [];
  equipmentName: EquipmentName = EquipmentName.VAT;
  EquipmentName = EquipmentName;

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  ngOnInit(): void {
    this.selectEquipment(this.equipmentName);
    for (const item of Object.keys(EquipmentName))
      this.equipmentList.push(EquipmentName[item]);
  }

  selectEquipment(name) {
    this.equipmentService
      .getEquipments(name)
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => {
        this.tableData = v.map((val) => {
          if (!val.options) val.options = {};
          if (!val.identifier) val.identifier = {};
          return val;
        });
      });
  }

  selectCheckBox() {
    this.isDisabled = this.allCheck ? false : true;
    this.tableData.map(
      (element) => (element.checked = this.allCheck ? true : false)
    );
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      this.tableData = this.defaultTable;
    } else {
      if (direction === 'asc') {
        if (column === 'date') {
          this.tableData = [...this.tableData].sort(
            (a: Date, b: Date) =>
              new Date(a[column]).getTime() - new Date(b[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => a[column] - b[column]
          );
        }
      } else if (direction === 'desc') {
        if (column === 'date') {
          this.tableData = [...this.tableData].sort(
            (a: Date, b: Date) =>
              new Date(b[column]).getTime() - new Date(a[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => b[column] - a[column]
          );
        }
      }
    }
  }
}
