<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="form-container card-box" *ngIf="successRegistration">
    <div class="icon-box">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="assets/img/icons/icon-3.svg#IconCheck"></use>
      </svg>
    </div>
    <p class="text-center">
      {{ "registration.success_registration" | translate }}
    </p>
  </div>
  <div class="form-container" *ngIf="!successRegistration">
    <div class="row">
      <div class="col-md-6">
        <h1>
          {{
            (userType === RoleTypeEnum.Patient
              ? "registration.header_as_patient"
              : "registration.header_as_doctor"
            ) | translate
          }}
        </h1>
      </div>
      <div class="col-md-6">
        <p>
          <mark class="mark">*</mark> {{ "registration.mandatory" | translate }}
        </p>
      </div>
    </div>
    <ng-container *ngIf="userType === RoleTypeEnum.Patient">
      <app-role-registration-patient
        (registration)="onSubmitForm($event)"
      ></app-role-registration-patient>
    </ng-container>
    <ng-container *ngIf="userType === RoleTypeEnum.Doctor">
      <app-role-registration-doctor
        (registration)="onSubmitForm($event)"
      ></app-role-registration-doctor>
    </ng-container>
  </div>
</div>
