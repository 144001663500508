<div class="disease-table-block">
  <ng-container *ngIf="false; else minnesotaSyndromicTables">
    <div class="disease-table-block-header">
      <h4 class="disease-table-block-header-title">
        {{ "calculation.disease_title" | translate }}
      </h4>
      <div class="disease-table-block-header-subtitle">
        {{ "calculation.disease_subtitle" | translate }}
      </div>
    </div>
    <table class="table m-0">
      <thead>
      <tr class="table-title">
        <th class="table-title-text">
          {{ "calculation.disease_name" | translate }}
        </th>
        <th class="table-title-text">
          {{ "calculation.disease_probability" | translate }}
        </th>
      </tr>
      </thead>
      <tbody *ngIf="biosignals?.length">
      <tr [ngClass]="AFib_marker == 'rhythm' ? 'greenClass' : ''">
        <td class="table-cell">{{ "calculation.sinys_rhythm" | translate }}</td>
        <td class="table-cell">{{ AFib_marker == "rhythm" ? "70%" : "0%" }}</td>
      </tr>
      <tr
        [ngClass]="
          AFib_marker == 'fibrillation' || AFib_marker_1 == 'Afib'
            ? 'redClass'
            : ''
        "
      >
        <td class="table-cell">
          {{ "calculation.atrial_fibrillation" | translate }}
        </td>
        <td class="table-cell">
          <ng-container *ngIf="AFib_marker_1 == 'Afib'">
            {{ AFib_marker == "fibrillation" ? "95%" : "80%" }}
          </ng-container>
          <ng-container *ngIf="AFib_marker_1 == 'Normal'">
            {{ AFib_marker == "fibrillation" ? "30%" : "0%" }}
          </ng-container>
        </td>
      </tr>
      <tr [ngClass]="AFib_marker == 'flutter' ? 'yellowClass' : ''">
        <td class="table-cell">
          {{ "calculation.atrial_flutter" | translate }}
        </td>
        <td class="table-cell">
          {{ AFib_marker == "flutter" ? "50%" : "0%" }}
        </td>
      </tr>
      <tr [ngClass]="AFib_marker == 'arrhythmia' ? 'yellowClass' : ''">
        <td class="table-cell">
          {{ "calculation.sinys_arrythmia" | translate }}
        </td>
        <td class="table-cell">
          {{ AFib_marker == "arrhythmia" ? "50%" : "0%" }}
        </td>
      </tr>
      <tr [ngClass]="displayRiskColor(averageValues.ArrhythmiaDegree / 3.2)">
        <td class="table-cell">
          {{ "calculation.disease_arrhythmia" | translate }}
        </td>
        <td class="table-cell">
          {{
            +averageValues.ArrhythmiaDegree == 1
              ? 0
              : +averageValues.ArrhythmiaDegree
                ? +(
                  (averageValues.ArrhythmiaDegree - 1) * 20 | number : "1.0-0"
                ) > 0
                  ? ((averageValues.ArrhythmiaDegree - 1) * 20 | number : "1.0-0")
                  : 0
                : 0
          }}%
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<ng-template #tipContent let-greeting="greeting">
  <p class="m-0">{{ "calculation.report_recomm" | translate }}</p>
  <a href="http://hyper.ahajournals.org/content/42/6/1206.long" target="_blank">
    Перейти за посиланням
  </a>
</ng-template>

<ng-template #minnesotaSyndromicTables>
  <div class="m-s-tables-wrapper">
    <div [style.width]="'100%'">
      <h4>Мінесотське кодування</h4>
      <table class="expert-table-style-3" [style.width]="'100%'">
        <thead>
        <tr>
          <th>Розділ</th>
          <th>Опис</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Паттерни Q і QS, 1-2-8</td>
          <td>Початкова амплітуда R зменшується до 2 мм або менше в кожному комплексі
            (і відсутність кодів 3-2, 7-1-1, 7-2-1 або 7-3) між V5 і V6.
            (Усі комплекси у відведенні V5 повинні мати початковий R > 2 мм.)</td>
        </tr>
        <tr>
          <td>Дефект провідності шлуночків, 7-6</td>
          <td>Неповна блокада лівої ніжки пучка Гіса.
            (Не кодувати при наявності будь - якої кодованої Q-або QS-хвилі.)
            Тривалість QRS ≥ 0,10 сек і <0,12 в більшості комплексів кожного з відведень I,
            aVL і V5 або V6.</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div [style.width]="'100%'">
      <h4>Синдромальне кодування</h4>
      <table class="expert-table-style-3" [style.width]="'100%'">
        <thead>
        <tr>
          <th>Ідентифікатор</th>
          <th>Опис</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>44</td>
          <td>Шлуночкова бігемінія</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
