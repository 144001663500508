<form [formGroup]="myForm">
  <div class="col-md-12 mb-3">
    <mat-form-field class="col-md-4">
      <mat-label>{{ "measurement.package_size" | translate }}</mat-label>
      <input formControlName="PackageSize" matInput type="number" />
      <mat-hint>{{ "measurement.in_bytes" | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ "measurement.pumping_speed" | translate }}</mat-label>
      <input formControlName="InfSp" matInput type="number" />
      <mat-hint>{{ "measurement.mmHg_sec" | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ "measurement.cuff_type" | translate }}</mat-label>
      <input formControlName="CuffSize" matInput type="number" />
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <div class="col-md-12">
      <h4>{{ "measurement.determination_measurement_modes" | translate }}</h4>
    </div>
    <mat-form-field class="col-md-6">
      <mat-label>{{ "measurement.day_begins" | translate }}</mat-label>
      <input
        formControlName="DayStart"
        matInput
        type="time"
        style="color-scheme: light"
      />
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ "measurement.night_begins" | translate }}</mat-label>
      <input formControlName="NightStart" matInput type="time" />
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <div class="col-md-12">
      <h4>{{ "measurement.should_transmit_signal" | translate }}</h4>
    </div>
    <mat-radio-group
      class="row col-md-12"
      formControlName="shouldTransmitSignal"
    >
      <mat-radio-button [value]="0" class="col-auto">0</mat-radio-button>
      <mat-radio-button [value]="1" class="col-auto">1</mat-radio-button>
    </mat-radio-group>
    <mat-hint class="col-md-12">
      {{ "measurement.parameter" | translate }}
      shouldTransmitSignal
    </mat-hint>
  </div>
  <div class="col-md-6 mt-3">
    <div class="col-md-12">
      <h4>
        {{ "measurement.determination_measurement_frequency" | translate }}
      </h4>
    </div>
    <mat-form-field class="col-md-6">
      <mat-label>{{ "measurement.day" | translate }}</mat-label>
      <input formControlName="DayPeriod" matInput type="number" />
      <mat-hint>
        {{ "measurement.frequency_in_minutes" | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ "measurement.night" | translate }}</mat-label>
      <input formControlName="NightPeriod" matInput type="number" />
      <mat-hint>
        {{ "measurement.frequency_in_minutes" | translate }}
      </mat-hint>
    </mat-form-field>
  </div>
</form>
