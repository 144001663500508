<nav id="subnav" class="navbar navbar-expand-lg py-0 navbar-dark">
  <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill">
    <li ngbNavItem>
      <a ngbNavLink>{{'form.general' | translate}}</a>
      <ng-template ngbNavContent>
        <app-consultant [data]="data?.doctor"></app-consultant>
      </ng-template>
    </li>
    <li ngbNavItem [disabled]="!data">
      <a ngbNavLink>{{'form.schedule' | translate}}</a>
      <ng-template ngbNavContent>
        <app-schedule [data]="data"></app-schedule>
      </ng-template>
    </li>
  </ul>
</nav>
<div [ngbNavOutlet]="nav"></div>
