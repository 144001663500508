
<!-- 
<div #ContainerRef class="w-100 h-100" (click)="fullEcgGraph(oscillogramData)">
  <ng-container *ngIf="oscillogramData">
    <ngx-charts-line-chart -->





<ng-container
  *ngIf="oscillogramData"

>
  <div
    #ContainerRef
    class="w-100 h-100"
    (click)="fullEcgGraph(oscillogramData)"
  >
    <ngx-charts-line-chart
      *ngIf="oscillogramData"
      [view]="[ContainerRef.offsetWidth, ContainerRef.offsetHeight]"
      [xAxis]="true"
      [yAxis]="true"
      [timeline]="false"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [tooltipDisabled]="true"
      [results]="rawSignalData"
      [xAxisTickFormatting]="formatAxisTickDateX"
      [yAxisTickFormatting]="formatAxisTickDateY"
      [autoScale]="true"
      [trimXAxisTicks]="false"
      [trimYAxisTicks]="false"
      [xAxisLabel]="'t, s'"
      [yAxisLabel]="'mV'"
      [xAxisTicks]="xAxisTicks"
      [rotateXAxisTicks]="false"
    >
    </ngx-charts-line-chart>
    <!-- [xAxisTicks]="xAxisTicks"
    [yAxisTicks]="yAxisTicks" -->
  <!-- </ng-container>
</div> -->


  </div>
</ng-container>
