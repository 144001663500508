import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import { MeasurementsDataUpdateService } from '../../../../services/measurements-data-update.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';

@Component({
  selector: 'app-complex-values-cards',
  templateUrl: './complex-values-cards.component.html',
  styleUrls: [
    './complex-values-cards.component.scss',
    '../main.component.scss',
  ],
})
export class ComplexValuesCardsComponent
  extends OnDestroyMixin
  implements OnInit
{
  currentBiosignal: Biosignal;
  biosignals;
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;

  constructor(
    private selectStatisticsChartPointService: MeasurementsDataUpdateService,
    public dataTypeService: DataTypeService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
    this.selectStatisticsChartPointService.updateMeasurementsRange$
      .pipe(untilComponentDestroyed(this))
      .subscribe((biosignalsRange) => {
        this.biosignals = biosignalsRange.rangeBiosignals;
      });
    this.selectStatisticsChartPointService.selectedMeasurement$
      .pipe(untilComponentDestroyed(this))
      .subscribe((currentBiosignal: Biosignal) => {
        this.currentBiosignal = currentBiosignal;
      });
  }
}
