import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { RoleTypeEnum } from '../model/enums/role.enum';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})

export class RegistrationComponent implements OnInit {
  role;
  RoleTypeEnum: typeof RoleTypeEnum = RoleTypeEnum;
  protected inner: { role?: string } = {};

  constructor(private roleService: RoleService, private router: Router) {}

  ngOnInit(): void {
    this.roleService.role$.subscribe((resp) => {
      this.role = resp;
      if (!resp) {
        this.inner.role = null;
        this.router.navigate(['/auth/registration']);
      }
    });
  }

  selectRole(role) {
    this.inner.role = role;
  }

  nextStep() {
    this.role = this.inner.role;
    this.router.navigate(['/auth/registration', this.role]);
  }
}
