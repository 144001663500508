export const Systola = [
  {
    minValue: 90,
    maxValue: 119,
    description: {
      ua: 'Не реєструється',
      en: 'Not registered',
      pl: 'Nie zarejestrowany',
    },
  },
  {
    minValue: 120,
    maxValue: 139,
    description: {
      ua: 'Предгіпертензія',
      en: 'Prehypertension',
      pl: 'Stan przednadciśnieniowy',
    },
  },
  {
    minValue: 140,
    maxValue: 159,
    description: {
      ua: 'Гіпертензія I стадії',
      en: 'Hypertension stage I',
      pl: 'Nadciśnienie tętnicze I stopnia',
    },
  },
  {
    minValue: 160,
    maxValue: 1000,
    description: {
      ua: 'Гіпертензія II стадії',
      en: 'Hypertension stage II',
      pl: 'Nadciśnienie tętnicze II stopnia',
    },
  },
];

export const Diastola = [
  {
    minValue: 60,
    maxValue: 79,
    description: {
      ua: 'Не реєструється',
      en: 'Not registered',
      pl: 'Nie zarejestrowany',
    },
  },
  {
    minValue: 80,
    maxValue: 89,
    description: {
      ua: 'Предгіпертензія',
      en: 'Prehypertension',
      pl: 'Stan przednadciśnieniowy',
    },
  },
  {
    minValue: 90,
    maxValue: 99,
    description: {
      ua: 'Гіпертензія I стадії',
      en: 'Hypertension stage I',
      pl: 'Nadciśnienie tętnicze I stopnia',
    },
  },
  {
    minValue: 100,
    maxValue: 1000,
    description: {
      ua: 'Гіпертензія II стадії',
      en: 'Hypertension stage II',
      pl: 'Nadciśnienie tętnicze II stopnia',
    },
  },
];
