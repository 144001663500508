import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Pipe({
  name: 'lang',
})
export class TranslatePipe implements PipeTransform {
  public language;

  constructor(public authService: AuthService) {
    this.language = this.authService.getLanguage();
  }

  transform(value: any): any {
    if (value) {
      return this.language == 'ua'
        ? value.ua
        : this.language == 'en'
        ? value.en
        : this.language == 'pl'
        ? value.pl
        : value;
    }
  }
}
