<header>
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-lg-6 col-md-4 col-4">
        <div class="logo">
          <img
            [src]="'registration.logo_header_src' | translate"
            alt="Oranta"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-8 col-8">
        <div class="row justify-content-end align-items-center">
          <div class="col-md-auto col-6">
            <app-lang></app-lang>
          </div>
          <div class="col-md-auto col-6">
            <div class="row flex-column">
              <button
                type="button"
                class="btn btn-primary col-auto my-2"
                (click)="demoSignIn('1', '1')"
              >
                {{ "login.demo" | translate }}
                {{ "measurement.patient" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-primary col-auto"
                (click)="demoSignIn('2', 'Dmytro11!!')"
              >
                {{ "login.demo" | translate }}
                {{ "measurement.doctor" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
