import { Component, Input, OnInit } from '@angular/core';
import { OscillogramData } from 'src/app/models/oscillogram-data';
import { FullWpRawChartComponent } from './full-wp-raw-chart/full-wp-raw-chart.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-wp-raw-signal-chart',
  templateUrl: './wp-raw-signal-chart.component.html',
  styleUrls: ['./wp-raw-signal-chart.component.scss'],
})
export class WpRawSignalChartComponent implements OnInit {
  @Input() set setOscillogramData(value: OscillogramData) {
    if (value) {
      this.oscillogramData = value;
      this.update();
    }
  }
  oscillogramData: OscillogramData;
  rawSignalData: any;

  @Input() set initData(value) {
    if (value) {
      setTimeout(() => {
        this.update();
      }, 200);
    }
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  update(): void {
    if (this.oscillogramData) {
      const timePoints = this.oscillogramData.timePoints;
      const valuePoints = this.oscillogramData.valuePoints;
      const series = [];

      for (let i = 0; i < timePoints.length; i += 1) {
        series.push({
          name: timePoints[i],
          value: valuePoints[i],
        });
      }
      this.rawSignalData = [
        {
          name: 'signal1',
          series,
        },
      ];
    }
  }

  fullPulsogramGraph(oscillogramData) {
    const dialogRef = this.dialog.open(FullWpRawChartComponent, {
      width: '90vw',
    });

    dialogRef.componentInstance.data = this.oscillogramData;
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
