import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RoleTypeEnum } from '../auth/model/enums/role.enum';
import { AuthService } from './../../services/auth/auth.service';
import { NAV_MENU } from '../../services/dictionaries/constants/nav-menu';
import { Router } from '@angular/router';
import { ChangeLangDetectorComponent } from '../global-components/change-lang-detector/change-lang-detector.component';
@Component({
  selector: 'app-download',
  templateUrl: './measurements-data.component.html',
  styleUrls: ['./measurements-data.component.scss'],
})
export class MeasurementsDataComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  active: number = 0;
  showNav: boolean = false;
  navMenu: Array<any> = [];
  RoleType: typeof RoleTypeEnum = RoleTypeEnum;
  constructor(
    public authService: AuthService,
    private router: Router,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
    this.navMenu = NAV_MENU;
  }

  ngOnInit(): void {
    this.authService.language$.subscribe((v) => {
      this.changLang();
    });
    const url = this.router.url;
    const fragment = url.split('/');
    switch (fragment[fragment.length - 1]) {
      case 'measurements-setting':
        this.active = 3;
        break;
      case 'mediapipe':
        this.active = 2;
        break;
      case 'user-device-setting':
        this.active = 1;
        break;
      case 'measurements-upload':
        this.active = 0;
        break;
    }
  }
}
