import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GraphType } from '../enums/graph_type.enum';

@Injectable({
  providedIn: 'root',
})
export class DataTypeService {
  public selectedType$: Observable<String>;
  private selectedTypeSubject: Subject<String>;

  constructor(private http: HttpClient) {
    this.selectedTypeSubject = new Subject<String>();
    this.selectedType$ = this.selectedTypeSubject.asObservable();
  }

  changeType(type: GraphType): void {
    this.selectedTypeSubject.next(type);
  }
}
