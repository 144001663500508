import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { EquipmentService } from 'src/app/services/equipment.service';

@Component({
  selector: 'app-item-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.scss'],
})
export class ItemRowComponent extends OnDestroyMixin implements OnInit {
  @Input() element;
  @Input() index;
  constructor(
    private equipmentService: EquipmentService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {}

  saveBiosignal(el) {
    this.equipmentService
      .createEquipmentSetting(el)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (v) => {
          this.translate
            .get(['alerts'])
            .pipe(untilComponentDestroyed(this))
            .subscribe((t) => {
              this.toastr.success(t.alerts.warning_4);
            });
        },
        (err: any) => {
          this.toastr.error(err.message);
        }
      );
  }
}
