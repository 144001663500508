import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { EquipmentName } from 'src/app/enums/equipment.enum';

@Component({
  selector: 'app-bp2-bp2a-setting',
  templateUrl: './bp2-bp2a-setting.component.html',
  styleUrls: ['./bp2-bp2a-setting.component.scss'],
})
export class Bp2Bp2aSettingComponent extends OnDestroyMixin implements OnInit {
  myForm: FormGroup = new FormGroup({});
  equipmentName = '';
  EquipmentName = EquipmentName;

  @Output() changeSetting = new EventEmitter();
  @Input() set setting(s) {
    if (s) {
      this.myForm.patchValue(s);
    }
  }
  @Input() set equipment(s) {
    if (s) {
      this.myForm = new FormGroup({});
      this.equipmentName = s;
      this.reactiveForm();
    }
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.reactiveForm();
    for (const field of Object.keys(this.myForm.controls))
      this.myForm.controls[field].valueChanges
        .pipe(untilComponentDestroyed(this))
        .subscribe((v) => {
          this.changeSetting.emit(this.myForm);
        });
  }

  reactiveForm() {
    if (this.equipmentName == EquipmentName.ВР2)
      this.myForm = this.fb.group({
        StartRegistr: [],
        FinishRegistr: [],
        RequestFreqOsc: [],
        SampleFreqRealTimeOsc: [],
        MesurDuration: [],
        RequestFreqECG: [],
        SampleFreqRealTimeECG: [],
        SampleFreqMemoryECG: [],
        EquipmentName: ['', Validators.required],
        NightStart: [],
        DayStart: [],
      });
    else if (this.equipmentName == EquipmentName.ВР2А)
      this.myForm = this.fb.group({
        StartRegistr: [],
        FinishRegistr: [],
        RequestFreqOsc: [],
        SampleFreqRealTimeOsc: [],
        EquipmentName: ['', Validators.required],
      });
  }
}
