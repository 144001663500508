import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from '../../measurements-data/services/patient.service';

@Component({
  selector: 'app-item-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.scss'],
})
export class ItemRowComponent implements OnInit {
  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  @Output() selected: EventEmitter<any> = new EventEmitter();
  myForm: FormGroup;
  element: any;
  index: number;
  isEdit: boolean = false;
  expand: boolean = false;
  @Input() page;
  @Input() itemsPerPage;
  @Input() set data(value) {
    if (value) {
      this.element = value;
    }
  }
  @Input() set dataIndex(value) {
    if (value) {
      this.index = value;
    }
  }
  @Input() set dataExpand(value) {
    this.expand = value;
  }

  constructor(
    private biosignalsService: BiosignalsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.reactiveForm();
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      Systola: [],
      Diastola: [],
      HeartRate: [],
      BloodGlucose: [],
    });
  }

  saveBiosignal() {
    this.isEdit = false;
    this.loaded.emit(true);
    this.biosignalsService
      .updateBiosignal(this.element.id, this.myForm.value)
      .subscribe(
        (resp) => {
          if (resp) {
            this.translate.get(['alerts']).subscribe((t) => {
              this.toastr.success(t.alerts.warning_5);
            });
            this.changed.emit(resp);
            this.loaded.emit(false);
          }
        },
        (err: any) => {
          this.loaded.emit(false);
          this.toastr.error(err.message);
        }
      );
  }

  selectCheckBox(event, id): void {
    this.selected.emit(id);
  }

  editBiosignal() {
    this.isEdit = true;
    this.myForm.patchValue(this.element);
  }

  CheckSpaces(str) {
    return str?.trim();
  }
  selectPatient(data: string) {
    this.patientService.getPatient(data).subscribe((p: any) => {
      this.patientService.setPatient = p;
      localStorage.setItem('selectedPatient', JSON.stringify(p));
    });
    if (localStorage.getItem('rufierTest') !== null) {
      localStorage.removeItem('rufierTest');
    }
  }
}
