<div class="w-100">
  <div mat-icon-button mat-dialog-close class="text-right">
    <mat-icon svgIcon="close"></mat-icon>
  </div>
</div>
<mat-dialog-content>
  <app-single-scrollable-chart
  [points_values]="oscillogramData"
    [yAxis]="yAxisTicks"
    [yMin]="timePointsInterval.minY"
    [yMax]="timePointsInterval.maxY"
  ></app-single-scrollable-chart>

  <ng-container *ngFor="let item of rawSignalData; let i = index">
    <app-multi-ten-seconds-charts
      [item]="item"
      [yAxis]="yAxisTicks"
      [yMin]="timePointsInterval.minY"
      [yMax]="timePointsInterval.maxY"
      [data]="data"
      [ecgWidth]="ecgWidth"
      [ecgHeight]="ecgHeight"
      [i]="i"
    ></app-multi-ten-seconds-charts>
  </ng-container>
</mat-dialog-content>
<!-- <mat-dialog-actions>
 <button mat-button [mat-dialog-close]="true" tabindex="1">Install</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button> 
</mat-dialog-actions>-->
