import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
  ) { }

  postFunctionTests(data) {
    return this.http
      .post<any>(`${environment.apiUrl}/function-test/create`, data)

  }

  getFunctionTests(data) {
    return this.http
      .post<any>(`${environment.apiUrl}/function-test/get/filters`, { filters: data })

  }

  updateFunctionTests(id, data) {
    return this.http
      .patch<any>(`${environment.apiUrl}/function-test/update/${id}`, data)

  }

  deleteFunctionTests(id) {
    return this.http
      .delete<any>(`${environment.apiUrl}/function-test/delete/${id}`)

  }

  // postOtherTests(data) {
  //   return this.http
  //     .post<any>('/api/v1/efficiency/other/create', data)
  //
  // }
  //
  // getOtherTests(data) {
  //   return this.http
  //     .post<any>('/api/v1/efficiency/other/get/filters', { filters: data })
  //
  // }
  //
  // updateOtherTests(id, data) {
  //   return this.http
  //     .patch<any>(`/api/v1/efficiency/other/update/${id}`, data)
  //
  // }
  //
  // deleteOtherTests(id) {
  //   return this.http
  //     .delete<any>(`/api/v1/efficiency/other/delete/${id}`)
  //
  // }
}
