import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColorSchemaType, DataPoints, HistogramType } from './models/histogram';
import { ButtonType } from './models/button';
import { BUTTON_DATA } from '../../../../services/dictionaries/constants/button-data';
import { Biosignal } from '../../../../models/biosignal';
import { MeasurementsDataUpdateService } from '../../../../services/measurements-data-update.service';
import { TranslatePipe } from './../../../../pipes/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { HISTOGRAM_AREA } from 'src/app/services/dictionaries/constants/histogram-area';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';

@Component({
  selector: 'app-block2-charts',
  templateUrl: './histograms-block2.component.html',
  styleUrls: ['./histograms-block2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslatePipe],
})
export class HistogramsBlock2Component
  extends ChangeLangDetectorComponent
  implements OnInit, AfterViewChecked
{
  container: ElementRef;
  @ViewChild('container', { static: false })
  set setContainer(directive: any) {
    if (directive) {
      setTimeout(() => {
        this.container = directive;
        if (!this.isFirst) this.firstUpdate();
      }, 0);
    }
  }
  @ViewChild('chartWrap', { static: false }) chartWrap: ElementRef;
  @Input() histogram: HistogramType;
  view: any[] = [420, 280];
  buttonList: ButtonType[] = [];
  histogramList: HistogramType[] = [];
  filterHistogramData: Biosignal[];
  histogramData: DataPoints[] = [];
  ColorSchemaType: ColorSchemaType;
  checkedHistograms = [];
  rangeBiosignals: Biosignal[] = [];
  isMobile: boolean;
  isFirst: boolean = false;
  ACTIVE_GRAPH: GraphType;
  constructor(
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    private ref: ChangeDetectorRef,
    private langPipe: TranslatePipe,
    public auth: AuthService,
    public dataTypeService: DataTypeService
  ) {
    super(ref);
    this.dataTypeService.selectedType$.subscribe((v: any) => {
      if (v && v !== this.ACTIVE_GRAPH) {
        this.init(v);
      }
    });
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.measurementsDataUpdateService.updateMeasurementsRange$.subscribe(
      (biosignals) => {
        this.rangeBiosignals = biosignals.rangeBiosignals;
        this.update();
      }
    );
    if (window.innerWidth <= 767) {
      this.isMobile = true;
    }

    this.init(GraphType.AO);
  }

  ngAfterViewChecked() {
    if (this.chartWrap) {
      const width = this.chartWrap.nativeElement.offsetWidth;
      this.view = [width, 280];
    }
  }

  init(graph_type: GraphType) {
    this.histogramList.forEach((name) => this.deleteElement(name));
    this.histogramList = [...[]];
    this.checkedHistograms = [...[]];
    this.buttonList = [...[]];
    this.isFirst = false;
    this.ACTIVE_GRAPH = graph_type;
    let buttonDataTemp = BUTTON_DATA.filter((el) =>
      el.graphType.includes(graph_type)
    );
    this.buttonList = JSON.parse(JSON.stringify(buttonDataTemp));
    this.ref.detectChanges();
  }
  update(): void {
    this.checkedHistograms.forEach((elem) => {
      this.fastReloadHistogram(elem.button, this.rangeBiosignals);
    });
  }

  onHistogramCheckButtonClick(event, button): void {
    const buttonEl = event.target;
    const containerEl = this.container.nativeElement;
    if (
      this.histogramList.length < 4 &&
      buttonEl.classList.contains('button-block__itemPassive')
    ) {
      this.checkedHistograms.push({ event, button });
      this.histogramData = [];
      this.generateHistogramData(button.histogramName, this.rangeBiosignals);
      buttonEl.classList.toggle('button-block__itemPassive');
      buttonEl.classList.toggle('button-block__itemActive');
      this.drawChart(button);
    } else if (
      this.histogramList.length > 0 &&
      buttonEl.classList.contains('button-block__itemActive')
    ) {
      this.checkedHistograms = this.checkedHistograms.filter(
        (element) => element.button !== button
      );
      buttonEl.classList.toggle('button-block__itemActive');
      buttonEl.classList.toggle('button-block__itemPassive');
      containerEl.children[button.histogramName].innerHTML =
        this.langPipe.transform(button.histogramCaption);
      this.deleteElement(button.histogramName);
    }
  }

  fastReloadHistogram(button, biosignalRange): void {
    this.deleteElement(button.histogramName);
    this.histogramData = [];
    this.generateHistogramData(button.histogramName, biosignalRange);
    this.drawChart(button);
  }

  drawChart(button): void {
    this.histogramList.push({
      name: button.histogramName,
      caption: button.histogramCaption,
      fullCaption: button.fullCaptionHistogram,
      data: this.histogramData,
      ColorSchemaType: this.ColorSchemaType,
    });
    this.ref.detectChanges();
  }

  generateHistogramData(histogramName, biosignals): void {
    const colorValue = [];
    if (HISTOGRAM_AREA[histogramName])
      HISTOGRAM_AREA[histogramName].forEach((range) => {
        let count = 0;
        this.rangeBiosignals.forEach((data) => {
          const value = this.getValueByFieldName(data, histogramName);
          if (value >= range.startNum && value <= range.endNum) {
            count++;
          }
        });
        colorValue.push(range.color);
        const tempObject = {
          name: range.name,
          value: count,
        };
        this.histogramData.push(tempObject);
      });
    this.ColorSchemaType = {
      name: 'colorShem',
      domain: colorValue,
    };
  }

  firstUpdate(): void {
    let i = 0;
    if (this.container) {
      while (!this.isMobile && i < 4) {
        const containerEl = this.container.nativeElement;
        containerEl.children[i].classList.contains('button-block__itemPassive')
          ? containerEl.children[i].click()
          : null;
        i++;
      }
      this.isFirst = true;
    }
  }

  deleteElement(name): void {
    const index = this.histogramList.findIndex((elem) => elem.name === name);
    if (index > -1) {
      this.histogramList.splice(index, 1);
    }
  }

  getValueByFieldName(data, keyName): any {
    const keys = Object.keys(data);
    for (const key of keys) {
      const value = data[key];
      if (key.toLowerCase() === keyName.toLowerCase()) {
        return value;
      } else if (typeof value === 'object') {
        const subCheckResult = this.getValueByFieldName(value, keyName);
        if (subCheckResult !== undefined) {
          return subCheckResult;
        }
      }
    }
  }
}
