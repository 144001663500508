import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from '../../measurements-data/services/patient.service';
import { Patient } from 'src/app/models/patient';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import {switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Component({
  selector: 'app-measurement-table',
  templateUrl: './measurement-table.component.html',
  styleUrls: ['./measurement-table.component.scss'],
})
export class MeasurementTableComponent extends OnDestroyMixin implements OnInit, OnChanges {
  tableBusy: boolean = false;
  expandRow: boolean = false;
  tableData = [];
  tableSortedData = [];
  defaultTable = [];
  biosignals: Biosignal[] = [];
  patients: Patient[] = [];

  itemsPerPage: number = 20;
  pageN: number = 1;

  @Input() patientData: Patient;
  @Input() chosenPatient: Patient;
  constructor(
    private biosignalsService: BiosignalsService,
    private authService: AuthService,
    private patientService: PatientService
  ) {
    super();
  }

  ngOnInit(): void {
    this.patientService.getDoctorPatients().pipe(
        switchMap(data => {
          this.patients = (data) ? data : null;
          if (this.authService.isPatient()) {
            this.setPatientData(this.patients ? this.patients[0] : null);
            return EMPTY;
          }
          return this.patientService.patient$;
        }),
        untilComponentDestroyed(this),
      ).subscribe(patient => {
        this.setPatientData(patient);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['chosenPatient'];
    if (change.currentValue || change.currentValue === null) {
      this.setPatientData(null);
    }
  }

  setPatientData(patient) {
    if (patient) {
      this.patientData = patient;
      // this.myForm.get('patientId').patchValue(patient._id);
      this.getBiosignals(patient._id);
    } else {
      this.patientData = null;
      // this.myForm.get('patientId').reset();
      this.getBiosignals(null);
    }
  }

  getBiosignals(patientId?) {
    this.tableBusy = true;
    this.biosignals = [];
    let startDate = new Date('1990-08-24').toISOString();
    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    let endDate = new Date('3000-08-24').toISOString();
    this.biosignalsService
      .getMeasurementData(startDate, endDate, patientId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        if (data && Object.values(data).length) {
          this.biosignals = data.measurementResults;
          this.updateTableData();
          this.tableBusy = false;
        } else {
          this.tableBusy = false;
          this.tableData = [];
        }
      });
  }
  updateTableData(): void {
    if (this.biosignals && this.biosignals.length) {
      this.tableData = [];
      this.biosignals.forEach((biosignal) => {
        const tempData = {
          id: biosignal._id,
          patient: '',
          PatientID: '',
          date: biosignal.SignalRegistrationDateTime,
          hospital: '',
          doctor: '',
          BloodGlucose: biosignal.BloodGlucose,
          phase: biosignal.phase,
          measurementGroup: biosignal.measurementGroup,
          signalType: biosignal.signalType,
          EquipmentName: biosignal.EquipmentName,
          name: biosignal.fileName,
          Systola: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Systola : 0,
          Diastola: biosignal.TimeAnalysis
            ? biosignal.TimeAnalysis.Diastola
            : 0,
          HeartRate: biosignal.TimeAnalysis
            ? biosignal.TimeAnalysis.HeartRate
            : 0,
          pars: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.PARSint : 0,
          ifsp: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.IFSP : 0,
          msr: biosignal.MorphologicalAnalysis
            ? biosignal.MorphologicalAnalysis.Ms
            : 0,
          // comment: biosignal.comment,
          biosignal,
          computeStatus: biosignal.ComputeStatus,
          status: biosignal.Status,
        };

        if (!this.patientData) {
          const patient = biosignal.PatientID as Patient;
          tempData.patient = `${patient.first_name} ${patient.last_name} ${
            patient.second_name ? patient.second_name : ''
          }`;
          tempData.PatientID = patient._id;
        } else {
          tempData.PatientID = biosignal.PatientID as string;
        }
        this.tableData.push(tempData);
        this.defaultTable = this.tableData;
        this.tableSortedData = this.tableData.slice();
        this.sortData({
          active: 'date',
          direction: 'desc',
        });
      });
      if (this.chosenPatient) {
        this.tableSortedData = this.tableSortedData.filter(d => d.PatientID === this.chosenPatient._id);
      }
    }
  }

  expand() {
    this.expandRow = !this.expandRow;
  }

  compare = (
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  sortData(sort: any) {
    let data = this.tableData.slice();
    if (this.chosenPatient) {
      data = data.filter(d => d.PatientID === this.chosenPatient._id);
    }
    if (!sort.active || sort.direction === '') {
      this.tableSortedData = data;
      return;
    }
    this.tableSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }
}
