import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../measurements-data/services/patient.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Patient } from 'src/app/models/patient';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Rights } from '../admin/constants/rights.enum';
import { RightsService } from 'src/app/services/rights.service';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';
import {
  NAV_MENU_CALCULATIONS,
  NAV_MENU_CALCULATIONS_2,
} from 'src/app/services/dictionaries/constants/nav-menu';
import { ChangeLangDetectorComponent } from '../global-components/change-lang-detector/change-lang-detector.component';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { LeftBtnMenuComponent } from './main-chart/left-btn-menu/left-btn-menu.component';
import { Biosignal } from 'src/app/models/biosignal';
import { BiosignalsRange } from 'src/app/models/biosignals-range';
const navMenu_active = {
  ao: 0,
  ecg: 1,
  wp: 2,
  cp: 3,
};
@Component({
  selector: 'app-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.scss'],
  providers: [TranslatePipe],
})
export class CalculationsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  busy: boolean = false;
  active = 0;
  active_2 = 0;
  patients: Patient[] = [];
  patient;
  Rights: typeof Rights = Rights;

  ACTIVE_GRAPH: GraphType = GraphType.AO;
  navMenu: Array<any> = [];
  navMenu_2: Array<any> = [];

  measurementsNumber: number;
  measurementsGrouped: any;

  displayNav: any;
  @ViewChild(LeftBtnMenuComponent) childAO!: LeftBtnMenuComponent;

  biosignals: Biosignal[];
  biosignalsRange: BiosignalsRange;

  constructor(
    config: NgbNavConfig,
    private patientService: PatientService,
    public authService: AuthService,
    public rightsService: RightsService,
    public dataTypeService: DataTypeService,
    public cd: ChangeDetectorRef,
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    public biosignalsService: BiosignalsService
  ) {
    super(cd);
    config.destroyOnHide = false;
    this.navMenu = NAV_MENU_CALCULATIONS;
    this.navMenu_2 = NAV_MENU_CALCULATIONS_2;
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.active_2 = 0;
      this.ACTIVE_GRAPH = v;
      switch (v) {
        case 'ao': {
          this.active = 0;
          if (this.measurementsNumber) this.setMeasurementValue();
          break;
        }
        case 'ecg':
          this.active = 1;
          this.setMeasurementValue();
          break;
        case 'wp':
          this.active = 2;
          this.setMeasurementValue();
          break;
        case 'cp':
          this.active = 3;
          this.setMeasurementValue();
          break;
      }
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
    this.authService.language$.subscribe((v) => {
      this.changLang();
    });

    this.measurementsDataUpdateService.updateMeasurementsRange$
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (biosignalsRange: BiosignalsRange) =>
          (this.biosignalsRange = biosignalsRange)
      );

    this.measurementsDataUpdateService.newMeasurements$.subscribe(
      (biosignals) => (this.biosignals = biosignals)
    );

    this.patientService.patient$
      .pipe(untilComponentDestroyed(this))
      .subscribe((patient) => {
        if (patient) {
          this.patient = patient;
          this.measurementsDataUpdateService.changeMeasurementsNumber(15);
        } else {
          this.patient = null;
          this.measurementsDataUpdateService.changeMeasurementsNumber(null);
        }
      });

    this.measurementsDataUpdateService.measurementsNumber$
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        this.measurementsNumber = resp;
        if (this.authService.isPatient() && !this.patient) {
          this.busy = true;
          this.loadData();
        } else if (!this.authService.isPatient() && this.patient) {
          this.busy = true;
          this.loadData();
        }
      });
    this.measurementsDataUpdateService.measurementsRange$
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        this.measurementsNumber = 0;
        if (this.authService.isPatient() && !this.patient) {
          this.busy = true;
          this.loadData(resp.startDate, resp.endDate);
        } else if (!this.authService.isPatient() && this.patient) {
          this.busy = true;
          this.loadData(resp.startDate, resp.endDate);
        }
      });
    this.measurementsDataUpdateService.changeMeasurementsNumber(15);
    localStorage.removeItem('rufierTest');
  }

  ngAfterViewInit(): void {
    this.dataTypeService.changeType(this.ACTIVE_GRAPH);
    this.cd.detectChanges();
  }

  setMeasurementValue() {
    if (this.ACTIVE_GRAPH && this.measurementsGrouped) {
      this.measurementsDataUpdateService.changeMeasurements(
        this.measurementsGrouped[this.ACTIVE_GRAPH].measurementResults
      );
    }
  }

  tabChange($event: any) {
    this.measurementsDataUpdateService.initSelectedOscillogram({
      timePoints: [],
      valuePoints: [],
    });
    const temp =
      $event.nextId == 0
        ? GraphType.AO
        : $event.nextId == 1
        ? GraphType.ECG
        : $event.nextId == 2
        ? GraphType.WP
        : $event.nextId == 3
        ? GraphType.CP
        : GraphType.AO;
    this.dataTypeService.changeType(temp);
    this.rightsService.setRights(temp);
    this.childAO.childMainGraph?.clearArea();
  }

  loadData(startDate = null, endDate = null) {
    setTimeout(() => {
      if (this.authService.isPatient() && !this.patient) {
        this.getBiosignalsData(
          null,
          this.measurementsNumber,
          startDate,
          endDate
        );
      } else if (!this.authService.isPatient() && this.patient) {
        this.getBiosignalsData(
          this.patient._id,
          this.measurementsNumber,
          startDate,
          endDate
        );
      }
    }, 2000);
  }

  getBiosignalsData(patient?, number?, startDate = null, endDate = null): void {
    if (this.ACTIVE_GRAPH) {
      if (number == 0 && !startDate && !endDate) {
        startDate = new Date('1990-08-24').toISOString();
        let date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        endDate = new Date(date).toISOString();
      }
      this.biosignalsService
        .getMeasurementDataGroupedBySignalType(
          startDate,
          endDate,
          patient,
          number
        )
        .pipe(untilComponentDestroyed(this))
        .subscribe((data: any) => {
          this.measurementsGrouped = data;

          if (
            this.ACTIVE_GRAPH == GraphType.WP ||
            this.ACTIVE_GRAPH == GraphType.CP
          ) {
            const EquipmentName =
              this.ACTIVE_GRAPH == GraphType.WP ? 'PulseFlex' : 'CranioFlex';
            this.measurementsGrouped[this.ACTIVE_GRAPH].measurementResults =
              this.measurementsGrouped['pulsogram'].measurementResults.filter(
                (v) => v.EquipmentName == EquipmentName
              );
          }
          this.setMeasurementValue();
          this.displayNav = [];
          Object.keys(this.measurementsGrouped).forEach((e) => {
            if (this.measurementsGrouped[e].measurementResults?.length)
              this.displayNav.push(navMenu_active[e]);
          });
          this.busy = false;
          setTimeout(() => {
            this.childAO?.childMainGraph?.getVerticalGridCoord();
            this.childAO?.childMainGraph?.drawVerticalLineAndSelectData('end');
          }, 0);
        });
    }
  }
}
