import { Component, OnInit } from '@angular/core';
import { MeasurementsType } from '../models/data';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { Biosignal } from 'src/app/models/biosignal';
import {
  DIPPERS_LIMITS,
  MEASUREMENTS_ITEMS_NAMES,
} from '../constants/biosignal-caption';
import { EquipmentService } from 'src/app/services/equipment.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-ao-indexs-table',
  templateUrl: './ao-indexs-table.component.html',
  styleUrls: ['./ao-indexs-table.component.scss'],
})
export class AoIndexsTableComponent implements OnInit {
  tableData: MeasurementsType[];

  constructor(
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    private equipmentService: EquipmentService
  ) {}

  ngOnInit(): void {
    this.measurementsDataUpdateService.newMeasurements$.subscribe(
      (biosignals: Biosignal[]) => {
        this.tableData = [];
        this.updateData(biosignals);
      }
    );
    this.measurementsDataUpdateService.updateMeasurementsRange$.subscribe(
      (biosignalsRange) => {
        this.tableData = [];
        this.updateData(biosignalsRange.rangeBiosignals);
      }
    );
  }

  private updateData(biosignals: Biosignal[]): void {
    const values = this.calculateValues(biosignals);
    this.tableData = [
      values.meanValues,
      values.maxValues,
      values.minValues,
      values.stdValues,
      values.dippersValues,
      values.nonDippersValues,
      values.extremeDippersValues,
      values.nightPeakersValues,
    ];
  }

  private calculateValues(biosignals: Biosignal[]): {
    maxValues: MeasurementsType;
    minValues: MeasurementsType;
    meanValues: MeasurementsType;
    stdValues: MeasurementsType;
    extremeDippersValues: MeasurementsType;
    dippersValues: MeasurementsType;
    nonDippersValues: MeasurementsType;
    nightPeakersValues: MeasurementsType;
  } {
    const maxValues = { indicator: 'Max' };
    const minValues = { indicator: 'Min' };
    const meanValues = { indicator: 'Mean' };
    const stdValues = { indicator: 'Std' };
    const extremeDippersValues = {
      indicator: DIPPERS_LIMITS['extreme-dippers'].name,
    };
    const dippersValues = { indicator: DIPPERS_LIMITS['dippers'].name };
    const nonDippersValues = { indicator: DIPPERS_LIMITS['non-dippers'].name };
    const nightPeakersValues = {
      indicator: DIPPERS_LIMITS['night-peakers'].name,
    };
    const average = (array) =>
      array.reduce((prev, curr) => prev + curr) / array.length;

    let deviceArray = [];
    let patientId: any;
    biosignals.forEach((b) => {
      if (!patientId) patientId = b.PatientID;
      let name = b.EquipmentName.split(' ')[0];
      if (!deviceArray.find((v) => v === name)) deviceArray.push(name);
    });

    deviceArray = deviceArray.map((v) =>
      this.equipmentService.getEquipmentPatientSetting(patientId, v)
    );

    forkJoin(deviceArray).subscribe((setting) => {
      MEASUREMENTS_ITEMS_NAMES.forEach((measurement) => {
        const tempValuesArray = [];
        let tempValuesArrayDay = [];
        let tempValuesArrayNight = [];
        let morningTime = '08:00';
        let eveningTime = '22:00';
        let morning;
        let evening;
        biosignals.forEach((biosignal) => {
          let name = biosignal.EquipmentName.split(' ')[0];
          let currSett: any = setting?.find(
            (s: any) => s?.EquipmentName == name
          );
          const registration = this.time(biosignal.SignalRegistrationDateTime);
          if (currSett) {
            let tempDay = (currSett.DayStart ? currSett.DayStart : morningTime)
              .split(':')
              .map((v) => Number(v));
            let tempNight = (
              currSett.NightStart ? currSett.NightStart : eveningTime
            )
              .split(':')
              .map((v) => Number(v));
            morning = new Date(1970, 1, 1, tempDay[0], tempDay[1], 0);
            evening = new Date(1970, 1, 1, tempNight[0], tempNight[1], 0);
          } else {
            let tempDay = morningTime.split(':').map((v) => Number(v));
            let tempNight = eveningTime.split(':').map((v) => Number(v));
            morning = new Date(1970, 1, 1, tempDay[0], tempDay[1], 0);
            evening = new Date(1970, 1, 1, tempNight[0], tempNight[1], 0);
          }

          if (biosignal[measurement.section]) {
            tempValuesArray.push(
              biosignal[measurement.section][measurement.caption]
            );
            if (biosignal[measurement.section][measurement.caption] != 0) {
              if (morning > registration || registration > evening)
                tempValuesArrayNight.push(
                  biosignal[measurement.section][measurement.caption]
                );
              else if (morning <= registration && registration <= evening)
                tempValuesArrayDay.push(
                  biosignal[measurement.section][measurement.caption]
                );
            }
          }
        });
        if (tempValuesArray.length) {
          maxValues[measurement.caption] = Math.max(...tempValuesArray);
          minValues[measurement.caption] = Math.min(...tempValuesArray);
          meanValues[measurement.caption] = average(tempValuesArray).toFixed(2);
          stdValues[measurement.caption] =
            Number(
              Math.sqrt(
                biosignals
                  .map((elem) =>
                    elem[measurement.section]
                      ? Math.pow(
                          elem[measurement.section][measurement.caption] -
                            meanValues[measurement.caption],
                          2
                        )
                      : null
                  )
                  .reduce((sum, elem) => (sum += elem), 0) /
                  (biosignals.length - 1)
              ).toFixed(2)
            ) || '-';
        } else {
          maxValues[measurement.caption] = 0;
          minValues[measurement.caption] = 0;
          meanValues[measurement.caption] = 0;
          stdValues[measurement.caption] = 0;
        }

        if (tempValuesArrayDay.length && tempValuesArrayNight.length) {
          let tempVal = (
            ((average(tempValuesArrayDay) - average(tempValuesArrayNight)) *
              100) /
            average(tempValuesArrayDay)
          ).toFixed(2);
          extremeDippersValues[measurement.caption] =
            Number(tempVal) <= DIPPERS_LIMITS['extreme-dippers'].max &&
            Number(tempVal) > DIPPERS_LIMITS['extreme-dippers'].min
              ? tempVal
              : '-';
          dippersValues[measurement.caption] =
            Number(tempVal) <= DIPPERS_LIMITS['dippers'].max &&
            Number(tempVal) > DIPPERS_LIMITS['dippers'].min
              ? tempVal
              : '-';
          nonDippersValues[measurement.caption] =
            Number(tempVal) <= DIPPERS_LIMITS['non-dippers'].max &&
            Number(tempVal) > DIPPERS_LIMITS['non-dippers'].min
              ? tempVal
              : '-';
          nightPeakersValues[measurement.caption] =
            Number(tempVal) <= DIPPERS_LIMITS['night-peakers'].max &&
            Number(tempVal) > DIPPERS_LIMITS['night-peakers'].min
              ? tempVal
              : '-';
        } else {
          extremeDippersValues[measurement.caption] = 0;
          dippersValues[measurement.caption] = 0;
          nonDippersValues[measurement.caption] = 0;
          nightPeakersValues[measurement.caption] = 0;
        }
      });
    });
    //

    return {
      maxValues,
      minValues,
      meanValues,
      stdValues,
      extremeDippersValues,
      dippersValues,
      nonDippersValues,
      nightPeakersValues,
    };
  }

  time(date) {
    let dateTemp = new Date(date);
    return new Date(
      1970,
      1,
      1,
      +dateTemp.getHours(),
      +dateTemp.getMinutes(),
      +dateTemp.getSeconds()
    );
  }
  dipperClass(indicator) {
    if (indicator == DIPPERS_LIMITS['dippers'].name) return '#66d850';
    else if (indicator == DIPPERS_LIMITS['night-peakers'].name)
      return '#edb900';
    else if (indicator == DIPPERS_LIMITS['non-dippers'].name) return '#edb900';
    else if (indicator == DIPPERS_LIMITS['extreme-dippers'].name)
      return '#edb900';
    else 'white';
  }
}
