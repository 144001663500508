import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralConclusionBoxComponent } from './general-conclusion-box.component';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GeneralConclusionBoxComponent],
  imports: [CommonModule, PipesModule, TranslateModule],
  exports: [GeneralConclusionBoxComponent],
})
export class GeneralConclusionBoxModule {}
