import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ECGamplitude } from 'src/app/enums/ecg.enum';

export interface DialogData {
  timePoints: number[];
  valuePoints: number[];
}
@Component({
  selector: 'app-full-ecg-chart',
  templateUrl: './full-ecg-chart.component.html',
  styleUrls: ['./full-ecg-chart.component.scss'],
})
export class FullEcgChartComponent implements OnInit {
  rawSignalData: any = [];
  yAxisTicks: any[] = [];
  ecgHeight: ECGamplitude = ECGamplitude.mm10;
  timePointsInterval: any = {
    minY: 0,
    maxY: 0,
  };

  timePoints: Array<number> = [];
  valuePoints: Array<number> = [];

  constructor(
    public dialogRef: MatDialogRef<FullEcgChartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.oscillogramData = data;
    const data2 = this.oscillogramData.oscillogramData[0];
    this.timePoints = [...data2.timePoints];
    this.valuePoints = [...data2.valuePoints];
  }
  oscillogramData: any;
  ngOnInit(): void {
    this.updateMultyChart();
    this.setAxisY();
  }

  ngAfterViewInit() {}

  setAxisY() {
    let axisValuePoints = [...this.valuePoints];
    axisValuePoints = axisValuePoints.map((val) => val * 2);
    this.yAxisTicks = [];
    for (let i: any = 0; i > Math.min(...axisValuePoints); i += -0.1)
      this.yAxisTicks.push(i.toFixed(2));
    for (let i: any = 0; i < Math.max(...axisValuePoints); i += 0.1)
      this.yAxisTicks.push(i.toFixed(2));
    this.timePointsInterval.minY = Math.min(...axisValuePoints);
    this.timePointsInterval.maxY = Math.max(...axisValuePoints);
  }

  updateMultyChart(): void {
    if (this.data) {
      let series_array: any = [];
      let counter_10seconds_interval: number = 0;
      let temp_array = new Array();
      this.timePoints.forEach((s, i) => {
        if (s <= (counter_10seconds_interval + 1) * 10) {
          temp_array.push({
            name: this.timePoints[i],
            value: this.valuePoints[i],
          });
          if (this.timePoints.length - 1 == i) series_array.push(temp_array);
        } else {
          series_array.push(temp_array);
          temp_array = [];
          counter_10seconds_interval++;
        }
      });

      this.rawSignalData = [];
      series_array.forEach((arr, i) => {
        this.rawSignalData.push([
          {
            name: `signal${i}`,
            series: arr,
          },
        ]);
      });
    }
  }
}
