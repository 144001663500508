import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MEASUREMENTS_NUMBERS } from '../../expert-system/constants/measurements_numbers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OscillogramnDialogComponent } from 'src/app/components/modals/oscillogram-dialog/oscillogram-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { CustomLinerChartServiceService } from '../custom-liner-chart-service.service';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';
import { MainGraphComponent } from '../charts/main-graph.component';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-left-btn-menu',
  templateUrl: './left-btn-menu.component.html',
  styleUrls: ['./left-btn-menu.component.scss'],
})
export class LeftBtnMenuComponent extends OnDestroyMixin implements OnInit {
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType = GraphType.AO;
  @Input() measurementsNumber: number = 15;
  measurementsNumbers = MEASUREMENTS_NUMBERS;

  sdpCurrPage = 0;
  sdpYScaleMin = 0;
  sdpYScaleMax = 0;

  @ViewChild(MainGraphComponent) childMainGraph!: MainGraphComponent;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  @Input() biosignalsRange;
  @Input() biosignals;

  @Input() set SET_ACTIVE_GRAPH(v: GraphType) {
    if (v) {
      this.sdpCurrPage = 0;
      this.ACTIVE_GRAPH = v;
    }
  }

  arterialStiffnessIndex: boolean = false;

  constructor(
    public dataTypeService: DataTypeService,
    public customLinerChartService: CustomLinerChartServiceService,
    public authService: AuthService,
    public modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  enableLine(name: string, checked: boolean): void {
    if (checked) {
      this.childMainGraph.data[this.sdpCurrPage].map((v, i) => {
        if (v.key == name)
          v.series = JSON.parse(
            JSON.stringify(
              this.childMainGraph.dataCopy[this.sdpCurrPage][i].series
            )
          );
      });
    } else {
      this.childMainGraph.data[this.sdpCurrPage].map((v, i) => {
        if (v.key == name) v.series = [];
      });
    }
    this.childMainGraph.data[this.sdpCurrPage] = [
      ...this.childMainGraph.data[this.sdpCurrPage],
    ];
    setTimeout(() => {
      this.childMainGraph.getHorizontalGridCoord();
      this.childMainGraph.drawVerticalLineAndSelectData('current');
      this.childMainGraph.updateChartAlignments();
    }, 1000);
  }

  checkAllLines() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = true;
    });
  }

  addSignalToComparison() {
    const modal = this.modalService.open(OscillogramnDialogComponent, {
      backdrop: false,
      ariaLabelledBy: 'modal-basic-title',
    });
    modal.componentInstance.biosignals = this.childMainGraph.biosignals;
    modal.result.then((result) => {}).catch(() => console.log('error'));
  }

  onChangeMeasurements() {
    localStorage.removeItem('rufierTest');
    this.measurementsDataUpdateService.changeMeasurementsNumber(
      this.measurementsNumber
    );
    this.checkAllLines();
    setTimeout(() => {
      this.childMainGraph.drawVerticalLineAndSelectData('end');
    }, 1000);
  }

  selectSdpPage(i: number): void {
    this.sdpCurrPage = i;
    if (i == 2 ?? this.ACTIVE_GRAPH == GraphType.AO) {
      this.sdpYScaleMin = 0.001;
      this.sdpYScaleMax = 1.0;
      this.childMainGraph.updateChartAlignments();
    } else {
      this.sdpYScaleMin = 0;
      this.sdpYScaleMax = 0;
    }
    setTimeout(() => {
      this.childMainGraph.showDots();
      this.childMainGraph.drawVerticalLineAndSelectData('current');
      if (this.ACTIVE_GRAPH == GraphType.AO && i == 3)
        this.childMainGraph.getVerticalGridCoord();
    }, 500);
  }

  getArterialStiffnessIndex(checked: boolean) {
    this.measurementsDataUpdateService.isOscillogramSubject.next(!checked);
  }
}
