<ng-container *ngIf="buttonList.length">
  <div #container class="button-block">
    <button
      (click)="onHistogramCheckButtonClick($event, button)"
      *ngFor="let button of buttonList"
      [ngClass]="'button-block__itemPassive'"
      id="{{ button.histogramName }}"
    >
      <ng-container *ngIf="!(reloading$ | async)">
        {{ button.histogramCaption | lang }}
      </ng-container>
    </button>
  </div>

  <!-- Block-2 -->
  <div class="block-2">
    <div
      #chartWrap
      *ngFor="let histogram of histogramList"
      class="block-2__item"
    >
      <ng-container *ngIf="!(reloading$ | async)">
        <h4 class="histogram-title" title="{{ histogram.fullCaption | lang }}">
          {{ histogram.caption | lang }}
        </h4>
      </ng-container>
      <div class="chart-wrapper">
        <ngx-charts-bar-vertical
          [results]="histogram.data"
          [scheme]="histogram.ColorSchemaType"
          [showDataLabel]="true"
          [view]="view"
          [xAxis]="true"
        >
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
</ng-container>
