

<!-- *ngFor="let line of chartsLineChart" -->


<div #xTicks class="x-ticks">
  <!-- <span>x-ticks</span> -->
</div>


<ngx-charts-line-chart
#chart
[view]="[scrollWidth, 300]"
[hidden]="isLoading"
[animations]="false"
(window:resize)="onResize($event)"
style="position: relative; float: none"
[xAxis]="true"
[yAxis]="true"
[rotateXAxisTicks]="false"
[tooltipDisabled]="false"
[results]="rawSignalData1"
[xAxisLabel]="'mV'"
[yAxisLabel]="'calculation.mmhg' | translate"
[yAxisTicks]="yAxisTicks"
[yAxisTickFormatting]="formatAxisTickDateY"
[xAxisTicks]="xAxisTicks"
[xAxisTickFormatting]="formatAxisTickDateX"
[showXAxisLabel]="false"
[showYAxisLabel]="false"
[yScaleMin]="timePointsInterval.minY"
[yScaleMax]="timePointsInterval.maxY"
>


<ng-template #tooltipTemplate let-model="model">
  <div class="chart-tooltip-container">
    <div class="content">
      <div class="text-tooltip text-center">
        sec:&nbsp;{{ model.name.toFixed(5) }}
      </div>
    </div>
    <div class="content">
      <div class="text-tooltip">
        mV:&nbsp;{{ (model.value / ecgHeight).toFixed(5) }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #seriesTooltipTemplate let-model="model">
  <div class="chart-tooltip-container">
    <div class="content" *ngFor="let item of model">
      <div class="color" [style.background-color]="item.color"></div>
      <div class="text-tooltip">
        mV:&nbsp;{{ (item.value / ecgHeight).toFixed(5) }}
      </div>
    </div>
  </div>
</ng-template>
</ngx-charts-line-chart>
<!-- <ng-template #tooltipTemplate let-model="model">
  <div class="chart-tooltip-container">
    <div class="content">
      <div class="text-tooltip text-center">
        sec:&nbsp;{{ model.name.toFixed(5) }}
      </div>
    </div>
    <div class="content">
      <div class="text-tooltip">
        mV:&nbsp;{{ (model.value / ecgHeight).toFixed(5) }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #seriesTooltipTemplate let-model="model">
  <div class="chart-tooltip-container">
    <div class="content" *ngFor="let item of model">
      <div class="color" [style.background-color]="item.color"></div>
      <div class="text-tooltip">
        mV:&nbsp;{{ (item.value / ecgHeight).toFixed(5) }}
      </div>
    </div>
  </div>
</ng-template> -->
<!-- *ngFor="let line of chartsLineChart" -->