<div class="modal-header">
  <h2 class="modal-title" id="exampleModalLabel">
    {{ "form.add_medical_institutions" | translate }}
  </h2>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="myForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label
            >{{ "form.name" | translate }} <span class="mark">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            formControlName="name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label
            >{{ "form.region" | translate }} <span class="mark">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            formControlName="region"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label
            >{{ "form.city" | translate }} <span class="mark">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            formControlName="city"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label
            >{{ "form.address" | translate }} <span class="mark">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            formControlName="address"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ "registration.telephone" | translate }}</label>
          <input type="text" class="form-control" formControlName="telephone" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ "form.site" | translate }}</label>
          <input type="text" class="form-control" formControlName="site" />
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    data-dismiss="modal"
    (click)="onClose()"
  >
    {{ "login.cancel" | translate }}
  </button>
  <button type="button" (click)="onSubmit()" class="btn btn-primary">
    {{ "form.save" | translate }}
  </button>
</div>
