export const BUTTON_DATA = [
  {
    name: {
      ua: 'ПАРС-АОІ',
      en: 'PARS-AOI',
      pl: 'WASR-AO',
    },
    sectionName: 'PARS',
  },
  {
    name: {
      ua: 'ІФСП',
      en: 'IFVP',
      pl: 'ZFPN',
    },
    sectionName: 'IFSP',
  },
  {
    name: {
      ua: 'AНС',
      en: 'ANS',
      pl: 'AUN',
    },
    sectionName: 'ANS',
  },
  {
    name: {
      ua: 'Серце',
      en: 'Heart',
      pl: 'Serce',
    },
    sectionName: 'Heart',
  },
  {
    name: {
      ua: 'Судини',
      en: 'Vessels',
      pl: 'Naczynia',
    },
    sectionName: 'Vessels',
  },
  {
    name: {
      ua: 'Нервова система',
      en: 'Nervous System',
      pl: 'System nerwowy',
    },
    sectionName: 'Nervous',
  },
  {
    name: {
      ua: 'Проба Руф’є',
      en: 'Rufier`s test',
      pl: 'Proces Ruffiera',
    },
    sectionName: 'Rufier',
  },
];
