import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConsultantComponent } from './consultant.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from '../../accordion/accordion.module';
import { MaterialModule } from 'src/app/material.module';
import { TelegramMessageBoxComponent } from './telegram-message-box/telegram-message-box.component';

@NgModule({
  declarations: [ConsultantComponent, TelegramMessageBoxComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbNavModule,
    NgbTooltipModule,
    TranslateModule,
    AccordionModule,
    MaterialModule,
  ],
  exports: [ConsultantComponent],
})
export class ConsultantModule {}
