import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WithingsService {
  constructor(private http: HttpClient) {}

  redirectToAuth(patientId) {
    return this.http.get<any>(
      `${environment.apiUrl}/withings/redirect/to/${patientId}`
    );
  }

  redirectToTest() {
    return this.http.get<any>(`${environment.apiUrl}/withings`);
  }

  updateListSignals(patientId) {
    return this.http.get<any>(
      `${environment.apiUrl}/withings/heart/list/${patientId}`
    );
  }
  // getDoctor(id: string): Observable<Doctor> {
  //     return this.http.get<Doctor>(`${environment.apiUrl}/doctors/${id}`);
  //   }

  //   postDoctor(data: Doctor): Observable<Doctor> {
  //     return this.http.post<Doctor>(`${environment.apiUrl}/doctors`, data);
  //   }

  //   updateDoctor(id: string, data: Doctor): Observable<Doctor> {
  //     return this.http.put<Doctor>(`${environment.apiUrl}/doctors/${id}`, data);
  //   }

  //   postSchedule(id: string, data: Schedule): Observable<Schedule> {
  //     return this.http.post<Schedule>(
  //       `${environment.apiUrl}/doctors/${id}/schedule`,
  //       data
  //     );
  //   }

  //   updateSchedule(
  //     consultantId: string,
  //     scheduleId: any,
  //     data: Schedule
  //   ): Observable<Schedule> {
  //     return this.http.put<Schedule>(
  //       `${environment.apiUrl}/doctors/${consultantId}/schedule/${scheduleId}`,
  //       data
  //     );
  //   }

  //   assignDoctor(data: any) {
  //     return this.http.patch<any>(
  //       `${environment.apiUrl}/patients/assign-doctor`,
  //       data
  //     );
  //   }

  //   getMedicalInstitutions() {
  //     return this.http.get<MedicalInstitution>(
  //       `${environment.apiUrl}/medical-institutions`
  //     );
  //   }

  //   postMedicalInstitution(data: MedicalInstitution) {
  //     return this.http.post<MedicalInstitution>(
  //       `${environment.apiUrl}/medical-institutions`,
  //       data
  //     );
  //   }

  //   postPhoto(id, data: any): Observable<any> {
  //     return this.http.post(`${environment.apiUrl}/doctors/${id}/photo`, data);
  //   }

  //   getPhoto(id): Observable<any> {
  //     return this.http.get(`${environment.apiUrl}/doctors/${id}/photo`, {
  //       responseType: 'blob',
  //     });
  //   }

  //   updatePassword(id: string, data: { password: string }): Observable<Doctor> {
  //     return this.http.put<Doctor>(
  //       `${environment.apiUrl}/doctors/update-password/${id}`,
  //       data
  //     );
  //   }
  // }
}
