import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StaffPatientsService } from 'src/app/services/staff-patients/staff-patients.service';
import { PatientService } from '../../measurements-data/services/patient.service';
import {PatientListMode} from '../../../enums/patient-list.enum';
import {Patient} from "../../../models/patient";

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent extends OnDestroyMixin implements OnInit, OnChanges {
  patientsBuff: Array<any> = [];
  patients: Array<any> = [];
  routerId: string = '';

  @Input() set doctorId(id: string) {
    if (id) {
      localStorage.setItem('doctorProfileId', id);
      if (localStorage.getItem('doctorProfileId')) {
        this.routerId = localStorage.getItem('doctorProfileId');
        this.getPatients(this.routerId);
      }
    }
  }

  @Input() mode: PatientListMode = PatientListMode.MEDIUM;
  @Input() searchData = '';
  @Output() onPatient: EventEmitter<Patient> = new EventEmitter();

  constructor(
    public patientService: PatientService,
    public auth: AuthService,
    private staffPatientsService: StaffPatientsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params: any) => {
        if (!localStorage.getItem('doctorProfileId') && params.id) {
          localStorage.setItem('doctorProfileId', params.id);
        }
        if (localStorage.getItem('doctorProfileId')) {
          this.routerId = localStorage.getItem('doctorProfileId');
        }
        this.getPatients(this.routerId);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchData'] && this.patientsBuff.length) {
      this.patients = this.patientsBuff.filter(patient => {
        const searchData = this.searchData.toLowerCase();
        return patient.last_name.toLowerCase().includes(searchData) ||
          patient.first_name.toLowerCase().includes(searchData) ||
          patient.second_name.toLowerCase().includes(searchData);
      });
    }
  }

  ngOnDestroy(): void {
    this.staffPatientsService.confirm = true;
  }

  getPatients(id: string) {
    if (id && this.auth.isAdmin()) this.getAdminPatientsByDoctorId(id);
    else this.getDoctorPatients();
  }

  getDoctorPatients() {
    this.patients = [];
    this.patientService
      .getDoctorPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.patientsBuff = data;
        this.patients = data;
      });
  }

  getAdminPatientsByDoctorId(id) {
    this.patients = [];
    this.patientService
      .getAdminPatientsByDoctorId(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.patientsBuff = data;
        this.patients = data;
      });
  }

  protected readonly PatientListMode = PatientListMode;
}
