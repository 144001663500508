import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgePipe } from './age.pipe';
import { FirstLetter } from './first-letter.pipe';
import { TranslatePipe } from './translate.pipe';
import { ValueByIdPipe } from './value-by-id.pipe';
import { WeekDayPipe } from './week-day.pipe';
import { CutTextPipe } from './cut-text-patient.pipe';
import { EquipmentIdentifierPipe } from './equipment-identifier.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    WeekDayPipe,
    ValueByIdPipe,
    FirstLetter,
    AgePipe,
    CutTextPipe,
    EquipmentIdentifierPipe,
  ],
  imports: [CommonModule],
  exports: [
    TranslatePipe,
    WeekDayPipe,
    ValueByIdPipe,
    FirstLetter,
    AgePipe,
    CutTextPipe,
    EquipmentIdentifierPipe,
  ],
})
export class PipesModule {}
