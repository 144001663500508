import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WeekDaysService {
  weekDay = [
    {
      value: 'mon',
      ua: 'Пн',
      en: 'Mon',
      pl: 'Po',
      checked: false,
      disabled: false,
    },
    {
      value: 'tue',
      ua: 'Вт',
      en: 'Tue',
      pl: 'Wt',
      checked: false,
      disabled: false,
    },
    {
      value: 'wed',
      ua: 'Ср',
      en: 'Wed',
      pl: 'Sr',
      checked: false,
      disabled: false,
    },
    {
      value: 'thu',
      ua: 'Чт',
      en: 'Thu',
      pl: 'Cz',
      checked: false,
      disabled: false,
    },
    {
      value: 'fri',
      ua: 'Пт',
      en: 'Fri',
      pl: 'Pi',
      checked: false,
      disabled: false,
    },
    {
      value: 'sat',
      ua: 'Сб',
      en: 'Sat',
      pl: 'So',
      checked: false,
      disabled: false,
    },
    {
      value: 'sun',
      ua: 'Нд',
      en: 'Sun',
      pl: 'Ni',
      checked: false,
      disabled: false,
    },
  ];
}
