import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceMeasurementsErrorService {
  constructor(private http: HttpClient) {}

  getDeviceMeasurmentsErrorAll(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/device-measurements-error/get`
    );
  }
}
