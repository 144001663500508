<form [formGroup]="myForm" class="col-md-12">
  <mat-form-field class="col-md-4">
    <mat-label>{{ "measurement.measurement_duration" | translate }}</mat-label>
    <input formControlName="MesurDuration" matInput type="number" />
    <mat-hint>{{ "measurement.in_seconds" | translate }}</mat-hint>
  </mat-form-field>
  <mat-form-field class="col-md-4">
    <mat-label>{{ "measurement.frequency_of_requests" | translate }}</mat-label>
    <input formControlName="RequestFreqECG" matInput type="number" />
    <mat-hint>{{ "measurement.in_seconds" | translate }}</mat-hint>
  </mat-form-field>
</form>
