import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from 'src/app/services/validators/password-strength.validators';
import { Doctor } from 'src/app/models/doctor';
import { StaffService } from 'src/app/services/staff.service';
import { ToastrService } from 'ngx-toastr';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';
import { MedicalInstitution } from 'src/app/models/medical-institution';

@Component({
  selector: 'app-role-registration-patient',
  templateUrl: './role-registration-patient.component.html',
  styleUrls: ['./role-registration-patient.component.scss'],
})
export class RoleRegistrationPatientComponent implements OnInit {
  myForm: FormGroup = this.initForm();
  medOrganisationInfoNoAvailable: boolean = false;
  isVisiblePassword: boolean = false;
  consultants: Doctor[] = [];
  doctors: Doctor[] = [];
  medicalInstitutions: MedicalInstitution[] = [];

  @Output('registration') registration = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private staffService: StaffService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.staffService.getMedicalInstitutions().subscribe((data: any) => {
      this.medicalInstitutions = data;
    });
  }

  onSubmitForm(): void {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.translate.get(['alerts']).subscribe((t) => {
        this.toastr.warning(t.alerts.warning_1);
      });
      return;
    }
    this.myForm
      .get('doctors')
      .patchValue(
        this.myForm.get('consultantId').value ||
          this.myForm.get('doctorId').value
          ? [
              this.myForm.get('consultantId').value,
              this.myForm.get('doctorId').value,
            ].filter((x) => !(x == undefined || x == null || !x))
          : []
      );

    if (this.myForm.get('birth_date').value) {
      this.myForm
        .get('birth_date')
        .patchValue(
          new Date(this.myForm.get('birth_date').value).toISOString()
        );
    }
    this.myForm.get('userType').patchValue(RoleTypeEnum.Patient);
    this.myForm
      .get('language')
      .patchValue(localStorage.getItem('language') || 'ua');
    if (!this.myForm.get('medicalInstitution').value) {
      this.myForm.get('medicalInstitution').patchValue(undefined);
    }

    delete this.myForm.value['consultantId'];
    delete this.myForm.value['doctorId'];

    this.registration.emit(this.myForm);
  }

  selectMedOrganization(id?: any) {
    if (
      (id && !this.medOrganisationInfoNoAvailable) ||
      (!id && this.medOrganisationInfoNoAvailable)
    )
      this.staffService.getDoctors().subscribe((data) => {
        if (!this.medOrganisationInfoNoAvailable) {
          this.consultants = data.filter(
            (i) => i.isConsultant && i.medicalInstitution?._id == id
          );
          this.doctors = data.filter(
            (i) => !i.isConsultant && i.medicalInstitution?._id == id
          );
        } else {
          this.consultants = data.filter(
            (i) => i.isConsultant && !i.medicalInstitution
          );
          this.doctors = data.filter(
            (i) => !i.isConsultant && !i.medicalInstitution
          );
        }
      });
    else {
      this.doctors = [];
      this.consultants = [];
    }
  }

  setGender(gender: 'FEMALE' | 'MALE') {
    this.myForm.get('gender').setValue(gender);
  }

  initForm() {
    return (this.myForm = this.fb.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      second_name: '',
      birth_date: ['', Validators.required],
      gender: ['', Validators.required],
      email: [''],
      phones: this.fb.array([this.createPhoneFormGroup()]),
      doctors: '',
      medicalInstitution: '',
      login: ['', Validators.required],
      password: ['', [Validators.required, PasswordStrengthValidator]],
      userType: [''],
      language: [''],

      //
      doctorId: [''],
      consultantId: [''],
    }));
  }

  get phones(): FormArray {
    return (<FormArray>this.myForm.get('phones')) as FormArray;
  }
  createPhoneFormGroup() {
    return this.fb.group({
      // type: [''],
      number: [''],
    });
  }
}
