import { ErrorMeasurments } from './../models/erroneous-biosignals';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MeasurementData } from '../models/measurement-data';
import { environment } from '../../environments/environment';
import { OscillogramData } from '../models/oscillogram-data';
import {
  ExtremesData,
  OscillogramExtremumData,
} from '../models/oscillogram-extremum-data';
import { Equipment } from '../models/equipment';

@Injectable({
  providedIn: 'root',
})
export class BiosignalsService {
  constructor(private http: HttpClient) {}

  getMeasurementData(
    startDate?,
    endDate?,
    patientId?,
    measurementsNumber?,
    signalType?
  ): Observable<MeasurementData> {
    let url = `${environment.apiUrl}/biosignals`;
    const datas = {
      startDate,
      endDate,
      patientId,
      measurementsNumber,
      signalType,
    };
    let params = new HttpParams();
    for (const [key, value] of Object.entries(datas)) {
      if (value) {
        params = params.append(`${key}`, `${value}`);
      }
    }
    return this.http.get<MeasurementData>(url, { params });
  }

  getMeasurementDataGroupedBySignalType(
    startDate?,
    endDate?,
    patientId?,
    measurementsNumber?,
    signalType?
  ): Observable<MeasurementData> {
    let url = `${environment.apiUrl}/biosignals/signaltype/grouped`;
    const datas = {
      startDate,
      endDate,
      patientId,
      measurementsNumber,
      signalType,
    };
    let params = new HttpParams();
    for (const [key, value] of Object.entries(datas)) {
      if (value) {
        params = params.append(`${key}`, `${value}`);
      }
    }
    return this.http.get<MeasurementData>(url, { params });
  }

  getOscillogram(id: string): Observable<OscillogramData> {
    return this.http.get<OscillogramData>(
      `${environment.apiUrl}/biosignals/${id}/oscillogram`
    );
  }

  getRawSignal(id: string, type: string): Observable<OscillogramData> {
    return this.http.get<OscillogramData>(
      `${environment.apiUrl}/signals/${type}/${id}/raw-data`
    );
  }

  uploadMeasurementFiles(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/biosignals`, formData);
  }

  updateBiosignal(id: string, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/biosignals/${id}`, data);
  }

  getOscillogramExtremes(id: string): Observable<OscillogramExtremumData> {
    return this.http.get<OscillogramExtremumData>(
      `${environment.apiUrl}/biosignals/${id}/extremums`
    );
  }

  changeExtremes(id: string, extremesData: ExtremesData): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/biosignals/${id}/extremums`,
      extremesData
    );
  }

  downloadBiosignal(patientId, id, json?): Observable<any> {
    const url = `${environment.apiUrl}/biosignals/${id}/measurement-file/download`;
    let params = new HttpParams();
    const data = {
      patientId,
      json,
    };
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        params = params.append(`${key}`, `${value}`);
      }
    }
    return this.http.get(url, { params, responseType: 'text' });
  }

  // old
  // removeBiosignal(id): Observable<any> {
  //   return this.http.delete(`${environment.apiUrl}/biosignals/${id}`);
  // }

  //new
  removeSignal(id, type): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/signals/${type}/${id}`);
  }

  redirectBiosignal(id: string, patientId: string): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/biosignals/${id}/patient`, {
      patientId,
    });
  }

  getEquipments(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/equipments`);
  }

  updateEquipment(id: string, data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/equipments/${id}`, data);
  }

  getStatistics(): Observable<Equipment> {
    return this.http.get<Equipment>(`${environment.apiUrl}/statistics`);
  }

  equipmentParse(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/equipments/import/parse`,
      data
    );
  }

  equipmentInsert(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/equipments/import/insert`,
      data
    );
  }

  getErrorMeasurments(from: Date, to: Date): Observable<ErrorMeasurments[]> {
    return this.http.get<ErrorMeasurments[]>(
      `${environment.apiUrl}/get-err-measurment?start_date=${from}&end_date=${to}`
    );
  }

  getFile(
    id: string,
    signalType: string,
    fileType: string
  ): Observable<{ data: ArrayBuffer; type: string }> {
    return this.http.get<{ data: ArrayBuffer; type: string }>(
      `${environment.apiUrl}/signals/${signalType}/${id}/${fileType}`
    );
  }
}
