import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Patient } from '../../../models/patient';
import { PatientDetails } from 'src/app/models/patient-details';
import { SubjectService } from 'src/app/services/subject.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService extends SubjectService {
  protected patient: BehaviorSubject<Patient | null> =
    new BehaviorSubject<Patient | null>(null);

  constructor(private http: HttpClient) {
    super();
    this.initPatient();
  }

  getPatient(id): Observable<Patient> {
    return this.http.get<Patient>(`${environment.apiUrl}/patients/` + id);
  }

  getDoctorPatients(): Observable<Patient[]> {
    return this.http.get<Patient[]>(`${environment.apiUrl}/patients`);
  }

  getAdminPatientsByDoctorId(doctorId: any): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${environment.apiUrl}/patients/admin/${doctorId}`
    );
  }

  public get patient$(): Observable<Patient | null> {
    return this.patient.asObservable();
  }

  public set setPatient(value: Patient | null) {
    this.patient.next(value);
  }

  public initPatient() {
    if (localStorage.getItem('selectedPatient') !== null) {
      const patient = JSON.parse(localStorage.getItem('selectedPatient'));
      this.setPatient = patient;
    }
  }

  getPhoto(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/patients/${id}/photo`, {
      responseType: 'blob',
    });
  }

  getPatientsDetails(): Observable<PatientDetails[]> {
    return this.http.get<PatientDetails[]>(
      `${environment.apiUrl}/patient-details`
    );
  }
}
