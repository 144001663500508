export class ComplexValueCard {
  value: number;
  color: string;
  message: string;

  constructor(value?, color?, message?) {
    this.value = value ? value : 0;
    this.color = color ? color : '';
    this.message = message ? message : '';
  }
}
