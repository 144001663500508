import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '../../../services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { RoleTypeEnum } from '../model/enums/role.enum';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  providers: [TranslatePipe],
})
export class PasswordRecoveryComponent
  extends OnDestroyMixin
  implements OnInit
{
  myForm: FormGroup;
  sendEmail: boolean = false;
  isHasError: boolean = false;
  isLoading: boolean = false;

  RoleTypeEnum: typeof RoleTypeEnum = RoleTypeEnum;
  protected inner: { role?: string } = {};

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private toastr: ToastrService,
    private translate: TranslatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  selectRole(role) {
    this.inner.role = role;
  }

  onSubmitForm(): void {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.toastr.warning(
        this.translate.transform({
          ua: "Не заповнено одне або декілька обов'язкових полів",
          en: 'One or more required fields are not filled',
        })
      );
      return;
    }
    this.isLoading = true;
    this.registrationService
      .restorePassword(this.myForm.value, this.inner.role as RoleTypeEnum)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.sendEmail = true;
          this.isHasError = false;
          this.toastr.success(
            this.translate.transform({
              ua: 'Перевірте свою поштову скриньку',
              en: 'Check your inbox',
            })
          );
        },
        (err) => {
          this.isLoading = false;
          this.sendEmail = true;
          this.isHasError = true;
        }
      );
  }

  tryAgain() {
    this.sendEmail = false;
    this.isHasError = false;
  }
}
