import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.scss']
})
export class RedirectDialogComponent implements OnInit {
  @Input() public patients;
  @Input() public patientId;

  patient;
  constructor(
    public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.modal.close(this.patient);
  }
}
