import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-duo-ek-setting',
  templateUrl: './duo-ek-setting.component.html',
  styleUrls: ['./duo-ek-setting.component.scss'],
})
export class DuoEKSettingComponent extends OnDestroyMixin implements OnInit {
  myForm: FormGroup = new FormGroup({});

  @Output() changeSetting = new EventEmitter();
  @Input() set setting(s) {
    if (s) this.myForm.patchValue(s);
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.reactiveForm();
    for (const field of Object.keys(this.myForm.controls))
      this.myForm.controls[field].valueChanges
        .pipe(untilComponentDestroyed(this))
        .subscribe((v) => {
          this.changeSetting.emit(this.myForm);
        });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      MesurDuration: [],
      RequestFreqECG: [],
      EquipmentName: ['', Validators.required],
    });
  }
}
