const pixel = 3.793627;
export enum ECGspeed {
  mm10 = 10 * pixel,
  mm25 = 25 * pixel,
  mm50 = 50 * pixel,
  mm75 = 75 * pixel,
  mm100 = 100 * pixel,
  //   mm10 = 37.93627 / 2,
  //   mm25 = 94.840675 / 2,
  //   mm50 = 189.68135 / 2,
}

export enum ECGamplitude {
  mm5 = 5 * pixel,
  mm10 = 10 * pixel,
  mm20 = 20 * pixel,
  //   mm5 = 18.968135 / 2,
  //   mm10 = 37.93627 / 2,
  //   mm20 = 75.87254 / 2,
}
