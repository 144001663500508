import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from 'src/app/services/staff.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { WeekDaysService } from 'src/app/services/dictionaries/week-days.service';
import { ChangeLangDetectorComponent } from '../change-lang-detector/change-lang-detector.component';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  myForm: FormGroup = new FormGroup({});
  consultant: any;
  updatedFormValues: any;

  routerId: string = '';
  @Input() set data(value) {
    if (value) {
      this.consultant = value;
      if (value.schedule) {
        this.myForm.patchValue(value.schedule);
      }
    }
  }

  constructor(
    public fb: FormBuilder,
    private toastr: ToastrService,
    private staffService: StaffService,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
    public weekDayDict: WeekDaysService,
    public cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super(cd);
  }

  public isProfile() {
    return this.router.url.includes('profile');
  }

  ngOnInit(): void {
    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params: any) => {
        if (!localStorage.getItem('doctorProfileId') && params.id) {
          localStorage.setItem('doctorProfileId', params.id);
        }
        this.routerId = localStorage.getItem('doctorProfileId');
        this.getStaffData();
      },
    });
    this.reactiveForm();

    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
    if (this.consultant && this.consultant.schedule) {
      this.myForm.patchValue(this.consultant.schedule);
    }
  }

  getStaffData() {
    this.staffService
      .getDoctor(this.routerId)
      // .getDoctor(this.auth.getId())
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        this.data = resp;
      });
  }

  save() {
    if (this.consultant.schedule) {
      this.myForm.get('id').patchValue(this.consultant.schedule._id);
    }
    this.myForm.get('staff_id').patchValue(this.consultant.doctor._id);
    this.getUpdatedValues();

    if (this.myForm.value.id) {
      this.staffService
        .updateSchedule(
          this.myForm.value.staff_id,
          this.myForm.value.id,
          this.updatedFormValues
        )
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (res) => {
            this.getStaffData();
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_5);
              });
            if (!this.isProfile()) {
              this.router.navigate(['/home/consultants']);
            }
          },
          (err: any) => {
            this.toastr.error(err);
          }
        );
    } else {
      this.staffService
        .postSchedule(this.myForm.value.staff_id, this.updatedFormValues)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (res) => {
            this.getStaffData();
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.warning(t.alerts.warning_4);
              });
            if (!this.isProfile()) {
              this.router.navigate(['/home/consultants']);
            }
          },
          (err: any) => {
            this.toastr.error(err);
          }
        );
    }
  }

  getUpdatedValues() {
    this.updatedFormValues = {};
    this.myForm['_forEachChild']((control, name) => {
      if (control.dirty) {
        this.updatedFormValues[name] = control.value;
      }
    });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      id: [],
      vacation_date_from: [''],
      vacation_date_to: [''],
      dayOfWeekWorkHours: [''],
      time_from: [''],
      time_to: [''],
      lunch_from: [''],
      lunch_to: [''],
      staff_id: [''],
    });
  }
}
