import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-change-lang-detector',
  templateUrl: './change-lang-detector.component.html',
  styleUrls: ['./change-lang-detector.component.scss'],
})
export class ChangeLangDetectorComponent
  extends OnDestroyMixin
  implements OnInit
{
  reloading: BehaviorSubject<boolean>;
  get reloading$(): Observable<boolean> {
    return this.reloading.asObservable();
  }
  constructor(public cd: ChangeDetectorRef) {
    super();
    this.reloading = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {}

  changLang() {
    this.reloading.next(true);
    this.cd.detectChanges();
    this.reloading.next(false);
    this.cd.detectChanges();
  }
}
