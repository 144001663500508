import { Component, Input, OnInit } from '@angular/core';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @Input() biosignals;
  @Input() biosignalsRange;
  isOsciligram: boolean = true;

  constructor(
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {}

  ngOnInit(): void {
    this.measurementsDataUpdateService.isOscillogram$.subscribe((v) => {
      this.isOsciligram = v;
    });
  }
}
