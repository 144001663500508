<div
  class="block-1__recomm error-message"
  *ngIf="
    (biosignal?.MorphologicalAnalysis &&
      biosignal?.MorphologicalAnalysis.M10 > 4) ||
    !averageIndex
  "
>
  <div class="block-1__recomm-item">
    <i class="block-1__recomm-icon">
      <img src="assets/img/alarm_icon.svg" />
    </i>
    <div class="block-1__text">
      {{ "calculation.recom_text" | translate }}
    </div>
  </div>
</div>

<div
  class="block-1__recomm"
  [ngClass]="setClassInterpretations()"
  *ngIf="averageIndex"
>
  <div class="block-1__recomm-item">
    <div class="conclusion">
      <div class="general-conclusion-container">
        <div class="title-conclusion">
          {{ "calculation.general_conclusion" | translate }}
        </div>
        <div class="description-conclusion">
          <div class="description-item">
            <div class="small-title">
              {{ "calculation.adaptation_level" | translate }}:
            </div>
            <div class="dynamic-info">
              <ng-container *ngIf="!(reloading$ | async)">
                {{ indexDescription?.adaptation_level | lang }}
              </ng-container>
            </div>
          </div>
          <div class="description-item central-item">
            <div class="small-title">
              {{ "calculation.functional_reserves" | translate }}:
            </div>
            <div class="dynamic-info">
              <ng-container *ngIf="!(reloading$ | async)">
                {{ indexDescription?.functional_reserves | lang }}
              </ng-container>
            </div>
          </div>
          <div class="description-item">
            <div class="small-title">
              {{ "calculation.psychoemotional_state" | translate }}:
            </div>
            <div class="dynamic-info">
              <ng-container *ngIf="!(reloading$ | async)">
                {{ indexDescription?.psychoemotional_state | lang }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="recommendation-container">
        <div class="title-recomm mr-1">
          {{ "calculation.recommended" | translate }}:
        </div>
        <div class="dynamic-info">
          <ng-container *ngIf="!(reloading$ | async)">
            {{ indexDescription?.recommended | lang }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
