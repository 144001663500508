import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${this.authService.getToken()}`)
        .set('accept-language', `${this.authService.getLanguage()}`)
    });

    return next.handle(request).pipe(
      tap((ev) => {}),
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse) {
          if (response.status == 401 || response.status == 403) {
            this.authService.logout();
          }
        }
        return observableThrowError(response);
      })
    );
  }
}
