import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { StaffService } from 'src/app/services/staff.service';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends OnDestroyMixin implements OnInit {
  active = 1;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private staffService: StaffService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params: any) => {
        if (params && params.id) {
          this.staffService
            .getDoctor(params.id)
            .pipe(untilComponentDestroyed(this))
            .subscribe((resp: any) => {
              this.data = resp;
            });
        }
      },
    });
  }
}
