import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NAV_MENU_ADMIN } from 'src/app/services/dictionaries/constants/nav-menu';
import { RoleTypeEnum } from '../auth/model/enums/role.enum';
import { ChangeLangDetectorComponent } from '../global-components/change-lang-detector/change-lang-detector.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  RoleType: typeof RoleTypeEnum = RoleTypeEnum;
  showNav: boolean = false;
  navMenu: Array<any> = [];
  active: number = 0;

  constructor(
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    public router: Router
  ) {
    super(cd);
    this.navMenu = NAV_MENU_ADMIN;
  }

  ngOnInit() {
    this.authService.language$.subscribe((v) => {
      this.changLang();
    });
    const url = this.router.url;
    const fragment = url.split('/');
    switch (fragment[fragment.length - 1]) {
      case 'panel':
        this.active = 0;
        break;
      case 'measurements-settings':
        this.active = 1;
        break;
      case 'measurements-error':
        this.active = 2;
        break;
      case 'doctors-info':
        this.active = 3;
        break;
      case 'device-settings':
        this.active = 4;
        break;
    }
  }
}
