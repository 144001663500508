import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { Biosignal } from 'src/app/models/biosignal';
import { Heart } from 'src/app/models/heart';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';
import { VALUES_HEADER } from '../../constants/expert-system-constants';

@Component({
  selector: 'app-heart',
  templateUrl: './heart.component.html',
  styleUrls: ['./heart.component.scss'],
})
export class HeartComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.heartTableData) {
        this.setAverageValuesAndInterpretations();
        this.setAverageValuesAndInterpretationsRhythm();
      }
    }
  }
  isDataLoaded = false;
  biosignals: Biosignal[];
  heartTableData: Heart;

  private dataToCalculateAverageValue: { object: string; property: string }[] =
    [
      { object: 'TimeAnalysis', property: 'HeartRate' },
      { object: 'TimeAnalysis', property: 'MoPos' },
      { object: 'TimeAnalysis', property: 'pNN50pos' },
      { object: 'MorphologicalAnalysis', property: 'M3' },
      { object: 'TimeAnalysis', property: 'Systola' },
    ];
  detectClassName: string;
  averageValues: any = {
    AMoPos: '0',
    pNN50pos: '0',
    V: '0',
    M3: '0',
    M7: '0',
    ArrhythmiaDegree: '0',
  };
  constructor(
    private expertSystemService: ExpertSystemService,
    private translate: TranslateService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
      this.getHeartData();
    });
  }

  getHeartData() {
    this.expertSystemService.getHeart().subscribe((data) => {
      this.heartTableData = data;
      this.isDataLoaded = true;
      if (this.biosignals && this.biosignals.length) {
        this.setAverageValuesAndInterpretations();
        this.setAverageValuesAndInterpretationsRhythm();
      }
    });
  }

  setAverageValuesAndInterpretations(): void {
    for (let i = 0; i < this.dataToCalculateAverageValue.length; i++) {
      const { object, property } = this.dataToCalculateAverageValue[i];
      const averageValue = this.calculateBiosignalsAverageValue(
        object,
        property
      );

      this.heartTableData.table.averageValues.row[i] = averageValue;

      this.heartTableData.table.interpretation.row[i] =
        this.calculateInterpretation(
          this.heartTableData.table.normalValues.row[i],
          averageValue
        );
      this.heartTableData.table.className.row[i] = this.calculateClassName(
        this.heartTableData.table.normalValues.row[i],
        averageValue
      );
    }
  }

  setAverageValuesAndInterpretationsRhythm() {
    this.averageValues.AMoPos = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'AMoPos'
    );
    this.averageValues.pNN50pos = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'pNN50pos'
    );
    this.averageValues.V = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'V'
    );
    this.averageValues.M3 = this.calculateBiosignalsAverageValue(
      'MorphologicalAnalysis',
      'M3'
    );
    this.averageValues.M10 = this.calculateBiosignalsAverageValue(
      'MorphologicalAnalysis',
      'M10'
    );
    this.averageValues.ArrhythmiaDegree = this.calculateBiosignalsAverageValue(
      'ExpertSystem',
      'ArrhythmiaDegree'
    );
    Object.values(this.heartTableData.rhythmTable).forEach((val) => {
      Object.values(val).forEach((item) => {
        if (item['row']) {
          let averageValue = this.averageValues[item['header']];
          this.calculateClassNameByRhythmTable(item, averageValue);
        }
      });
    });
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    if (this.biosignals) {
      if (this.biosignals.length == 1) {
        this.heartTableData.table.averageValues.header =
          VALUES_HEADER.soleValue;
        return this.biosignals[0][object]
          ? this.biosignals[0][object][property]
          : null;
      } else {
        this.heartTableData.table.averageValues.header = VALUES_HEADER.average;
        let sum = 0,
          count = 0;
        this.biosignals.forEach((biosignal) => {
          if (biosignal[object]) {
            sum += biosignal[object][property];
            count += 1;
          }
        });

        return (sum / count).toFixed(1);
      }
    }
  }

  calculateInterpretation(range: string, comparisonValue: string): any {
    let message = '';
    this.translate.get(['measurement']).subscribe((t) => {
      const [minValue, maxValue] = range.split(' - ');
      if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
        message = t.measurement.norm;
      } else if (comparisonValue > maxValue) {
        message = t.measurement.above_norm;
      } else {
        message = t.measurement.less_norm;
      }
    });
    return message;
  }

  calculateClassName(range: string, comparisonValue: string): string {
    const [minValue, maxValue] = range.split(' - ');
    if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
      return 'success';
    } else if (comparisonValue > maxValue) {
      return 'warning';
    } else {
      return 'warning';
    }
  }

  calculateClassNameByRhythmTable(item, comparisonValue: string) {
    item['className'] = [];
    item['row'].forEach((element, i) => {
      const minValue = element.minValue,
        maxValue = element.maxValue;
      if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
        switch (i) {
          case 1: {
            item['className'].push('success');
            break;
          }
          case 2:
          case 3: {
            item['className'].push('warning');
            break;
          }
          case 4:
          case 5: {
            element.className = 'error';
            item['className'].push('error');
            break;
          }
        }
      } else {
        item['className'].push('');
      }
    });
  }
}
