<div class="container-fluid">
  <div class="row my-3 justify-content-end">
    <div class="col-md-3 col-xs-6">
      <ng-select
        [(ngModel)]="selectedBiosignalIndex"
        (change)="onBiosignalChange($event)"
      >
        <ng-option
          *ngFor="let biosignal of biosignals; let i = index"
          [value]="i"
        >
          {{ biosignalsFmtDates[i] }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-md-auto">
      <button
        class="btn btn-primary"
        [disabled]="!changesWereMade"
        (click)="saveChanges()"
      >
        {{ "form.save" | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <canvas
        #chart
        width="400"
        height="100"
        (click)="onChartLeftButton($event)"
        (contextmenu)="onChartRightButton($event)"
      >
      </canvas>
    </div>
  </div>
</div>
