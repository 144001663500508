<div class="block-1">
  <div class="block-1__item-1">
    <div class="block-1__osc">
      <app-raw-signal-chart [hidden]="!isOsciligram"></app-raw-signal-chart>
      <app-regressive-model-atd-ats
        [hidden]="isOsciligram"
      ></app-regressive-model-atd-ats>
    </div>
    <app-general-conclusion-box
      [setBiosignalsRange]="biosignalsRange"
    ></app-general-conclusion-box>
  </div>

  <div class="block-1__item-2">
    <app-disease-chance-table
      class="block-1__block-2"
      [setBiosignalsRange]="biosignalsRange"
    ></app-disease-chance-table>
  </div>

  <app-complex-values-cards class="block-1__item-3"></app-complex-values-cards>
</div>

<app-block2-charts></app-block2-charts>

<div class="block-3">
  <div class="block-3__item">
    <app-measurement-table
      [setBiosignals]="biosignals"
      [setBiosignalsRange]="biosignalsRange"
    ></app-measurement-table>
  </div>
  <div class="block-3__item block-3__item_mod">
    <app-indexes-table></app-indexes-table>
  </div>
</div>
