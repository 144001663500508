export const INTERPRETATIONS_DATA = [
  {
    value: 1,
    adaptation_level: {
      ua: 'оптимальний',
      en: 'optimal',
      pl: 'optymalny',
    },
    functional_reserves: {
      ua: 'високі',
      en: 'high',
      pl: 'wysoka',
    },
    psychoemotional_state: {
      ua: 'відмінний',
      en: 'excellent',
      pl: 'Bardzo dobry',
    },
    recommended: {
      ua: 'підтримувати здоровий спосіб життя',
      en: 'maintain a healthy lifestyle',
      pl: 'prowadzić zdrowy tryb życia',
    },
  },
  {
    value: 2,
    adaptation_level: {
      ua: 'функціональне напруження',
      en: 'functional stress',
      pl: 'stres funkcjonalny',
    },
    functional_reserves: {
      ua: 'в нормі',
      en: 'normal',
      pl: 'normalna',
    },
    psychoemotional_state: {
      ua: 'добрий',
      en: 'good',
      pl: 'dobry',
    },
    recommended: {
      ua: 'відкоригувати спосіб життя',
      en: 'adjust your lifestyle',
      pl: 'dostosować styl życia',
    },
  },
  {
    value: 3,
    adaptation_level: {
      ua: 'стан перенапруження',
      en: 'state of overstrain',
      pl: 'stan przepięcia',
    },
    functional_reserves: {
      ua: 'нижче норми',
      en: 'below normal',
      pl: 'poniżej średniej',
    },
    psychoemotional_state: {
      ua: 'задовільний',
      en: 'satisfactory',
      pl: 'zadowalający',
    },
    recommended: {
      ua: 'повторне обстеження, превентивна реабілітація',
      en: 're-examination, preventive rehabilitation',
      pl: 'ponowne badanie, rehabilitacja profilaktyczna',
    },
  },
  {
    value: 4,
    adaptation_level: {
      ua: 'зрив механізмів адаптації з перевагою неспецифічних змін',
      en: 'failure of adaptation mechanisms with the predominance of nonspecific changes',
      pl: 'zakłócenie mechanizmów adaptacyjnych z preferencją dla zmian niespecyficznych',
    },
    functional_reserves: {
      ua: 'резерви низькі',
      en: ' reserves are low',
      pl: 'rezerwy są niskie',
    },
    psychoemotional_state: {
      ua: 'нервове перенапруження',
      en: 'nervous overstrain',
      pl: 'Napięcie nerwowe',
    },
    recommended: {
      ua: 'консультація лікаря',
      en: 'doctor`s consultation',
      pl: 'konsultacja lekarska',
    },
  },
  {
    value: 5,
    adaptation_level: {
      ua: 'преморбідний стан з перевагою специфічних змін',
      en: 'premorbid state with predominance of specific changes',
      pl: 'stan przedchorobowy z preferencją dla określonych zmian',
    },
    functional_reserves: {
      ua: 'резерви вичерпані',
      en: 'reserves are exhausted',
      pl: 'wyczerpały się rezerwy',
    },
    psychoemotional_state: {
      ua: 'нервовий стрес ознаки депресії',
      en: 'nervous stress is a sign of depression',
      pl: 'nerwowe oznaki depresji',
    },
    recommended: {
      ua: 'клінічне обстеження і лікування',
      en: 'clinical examination and treatment',
      pl: 'badanie kliniczne i leczenie',
    },
  },
];
