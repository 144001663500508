<ng-container *ngIf="!(reloading$ | async)">
  <h1>{{ "calculation.heart" | translate }}</h1>
  <div class="expert-table-wrapper">
    <table *ngIf="isDataLoaded" class="expert-table-style-2">
      <thead>
        <tr>
          <th scope="col">{{ heartTableData.table.names.header | lang }}</th>
          <th scope="col">{{ heartTableData.table.marks.header | lang }}</th>
          <th scope="col">
            {{ heartTableData.table.normalValues.header | lang }}
          </th>
          <th scope="col">
            {{ heartTableData.table.averageValues.header | lang }}
          </th>
          <th scope="col">
            {{ heartTableData.table.interpretation.header | lang }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let headerNames of heartTableData.table.names.row;
            let i = index
          "
        >
          <td>{{ headerNames | lang }}</td>
          <td>{{ heartTableData.table.marks.row[i] | lang }}</td>
          <td>{{ heartTableData.table.normalValues.row[i] }}</td>
          <td [ngClass]="heartTableData.table.className.row[i]">
            {{ heartTableData.table.averageValues.row[i] }}
          </td>
          <td [ngClass]="heartTableData.table.className.row[i]">
            {{ heartTableData.table.interpretation.row[i] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <h1>
    {{ "calculation.disease_arrhythmia_ttl" | translate }} =
    {{ averageValues.ArrhythmiaDegree }} ({{
      +averageValues.ArrhythmiaDegree == 1
        ? 0
        : +averageValues.ArrhythmiaDegree
        ? ((averageValues.ArrhythmiaDegree - 1) * 20 | number : "1.0-0")
        : 0
    }}%)
  </h1>
  <div class="expert-table-wrapper">
    <table class="expert-table-style-2">
      <thead>
        <tr>
          <th>{{ "calculation.levels" | translate }}</th>
          <th>{{ heartTableData?.rhythmTable.modeAmplitude.header | lang }}</th>
          <th>
            {{ heartTableData?.rhythmTable.numberIntervals.header | lang }}
          </th>
          <th>{{ heartTableData?.rhythmTable.stability.header | lang }}</th>
          <th>{{ heartTableData?.rhythmTable.brokenRhythm.header | lang }}</th>
          <th>
            {{ heartTableData?.rhythmTable.levelExcitability.header | lang }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>AMo</td>
          <td>pNN50</td>
          <td>{{ heartTableData?.rhythmTable.stability.V.header }}</td>
          <td>{{ heartTableData?.rhythmTable.brokenRhythm.M3.header }}</td>
          <td>M10</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <strong>{{ averageValues.AMoPos }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.pNN50pos }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.V }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.M3 }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.M7 }}</strong>
          </td>
        </tr>

        <tr
          *ngFor="
            let row of heartTableData?.rhythmTable.modeAmplitude.AMoPos.row;
            let i = index
          "
        >
          <ng-container *ngIf="i > 0">
            <td>{{ i }}</td>
            <td
              [ngClass]="
                heartTableData?.rhythmTable.modeAmplitude.AMoPos.className
                  ? heartTableData?.rhythmTable.modeAmplitude.AMoPos.className[
                      i
                    ]
                  : ''
              "
              [innerHTML]="
                heartTableData?.rhythmTable.modeAmplitude.AMoPos.row[i]
                  .description
              "
            ></td>

            <td
              [ngClass]="
                heartTableData?.rhythmTable.numberIntervals.pNN50pos.className
                  ? heartTableData?.rhythmTable.numberIntervals.pNN50pos
                      .className[i]
                  : ''
              "
              [innerHTML]="
                heartTableData?.rhythmTable.numberIntervals.pNN50pos.row[i]
                  .description
              "
            ></td>

            <td
              [ngClass]="
                heartTableData?.rhythmTable.stability.V.className
                  ? heartTableData?.rhythmTable.stability.V.className[i]
                  : ''
              "
              [innerHTML]="
                heartTableData?.rhythmTable.stability.V.row[i].description
              "
            ></td>

            <td
              [ngClass]="
                heartTableData?.rhythmTable.brokenRhythm.M3.className
                  ? heartTableData?.rhythmTable.brokenRhythm.M3.className[i]
                  : ''
              "
              [innerHTML]="
                heartTableData?.rhythmTable.brokenRhythm.M3.row[i].description
              "
            ></td>
            <ng-container
              *ngIf="heartTableData?.rhythmTable.levelExcitability.M7.row[i]"
            >
              <td
                [ngClass]="
                  heartTableData?.rhythmTable.levelExcitability.M7.className
                    ? heartTableData?.rhythmTable.levelExcitability.M7
                        .className[i]
                    : ''
                "
                [innerHTML]="
                  heartTableData?.rhythmTable.levelExcitability.M7.row[i]
                    .description
                "
              ></td>
            </ng-container>
            <ng-container
              *ngIf="!heartTableData?.rhythmTable.levelExcitability.M7.row[i]"
            >
              <td></td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
