import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';


@Component({
  selector: 'app-indexes-table',
  templateUrl: './indexes-table.component.html',
  styleUrls: ['./indexes-table.component.scss'],
})
export class IndexesTableComponent implements OnInit {
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;

  constructor(
    public dataTypeService: DataTypeService,
    private cd: ChangeDetectorRef
  ) {
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {}
}
