<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Equipment</h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-wrapper">
    <table class="expert-table-style-3">
      <thead>
        <tr>
          <th>
            <label>
              <input
                [(ngModel)]="allCheck"
                (change)="selectCheckBox()"
                class="customCheckBox"
                type="checkbox"
              />
            </label>
          </th>
          <th>№</th>
          <th>ID</th>
          <th>MacId</th>
          <th>{{ "measurement.name_meter" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <td>
            <label>
              <input
                [checked]="isChecked(item)"
                (click)="equipments.toggle(item)"
                class="customCheckBox"
                type="checkbox"
              />
            </label>
          </td>
          <td>{{ i + 1 }}</td>
          <td>{{ item.EquipmentID }}</td>
          <td>{{ item.EquipmentMacId }}</td>
          <td>{{ item.EquipmentName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="close()">
    {{ "login.cancel" | translate }}
  </button>
  <button class="btn btn-danger" (click)="submit()">
    {{ "measurement.confirm" | translate }}
  </button>
</div>
