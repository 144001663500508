<tr [ngClass]="{ 'child-row': childRow }">
  <ng-container *ngFor="let item of cols">
    <td *ngIf="item.field === 'numberFull'">
      {{ index + 1 }}
    </td>
    <td
      *ngIf="
        item.field !== 'patientName' &&
        item.field !== 'doctorName' &&
        item.field !== 'numberFull' &&
        item.field !== 'contactPerson' &&
        item.field !== 'purchaseStatus' &&
        item.field !== 'isContactPerson'
      "
    >
      {{ element[item.field] }}
    </td>
    <td *ngIf="item.field === 'patientName'">
      <a
        *ngIf="element.patientId && element.id"
        (click)="selectPatient(element.id)"
        [routerLink]="['/home/profile/patient/', element.id]"
      >
        {{ element.patientName }}
      </a>
      <span *ngIf="!(element.patientId && element.id)">
        {{ element.patientName }}
      </span>
    </td>
    <td *ngIf="item.field === 'doctorName'">
      <span
        (click)="
          element.doctorLength > 1 ? showMoreInfo(element.patientId) : ''
        "
      >
        <a
          *ngIf="!(element.doctorLength > 1)"
          [routerLink]="[
            '/home/profile/staff/patients',
            { id: element.doctorId }
          ]"
        >
          {{ element.doctorName }}
        </a>
        <ng-container *ngIf="element.doctorLength > 1">
          <button
            *ngIf="arrowDown"
            type="button"
            class="btn btn-outline-secondary btn-sm d-flex"
            style="font-size: 10px"
          >
            <i class="bi bi-list-task mr-1"></i>
            {{ "measurement.expand_list" | translate }}
          </button>
          <button
            *ngIf="!arrowDown"
            type="button"
            class="btn btn-secondary btn-sm d-flex"
            style="font-size: 10px"
          >
            <i class="bi bi-list-task mr-1"></i>
            {{ "measurement.collapse_list" | translate }}
          </button>
        </ng-container>
      </span>
    </td>
    <td *ngIf="item.field === 'contactPerson'">
      <ng-container *ngIf="element.equipmentID">
        <ng-container *ngIf="!isEdit[item.field]">
          <div class="d-flex align-items-center justify-content-center">
            <span>{{ element.contactPerson }}</span>
            <button
              (click)="edit(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'form.edit' | translate }}"
              placement="top"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isEdit[item.field]">
          <div class="d-flex align-items-center justify-content-center">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="contactPerson"
            />
            <button
              *ngIf="element.contactPerson !== contactPerson"
              (click)="save(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'form.save' | translate }}"
              placement="top"
            >
              <i class="bi bi-sd-card"></i>
            </button>
            <button
              *ngIf="element.contactPerson === contactPerson"
              (click)="cancel(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'login.cancel' | translate }}"
              placement="top"
            >
              <i class="bi bi-x"></i>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </td>

    <td *ngIf="item.field === 'isContactPerson'">
      <label class="mb-0" *ngIf="element.equipmentID">
        <input
          type="checkbox"
          [(ngModel)]="element.isContactPerson"
          (change)="setContactPerson()"
          class="customCheckBox"
        />
      </label>
    </td>

    <td *ngIf="item.field === 'purchaseStatus'">
      <ng-container *ngIf="element.equipmentID">
        <ng-container *ngIf="!isEdit[item.field]">
          <div class="d-flex align-items-center justify-content-center">
            <span>{{ element.purchaseStatus }}</span>
            <button
              (click)="edit(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'form.edit' | translate }}"
              placement="top"
            >
              <i class="bi bi-pencil"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isEdit[item.field]">
          <div class="d-flex align-items-center justify-content-center">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="purchaseStatus"
            />
            <button
              *ngIf="element.purchaseStatus !== purchaseStatus"
              (click)="save(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'form.save' | translate }}"
              placement="top"
            >
              <i class="bi bi-sd-card"></i>
            </button>
            <button
              *ngIf="element.purchaseStatus === purchaseStatus"
              (click)="cancel(item.field)"
              class="addBtn ml-1"
              ngbTooltip="{{ 'login.cancel' | translate }}"
              placement="top"
            >
              <i class="bi bi-x"></i>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>
  <td class="text-center">
    <label class="mb-0" *ngIf="element.equipmentID">
      <input
        type="checkbox"
        [(ngModel)]="element.active"
        (change)="confirm()"
        class="customCheckBox"
      />
    </label>
  </td>
</tr>
