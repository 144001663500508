import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { WithingsService } from 'src/app/services/withings.service';

@Component({
  selector: 'app-withing-panel',
  templateUrl: './withing-panel.component.html',
  styleUrls: ['./withing-panel.component.scss'],
})
export class WithingPanelComponent extends OnDestroyMixin implements OnInit {
  @Input() public patientId;
  @Input() public withings: boolean = false;
  busy: boolean = false;
  constructor(
    public modal: NgbActiveModal,
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    public withingsService: WithingsService,
    public toastr: ToastrService,
    public fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {}

  onClose() {
    this.modal.close();
  }

  redirectToWithings() {
    this.withingsService
      .redirectToAuth(this.patientId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((url) => {
        this.generateRedirectUri(url);
      });
  }

  generateRedirectUri(url) {
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', `_blank`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
