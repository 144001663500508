import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-pars-aoi-tab',
  templateUrl: './pars-aoi-tab.component.html',
  styleUrls: ['./pars-aoi-tab.component.scss'],
})
export class ParsAoiTabComponent implements OnInit {
  @Input() biosignalsData = [];
  @Input() biosignalsRange = [];

  constructor() {}

  ngOnInit(): void {}
}
