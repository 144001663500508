import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RoleLoginComponent } from './login/role-login/role-login.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegistrationComponent } from './registration/registration.component';
import { RoleRegistrationComponent } from './registration/role-registration/role-registration.component';

const routes: Routes = [
  {
    path: '', component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login', component: LoginComponent,
        children: [
          { path: '', component: RoleLoginComponent },
          { path: ':admin', component: RoleLoginComponent }
        ]
      },
      { path: 'password-recovery', component: PasswordRecoveryComponent },
      {
        path: 'registration', component: RegistrationComponent,
        children: [
          { path: ':role', component: RoleRegistrationComponent }
        ]
      }],
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
