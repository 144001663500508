<ng-container *ngIf="!(reloading$ | async)">
  <div *ngIf="AnsTableData">
    <h2>{{ "calculation.ans_ttl" | translate }}</h2>
    <div class="expert-index">
      <div class="expert-index-item">
        <h1>
          {{ AnsTableData?.ITsSChG.name | lang }}

          {{ averageValues.ICHH }}
        </h1>

        <p>
          <strong>{{ AnsTableData?.ITsSChG.shortDescription | lang }}</strong>
          {{ indexDescriptionICHH | lang }}
        </p>
      </div>
      <div class="expert-index-item">
        <h1>
          {{ AnsTableData?.ITsSudChG.name | lang }}

          {{ averageValues.ICVH }}
        </h1>
        <p>
          <strong>{{ AnsTableData?.ITsSudChG.shortDescription | lang }}</strong>
          {{ indexDescriptionICVH | lang }}
        </p>
      </div>
    </div>

    <div class="expert-table-wrapper">
      <table class="expert-table-style-1 fz-11">
        <thead>
          <tr>
            <th colspan="4">
              <strong>{{
                AnsTableData.tableInterpretation.levelsAutonomic.header | lang
              }}</strong>
            </th>
            <th rowspan="2">
              <strong>{{
                AnsTableData.tableInterpretation.levelsFunctionalState.header
                  | lang
              }}</strong>
            </th>
            <th colspan="2">
              <strong>{{
                AnsTableData.tableInterpretation.psychoEmotionalState.header
                  | lang
              }}</strong>
            </th>
            <th rowspan="2">
              <strong>{{
                AnsTableData.tableInterpretation.recommended.header | lang
              }}</strong>
            </th>
          </tr>
          <tr>
            <th>
              <strong>{{
                AnsTableData.tableInterpretation.levelsAutonomic.levels.header
                  | lang
              }}</strong>
            </th>
            <th>
              <strong>{{
                AnsTableData.tableInterpretation.levelsAutonomic.vegetative
                  .header | lang
              }}</strong>
            </th>
            <th>
              <strong>{{
                AnsTableData.tableInterpretation.levelsAutonomic.levelAdaptation
                  .header | lang
              }}</strong>
            </th>
            <th>
              <strong>{{
                AnsTableData.tableInterpretation.levelsAutonomic
                  .functionalReserves.header | lang
              }}</strong>
            </th>
            <th>
              <strong>{{
                AnsTableData.tableInterpretation.psychoEmotionalState.header
                  | lang
              }}</strong>
            </th>
            <th style="border-right: 1px solid #a9a9a9">
              <strong>{{
                AnsTableData.tableInterpretation.psychoEmotionalState.activity
                  .header | lang
              }}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let item of AnsTableData.tableInterpretation.levelsAutonomic
                .vegetative.row;
              let i = index
            "
          >
            <tr *ngIf="averageIndex === i">
              <td>
                <strong>{{ i }}</strong>
              </td>
              <td>
                <strong>{{
                  AnsTableData.tableInterpretation.levelsAutonomic.vegetative
                    .row[i] | lang
                }}</strong>
              </td>
              <td>
                {{
                  AnsTableData.tableInterpretation.levelsAutonomic
                    .levelAdaptation.row[i] | lang
                }}
              </td>
              <td>
                {{
                  AnsTableData.tableInterpretation.levelsAutonomic
                    .functionalReserves.row[i] | lang
                }}
              </td>
              <td>
                {{
                  AnsTableData.tableInterpretation.levelsFunctionalState.row[i]
                    | lang
                }}
              </td>
              <td>
                {{
                  AnsTableData.tableInterpretation.psychoEmotionalState
                    .psychoEmotional.row[i] | lang
                }}
              </td>
              <td>
                {{
                  AnsTableData.tableInterpretation.psychoEmotionalState.activity
                    .row[i] | lang
                }}
              </td>
              <td>
                {{ AnsTableData.tableInterpretation.recommended.row[i] | lang }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="expert-table-wrapper html2pdf__page-break">
      <table class="expert-table-style-2">
        <thead>
          <tr>
            <th>{{ "calculation.levels" | translate }}</th>
            <th colspan="2">
              {{
                AnsTableData?.cardiacFactorTable.regulationEffect.header | lang
              }}
            </th>
            <th colspan="2">
              {{
                AnsTableData?.cardiacFactorTable.spectralStructure.header | lang
              }}
            </th>
            <th colspan="2">
              {{
                AnsTableData?.cardiacFactorTable.spectralStructureInterval
                  .header | lang
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              {{
                AnsTableData?.cardiacFactorTable.regulationEffect.Chss.header
                  | lang
              }}
            </td>
            <td>
              {{
                AnsTableData?.cardiacFactorTable.regulationEffect.ATd.header
                  | lang
              }}
            </td>

            <td>
              {{
                AnsTableData?.cardiacFactorTable.spectralStructure.VLF.header
              }}
            </td>
            <td>
              {{ AnsTableData?.cardiacFactorTable.spectralStructure.HF.header }}
            </td>

            <td>
              {{
                AnsTableData?.cardiacFactorTable.spectralStructureInterval.VLF
                  .header
              }}
            </td>
            <td>
              {{
                AnsTableData?.cardiacFactorTable.spectralStructureInterval.HF
                  .header
              }}
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <strong>{{ averageValues.Chss }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.ATd }}</strong>
            </td>

            <td>
              <strong>{{ averageValues.FFT.perVLF }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.FFT.perHF }}</strong>
            </td>

            <td>
              <strong>{{ averageValues.FFT_pos_interval.perVLF }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.FFT_pos_interval.perHF }}</strong>
            </td>
          </tr>

          <tr
            *ngFor="
              let row of AnsTableData?.cardiacFactorTable.regulationEffect.Chss
                .row;
              let i = index
            "
          >
            <ng-container *ngIf="i > 0">
              <td>{{ i }}</td>
              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.regulationEffect.Chss
                    .className
                    ? AnsTableData?.cardiacFactorTable.regulationEffect.Chss
                        .className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.regulationEffect.Chss.row[i]
                    .description
                "
              ></td>

              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.regulationEffect.ATd
                    .className
                    ? AnsTableData?.cardiacFactorTable.regulationEffect.ATd
                        .className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.regulationEffect.ATd.row[i]
                    .description
                "
              ></td>

              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.spectralStructure.VLF
                    .className
                    ? AnsTableData?.cardiacFactorTable.spectralStructure.VLF
                        .className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.spectralStructure.VLF.row[i]
                    .description
                "
              ></td>

              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.spectralStructure.HF
                    .className
                    ? AnsTableData?.cardiacFactorTable.spectralStructure.HF
                        .className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.spectralStructure.HF.row[i]
                    .description
                "
              ></td>

              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.spectralStructureInterval.VLF
                    .className
                    ? AnsTableData?.cardiacFactorTable.spectralStructureInterval
                        .VLF.className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.spectralStructureInterval.VLF
                    .row[i].description
                "
              ></td>
              <td
                [ngClass]="
                  AnsTableData?.cardiacFactorTable.spectralStructureInterval.HF
                    .className
                    ? AnsTableData?.cardiacFactorTable.spectralStructureInterval
                        .HF.className[i]
                    : ''
                "
                [innerHTML]="
                  AnsTableData?.cardiacFactorTable.spectralStructureInterval.HF
                    .row[i].description
                "
              ></td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <h1 class="expert-system-title">
      {{ AnsTableData?.firstIndicatorName | lang }}
    </h1>

    <div class="expert-index">
      <div class="expert-index-item">
        <h1>{{ AnsTableData?.IVSChG.name | lang }} {{ averageValues.IVHH }}</h1>
        <p>
          <strong>{{ AnsTableData?.IVSChG.shortDescription | lang }}</strong>
          {{ indexDescriptionIVHH | lang }}
        </p>
      </div>
      <div class="expert-index-chart">
        <div class="chartarea">
          <canvas
            *ngIf="cardiacChartData"
            baseChart
            width="220"
            height="220"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [labels]="chartLabels"
            [data]="cardiacChartData"
            [colors]="pieChartColors"
            [labels]="pieChartLabels"
          ></canvas>
        </div>
        <div class="chartarea-label">
          <div class="chartarea-interpretation">
            <!-- <p [ngClass]="chartDescrption.FFT_pos_interval.perULF.className">%ULF
            ({{averageValues.FFT_pos_interval.perULF}}) - {{chartDescrption.FFT_pos_interval.perULF.description | lang}}
          </p> -->
            <p [ngClass]="chartDescrption.FFT_pos_interval.perVLF.className">
              %VLF ({{ averageValues.FFT_pos_interval.perVLF }}) -
              {{ chartDescrption.FFT_pos_interval.perVLF.description | lang }}
            </p>
            <p [ngClass]="chartDescrption.FFT_pos_interval.perLF.className">
              %LF ({{ averageValues.FFT_pos_interval.perLF }}) -
              {{ chartDescrption.FFT_pos_interval.perLF.description | lang }}
            </p>
            <p [ngClass]="chartDescrption.FFT_pos_interval.perHF.className">
              %HF ({{ averageValues.FFT_pos_interval.perHF }}) -
              {{ chartDescrption.FFT_pos_interval.perHF.description | lang }}
            </p>
          </div>
          <div class="chartarea-legend">
            <ul>
              <!-- <li>
              <div class="legend-item orange"></div> %ULF - {{'calculation.central_nervous_system' | translate}}
            </li> -->
              <li>
                <div class="legend-item green"></div>
                %VLF - {{ "calculation.hypothalamic_pituitary" | translate }}
              </li>
              <li>
                <div class="legend-item red"></div>
                %LF - {{ "calculation.vegetative_contour" | translate }}
              </li>
              <li>
                <div class="legend-item yellow"></div>
                %HF - {{ "calculation.autonomous_circuit" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="expert-table-wrapper">
      <table class="expert-table-style-1">
        <thead>
          <tr>
            <!-- <th colspan="2">{{AnsTableData?.cardiacFactor.ULF.header | lang}}</th> -->
            <th colspan="2">
              {{ AnsTableData?.cardiacFactor.VLF.header | lang }}
            </th>
            <th colspan="2">
              {{ AnsTableData?.cardiacFactor.LF.header | lang }}
            </th>
            <th colspan="2">
              {{ AnsTableData?.cardiacFactor.HF.header | lang }}
            </th>
            <th>{{ AnsTableData?.cardiacFactor.TP.header | lang }}</th>
          </tr>
          <tr>
            <!-- <th>{{AnsTableData?.cardiacFactor.ULF.ULFPercent.header | lang}}</th>
          <th>{{AnsTableData?.cardiacFactor.ULF.ULFint.header | lang}}</th> -->
            <th>
              {{ AnsTableData?.cardiacFactor.VLF.VLFPercent.header | lang }}
            </th>
            <th>{{ AnsTableData?.cardiacFactor.VLF.VLFint.header | lang }}</th>
            <th>
              {{ AnsTableData?.cardiacFactor.LF.LFPercent.header | lang }}
            </th>
            <th>{{ AnsTableData?.cardiacFactor.LF.LFint.header | lang }}</th>
            <th>
              {{ AnsTableData?.cardiacFactor.HF.HFPercent.header | lang }}
            </th>
            <th>{{ AnsTableData?.cardiacFactor.HF.HFint.header | lang }}</th>
            <th>{{ AnsTableData?.cardiacFactor.TP.TP.header | lang }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td>{{averageValues.FFT_pos_interval.perULF}}</td>
          <td>{{averageValues.FFT_pos_interval.ULF}}</td> -->
            <td>{{ averageValues.FFT_pos_interval.perVLF }}</td>
            <td>{{ averageValues.FFT_pos_interval.VLF }}</td>
            <td>{{ averageValues.FFT_pos_interval.perLF }}</td>
            <td>{{ averageValues.FFT_pos_interval.LF }}</td>
            <td>{{ averageValues.FFT_pos_interval.perHF }}</td>
            <td>{{ averageValues.FFT_pos_interval.HF }}</td>
            <td>{{ averageValues.FFT_pos_interval.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="expert-table-wrapper html2pdf__page-break">
      <table class="expert-table-style-2">
        <thead>
          <tr>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.Points.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.ATd.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.VPR.header | lang }}
            </th>

            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.IN.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.HVR_V.header | lang }}
            </th>
            <th>{{ "calculation.weight_spectr" | translate }}</th>
          </tr>
          <tr>
            <th>
              {{
                AnsTableData?.vascularFactorTable.fPercent.header_interval
                  | lang
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ AnsTableData?.vascularFactorTable.Points.row[0] }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.Mo.row[0] | lang }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.VPR.row[0] }}</td>

            <td>{{ AnsTableData?.vascularFactorTable.IN.row[0] }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.HVR_V.row[0] }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.fPercent.row[0] }}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <strong
                >{{ averageValues.Mo }}; {{ averageValues.Chss }};
                {{ averageValues.ATs }}</strong
              >
            </td>
            <td>
              <strong>{{ averageValues.VPR }}</strong>
            </td>

            <td>
              <strong>{{ averageValues.IN }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.HVR_V }}</strong>
            </td>
            <td>
              <strong
                >{{ averageValues.FFT_pos_interval.perULF }};
                {{ averageValues.FFT_pos_interval.perVLF }};
                {{ averageValues.FFT_pos_interval.perLF }};
                {{ averageValues.FFT_pos_interval.perHF }}</strong
              >
            </td>
          </tr>
          <tr
            *ngFor="
              let row of AnsTableData?.vascularFactorTable.Points.row;
              let i = index
            "
          >
            <ng-container *ngIf="i > 1">
              <td>
                <strong>{{
                  AnsTableData?.vascularFactorTable.Points.row[i]
                }}</strong>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.Mo.className
                    ? AnsTableData?.vascularFactorTable.Mo.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.Mo.className &&
                    AnsTableData?.vascularFactorTable.Mo.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.Mo.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.VPR.className
                    ? AnsTableData?.vascularFactorTable.VPR.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.VPR.className &&
                    AnsTableData?.vascularFactorTable.VPR.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.VPR.row[i].description
                  "
                ></span>
              </td>

              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.IN.className
                    ? AnsTableData?.vascularFactorTable.IN.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.IN.className &&
                    AnsTableData?.vascularFactorTable.IN.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.IN.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.HVR_V.className
                    ? AnsTableData?.vascularFactorTable.HVR_V.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.HVR_V.className &&
                    AnsTableData?.vascularFactorTable.HVR_V.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.HVR_V.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.fPercent.className
                    ? AnsTableData?.vascularFactorTable.fPercent.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.fPercent.className &&
                    AnsTableData?.vascularFactorTable.fPercent.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.fPercent.row[i]
                      .description
                  "
                ></span>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <h1 class="expert-system-title">
      {{ AnsTableData?.secondIndicatorName | lang }}
    </h1>

    <div class="expert-index">
      <div class="expert-index-item">
        <h1>
          {{ AnsTableData?.IVSudChG.name | lang }} {{ averageValues.IVVH }}
        </h1>
        <p>
          <strong>{{ AnsTableData?.IVSudChG.shortDescription | lang }}</strong>
          {{ indexDescriptionIVVH | lang }}
        </p>
      </div>
      <div class="expert-index-chart">
        <div class="chartarea">
          <canvas
            *ngIf="vascularChartData"
            baseChart
            width="220"
            height="220"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [labels]="chartLabels"
            [data]="vascularChartData"
            [colors]="pieChartColors"
            [labels]="pieChartLabels"
          ></canvas>
        </div>
        <div class="chartarea-label">
          <div class="chartarea-interpretation">
            <!-- <p [ngClass]="chartDescrption.FFT.perULF.className">%ULF ({{averageValues.FFT.perULF}}) -
            {{chartDescrption.FFT.perULF.description | lang}}</p> -->
            <p [ngClass]="chartDescrption.FFT.perVLF.className">
              %VLF ({{ averageValues.FFT.perVLF }}) -
              {{ chartDescrption.FFT.perVLF.description | lang }}
            </p>
            <p [ngClass]="chartDescrption.FFT.perLF.className">
              %LF ({{ averageValues.FFT.perLF }}) -
              {{ chartDescrption.FFT.perLF.description | lang }}
            </p>
            <p [ngClass]="chartDescrption.FFT.perHF.className">
              %HF ({{ averageValues.FFT.perHF }}) -
              {{ chartDescrption.FFT.perHF.description | lang }}
            </p>
          </div>
          <div class="chartarea-legend">
            <ul>
              <!-- <li>
              <div class="legend-item orange"></div> %ULF - {{'calculation.central_nervous_system' | translate}}
            </li> -->
              <li>
                <div class="legend-item green"></div>
                %VLF - {{ "calculation.hypothalamic_pituitary" | translate }}
              </li>
              <li>
                <div class="legend-item red"></div>
                %LF - {{ "calculation.vegetative_contour" | translate }}
              </li>
              <li>
                <div class="legend-item yellow"></div>
                %HF - {{ "calculation.autonomous_circuit" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="expert-table-wrapper">
      <table class="expert-table-style-1">
        <thead>
          <tr>
            <!-- <th colspan="2" class="expert-system-table-th">{{AnsTableData?.vascularFactor.ULF.header | lang}}</th> -->
            <th colspan="2" class="expert-system-table-th">
              {{ AnsTableData?.vascularFactor.VLF.header | lang }}
            </th>
            <th colspan="2" class="expert-system-table-th">
              {{ AnsTableData?.vascularFactor.LF.header | lang }}
            </th>
            <th colspan="2" class="expert-system-table-th">
              {{ AnsTableData?.vascularFactor.HF.header | lang }}
            </th>
            <th class="expert-system-table-th">
              {{ AnsTableData?.vascularFactor.TP.header | lang }}
            </th>
          </tr>
          <tr>
            <!-- <th class="expert-system-table-td">{{AnsTableData?.vascularFactor.ULF.ULFPercent.header | lang}}</th>
          <th class="expert-system-table-td">{{AnsTableData?.vascularFactor.ULF.ULFint.header | lang}}</th> -->
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.VLF.VLFPercent.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.VLF.VLFint.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.LF.LFPercent.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.LF.LFint.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.HF.HFPercent.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.HF.HFint.header | lang }}
            </th>
            <th class="expert-system-table-td">
              {{ AnsTableData?.vascularFactor.TP.TP.header | lang }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td>{{averageValues.FFT.perULF}}</td>
          <td>{{averageValues.FFT.ULF}}</td> -->
            <td>{{ averageValues.FFT.perVLF }}</td>
            <td>{{ averageValues.FFT.VLF }}</td>
            <td>{{ averageValues.FFT.perLF }}</td>
            <td>{{ averageValues.FFT.LF }}</td>
            <td>{{ averageValues.FFT.perHF }}</td>
            <td>{{ averageValues.FFT.HF }}</td>
            <td>{{ averageValues.FFT.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="expert-table-wrapper">
      <table class="expert-table-style-2">
        <thead>
          <tr>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.Points.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.ATd.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.VPR.header | lang }}
            </th>
            <th rowspan="2">
              {{ AnsTableData?.vascularFactorTable.HVR_V.header | lang }}
            </th>
            <th colspan="2">{{ "calculation.weight_spectr" | translate }}</th>
          </tr>
          <tr>
            <th>
              {{
                AnsTableData?.vascularFactorTable.fPercent.header_negative
                  | lang
              }}
            </th>
            <th>
              {{ AnsTableData?.vascularFactorTable.fPercent.header | lang }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ AnsTableData?.vascularFactorTable.Points.row[0] }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.ATd.row[0] | lang }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.VPR.row[0] }}</td>
            <td>{{ AnsTableData?.vascularFactorTable.HVR_V.row[0] }}</td>
            <td>
              {{
                AnsTableData?.vascularFactorTable.fPercent
                  .rowNegativeIntervals[0]
              }}
            </td>
            <td>
              {{ AnsTableData?.vascularFactorTable.fPercent.rowSignalValue[0] }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <strong>{{ averageValues.ATd }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.VPR }}</strong>
            </td>
            <td>
              <strong>{{ averageValues.HVR_V }}</strong>
            </td>
            <td>
              <strong
                >{{ averageValues.FFT_neg_interval.perULF }};
                {{ averageValues.FFT_neg_interval.perVLF }};
                {{ averageValues.FFT_neg_interval.perLF }};
                {{ averageValues.FFT_neg_interval.perHF }}</strong
              >
            </td>
            <td>
              <strong
                >{{ averageValues.FFT.perULF }}; {{ averageValues.FFT.perVLF }};
                {{ averageValues.FFT.perLF }};
                {{ averageValues.FFT.perHF }}</strong
              >
            </td>
          </tr>
          <tr
            *ngFor="
              let row of AnsTableData?.vascularFactorTable.Points.row;
              let i = index
            "
          >
            <ng-container *ngIf="i > 1">
              <td>
                <strong>{{
                  AnsTableData?.vascularFactorTable.Points.row[i]
                }}</strong>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.ATd.className
                    ? AnsTableData?.vascularFactorTable.ATd.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.ATd.className &&
                    AnsTableData?.vascularFactorTable.ATd.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.ATd.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.VPR.className
                    ? AnsTableData?.vascularFactorTable.VPR.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.VPR.className &&
                    AnsTableData?.vascularFactorTable.VPR.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.VPR.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.HVR_V.className
                    ? AnsTableData?.vascularFactorTable.HVR_V.className[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.HVR_V.className &&
                    AnsTableData?.vascularFactorTable.HVR_V.className[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.HVR_V.row[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.fPercent
                    .classNameNegativeIntervals
                    ? AnsTableData?.vascularFactorTable.fPercent
                        .classNameNegativeIntervals[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.fPercent
                      .classNameNegativeIntervals &&
                    AnsTableData?.vascularFactorTable.fPercent
                      .classNameNegativeIntervals[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.fPercent
                      .rowNegativeIntervals[i].description
                  "
                ></span>
              </td>
              <td
                [ngClass]="
                  AnsTableData?.vascularFactorTable.fPercent
                    .classNameSignalValue
                    ? AnsTableData?.vascularFactorTable.fPercent
                        .classNameSignalValue[i]
                    : ''
                "
              >
                <ng-container
                  *ngIf="
                    AnsTableData?.vascularFactorTable.fPercent
                      .classNameSignalValue &&
                    AnsTableData?.vascularFactorTable.fPercent
                      .classNameSignalValue[i]
                  "
                >
                  {{
                    AnsTableData?.vascularFactorTable.Descriptions.row[i] | lang
                  }}</ng-container
                >
                <span
                  [innerHTML]="
                    AnsTableData?.vascularFactorTable.fPercent.rowSignalValue[i]
                      .description
                  "
                ></span>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
