<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <div
    class="active-tab"
    [ngClass]="{ open: showNav }"
    (click)="showNav = !showNav"
  >
    <ng-container *ngIf="!(reloading$ | async)">
      {{ navMenu[active]?.name | lang }}
    </ng-container>
    <div class="expand"></div>
  </div>
  <nav
    class="navbar navbar-expand-lg py-0 navbar-dark"
    id="subnav"
    [ngClass]="{ show: showNav }"
  >
    <ul
      #nav="ngbNav"
      class="nav-tabs nav-fill w-100 ml-5 mr-5"
      ngbNav
      [(activeId)]="active"
    >
      <li ngbNavItem [ngbNavItem]="0">
        <a
          ngbNavLink
          routerLink="/home/admin/device-settings/global"
          routerLinkActive="active"
        >
          <ng-container *ngIf="!(reloading$ | async)">
            {{ navMenu[0]?.name | lang }}
          </ng-container>
        </a>
      </li>
      <li ngbNavItem [ngbNavItem]="1">
        <a
          ngbNavLink
          routerLink="/home/admin/device-settings/current"
          routerLinkActive="active"
        >
          <ng-container *ngIf="!(reloading$ | async)">
            {{ navMenu[1]?.name | lang }}
          </ng-container>
        </a>
      </li>
      <li ngbNavItem [ngbNavItem]="2">
        <a
          ngbNavLink
          routerLink="/home/admin/device-settings/range"
          routerLinkActive="active"
        >
          <ng-container *ngIf="!(reloading$ | async)">
            {{ navMenu[2]?.name | lang }}
          </ng-container>
        </a>
      </li>
    </ul>
  </nav>

  <router-outlet></router-outlet>
</div>
