import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShortDataService } from '../../services/short-data.service';
import { EquipmentTypeEnum } from '../../model/enums/equipment-type.enum';
import { SignalTypeEnum } from '../../model/enums/signal-type.enum';
import { PhaseTypeEnum } from '../../model/enums/phase-type.enum';
import { GraphType } from 'src/app/enums/graph_type.enum';

@Component({
  selector: 'app-ao-upload-form',
  templateUrl: './ao-upload-form.component.html',
  styleUrls: ['./ao-upload-form.component.scss'],
})
export class AoUploadFormComponent implements OnInit {
  aoForm: FormGroup = new FormGroup({});
  @Output() change = new EventEmitter();
  @Input() shortDataService: ShortDataService;

  phase: Array<any> = [];
  oscillograms: Array<any> = [];
  oscillogram: boolean = true;
  SignalType: typeof SignalTypeEnum = SignalTypeEnum;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.reactiveForm();
    this.aoForm.valueChanges.subscribe((v) => {
      this.change.emit({ type: GraphType.AO, formValue: v });
    });
  }

  reactiveForm() {
    this.aoForm = this.fb.group({
      EquipmentName: ['', Validators.required],
      measurementGroup: [''],
      phase: ['', Validators.required],
      signalType: [''],
      phaseType: [''],
    });
  }

  chooseExactly(e) {
    const value = e;
    // this.isHide = false;
    this.phase = [];
    this.oscillograms = [];
    if (value === EquipmentTypeEnum.Wat) {
      // this.isHide = true;
      this.oscillogram = false;
      this.phase.push({ name: '1 фаза', id: '1' }, { name: '3 фази', id: '3' });
    }

    if (value === EquipmentTypeEnum.Omron) {
      this.oscillograms.push({
        id: this.SignalType.Oscillogram,
        name: 'Осцилограма',
      });
    } else {
      this.oscillograms.push(
        { id: this.SignalType.Oscillogram, name: 'Осцилограма' },
        { id: this.SignalType.Curve, name: 'Крива' }
      );
    }

    if (
      value === EquipmentTypeEnum.Omron ||
      value === EquipmentTypeEnum.DrFrei
    ) {
      this.oscillogram = true;
      this.phase.push({ name: '1 фаза', id: '1' }, { name: '2 фази', id: '2' });
    }
  }
  chooseFases(value) {
    if (this.aoForm.get('EquipmentName').value === EquipmentTypeEnum.Wat) {
      if (value == '1') {
        this.aoForm.get('phaseType').patchValue(PhaseTypeEnum.Asc);
      } else if (value == '3') {
        this.aoForm.get('phaseType').patchValue(PhaseTypeEnum.Compr_Satds_Sats);
      }
    }

    if (this.aoForm.get('EquipmentName').value === EquipmentTypeEnum.Omron) {
      if (value == '1') {
        this.aoForm.get('phaseType').patchValue(PhaseTypeEnum.Desc);
      } else if (value == '2') {
        this.aoForm.get('phaseType').patchValue(PhaseTypeEnum.Asc_and_Desc);
      }
    }
  }
}
