import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { Biosignal } from 'src/app/models/biosignal';
import { ParsAoi } from 'src/app/models/pars-aoi';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';

@Component({
  selector: 'app-pars',
  templateUrl: './pars.component.html',
  styleUrls: ['./pars.component.scss'],
})
export class ParsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.parsAoiTableData) {
        this.setAverageValuesAndInterpretations();
      }
    }
  }
  parsAoiTableData: ParsAoi;
  biosignals: Biosignal[];
  indicatorDescription: string;
  averageValues = {
    Mo: '0',
    VPR: '0',
    AMo_IN: '0',
    IN: '0',
    HVR_V: '0',
    V: '0',
    VLF: '0',
    LF: '0',
    HF: '0',
    parsAoi: 0,
  };

  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
    this.getParsData();
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
  }

  getParsData() {
    this.expertSystemService
      .getParsAoi()
      .pipe(untilComponentDestroyed(this))
      .subscribe((parsAoi) => {
        this.parsAoiTableData = parsAoi;
        if (this.biosignals && this.biosignals.length) {
          this.calculateClassInterpretation(this.parsAoiTableData.table);
          this.setAverageValuesAndInterpretations();
        }
      });
  }

  setAverageValuesAndInterpretations(): void {
    this.averageValues.Mo = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'MoPos'
    );
    this.averageValues.VPR = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'VPRpos'
    );
    this.averageValues.AMo_IN = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'AMoPos'
    );
    this.averageValues.IN = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'INpos'
    );
    this.averageValues.HVR_V = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'HVRindexPos'
    );
    this.averageValues.V = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'V'
    );
    this.averageValues.VLF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perVLF'
    );
    this.averageValues.LF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perLF'
    );
    this.averageValues.HF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perHF'
    );
    this.averageValues.parsAoi = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'PARSint')
    );
    this.expertSystemService.pars = +this.averageValues.parsAoi;
    if (+this.averageValues.parsAoi > 0 && +this.averageValues.parsAoi <= 10) {
      this.indicatorDescription =
        this.parsAoiTableData.indicatorDescription.find(
          (obj) =>
            +this.averageValues.parsAoi >= obj.minValue &&
            +this.averageValues.parsAoi <= obj.maxValue
        ).description;
    } else {
      this.indicatorDescription = '';
    }
    if (this.parsAoiTableData) {
      this.calculateClassInterpretation(this.parsAoiTableData.table);
    }
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    let sum = 0,
      count = 0;
    this.biosignals.forEach((biosignal) => {
      if (biosignal[object]) {
        sum += biosignal[object][property];
        count += 1;
      }
    });
    return sum ? (sum / count).toFixed(2) : '0';
  }

  calculateClassInterpretation(table) {
    Object.entries(table).forEach((values) => {
      let maxValues = values[1]['row'][values[1]['row'].length - 1].maxValue;
      if (Array.isArray(maxValues)) {
        let averageValues = [];
        maxValues.forEach((item) => {
          const itemObj = Object.entries(item);
          let obj = {};
          obj[itemObj[0][0]] = this.averageValues[itemObj[0][0]];
          averageValues.push(obj);
          values[1]['className'] = [];
          this.setActiveClass(
            values[1]['row'],
            values[1]['className'],
            averageValues
          );
        });
      } else {
        let averageValue = this.averageValues[values[0]];
        values[1]['className'] = [];
        this.setActiveClass(
          values[1]['row'],
          values[1]['className'],
          averageValue
        );
      }
    });
  }

  setActiveClass(cols, colsClasses, averageValues) {
    cols.forEach((col, i) => {
      let isEquals: any = [];
      if (i > 1) {
        colsClasses[i] = '';
        isEquals = this.calculateInterpretation(col, averageValues) || [];
        if (isEquals && isEquals.length) {
          switch (i) {
            case 4: {
              if (
                !colsClasses[3] &&
                !colsClasses[5] &&
                !colsClasses[2] &&
                !colsClasses[6]
              ) {
                colsClasses[i] = 'success';
              }
              break;
            }
            case 3:
            case 5: {
              if (!colsClasses[2] && !colsClasses[6]) {
                colsClasses[4] = '';
                colsClasses[i] = 'warning';
                if (colsClasses[3]) {
                  colsClasses[5] = '';
                }
              }
              break;
            }
            case 2:
              colsClasses[4] = '';
              colsClasses[3] = '';
              colsClasses[5] = '';
              colsClasses[i] = 'error';
            case 6:
              colsClasses[2] = '';
              colsClasses[4] = '';
              colsClasses[3] = '';
              colsClasses[5] = '';
              colsClasses[i] = 'error';
              break;
          }
        }
      }
    });
  }

  calculateInterpretation(values: any, comparisonValue: any): any {
    if (typeof values.maxValue === 'number') {
      const minValue = values.minValue;
      const maxValue = values.maxValue;
      let idx = [];
      if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
        idx.push(+comparisonValue);
        return idx;
      }
    } else if (Array.isArray(values.maxValue)) {
      if (Array.isArray(comparisonValue)) {
        let idx = [];
        comparisonValue.forEach((item) => {
          const [keys] = Object.entries(item);
          const minValue = values.minValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          const maxValue = values.maxValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          if (minValue && maxValue) {
            if (
              +item[keys[0]] >= +minValue[keys[0]] &&
              +item[keys[0]] <= +maxValue[keys[0]]
            ) {
              idx.push(item[keys[0]]);
            }
          }
        });
        return idx;
      } else {
        const minValue = values.minValue[0];
        const maxValue = values.maxValue[0];
        let idx = [];
        if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
          idx.push(+comparisonValue);
          return idx;
        }
      }
    }
  }
}
