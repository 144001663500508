import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataTypeService } from 'src/app/services/data-type.service';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { OscillogramData } from 'src/app/models/oscillogram-data';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { PatientService } from 'src/app/components/measurements-data/services/patient.service';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { BiosignalsRange } from 'src/app/models/biosignals-range';

@Component({
  selector: 'app-regressive-model-atd-ats',
  templateUrl: './regressive-model-atd-ats.component.html',
  styleUrls: ['./regressive-model-atd-ats.component.scss'],
})
export class RegressiveModelAtdAtsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;
  biosignals: Biosignal[];
  biosignalsRange: Biosignal[];

  oscillogramData: any;
  rawSignalData: any;
  yScaleMin;
  yScaleMax;
  xScaleMax;
  yScaleNormal;
  view: any = true;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = false;
    setTimeout(() => {
      this.view = true;
    }, 0);
  }

  patientId: string | null = null;
  constructor(
    public dataTypeService: DataTypeService,
    public auth: AuthService,
    public cd: ChangeDetectorRef,
    private patientService: PatientService,
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {
    super(cd);
    this.patientService.patient$
      .pipe(untilComponentDestroyed(this))
      .subscribe((patient) => {
        if (patient) this.patientId = patient._id;
        else this.patientId = null;
      });
  }

  ngOnInit(): void {
    this.measurementsDataUpdateService.updateMeasurementsRange$
      .pipe(untilComponentDestroyed(this))
      .subscribe((biosignalsRange: BiosignalsRange) => {
        this.biosignalsRange = biosignalsRange.rangeBiosignals;
        this.oscillogramData = this.regressiveModel();
        const xRes: number[] = this.oscillogramData.systola;
        const yRes: number[] = this.oscillogramData.regression.diastola;
        this.yScaleMin = Math.min(...yRes);
        this.yScaleMax = Math.max(...yRes);
        let series = [];
        for (let i = 0; i < xRes.length; i += 1) {
          series.push({
            name: xRes[i],
            value: yRes[i],
          });
        }
        this.rawSignalData = [
          {
            name: 'signal1',
            series,
          },
        ];
      });
  }

  regressiveModel() {
    let x = this.biosignalsRange
      .map((v) => (v.TimeAnalysis?.Systola ? v.TimeAnalysis?.Systola : null))
      .filter((v) => v);
    let y = this.biosignalsRange
      .map((v) => (v.TimeAnalysis?.Diastola ? v.TimeAnalysis?.Diastola : null))
      .filter((v) => v);
    let sumX = x.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    let sumY = y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    let sumXY = x
      .map((v, i) => v * y[i])
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

    let SumXsq = x
      .map((v, i) => v * v)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

    let SumYsq = y
      .map((v, i) => v * v)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

    let k1 =
      (x.length * sumXY - sumX * sumY) / (x.length * SumXsq - sumX * sumX);
    let b1 = (sumY - k1 * sumX) / x.length;
    let yReg = x.map((v) => k1 * v + b1);

    let k2 =
      (y.length * sumXY - sumX * sumY) / (x.length * SumYsq - sumY * sumY);
    let b2 = (sumX - k2 * sumY) / y.length;
    let xReg = y.map((v) => k2 * v + b2);

    return {
      systola: x,
      diastola: y,
      regression: { systola: xReg, diastola: yReg },
      coefficient: {
        systola: { k: k1.toFixed(2), b: b1.toFixed(2) },
        diastola: { k: k2.toFixed(2), b: b2.toFixed(2) },
      },
    };
  }
}
