import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { EquipmentName } from 'src/app/enums/equipment.enum';
import { EquipmentRangeSetting } from 'src/app/models/equipment';
import { EquipmentService } from 'src/app/services/equipment.service';
import { SortEvent } from '../../measurements-settings/interfaces/event.interface';
import { SortableHeader } from 'src/app/directives/sortable-header/sortable-header.directive';

@Component({
  selector: 'app-device-range-setting',
  templateUrl: './device-range-setting.component.html',
  styleUrls: ['./device-range-setting.component.scss'],
})
export class DeviceRangeSettingComponent
  extends OnDestroyMixin
  implements OnInit
{
  settingForm: FormGroup = new FormGroup({});

  equipmentRangeSetting!: EquipmentRangeSetting[];
  equipmentList: EquipmentName[] = [];
  EquipmentName = EquipmentName;

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  tableData: any[] = [];
  defaultTable = [];
  tableBusy: boolean = false;
  constructor(
    private equipmentService: EquipmentService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    super();
    for (const item of Object.keys(EquipmentName))
      this.equipmentList.push(EquipmentName[item]);
  }

  ngOnInit(): void {
    this.reactiveForm();
  }

  ngAfterViewInit() {
    this.selectEquipment(EquipmentName.VAT);
  }

  changeRange(e) {
    let incorrect = this.equipmentRangeSetting.find(
      (v) => e >= v.StartRangeId && e <= v.EndRangeId
    );
  }

  reactiveForm() {
    this.settingForm = this.fb.group({
      EquipmentName: [EquipmentName.VAT, Validators.required],
      PackageSize: [''],
      InfSp: [],
      CuffSize: [],
      shouldTransmitSignal: [],
      StartRangeId: ['', Validators.required],
      EndRangeId: ['', Validators.required],
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      this.tableData = this.defaultTable;
    } else {
      if (direction === 'asc') {
        if (column === 'date') {
          this.tableData = [...this.tableData].sort(
            (a: Date, b: Date) =>
              new Date(a[column]).getTime() - new Date(b[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => a[column] - b[column]
          );
        }
      } else if (direction === 'desc') {
        if (column === 'date') {
          this.tableData = [...this.tableData].sort(
            (a: Date, b: Date) =>
              new Date(b[column]).getTime() - new Date(a[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => b[column] - a[column]
          );
        }
      }
    }
  }

  selectEquipment(name) {
    this.equipmentService
      .getEquipmentRangeSettingAll(`${name}`)
      .pipe(untilComponentDestroyed(this))
      .subscribe((v: any) => {
        this.settingForm.reset();
        this.reactiveForm();
        this.equipmentRangeSetting = v;
        this.tableData = v;
      });
  }

  saveSetting() {
    if (!this.settingForm.valid) {
      this.settingForm.markAllAsTouched();
      this.toastr.warning('Заповніть всі поля!');
      return;
    }
    let obj = {};
    for (const field of Object.keys(this.settingForm.controls))
      obj = Object.assign(obj, {
        [field]: this.settingForm.controls[field].value,
      });
    this.equipmentService.createEquipmentRangeSetting(obj).subscribe(
      (resp) => {
        this.translate
          .get(['alerts'])
          .pipe(untilComponentDestroyed(this))
          .subscribe((t) => {
            this.toastr.success(t.alerts.warning_4);
          });
        this.selectEquipment(this.settingForm.get('EquipmentName').value);
      },
      (err: any) => {
        this.toastr.error(err.message);
      }
    );
  }
}
