export const MEASUREMENTS_ITEMS_NAMES = [
  {
    section: 'TimeAnalysis',
    caption: 'Systola',
  },
  {
    section: 'TimeAnalysis',
    caption: 'Diastola',
  },
  {
    section: 'TimeAnalysis',
    caption: 'HeartRate',
  },
  {
    section: 'TimeAnalysis',
    caption: 'PARSint',
  },
  {
    section: 'TimeAnalysis',
    caption: 'IFSP',
  },
  {
    section: 'MorphologicalAnalysis',
    caption: 'Ms',
  },
  {
    section: 'TimeAnalysis',
    caption: 'IVHH',
  },
  {
    section: 'TimeAnalysis',
    caption: 'ICHH',
  },
  {
    section: 'TimeAnalysis',
    caption: 'IVVH',
  },
  {
    section: 'TimeAnalysis',
    caption: 'ICVH',
  },
  {
    section: 'TimeAnalysis',
    caption: 'MAP',
  },
  {
    section: 'TimeAnalysis',
    caption: 'PP',
  },
];

export const DIPPERS_LIMITS = {
  'extreme-dippers': {
    name: 'extreme-dippers',
    min: 20,
    max: 1000,
  },
  dippers: {
    name: 'dippers',
    min: 10,
    max: 20,
  },
  'non-dippers': {
    name: 'non-dippers',
    min: 0,
    max: 10,
  },
  'night-peakers': {
    name: 'night-peakers',
    min: -1000,
    max: 0,
  },
};
