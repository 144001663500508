import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgModule } from '@angular/core';
import { MorphologicalAnalysisComponent } from './morphological-analysis/morphological-analysis.component';
import { DiseaseChanceTableComponent } from './main/disease-chance-table/disease-chance-table.component';
import { RawSignalChartComponent } from './main/raw-signal-chart/raw-signal-chart.component';
import {
  NgbNavConfig,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MeasurementTableComponent } from './measurement-table/measurement-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComplexValuesCardsComponent } from './main/complex-values-cards/complex-values-cards.component';
import { HistogramsBlock2Component } from './main/histograms-block2/histograms-block2.component';
import { IndexesTableComponent } from './main/indexes-table/indexes-table.component';
import { ExpertSystemComponent } from './expert-system/expert-system.component';
import { HeartTabComponent } from './expert-system/heart-tab/heart-tab.component';
import { NervousSystemTabComponent } from './expert-system/nervous-system-tab/nervous-system-tab.component';
import { VesselsTabComponent } from './expert-system/vessels-tab/vessels-tab.component';
import { AnsTabComponent } from './expert-system/ans-tab/ans-tab.component';
import { ExtremumConformComponent } from './extremum-conform/extremum-conform.component';
import { ChartsModule } from 'ng2-charts';
import { ConfirmChangesDialogComponent } from './extremum-conform/confirm-changes-dialog/confirm-changes-dialog.component';
import { NervousSystemTableComponent } from './expert-system/nervous-system-tab/nervous-system-table/nervous-system-table.component';
import { IfspTabComponent } from './expert-system/ifsp-tab/ifsp-tab.component';
import { ExpertTabComponent } from './expert-system/expert-tab/expert-tab.component';
import { RufierTestTabComponent } from './expert-system/rufier-test-tab/rufier-test-tab.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportComponent } from './report/report.component';
import { ParsAoiTabComponent } from './expert-system/pars-aoi-tab/pars-aoi-tab.component';
import { ParsComponent } from './expert-system/pars-aoi-tab/pars/pars.component';
import { IfspComponent } from './expert-system/ifsp-tab/ifsp/ifsp.component';
import { AnsComponent } from './expert-system/ans-tab/ans/ans.component';
import { HeartComponent } from './expert-system/heart-tab/heart/heart.component';
import { VesselsComponent } from './expert-system/vessels-tab/vessels/vessels.component';
import { NervousComponent } from './expert-system/nervous-system-tab/nervous/nervous.component';
import { AccordionModule } from '../accordion/accordion.module';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { OscillogramnDialogComponent } from '../modals/oscillogram-dialog/oscillogram-dialog.component';
import { NgbModalDraggableModule } from 'ngb-modal-draggable';
import { MartineTestComponent } from './expert-system/martine-test/martine-test.component';
import { GeneralConclusionBoxModule } from '../global-components/general-conclusion-box/general-conclusion-box.module';
import { ChangeLangDetectorModule } from '../global-components/change-lang-detector/change-lang-detector.module';
import { DateFilterComponent } from './main-chart/charts/date-filter/date-filter.component';
import { MaterialModule } from 'src/app/material.module';
import { MainGraphComponent } from './main-chart/charts/main-graph.component';
import { AoRawSignalChartComponent } from './main/raw-signal-chart/ao-raw-signal-chart/ao-raw-signal-chart.component';
import { EcgRawSignalChartComponent } from './main/raw-signal-chart/ecg-raw-signal-chart/ecg-raw-signal-chart.component';
import { FullEcgChartComponent } from './main/raw-signal-chart/ecg-raw-signal-chart/full-ecg-chart/full-ecg-chart.component';
import { SingleScrollableChartComponent } from './main/raw-signal-chart/ecg-raw-signal-chart/full-ecg-chart/single-scrollable-chart/single-scrollable-chart.component';
import { MultiTenSecondsChartsComponent } from './main/raw-signal-chart/ecg-raw-signal-chart/full-ecg-chart/multi-ten-seconds-charts/multi-ten-seconds-charts.component';
import { EcgIndexsTableComponent } from './main/indexes-table/ecg-indexs-table/ecg-indexs-table.component';
import { AoIndexsTableComponent } from './main/indexes-table/ao-indexs-table/ao-indexs-table.component';
import { LeftBtnMenuComponent } from './main-chart/left-btn-menu/left-btn-menu.component';
import { WpRawSignalChartComponent } from './main/raw-signal-chart/wp-raw-signal-chart/wp-raw-signal-chart.component';
import { CpRawSignalChartComponent } from './main/raw-signal-chart/cp-raw-signal-chart/cp-raw-signal-chart.component';
import { FullWpRawChartComponent } from './main/raw-signal-chart/wp-raw-signal-chart/full-wp-raw-chart/full-wp-raw-chart.component';
import { WithingPanelComponent } from '../modals/withing-panel/withing-panel.component';
import { RegressiveModelAtdAtsComponent } from './main/regressive-model-atd-ats/regressive-model-atd-ats.component';
import { AngularWindowModule } from '../global-components/angular-window/angular-window.module';
import { chartsLineChartComponent } from './main/raw-signal-chart/ecg-raw-signal-chart/full-ecg-chart/single-scrollable-chart/charts-line-chart/charts-line-chart.component';

import { MeasurementTableAoComponent } from './measurement-table/measurement-table-ao/measurement-table-ao.component';
import { DiseaseChanceTableAoComponent } from './main/disease-chance-table/disease-chance-table-ao/disease-chance-table-ao.component';
import { DiseaseChanceTableEcgComponent } from './main/disease-chance-table/disease-chance-table-ecg/disease-chance-table-ecg.component';
import { ComplexValuesCardsAoComponent } from './main/complex-values-cards/complex-values-cards-ao/complex-values-cards-ao.component';
import { ComplexValuesCardsEcgComponent } from './main/complex-values-cards/complex-values-cards-ecg/complex-values-cards-ecg.component';

export const calculationsRoutes: Routes = [
  { path: 'main', component: MainComponent },
];

@NgModule({
  imports: [
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    AccordionModule,
    TranslateModule,
    PipesModule,
    GeneralConclusionBoxModule,
    ChangeLangDetectorModule,
    MaterialModule,
    NgbTooltipModule,
    NgbNavModule,
    NgSelectModule,
    NgbModalDraggableModule,
    AngularWindowModule,
  ],
  declarations: [
    MainComponent,
    MorphologicalAnalysisComponent,
    RawSignalChartComponent,
    MeasurementTableComponent,
    MeasurementTableAoComponent,
    DiseaseChanceTableComponent,
    DiseaseChanceTableEcgComponent,
    DiseaseChanceTableAoComponent,
    HistogramsBlock2Component,
    ComplexValuesCardsComponent,
    ComplexValuesCardsEcgComponent,
    ComplexValuesCardsAoComponent,
    IndexesTableComponent,
    ExpertSystemComponent,
    HeartTabComponent,
    ParsAoiTabComponent,
    NervousSystemTabComponent,
    VesselsTabComponent,
    AnsTabComponent,
    ExtremumConformComponent,
    ConfirmChangesDialogComponent,
    NervousSystemTableComponent,
    IfspTabComponent,
    ExpertTabComponent,
    RufierTestTabComponent,
    ReportComponent,
    ParsComponent,
    IfspComponent,
    AnsComponent,
    HeartComponent,
    VesselsComponent,
    NervousComponent,
    MartineTestComponent,
    OscillogramnDialogComponent,
    DateFilterComponent,
    MainGraphComponent,
    AoRawSignalChartComponent,
    EcgRawSignalChartComponent,
    FullEcgChartComponent,
    SingleScrollableChartComponent,
    MultiTenSecondsChartsComponent,
    EcgIndexsTableComponent,
    AoIndexsTableComponent,
    LeftBtnMenuComponent,
    WpRawSignalChartComponent,
    CpRawSignalChartComponent,
    FullWpRawChartComponent,
    WithingPanelComponent,
    RegressiveModelAtdAtsComponent,
    chartsLineChartComponent
  ],
  exports: [
    MainComponent,
    MorphologicalAnalysisComponent,
    MeasurementTableComponent,
    MeasurementTableAoComponent,
    ExpertSystemComponent,
    ExtremumConformComponent,
    ReportComponent,
    ParsComponent,
    IfspComponent,
    AnsComponent,
    HeartComponent,
    VesselsComponent,
    RufierTestTabComponent,
    NervousComponent,
    MartineTestComponent,
    RawSignalChartComponent,
    AoRawSignalChartComponent,
    EcgRawSignalChartComponent,
    EcgIndexsTableComponent,
    AoIndexsTableComponent,
    MainGraphComponent,
    LeftBtnMenuComponent,
  ],
  entryComponents: [],
  providers: [NgbNavConfig, DatePipe],
})
export class CalculationsModule {}
