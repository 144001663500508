import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OscillogramData } from 'src/app/models/oscillogram-data';
import { DialogData } from '../../ecg-raw-signal-chart/full-ecg-chart/full-ecg-chart.component';
import { ECGspeed } from 'src/app/enums/ecg.enum';

@Component({
  selector: 'app-full-wp-raw-chart',
  templateUrl: './full-wp-raw-chart.component.html',
  styleUrls: ['./full-wp-raw-chart.component.scss'],
})
export class FullWpRawChartComponent implements OnInit {
  @ViewChild('chart') chart: any;
  @ViewChild('xTicks') xTicks: any;
  @Input() set data(value) {
    if (value) {
      this.oscillogramData = value;
      this.update();
      this.setContainerWidth();
      setTimeout(() => {
        this.getLabelsY();
      }, 10);
    }
  }

  oscillogramData: OscillogramData;
  rawSignalData: any;
  isLoading: boolean = false;

  rawSignalData1: any = [];
  scrollWidth: number = 0;
  scrollHeight: number = 0;

  // constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  //   this.oscillogramData = data;
  // }
  constructor() {}

  ngOnInit(): void {}

  onResize(e) {
    // this.isLoading = true;
    // setTimeout(() => {
    //   // this.getYTicks();
    //   this.isLoading = false;
    // }, 1000);
  }
  update(): void {
    if (this.oscillogramData) {
      const timePoints = this.oscillogramData.timePoints;
      const valuePoints = this.oscillogramData.valuePoints;
      const series = [];

      for (let i = 0; i < timePoints.length; i += 1) {
        series.push({
          name: timePoints[i],
          value: valuePoints[i],
        });
      }
      this.rawSignalData = [
        {
          name: 'signal1',
          series,
        },
      ];
    }
  }

  setContainerWidth() {
    this.scrollWidth =
      (Math.max(...this.oscillogramData.timePoints) -
        Math.min(...this.oscillogramData.timePoints)) *
        25 *
        3.79362 +
      15;
  }
  getLabelsY() {
    const y = this.chart.chartElement.nativeElement.querySelector('.y');
    const tickArrayY = y.querySelector('g').querySelector('g').children;
    const arr = Array.prototype.slice.call(tickArrayY).slice();
    this.xTicks.nativeElement.replaceChildren();
    for (let i = 0, im = arr.length; im > i; i++) {
      this.xTicks.nativeElement.appendChild(arr[i].cloneNode(true));
    }
    let stringWithHtmlTobeSavedInTextArea: string = '';
    Array.prototype.slice
      .call(this.xTicks.nativeElement.children)
      .slice()
      .forEach((element) => {
        const title = element.querySelector('title').textContent;
        if (title) {
          let attr = element
            .getAttribute('transform')
            .split(',')[1]
            .split(')')[0];
          const text = Number(element.querySelector('text').getAttribute('x'));
          let temp = 0;
          if (Number(attr) >= 0) temp = Number(attr) + text + 9;
          else if (Number(attr) < 0) temp = Number(attr) - text + 3;
          stringWithHtmlTobeSavedInTextArea += `<div  style='position:absolute; padding-right:5px; top:${temp}px; right:0px; font-size:12px; width:max-content;'>${title}</div>`;
        }
      });
    this.xTicks.nativeElement.innerHTML = stringWithHtmlTobeSavedInTextArea;
    this.isLoading = false;
  }
}
