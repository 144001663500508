<div class="main-wrapper">
  <div class="justify-content-center">
    <div class="d-flex justify-content-center flex-wrap">
      <button
        (click)="heckButtonClick($event, 'ao')"
        class="btn-default button_active m-2"
      >
        {{ "admin.ao" | translate }}
      </button>
      <button (click)="heckButtonClick($event, 'ecg')" class="btn-default m-2">
        {{ "admin.ecg" | translate }}
      </button>
      <button (click)="heckButtonClick($event, 'wp')" class="btn-default m-2">
        {{ "admin.wp" | translate }}
      </button>
      <button (click)="heckButtonClick($event, 'cp')" class="btn-default m-2">
        {{ "admin.cp" | translate }}
      </button>
    </div>

    <div class="row">
      <div class="col">
        <div class="card-box">
          <div class="col-md-12">
            <div class="row justify-content-between mb-2 card-header">
              <div class="col-3 text-center">
                <h4>{{ "admin.doctor" | translate }}</h4>
              </div>
              <div class="col-3 text-center">
                <h4>{{ "admin.consultants" | translate }}</h4>
              </div>
              <div class="col-3 text-center">
                <h4>{{ "admin.patients" | translate }}</h4>
              </div>
              <div class="col-3 text-center">
                <h4>{{ "admin.permissions" | translate }}</h4>
              </div>
            </div>
            <div
              class="row justify-content-between card-row"
              *ngFor="let item of permissionsMobile; let i = index"
            >
              <div class="col-md-3">
                <div class="form-check">
                  <label>
                    <input
                      class="form-check-input position-static"
                      type="checkbox"
                      [checked]="isPermissions(item.rights, 'DOCTOR')"
                      (change)="select($event, item, 'DOCTOR')"
                    />
                    <span>{{ "admin.doctor" | translate }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check">
                  <label>
                    <input
                      class="form-check-input position-static"
                      type="checkbox"
                      [checked]="isPermissions(item.rights, 'CONSULTANT')"
                      (change)="select($event, item, 'CONSULTANT')"
                    />
                    <span>{{ "admin.consultants" | translate }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check">
                  <label>
                    <input
                      class="form-check-input position-static"
                      type="checkbox"
                      [checked]="isPermissions(item.rights, 'PATIENT')"
                      (change)="select($event, item, 'PATIENT')"
                    />
                    <span>{{ "admin.patients" | translate }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <p>{{ "admin." + item.code | translate }}</p>
              </div>
            </div>

            <div class="row d-flex justify-content-end">
              <div class="col-auto">
                <a (click)="submit()" class="btn-default">{{
                  "form.save" | translate
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
