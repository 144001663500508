<form [formGroup]="myForm" class="mb-3">
  <div class="row">
    <div class="col-md-12 h4">
      {{ "measurement.signal_registration_date" | translate }}
    </div>
  </div>
  <div class="row">
    <mat-form-field class="col-md-3">
      <mat-label>{{ "form.from" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="fromDate"
        (dateInput)="onDateInput()"
        formControlName="fromDate"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="fromDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDate touchUi></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-3">
      <mat-label>{{ "form.to" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="toDate"
        (dateInput)="onDateInput()"
        formControlName="toDate"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="toDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #toDate touchUi></mat-datepicker>
    </mat-form-field>
  </div>
</form>

<div class="expert-table-wrapper">
  <table
    id="myTable"
    class="expert-table-style-3 table-wrapper"
    matSort
    (matSortChange)="sortData($event)"
  >
    <thead>
      <tr>
        <th>№</th>
        <th mat-sort-header="SignalRegistrationDateTime" class="text-left">
          {{ "measurement.signal_registration_date" | translate }}
        </th>
        <th mat-sort-header="uploadDateTime" class="text-left">
          {{ "measurement.download_date" | translate }}
        </th>
        <th>{{ "registration.patientName" | translate }}</th>
        <th>{{ "registration.doctorName" | translate }}</th>
        <th>ID</th>
        <th>{{ "measurement.name_meter" | translate }}</th>
        <th>MacId</th>
        <th mat-sort-header="Status" class="text-left">
          {{ "measurement.status" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let err of sortedData
            | paginate
              : {
                  currentPage: p1,
                  itemsPerPage: itemsPerPage,
                  id: 'pagination1'
                };
          let i = index
        "
      >
        <td>
          <span *ngIf="p1 == 1">{{ i + 1 }}</span>
          <span *ngIf="p1 > 1">{{ (p1 - 1) * itemsPerPage + (i + 1) }}</span>
        </td>
        <td>{{ err.SignalRegistrationDateTime | date : "dd-MM-YYYY" }}</td>
        <td>{{ err.uploadDateTime | date : "dd-MM-YYYY" }}</td>
        <td>
          {{ err.PatientID.first_name }}
          {{ err.PatientID.last_name }}
          {{ err.PatientID.second_name }}
        </td>
        <td>
          {{ err.DoctorID?.last_name ? err.DoctorID?.first_name : "" }}
          {{ err.DoctorID?.last_name ? err.DoctorID?.last_name : "" }}
          {{ err.DoctorID?.last_name ? err.DoctorID?.second_name : "" }}
        </td>
        <td>{{ err.EquipmentID }}</td>
        <td>{{ err.EquipmentName }}</td>
        <td>{{ err.EquipmentMacId }}</td>
        <td>{{ err.Status }}</td>
      </tr>
    </tbody>
  </table>
</div>
<paging-controls
  id="pagination1"
  (pageChange)="p1 = $event"
  [isItemsPerPage]="false"
  maxSize="9"
  directionLinks="true"
  autoHide="true"
  responsive="false"
></paging-controls>
<button class="btn btn-primary mt-3" (click)="generateCsv()">
  {{ "measurement.download" | translate }}
</button>
<hr />

<div class="expert-table-wrapper mt-5">
  <table
    id="myTable"
    class="expert-table-style-3 table-wrapper"
    matSort
    (matSortChange)="sortDeviceMeasurementsErrData($event)"
  >
    <thead>
      <tr>
        <th>№</th>
        <th mat-sort-header="timestamp" class="text-left">timestamp</th>
        <th mat-sort-header="deviceId">deviceId</th>
        <th>message</th>
        <th>action</th>
        <th>type</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let err of errSortedDeviceMeasurementsData
            | paginate
              : {
                  currentPage: p2,
                  itemsPerPage: itemsPerPage,
                  id: 'pagination2'
                };
          let i = index
        "
      >
        <td>
          <span *ngIf="p2 == 1">{{ i + 1 }}</span>
          <span *ngIf="p2 > 1">{{ (p2 - 1) * itemsPerPage + (i + 1) }}</span>
        </td>
        <td>{{ err.timestamp | date : "dd-MM-YYYY, HH:mm" }}</td>
        <td>{{ err.deviceId }}</td>
        <td>{{ err.message }}</td>
        <td>{{ err.action }}</td>
        <td>{{ err.type }}</td>
      </tr>
    </tbody>
  </table>
</div>
<paging-controls
  id="pagination2"
  (pageChange)="p2 = $event"
  [isItemsPerPage]="false"
  maxSize="9"
  directionLinks="true"
  autoHide="true"
  responsive="false"
></paging-controls>
