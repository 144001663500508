import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConsultantsComponent } from './consultants.component';
import { EditComponent } from './edit/edit.component';
import { ConsultantsRoutingModule } from './consultants-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { AccordionModule } from '../accordion/accordion.module';
import { ConsultantItemComponent } from './item/item.component';
import { ConsultantModule } from '../global-components/consultant/consultant.module';
import { ScheduleModule } from '../global-components/schedule/schedule.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [ConsultantsComponent, EditComponent, ConsultantItemComponent],
  imports: [
    CommonModule,
    ConsultantsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    AccordionModule,
    NgbNavModule,
    NgSelectModule,
    NgbTooltipModule,
    ConsultantModule,
    ScheduleModule,
    MaterialModule,
  ],
  exports: [ConsultantItemComponent],
  entryComponents: [],
})
export class ConsultantsModule {}
