import { Injectable } from '@angular/core';
import { PhaseTypeEnum } from '../model/enums/phase-type.enum';
import { EquipmentTypeEnum } from '../model/enums/equipment-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ShortDataService {
  equipment_ao = [
    { value: EquipmentTypeEnum.Wat, ua: 'BAT41-2', en: 'VAT' },
    // { value: 'VAT41-2', ua: 'BAT41-2', en: 'VAT' },
    { value: EquipmentTypeEnum.Omron, ua: 'Омрон', en: 'Omron' },
    { value: EquipmentTypeEnum.DrFrei, ua: 'Др.Фрей', en: 'Dr.Frai' },
  ];

  equipment_ecg = [{ value: 'Withings', ua: 'Withings', en: 'Withings' }];

  equipment_wp = [{ value: 'PulseFlex', ua: 'PulseFlex', en: 'PulseFlex' }];

  equipment_cp = [{ value: 'CranioFlex', ua: 'CranioFlex', en: 'CranioFlexF' }];

  phases = [
    { id: PhaseTypeEnum.Asc, value: 'Зростаюча' },
    { id: PhaseTypeEnum.Desc, value: 'Спадаюча' },
    { id: PhaseTypeEnum.Asc_and_Desc, value: 'Зростаюча + спадаюча' },
    { id: PhaseTypeEnum.Compr_Satds_Sats, value: 'Компр + S АТд + S АТс' },
  ];

  constructor() {}
}
