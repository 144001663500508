<div class="d-flex flex-row justify-content-center">
  <div class="button-3 mx-3">
    <input
      id="count_unit"
      type="radio"
      name="radio3"
      checked
      (click)="this.date.emit(false)"
    />
    <label class="lb-mr" for="count_unit">№</label>
  </div>
  <div class="button-3 mx-3">
    <input
      id="date_unit"
      type="radio"
      name="radio3"
      (click)="this.date.emit(true); this.setLimitsDateFilter()"
    />
    <label class="lb-mr" for="date_unit">
      {{ "measurement.date" | translate }}
    </label>
  </div>
  <div class="date-range mx-3" *ngIf="sdpDateUnits">
    <button class="addBtn date-icon" (click)="isOpenCalendar = !isOpenCalendar">
      <i class="bi bi-calendar-week"></i>
    </button>
    <div class="date-container" *ngIf="isOpenCalendar">
      <div class="row">
        <mat-form-field class="datepicker col-6">
          <mat-label>{{ "calculation.date_from" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="fromDatePicker"
            [(ngModel)]="fromDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker> </mat-datepicker>
        </mat-form-field>
        <div class="form-group col-6">
          <label for="">{{ "calculation.time_from" | translate }}</label>
          <input
            type="time"
            class="form-control"
            placeholder="dd.mm.yyyy"
            [(ngModel)]="fromTime"
          />
        </div>
        <mat-form-field class="datepicker col-6">
          <mat-label>{{ "calculation.date_to" | translate }}</mat-label>
          <input matInput [matDatepicker]="toDatePicker" [(ngModel)]="toDate" />
          <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
          </mat-datepicker-toggle>
          <mat-datepicker #toDatePicker> </mat-datepicker>
        </mat-form-field>
        <div class="form-group col-6">
          <label for="">{{ "calculation.time_to" | translate }}</label>
          <input
            type="time"
            class="form-control"
            placeholder="dd.mm.yyyy"
            [(ngModel)]="toTime"
          />
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <div class="col-auto">
          <button class="btn btn-primary" (click)="isOpenCalendar = false">
            {{ "login.cancel" | translate }}
          </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary" (click)="onDateSelection()">
            {{ "form.save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
