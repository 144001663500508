<div class="expert-tab-wrapper">
  <div class="disease-chance">
    <app-disease-chance-table
      [setBiosignalsRange]="biosignalsData"
    ></app-disease-chance-table>
  </div>
</div>
<div class="e-s-measurement-table-wrapper">
  <app-measurement-table></app-measurement-table>
</div>
