import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserRightsFiltered } from '../models/user-rights';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  get(): Observable<UserRightsFiltered> {
    return this.http.get<UserRightsFiltered>(
      `${environment.apiUrl}/user-rights`
    );
  }

  // post(data: Consultant): Observable<Consultant> {
  //   return this.http.post<Consultant>(`${environment.apiUrl}/consultants`, data);
  // }

  update(data: any): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/user-rights`, data);
  }
}
