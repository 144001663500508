import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RoleTypeEnum } from 'src/app/components/auth/model/enums/role.enum';
import { SubjectService } from '../subject.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends SubjectService {
  token: string;
  language: string;
  withingsToken: string;
  language$ = new BehaviorSubject('ua');
  protected subjects: any = {};
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService
  ) {
    super();
    this.token = localStorage.getItem('token');
    this.language = localStorage.getItem('language');
    this.withingsToken = localStorage.getItem('withingsToken');
  }

  authUser(user): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/signin`, user);
  }

  setUser(token, user): void {
    if (token) {
      localStorage.setItem('token', token);
      this.token = token;
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUser(): any {
    if (localStorage.getItem('currentUser') !== null) {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        return user;
      }
    }
  }

  setLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.language = language;
    this.language$.next(language);
  }

  logout(): void {
    localStorage.clear();
    // this.token = null;
    this.router.navigate(['/auth']);
  }

  isLoggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  public getToken(): string {
    return this.token;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getRole() {
    return this.jwtHelper.decodeToken(this.token).role;
  }

  public getId() {
    return this.jwtHelper.decodeToken(this.token).id;
  }

  public isPatient() {
    return this.getRole() === RoleTypeEnum.Patient;
  }

  public isDoctor() {
    return this.getRole() === RoleTypeEnum.Doctor;
  }

  public isConsultant() {
    return this.getRole() === RoleTypeEnum.Consultant;
  }

  public isAdmin() {
    return this.getRole() === RoleTypeEnum.Admin;
  }

  public get photo$(): Observable<any> {
    return this.getSubject$('photo');
  }

  public set photo(value: any) {
    this.getSubject('photo').next(value);
  }
}
