<div class="sdp">
  <div class="sdp__buttons">
    <div
      class="sdp__buttons__block-1"
      *ngIf="customLinerChartService.line_btn[ACTIVE_GRAPH]"
    >
      <div
        *ngFor="
          let item of customLinerChartService.line_btn[ACTIVE_GRAPH][
            sdpCurrPage
          ];
          let i = index
        "
      >
        <div class="button-1" [class]="item.class">
          <input
            #checkboxes
            id="{{ item.id }}"
            type="checkbox"
            checked
            (click)="enableLine(item.line_name, $event.target.checked)"
          />
          <label class="lb-mr" for="{{ item.id }}"></label>
          {{ item.label | translate }}
        </div>
      </div>
    </div>
    <div class="sdp_wrap">
      <div class="sdp__buttons__block-3">
        <ng-select
          class="box-select"
          [clearable]="false"
          bindLabel="label"
          bindValue="id"
          [(ngModel)]="measurementsNumber"
          (change)="onChangeMeasurements()"
        >
          <ng-option *ngFor="let item of measurementsNumbers" [value]="item.id">
            {{ item.label | lang }}
          </ng-option>
        </ng-select>
      </div>
      <div class="btn-wrap">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addSignalToComparison()"
        >
          <span>{{ "admin.comparison" | translate }}</span>
        </button>
      </div>
      <div class="sdp__buttons__block-2">
        <div
          class="button-2"
          *ngFor="
            let item of customLinerChartService.line_btn[ACTIVE_GRAPH];
            let i = index
          "
        >
          <input
            id="page-btn-{{ i }}"
            type="radio"
            name="radio2"
            [checked]="i == 0 ? true : false"
            (click)="selectSdpPage(i)"
          />
          <label for="page-btn-{{ i }}">{{ i + 1 }}</label>
        </div>
      </div>
      <mat-checkbox
        [(ngModel)]="arterialStiffnessIndex"
        (change)="getArterialStiffnessIndex($event.checked)"
        >{{ "calculation.arterial_stiffness_index" | translate }}</mat-checkbox
      >
    </div>
  </div>
  <app-main-graph
    [chartsDataIndex]="sdpCurrPage"
    [measurementsNumber]="measurementsNumber"
    [SET_ACTIVE_GRAPH]="ACTIVE_GRAPH"
    [setBiosignalsRange]="biosignalsRange"
    [setBiosignals]="biosignals"
  ></app-main-graph>
</div>
