<div #ContainerRef style="height: 200px; width: 100%">
  <ngx-charts-line-chart
    #chart
    [view]="[ContainerRef.offsetWidth, ContainerRef.offsetHeight]"
    (window:resize)="onResize($event)"
    [xAxis]="true"
    [yAxis]="true"
    [rotateXAxisTicks]="false"
    [showXAxisLabel]="false"
    [showYAxisLabel]="false"
    [results]="item"
    [yAxisTickFormatting]="formatAxisTickDateY"
    [xAxisTickFormatting]="formatAxisTickDateX"
    [yAxisTicks]="yAxisTicks"
    [xAxisTicks]="xAxisTicks"
    [xScaleMin]="min"
    [xScaleMax]="min + 10"
    [yScaleMin]="timePointsInterval.minY"
    [yScaleMax]="timePointsInterval.maxY"
  >
  </ngx-charts-line-chart>
</div>
