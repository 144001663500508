<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{"measurement.recalculate"
    | translate}}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('CLOSE')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{'measurement.number_measurement' | translate}} {{biosignals.length}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss('CANCEL')">{{'login.cancel' | translate}}</button>
  <button class="btn btn-danger" (click)="submit()">{{'measurement.confirm' | translate}}</button>
</div>
