import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OscillogramData } from 'src/app/models/oscillogram-data';
import { FullEcgChartComponent } from './full-ecg-chart/full-ecg-chart.component';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-ecg-raw-signal-chart',
  templateUrl: './ecg-raw-signal-chart.component.html',
  styleUrls: ['./ecg-raw-signal-chart.component.scss'],
})
export class EcgRawSignalChartComponent implements OnInit {
  leadId: string;
  ecgLeadsFull: any;
  @Input() set setOscillogramData(value: OscillogramData) {

    if (value) {
      let keys = Object.keys(value);
      this.timePoints = value[keys[0]].timePoints;
      this.valuePoints = value[keys[0]].valuePoints;
      for (const key in value) {
        this.transformValuePointsToMV(value[key]);
      }


    // this.ecgLeadsFull = value;
    // if (value && this.leadId) {
    //   this.oscillogramData = this.transformValuePointsToMV(value[this.leadId]);
    //   this.timePoints = this.oscillogramData.timePoints;
    //   this.valuePoints = this.oscillogramData.valuePoints;

      this.update();
    }
  }
  // oscillogramData: [OscillogramData];
  oscillogramData: any = [];
  rawSignalData: any;

  xAxisTicks: number[] = [];
  yAxisTicks: number[] = [];

  timePoints: Array<number> = [];
  valuePoints: Array<number> = [];
  constructor(
    public dialog: MatDialog,
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {}

  ngOnInit(): void {
    this.measurementsDataUpdateService.changeEcgLead('I');
    this.measurementsDataUpdateService.selectedEcgLead$.subscribe((id) => {
      this.leadId = id;
      // if (this.oscillogramData) {
      //   this.oscillogramData = this.transformValuePointsToMV(
      //     this.ecgLeadsFull[this.leadId]
      //   );
      //   this.timePoints = this.oscillogramData.timePoints;
      //   this.valuePoints = this.oscillogramData.valuePoints;
      //   this.update();
      // }

    });
  }

  numberWithSpaces(x: number) {
    let parts = x.toString().split('.');
    return parts[0].length > 1 ? 1 : 0;
  }

  transformValuePointsToMV(oscillogramData: OscillogramData): OscillogramData {
    if (
      oscillogramData?.timePoints?.length &&
      oscillogramData?.valuePoints?.length
    ) {
      oscillogramData.timePoints = oscillogramData.timePoints.slice(
        0,
        oscillogramData.valuePoints.length
      );

    // this.oscillogramData.push(oscillogramData);
    // return;


      let maxPointValue: number = Math.max(...oscillogramData.valuePoints);
      let spaceCounter: number = this.numberWithSpaces(maxPointValue);
      const voltConvertation: number = 1000 * spaceCounter;
      if (voltConvertation)
        oscillogramData.valuePoints = oscillogramData.valuePoints.map(
          (v) => v / voltConvertation
        );
        this.oscillogramData.push(oscillogramData);
    return;
    }


  }

  update(): void {
    if (this.oscillogramData) {
      this.rawSignalData = [...[]];
      const series = [];
      let index: number = 0;
      for (let i = 0; i < this.timePoints.length; i += 1) {
        if (this.timePoints[i] <= 15 && this.timePoints[i] >= 5) {
          series.push({
            name: this.timePoints[i],
            value: this.valuePoints[i],
          });
          index = i;
        } else if (this.timePoints[i] > 15) {
          index = i;
          break;
        }
      }

      for (let i = 0; i > Math.min(...this.valuePoints); i += -0.5)
        this.yAxisTicks.push(i);

      for (let i = 0; i < Math.max(...this.valuePoints); i += 0.5)
        this.yAxisTicks.push(i);

      for (let i = 5; i <= 15; i += 1) {
        this.xAxisTicks.push(i);
      }

      this.rawSignalData = [{ name: 'signal1', series }];
    }
  }

  formatAxisTickDateX = (i) => {
    return i;
  };

  formatAxisTickDateY = (i) => {
    return (i / 10) % 0.5 == 0 ? (i / 10).toFixed(1) : '';
  };

  formatAxisTickDate = (i) => {
    return i;
  };

  fullEcgGraph(oscillogramData) {

    const dialogRef = this.dialog.open(FullEcgChartComponent, {
      width: '90vw',
      data: {
        oscillogramData: this.oscillogramData,
      },
    });
    

    //-------------need fix for new structure ecg---------
    // const dialogRef = this.dialog.open(FullEcgChartComponent, {
    //   width: '90vw',
    //   data: {
    //     timePoints: this.oscillogramData.timePoints,
    //     valuePoints: this.oscillogramData.valuePoints,
    //   },
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log('The dialog was closed');
    // });
  }
}
