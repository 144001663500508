import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { ShortDataService } from '../../services/short-data.service';

@Component({
  selector: 'app-wp-upload-form',
  templateUrl: './wp-upload-form.component.html',
  styleUrls: ['./wp-upload-form.component.scss'],
})
export class WpUploadFormComponent implements OnInit {
  wpForm: FormGroup = new FormGroup({});
  @Output() change = new EventEmitter();
  @Input() shortDataService: ShortDataService;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.reactiveForm();
    this.wpForm.valueChanges.subscribe((v) => {
      this.change.emit({ type: GraphType.WP, formValue: v });
    });
  }

  reactiveForm() {
    this.wpForm = this.fb.group({
      EquipmentName: ['', Validators.required],
    });
  }
}
