import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpecialityLevelsService {
  specialty_level = [
    {
      value: 'BASIC',
      ua: 'Лікар-спеціаліст',
      en: 'BASIC',
      pl: 'Lekarz specjalista',
    },
    {
      value: 'FIRST',
      ua: 'Перша категорія',
      en: 'FIRST',
      pl: 'Doktor pierwsza kategoria',
    },
    {
      value: 'HIGHEST',
      ua: 'Вища категорія',
      en: 'HIGHEST',
      pl: 'Doktor wyższej kategorii',
    },
    {
      value: 'NOT_APPLICABLE',
      ua: 'Не застосовується',
      en: 'NOT_APPLICABLE',
      pl: 'Nie dotyczy',
    },
    {
      value: 'SECOND',
      ua: 'Друга категорія',
      en: 'SECOND',
      pl: 'Doktor druga kategorii',
    },
  ];
}
