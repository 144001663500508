import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDay'
})
export class WeekDayPipe implements PipeTransform {
  weakDay = [
    { value: 'sun', ua: 'Нед', en: 'Sun' },
    { value: 'mon', ua: 'Пон', en: 'Mon' },
    { value: 'tue', ua: 'Віт', en: 'Tue' },
    { value: 'wed', ua: 'Сер', en: 'Wed' },
    { value: 'thu', ua: 'Чет', en: 'Thu' },
    { value: 'fri', ua: 'Птн', en: 'Fri' },
    { value: 'sat', ua: 'Суб', en: 'Sat' }
  ];

  transform(value: any): any {
    if (!value || value == [] || value == [""]) {
      return [];
    } else {
      const dayOfWeak:any[] = [];
      value.forEach((element:any) => {
        this.weakDay.forEach(elemWeakDay => {
          if (elemWeakDay.value == element) dayOfWeak.push(elemWeakDay)
        })
      });
      return dayOfWeak;
    }
  }
}
