<div class="sdp__chartarea">
  <div class="sdp__help-text">
    <p>{{ "calculation.help_text" | translate }}</p>
    <button type="button" class="btn btn-primary wsn" (click)="resetData()">
      <span>{{ "form.reload" | translate }}</span>
      <i class="bi bi-arrow-repeat"></i>
    </button>
  </div>
  <div class="sdp__chartoverlay" #containerRef>
    <ngx-charts-line-chart
      [view]="view"
      *ngIf="data.length > 0"
      class="sdp__chart"
      id="chart"
      #chart
      [results]="data[chartsDataIndex]"
      [xAxis]="true"
      [yAxis]="true"
      [scheme]="colorScheme[chartsDataIndex]"
      (contextmenu)="sdpOnContextMenu($event)"
      (dblclick)="sdpOnContextMenu($event)"
      (touchstart)="touchstart($event)"
      (touchend)="touchend($event)"
      (touchmove)="touchmove($event)"
      (mousedown)="sdpOnMouseDown($event)"
      (mousemove)="sdpOnMouseHover($event)"
      (mouseup)="sdpOnMouseUp($event)"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [yAxisLabel]="
        customLinerChartService.graph_y_label[ACTIVE_GRAPH][chartsDataIndex]
          | translate
      "
      [autoScale]="true"
      [trimXAxisTicks]="true"
      [trimYAxisTicks]="true"
      [xAxisTickFormatting]="
        sdpDateUnits ? formatXAxisTickDate : formatXAxisTick
      "
      [yScaleMin]="
        chartsDataIndex == 3 ? (diastola.min < 60 ? diastola.min : 60) : ''
      "
      [yScaleMax]="
        chartsDataIndex == 3
          ? systola.max > 140
            ? systola.max
            : DIPPERRS_THRESHOLDS.s_val
          : ''
      "
      [yAxisTicks]="
        chartsDataIndex == 3 && yDippersTicks?.length ? yDippersTicks : ''
      "
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class="chart-tooltip-container">
          <div class="content">
            <div class="text-tooltip text-center">
              {{
                model.key.EquipmentName
                  | equipmentIdentifier : model.key.EquipmentID
              }}
              ‧
              {{ chartNameService.chart_name[model.series] | lang }} ‧
              {{ model?.name }}
            </div>
          </div>
          <div class="content">
            <div class="text-tooltip">
              {{ model.value | number : "1.2-2" }}
            </div>
          </div>
          <div class="content" *ngIf="sdpDateUnits">
            <div class="text-tooltip">
              {{ model.key.date | date : "dd-MM-YYYY, HH:mm" }}
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #seriesTooltipTemplate let-model="model">
        <div class="chart-tooltip-container">
          {{
            model[0].key.EquipmentName
              | equipmentIdentifier : model[0].key.EquipmentID
          }}
          <div class="content" *ngFor="let item of model">
            <div class="color" [style.background-color]="item.color"></div>
            <div class="text-tooltip">
              {{ chartNameService.chart_name[item.series] | lang }}:
              {{ item.value | number : "1.2-2" }}
            </div>
          </div>
          <div class="text-tooltip" *ngIf="sdpDateUnits">
            {{ model[0].key.date | date : "dd-MM-YYYY, HH:mm" }}
          </div>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
    <div
      #sdp_chart_thresholds
      class="sdp__chart_thresholds"
      [hidden]="chartsDataIndex !== 2"
    >
      <div #sdp_thresh_1 class="sdp__dash_line1"></div>
      <div #sdp_thresh_2 class="sdp__dash_line2"></div>
      <div #sdp_thresh_3 class="sdp__dash_line3"></div>
    </div>
    <div
      #sdp_chart_thresholds_dippers
      class="sdp__chart_thresholds"
      [hidden]="chartsDataIndex !== 3"
    >
      <div #sdp_thresh_dipper_1 class="sdp__dash_line1"></div>
      <div #sdp_thresh_dipper_2 class="sdp__dash_line1"></div>
      <div #sdp_thresh_dipper_5 class="sdp__dash_line1"></div>
      <div #sdp_thresh_dipper_3 class="sdp__dash_line2"></div>
      <div #sdp_thresh_dipper_4 class="sdp__dash_line2"></div>
    </div>

    <div
      #sdp_range_selection
      id="sdp_range_selection"
      class="sdp__chart_selection"
      [hidden]="!selectionVisible"
    ></div>
    <div
      #sdp_single_selection
      id="sdp_single_selection"
      class="sdp__chart_selection2"
    ></div>
  </div>
  <app-date-filter
    [sdpDateUnits]="sdpDateUnits"
    [biosignalsCopy]="biosignalsCopy"
    [biosignals]="biosignals"
    (date)="switchToDateUnits($event)"
    (filterBiosignal)="filterBiosignal($event)"
  ></app-date-filter>
</div>
