import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParsAoi } from '../models/pars-aoi';
import { environment } from '../../environments/environment';
import { Vessels } from '../models/vessels';
import { NervousSystem } from '../models/nervous-system/nervous-system';
import { Heart } from '../models/heart';
import { Ifsp } from '../models/ifsp';
import { Ans } from '../models/ans';
import { RufierTest } from '../models/rufier';
import { SubjectService } from './subject.service';

@Injectable({
  providedIn: 'root',
})
export class ExpertSystemService extends SubjectService {
  private inner: {
    pars: number;
    ifsp: number;
    ans: number;
  } = { pars: null, ifsp: null, ans: null };
  protected subjects: any = {};
  constructor(private http: HttpClient) {
    super();
  }

  getParsAoi(): Observable<ParsAoi> {
    return this.http.get<ParsAoi>(
      `${environment.apiUrl}/expert-system/PARS-AO`
    );
  }

  getVessels(): Observable<Vessels> {
    return this.http.get<Vessels>(
      `${environment.apiUrl}/expert-system/vessels`
    );
  }

  getNervousSystem(): Observable<NervousSystem> {
    return this.http.get<NervousSystem>(
      `${environment.apiUrl}/expert-system/nervous-system`
    );
  }

  getHeart(): Observable<Heart> {
    return this.http.get<Heart>(`${environment.apiUrl}/expert-system/heart`);
  }

  getIfsp(): Observable<Ifsp> {
    return this.http.get<Ifsp>(`${environment.apiUrl}/expert-system/IFSP`);
  }

  getAns(): Observable<Ans> {
    return this.http.get<Ans>(`${environment.apiUrl}/expert-system/ANS`);
  }

  getRufierTest(): Observable<RufierTest> {
    return this.http.get<RufierTest>(
      `${environment.apiUrl}/expert-system/rufier-test`
    );
  }

  public get pars$(): Observable<any> {
    return this.getSubject$('pars');
  }

  public get pars(): number {
    return this.inner.pars;
  }

  public set pars(value: number) {
    this.inner.pars = value;
    this.getSubject('pars').next(value);
  }

  public get ifsp$(): Observable<any> {
    return this.getSubject$('ifsp');
  }

  public get ifsp(): number {
    return this.inner.ifsp;
  }

  public set ifsp(value: number) {
    this.inner.ifsp = value;
    this.getSubject('ifsp').next(value);
  }

  public get ans$(): Observable<any> {
    return this.getSubject$('ans');
  }

  public get ans(): number {
    return this.inner.ans;
  }

  public set ans(value: number) {
    this.inner.ans = value;
    this.getSubject('ans').next(value);
  }
}
