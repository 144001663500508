<div class="table-wrapper" *ngIf="biosignals">
  <p class="expand-table" (click)="expand()">
    <ng-container *ngIf="expandRow">{{
      "measurement.hide" | translate
    }}</ng-container>
    <ng-container *ngIf="!expandRow">{{
      "measurement.expand" | translate
    }}</ng-container>
    <i class="bi bi-chevron-bar-contract contract" *ngIf="expandRow"></i>
    <i class="bi bi-chevron-bar-expand expand" *ngIf="!expandRow"></i>
  </p>
  <table id="myTable" class="expert-table-style-3">
    <thead>
      <tr>
        <th></th>
        <th (click)="sortTable(1)" style="cursor: pointer">
          {{ "measurement.date" | translate }}
        </th>
        <th>{{ "registration.name" | translate }}</th>
        <th (click)="sortTable(3)" style="cursor: pointer">
          {{ "calculation.ats" | translate }}
        </th>
        <th (click)="sortTable(4)" style="cursor: pointer">
          {{ "calculation.atd" | translate }}
        </th>
        <th (click)="sortTable(5)" style="cursor: pointer">
          {{ "calculation.chss" | translate }}
        </th>
        <th (click)="sortTable(6)" style="cursor: pointer">
          {{ "calculation.pars_ao" | translate }}
        </th>
        <th (click)="sortTable(7)" style="cursor: pointer">
          {{ "calculation.IFVP" | translate }}
        </th>
        <th (click)="sortTable(8)" style="cursor: pointer">
          {{ "calculation.msr" | translate }}
        </th>
        <th (click)="sortTable(9)" style="cursor: pointer">
          {{ "calculation.ebc" | translate }}
        </th>
        <th *ngIf="expandRow">{{ "measurement.calc_status" | translate }}</th>
        <th *ngIf="expandRow">{{ "measurement.status" | translate }}</th>
        <th>{{ "calculation.comment" | translate }}</th>
        <th>
          <input
            [(ngModel)]="masterSelected"
            (change)="checkUncheckAll()"
            class="customCheckBox"
            type="checkbox"
          />
        </th>
      </tr>
    </thead>
    <tbody id="myBody">
      <tr
        #trInTable
        (click)="selectRowByTable($event, element.id)"
        *ngFor="let element of tableData; let i = index"
        [id]="element.id"
        [ngClass]="
          currentBiosignal && element.id == currentBiosignal._id
            ? 'checkedTR'
            : ''
        "
      >
        <td>{{ i + 1 }}</td>
        <td>{{ element.date }}</td>
        <td>{{ element.name }}</td>
        <td>{{ element.systola }}</td>
        <td>{{ element.diastola }}</td>
        <td>{{ element.heartRate }}</td>
        <td>{{ element.pars }}</td>
        <td>{{ element.ifsp }}</td>
        <td>{{ element.msr }}</td>
        <td>{{ (element.systola - element.diastola) * element.heartRate }}</td>
        <td *ngIf="expandRow">{{ element.computeStatus }}</td>
        <td *ngIf="expandRow">{{ element.status }}</td>
        <td>
          <input
            [value]="element.comment"
            [(ngModel)]="element.comment"
            (blur)="onBlurMethod(element.comment, element.id)"
          />
        </td>
        <td>
          <label style="width: 18px; height: 100%; margin: 0">
            <input
              [disabled]="element.biosignal.checked && blockFollowingUncheck"
              (change)="isAllSelected()"
              [(ngModel)]="element.biosignal.checked"
              class="customCheckBox"
              type="checkbox"
            />
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</div>
