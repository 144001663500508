<div class="row">
  <div class="col">
    <h1 class="slide-title">
      {{ "measurement.download_data" | translate }}
    </h1>
  </div>
</div>
<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="table-wrapper">
        <table id="myTable" class="expert-table-style-3">
          <thead>
            <tr>
              <th></th>
              <th (click)="sortTable(1)" style="cursor: pointer">ua</th>
              <th (click)="sortTable(2)" style="cursor: pointer">en</th>
              <th (click)="sortTable(3)" style="cursor: pointer">createdBy</th>

              <th (click)="sortTable(4)" style="cursor: pointer">userType</th>
              <th (click)="sortTable(5)" style="cursor: pointer">createdAt</th>
              <th (click)="sortTable(6)" style="cursor: pointer"></th>
              <th>
                <input
                  (change)="changeConditionCheckbox($event)"
                  [checked]="false"
                  class="customCheckBox"
                  type="checkbox"
                />
              </th>
            </tr>
          </thead>
          <tbody id="myBody">
            <tr
              #trInTable
              (click)="selectRowByTable($event, element._id)"
              *ngFor="let element of monitoringPrograms; let i = index"
              [id]="element._id"
              [ngClass]="
                currentBiosignal && element._id == currentBiosignal._id
                  ? 'checkedTR'
                  : ''
              "
            >
              <td>{{ i + 1 }}</td>
              <td>{{ element.ua }}</td>
              <td>{{ element.en }}</td>
              <td>{{ element.createdBy }}</td>
              <td>{{ element.userType }}</td>
              <td>{{ element.createdAt | date : "HH:mm, dd/MM/YYYY" }}</td>

              <td>
                <input
                  [value]="element.comment"
                  [(ngModel)]="element.comment"
                  (blur)="onBlurMethod(element.comment, element._id)"
                />
              </td>
              <td>
                <label style="width: 18px; height: 100%; margin: 0">
                  <input
                    (change)="selectCheckBox($event, element._id)"
                    [(ngModel)]="element.checked"
                    class="customCheckBox"
                    type="checkbox"
                    [disabled]="
                      element.checked && blockFollowingUncheck
                    "
                  />
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
