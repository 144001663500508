import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recalculate-dialog',
  templateUrl: './recalculate-dialog.component.html',
  styleUrls: ['./recalculate-dialog.component.scss']
})
export class RecalculateDialogComponent implements OnInit {
  @Input() public biosignals;

  patient;
  constructor(
    public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.modal.close(this.biosignals);
  }
}
