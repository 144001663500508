import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Equipment } from 'src/app/models/equipment';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatePipe } from '@angular/common';
import { AgePipe } from 'src/app/pipes/age.pipe';
import { ValueByIdPipe } from 'src/app/pipes/value-by-id.pipe';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SortableHeader } from 'src/app/directives/sortable-header/sortable-header.directive';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { QualificationTypeService } from 'src/app/services/dictionaries/qualification-type.service';
import { DataService } from '../../measurements-data/services/data.service';
import { ParseDialogComponent } from './dialogs/confirm-download-dialog/parse-dialog.component';
import { SortEvent } from './interfaces/event.interface';
@Component({
  selector: 'app-measurements-settings',
  templateUrl: './measurements-settings.component.html',
  styleUrls: ['./measurements-settings.component.scss'],
  providers: [DatePipe, AgePipe, ValueByIdPipe, TranslatePipe],
})
export class MeasurementsSettingsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  tableBusy: boolean = false;
  expandRow: Array<number> = [];
  tableCols: any[] = [];
  tableData: Equipment[] = [];
  defaultTable = [];
  tableCsvData: any[] = [];
  files;
  formData: FormData;
  cols = [];
  selectedCols = [];
  equipments = [];

  busy: boolean = false;
  constructor(
    private biosignalsService: BiosignalsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private agePipe: AgePipe,
    private valueByIdPipe: ValueByIdPipe,
    private translate: TranslatePipe,
    private translateService: TranslateService,
    private dataService: DataService,
    private biosignalService: BiosignalsService,
    public auth: AuthService,
    public cd: ChangeDetectorRef,
    public genderDict: GenderService,
    public qualificationTypeDict: QualificationTypeService
  ) {
    super(cd);
    this.cols = this.dataService.cols;
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });

    this.biosignalsService
      .getEquipments()
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        console.log('getEquipments', resp);

        this.equipments = resp;
      });
    this.updateStatistics();
  }

  updateStatistics(isUpdated?) {
    this.busy = true;
    this.biosignalsService
      .getStatistics()
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        this.busy = false;
        console.log('getStatistics', resp);
        this.gridObjectTable(resp, isUpdated);
      });
  }

  gridObjectTable(table, isUpdated?) {
    let gridArr: Array<any> = [];
    let ids = 1;
    let count = 1;
    table.forEach((el) => {
      let datas: any = {};
      let datasChildArr: any = [];
      if (el.patient && el.patient.doctors && el.patient.doctors.length) {
        if (el.patient.doctors.length > 1) {
          datas.doctorName = el.patient.doctors.length;
          datas.doctorId = el.patient.doctors[0]._id;
          datas.doctorAge = '-';
          datas.doctorGender = '-';
          datas.doctorEducation = '-';
          datas.doctorQualifications = '-';
          datas.doctorSpecialities = '-';
          datas.doctorLength = el.patient.doctors.length;
          datas.checked = false;
          el.patient.doctors.forEach((item) => {
            let dataDoctor: any = {};
            dataDoctor.equipmentID = el.EquipmentID || '';
            dataDoctor.equipmentMacId = el.EquipmentMacId || '';
            dataDoctor.equipmentName = el.EquipmentName || '';
            dataDoctor.count = el.count;
            dataDoctor.firstMeasurementDate = this.datePipe.transform(
              el.firstMeasurementDate,
              'MM/dd/yy'
            );
            dataDoctor.lastMeasurementDate = this.datePipe.transform(
              el.lastMeasurementDate,
              'MM/dd/yy'
            );
            dataDoctor.patientName =
              el.patient.last_name +
              ' ' +
              el.patient.first_name +
              ' ' +
              el.patient.second_name;
            dataDoctor.patientAge = this.agePipe.transform(
              el.patient.birth_date
            );
            dataDoctor.patientGender = this.translate.transform(
              this.valueByIdPipe.transform(
                el.patient.gender,
                this.genderDict.gender,
                'value'
              )
            );
            dataDoctor.doctorName =
              item.first_name +
              ' ' +
              el.patient.doctors[0].last_name +
              ' ' +
              el.patient.doctors[0].second_name;
            dataDoctor.doctorId = item._id;
            dataDoctor.doctorAge = this.agePipe.transform(item.birth_date);
            dataDoctor.doctorGender = this.translate.transform(
              this.valueByIdPipe.transform(
                item.gender,
                this.genderDict.gender,
                'value'
              )
            );
            dataDoctor.doctorEducation = item.educations[0].institution_name;
            dataDoctor.doctorQualifications = this.translate.transform(
              this.valueByIdPipe.transform(
                item.qualifications[0].type,
                this.qualificationTypeDict.qualification_type,
                'value'
              )
            );
            dataDoctor.doctorSpecialities = item.specialities[0].speciality;
            dataDoctor.successfulComputationCount =
              el.successfulComputationCount;
            dataDoctor.unsuccessfulComputationCount =
              el.unsuccessfulComputationCount;
            dataDoctor.childId = ids;
            datasChildArr.push(dataDoctor);
          });
        } else {
          datas.doctorName =
            el.patient.doctors[0].first_name +
            ' ' +
            el.patient.doctors[0].last_name +
            ' ' +
            el.patient.doctors[0].second_name;
          datas.doctorId = el.patient.doctors[0]._id;
          datas.doctorAge = this.agePipe.transform(
            el.patient.doctors[0].birth_date
          );
          datas.doctorGender = this.translate.transform(
            this.valueByIdPipe.transform(
              el.patient.doctors[0].gender,
              this.genderDict.gender,
              'value'
            )
          );
          datas.doctorEducation =
            el.patient.doctors[0].educations[0].institution_name;
          datas.doctorQualifications = this.translate.transform(
            this.valueByIdPipe.transform(
              el.patient.doctors[0].qualifications[0].type,
              this.qualificationTypeDict.qualification_type,
              'value'
            )
          );
          datas.doctorSpecialities =
            el.patient.doctors[0].specialities[0].speciality;
          datas.patientId = ids;
          datas.id = el.patient._id;
          datas.checked = false;
          datas.doctorLength = el.patient.doctors.length;
        }
      }
      gridArr.push({
        number: count,
        numberFull: 1,
        equipmentID: el.EquipmentID || '',
        equipmentMacId: el.EquipmentMacId || '',
        equipmentName: el.EquipmentName || '',
        count: el.count,
        patientId: ids,
        id: el.patient?._id,
        firstMeasurementDate: this.datePipe.transform(
          el.firstMeasurementDate,
          'MM/dd/yy'
        ),
        lastMeasurementDate: this.datePipe.transform(
          el.lastMeasurementDate,
          'MM/dd/yy'
        ),
        patientName: el.patient
          ? el.patient.last_name +
            ' ' +
            el.patient.first_name +
            ' ' +
            el.patient.second_name
          : '',
        patientAge: this.agePipe.transform(el.patient?.birth_date),
        patientGender: this.translate.transform(
          this.valueByIdPipe.transform(
            el.patient?.gender,
            this.genderDict?.gender,
            'value'
          )
        ),
        ...datas,
        successfulComputationCount: el.successfulComputationCount,
        unsuccessfulComputationCount: el.unsuccessfulComputationCount,
        contactPerson: el.contactPerson,
        purchaseStatus: el.purchaseStatus,
        active: el.active,
        isContactPerson: false,
      });
      gridArr.push(...datasChildArr);
      ids += 1;
      count += 1;
    });
    this.tableCols = gridArr;
    if (isUpdated) {
      this.generateTable();
    }
  }

  equipmentParse(event: any) {
    const file = event.target.files[0];
    this.formData = new FormData();
    this.formData.append('monitorsCsv', file);
    this.formData.append(file.name, '' + file.lastModified);
    this.biosignalsService
      .equipmentParse(this.formData)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (resp) => {
          if (resp) {
            const modal = this.modalService.open(ParseDialogComponent);
            modal.componentInstance.data = resp;
            modal.result.then((result) => {
              if (result && result.length) {
                this.biosignalsService
                  .equipmentInsert(result)
                  .pipe(untilComponentDestroyed(this))
                  .subscribe(
                    (resp) => {
                      this.translateService
                        .get(['alerts'])
                        .pipe(untilComponentDestroyed(this))
                        .subscribe((t) => {
                          this.toastr.warning(t.alerts.warning_6);
                        });
                    },
                    (err) => {
                      this.toastr.error(err.message);
                    }
                  );
              }
            });
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
  }

  deleteEquipment(id) {}

  selectAll() {
    this.selectedCols = this.cols.map((x) => x);
    this.generateTable();
  }

  generateTable() {
    this.tableData = [];
    this.tableCsvData = [];
    let selectedColsClone = [];
    selectedColsClone = [...this.selectedCols];
    selectedColsClone.push({ field: 'patientId' });
    selectedColsClone.push({ field: 'doctorId' });
    selectedColsClone.push({ field: 'doctorLength' });
    selectedColsClone.push({ field: 'childId' });
    selectedColsClone.push({ field: 'id' });
    selectedColsClone.push({ field: 'active' });
    this.tableCols.forEach((i) => {
      let data: any = {};
      let csvData = {};
      selectedColsClone.forEach((j) => {
        Object.entries(i).forEach((k) => {
          if (k[0] === j.field) {
            data[k[0]] = k[1];
          }
        });
      });
      this.selectedCols.forEach((j) => {
        Object.entries(i).forEach((k) => {
          if (k[0] === j.field) {
            csvData[k[0]] = k[1];
          }
        });
      });
      this.tableData.push(data);
      this.defaultTable = this.tableData;
      this.tableCsvData.push(csvData);
    });
  }

  onSelect(data) {
    this.expandRow.includes(data)
      ? this.expandRow.splice(this.expandRow.indexOf(data), 1)
      : this.expandRow.push(data);
  }

  generateCSV() {
    const headers = this.selectedCols.map((i) => this.translate.transform(i));
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Report ${this.datePipe.transform(new Date(), 'dd-MM-yyyy')}`,
      headers,
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(
      this.tableCsvData,
      `Report-${this.datePipe.transform(new Date(), 'dd-MM-yy')}`,
      options
    );
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      this.tableData = this.defaultTable;
    } else {
      if (direction === 'asc') {
        if (column === 'firstMeasurementDate') {
          console.log(this.tableData, 'data');
          this.tableData = [...this.tableData].sort(
            (a: any, b: any) =>
              new Date(a[column]).getTime() - new Date(b[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => a[column] - b[column]
          );
        }
      } else if (direction === 'desc') {
        if (column === 'firstMeasurementDate') {
          this.tableData = [...this.tableData].sort(
            (a: any, b: any) =>
              new Date(b[column]).getTime() - new Date(a[column]).getTime()
          );
        } else {
          this.tableData = [...this.tableData].sort(
            (a, b) => b[column] - a[column]
          );
        }
      }
    }
  }

  createFieldEquipment(item) {
    const obj = {};
    obj[item.key] = item.data;
    const equipment = this.equipments.find(
      (i) => i['EquipmentID'] === item.equipmentID
    );
    this.biosignalService
      .updateEquipment(equipment._id, obj)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp) {
          this.tableData = [];
          this.updateStatistics(true);
          this.translateService
            .get(['alerts'])
            .pipe(untilComponentDestroyed(this))
            .subscribe((t) => {
              this.toastr.success(t.alerts.warning_5);
            });
        }
      });
  }
}
