import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { ShortDataService } from '../../services/short-data.service';

@Component({
  selector: 'app-cranial-upload-form',
  templateUrl: './cranial-upload-form.component.html',
  styleUrls: ['./cranial-upload-form.component.scss'],
})
export class CranialUploadFormComponent implements OnInit {
  cpForm: FormGroup = new FormGroup({});
  @Output() change = new EventEmitter();
  @Input() shortDataService: ShortDataService;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.reactiveForm();
    this.cpForm.valueChanges.subscribe((v) => {
      this.change.emit({ type: GraphType.CP, formValue: v });
    });
  }

  reactiveForm() {
    this.cpForm = this.fb.group({
      EquipmentName: ['', Validators.required],
    });
  }
}
