<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ "measurement.download_datas" | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="modal.dismiss('CLOSE')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-check">
    <input
      class="form-check-input"
      id="txt"
      type="radio"
      [(ngModel)]="format"
      [value]="'txt'"
    />
    <label class="form-check-label" for="txt">
      {{ "measurement.download_txt" | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      id="json"
      type="radio"
      [(ngModel)]="format"
      [value]="'json'"
    />
    <label class="form-check-label" for="json">
      {{ "measurement.download_json" | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      id="csv"
      type="radio"
      [(ngModel)]="format"
      [value]="'csv'"
    />
    <label class="form-check-label" for="csv">
      {{ "measurement.download_csv" | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      id="csv_new"
      type="radio"
      [(ngModel)]="format"
      [value]="'csv_new'"
    />
    <label class="form-check-label" for="csv_new">
      {{ "measurement.download_csv" | translate }} (NEW)
    </label>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss('CANCEL')">
    {{ "login.cancel" | translate }}
  </button>
  <button class="btn btn-danger" (click)="submit()">
    {{ "measurement.confirm" | translate }}
  </button>
</div>
