export const EXPERT_SYSTEM_THRESHOLDS = {
  critical: 0.8,
  medium: 0.5,
  low: 0.2,
};

export const DIPPERRS_THRESHOLDS = {
  s_min: 90,
  s_max: 120,
  s_val: 140,
  d_min: 59.79,
  d_max: 79,

};
