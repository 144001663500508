import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpecializationLevelsService {
  levelsSpecialises = [
    {
      value: 'EXPERT',
      ua: 'Спеціаліст',
      en: 'EXPERT',
      pl: 'Specjalista',
    },
    {
      value: 'MASTER',
      ua: 'Магістр',
      en: 'MASTER',
      pl: 'Magister',
    },
    {
      value: 'BACHELOR',
      ua: 'Бакалавр',
      en: 'BACHELOR',
      pl: 'Bakałarz',
    },
    {
      value: 'JUNIOR_EXPERT',
      ua: 'Молодший спеціаліст',
      en: 'JUNIOR_EXPERT',
      pl: 'Młodszy specjalista',
    },
  ];
}
