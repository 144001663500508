import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ExpertSystemTableColumn } from '../../../../../models/expert-system-table-column';
import { BiosignalsRange } from '../../../../../models/biosignals-range';
import { Biosignal } from '../../../../../models/biosignal';
import { NervousSystem } from '../../../../../models/nervous-system/nervous-system';
import { MeasurementsDataUpdateService } from '../../../../../services/measurements-data-update.service';
import { ExpertSystemService } from '../../../../../services/expert-system.service';
import { NervousSystemTableValue } from '../../../../../models/nervous-system/nervous-system-table-value';
import { VALUES_HEADER } from '../../constants/expert-system-constants';
import { TranslateService } from '@ngx-translate/core';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-nervous-system-table',
  templateUrl: './nervous-system-table.component.html',
  styleUrls: ['./nervous-system-table.component.scss'],
})
export class NervousSystemTableComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.data) this.setContentValue();
    }
  }
  @Input() byHilbertHuang = false;
  data: NervousSystemTableValue;
  averageValues: ExpertSystemTableColumn;
  patient;
  biosignals;
  interpretation;

  constructor(
    private expertSystemService: ExpertSystemService,
    private translate: TranslateService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.getNervousData();
  }

  setContentValue() {
    this.calcAvgValues(this.biosignals);
    this.data.className = [];
    for (let i = 0; i < this.averageValues.row.length; i++) {
      this.data.interpretation.row[i] = this.calculateInterpretation(
        this.data.normalValues.row[i],
        this.averageValues.row[i]
      );
      this.data.className[i] = this.calculateClassName(
        this.data.normalValues.row[i],
        this.averageValues.row[i]
      );
    }
  }
  getNervousData() {
    this.expertSystemService
      .getNervousSystem()
      .subscribe((nervousSystem: NervousSystem) => {
        this.data = this.byHilbertHuang
          ? nervousSystem.hilbertHuang
          : nervousSystem.adaptation;
        this.averageValues = this.data.averageValues_Values;
        if (this.biosignals && this.biosignals.length) this.setContentValue();
      });
  }

  calcAvgValues(biosignals: Biosignal[]): void {
    if (biosignals && biosignals.length) {
      this.averageValues.row = this.getAvgValues(
        biosignals,
        this.byHilbertHuang
      );
      this.averageValues.header =
        biosignals.length > 1 ? VALUES_HEADER.average : VALUES_HEADER.soleValue;
    }
  }

  getAvgValues(biosignalsRange: Biosignal[], byHilbertHuang): string[] {
    let perDelta = 0;
    let perTeta = 0;
    let perAlpha = 0;
    let perBeta = 0;

    const column: string[] = [];
    if (biosignalsRange && biosignalsRange.length) {
      for (const biosignal of biosignalsRange) {
        if (byHilbertHuang && biosignal.HilbertHuang) {
          perDelta += biosignal.HilbertHuang.perDelta;
          perTeta += biosignal.HilbertHuang.perTeta;
          perAlpha += biosignal.HilbertHuang.perAlpha;
          perBeta += biosignal.HilbertHuang.perBeta;
        } else {
          if (biosignal.FFT) {
            perDelta += biosignal.FFT.perDelta;
            perTeta += biosignal.FFT.perTeta;
            perAlpha += biosignal.FFT.perAlpha;
            perBeta += biosignal.FFT.perBeta;
          }
        }
      }
      const elementCount = biosignalsRange.length;
      column[0] = this.roundToTwoNumbers(perDelta / elementCount) + '%';
      column[1] = this.roundToTwoNumbers(perTeta / elementCount) + '%';
      column[2] = this.roundToTwoNumbers(perAlpha / elementCount) + '%';
      column[3] = this.roundToTwoNumbers(perBeta / elementCount) + '%';
    }
    return column;
  }

  calculateInterpretation(
    range: { minValue: number; maxValue: number },
    comparisonValue: string
  ): any {
    let message;
    this.translate.get(['measurement']).subscribe((t) => {
      const minValue = range.minValue,
        maxValue = range.maxValue;
      const value = +comparisonValue.split('%')[0];
      if (value >= +minValue && value <= +maxValue) {
        message = t.measurement.norm;
      } else if (value > maxValue) {
        message = t.measurement.above_norm;
      } else {
        message = t.measurement.less_norm;
      }
    });
    return message;
  }

  calculateClassName(
    range: { minValue: number; maxValue: number },
    comparisonValue: string
  ): string {
    const minValue = range.minValue,
      maxValue = range.maxValue;
    const value = +comparisonValue.split('%')[0];

    if (value >= minValue && +comparisonValue <= maxValue) {
      return 'success';
    } else if (value > maxValue) {
      return 'error';
    } else {
      return 'warning';
    }
  }

  roundToTwoNumbers(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
