import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  row = '';
  line = '';
  row0 = '';
  headers1 = [
    'RequestDateTime',
    'ComputeDateTime',
    'SignalType',
    'Monitor',
    'RecordsCount',
    'ComputeMode',
  ];

  headers2 = [
    'HospitalID',
    'DoctorID',
    'PatientID',
    'Status',
    'MeasurementID',
    'EquipmentID',
    'EquipmentName',
    'EquipmentMacId',
    'SignalRegistrationDateTime',
    'BloodGlucose',
  ];

  headers3 = ['FileName', 'Segment', 'Fs'];

  headers4 = [
    'SDNNpos',
    'NN50pos',
    'pNN50pos',
    'SDSDpos',
    'MoPos',
    'AMoPos',
    'BPpos',
    'IVRpos',
    'VPRpos',
    'INpos',
    'HVRindexPos',
    'MxDMnPos',
    'SIpos',
    'HRpos',
    'L1pos',
    'L2pos',
    'SDNNneg',
    'NN50neg',
    'pNN50neg',
    'SDSDneg',
    'MoNeg',
    'AMoNeg',
    'BPneg',
    'IVRneg',
    'VPRneg',
    'INneg',
    'HVRindexNeg',
    'MxDMnNeg',
    'SIneg',
    'HRneg',
    'L1neg',
    'L2neg',
    'RRposStd',
    'RRposMean',
    'RRnegStd',
    'RRnegMean',
    'Systola',
    'Systola_calc',
    'Diastola',
    'Diastola_calc',
    'HeartRate',
    'MAP',
    'PP',
    'powerStd',
    'BPM',
    'RMSSD',
    'PARS',
    'PARSint',
    'V',
    'IFSP',
    'IVHH',
    'ICHH',
    'IVVH',
    'ICVH',
  ];

  headers5 = [
    'M1',
    'M2',
    'M3',
    'M4',
    'M5',
    'M6',
    'M7',
    'M8',
    'M9',
    'M10',
    'Ms',
  ];

  headers_HilbertHuang_pos_neg_interval = [
    'ULF',
    'VLF',
    'LF',
    'HF',
    'Total04',
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
  ];

  headers_FFT_pos_ned_interval = [
    'ULF',
    'VLF',
    'LF',
    'HF',
    'Total04',
    // 'Total025',
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'K',
  ];

  headers_HilbertHuang = [
    'ULF',
    'VLF',
    'LF',
    'HF',
    'Alpha',
    'Beta',
    'Teta',
    'Delta',
    'Fx25x60',
    'Total04',
    'Total025',
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'perDelta',
    'perTeta',
    'perAlpha',
    'perBeta',
  ];

  headers_FFT = [
    'ULF',
    'VLF',
    'LF',
    'HF',
    'Alpha',
    'Beta',
    'Teta',
    'Delta',
    'Total04',
    'Total025',
    'K',
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'perAlpha',
    'perBeta',
    'perDelta',
    'perTeta',
  ];

  headers8 = [
    'HurstTotal',
    'Hurst0to20',
    'Hurst20to70',
    'Hurst70to100',
    'Hurst100to70',
    'Hurst70toEnd',
  ];
  headers9 = [
    'ArrhythmiaDegree',
    'HeartDiseases',
    'AG1',
    'AG2',
    'LungDiseases',
    'PsychicalDiseases',
  ];
  constructor() {}

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  headers(header: Array<any>) {
    header.forEach((v) => {
      this.row += v + ';';
      this.row0 += ';';
    });
  }

  info(header: Array<any>, obj, toString?: boolean) {
    header.forEach((v) => {
      if (obj[v] != null && obj[v] != 'undefined') {
        if (toString) this.line += obj[v]?.toString().replace('.', ',') + ';';
        else this.line += obj[v] + ';';
      } else this.line += '-;';
    });
  }

  ConvertToCSV(objArray) {
    this.row = '';
    this.line = '';
    this.row0 = '';
    this.headers(this.headers1);
    this.headers(this.headers2);
    this.headers(this.headers3);
    this.row += 'ComputeStatus;';
    this.row0 += ';';
    this.headers(this.headers4);
    this.headers(this.headers5);
    this.row += ';';
    this.row0 += 'FFT;';
    this.headers(this.headers_FFT);
    this.row += ';';
    this.row0 += 'HilbertHuang;';
    this.headers(this.headers_HilbertHuang);
    this.row += ';';
    this.row0 += 'FFT_pos_interval;';
    this.headers(this.headers_FFT_pos_ned_interval);
    this.row += ';';
    this.row0 += 'FFT_neg_interval;';
    this.headers(this.headers_FFT_pos_ned_interval);
    this.row += ';';
    this.row0 += 'HilbertHuang_pos_interval;';
    this.headers(this.headers_HilbertHuang_pos_neg_interval);
    this.row += ';';
    this.row0 += 'HilbertHuang_neg_interval;';
    this.headers(this.headers_HilbertHuang_pos_neg_interval);
    this.headers(this.headers8);
    this.headers(this.headers9);

    objArray.forEach((obj) => {
      this.info(this.headers1, obj.Description);
      this.info(this.headers2, obj.Records.x1.MeasurementInformation, true);
      this.info(this.headers3, obj.Records.x1);
      this.line += obj.Records.x1.Results.ComputeStatus + ';';
      this.info(this.headers4, obj.Records.x1.Results.TimeAnalysis, true);
      this.info(
        this.headers5,
        obj.Records.x1.Results.MorphologicalAnalysis,
        true
      );
      this.line += ';';
      this.info(
        this.headers_FFT,
        obj.Records.x1.Results.SpectralAnalysis.FFT,
        true
      );
      this.line += ';';
      this.info(
        this.headers_HilbertHuang,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang,
        true
      );
      this.line += ';';
      this.info(
        this.headers_FFT_pos_ned_interval,
        obj.Records.x1.Results.SpectralAnalysis.FFT_pos_interval,
        true
      );
      this.line += ';';
      this.info(
        this.headers_FFT_pos_ned_interval,
        obj.Records.x1.Results.SpectralAnalysis.FFT_neg_interval,
        true
      );
      this.line += ';';
      this.info(
        this.headers_HilbertHuang_pos_neg_interval,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang_pos_interval,
        true
      );
      this.line += ';';
      this.info(
        this.headers_HilbertHuang_pos_neg_interval,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang_neg_interval,
        true
      );
      this.info(this.headers8, obj.Records.x1.Results.FractalAnalysis, true);
      this.info(this.headers9, obj.Records.x1.Results.ExpertSystem, true);

      this.line += '\r\n';
    });
    return this.row0 + '\r\n' + this.row + '\r\n' + this.line + '\r\n';
  }
}
