import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../../measurements-data/services/patient.service';
import { DataService } from './data.service';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-martine-test',
  templateUrl: './martine-test.component.html',
  styleUrls: ['./martine-test.component.scss'],
})
export class MartineTestComponent implements OnInit {
  myForm: FormGroup;
  signalHRFileName: string;
  signalBeforeFileName: string;
  signalAfterFileName: string;
  signal3minFileName: string;
  signal4minFileName: string;
  signal6minFileName: string;
  signal5minFileName: string;
  langUA: boolean;
  langEN: boolean;
  saveInfo: boolean = false;
  testData: any;
  saveEditInfo = false;
  column4 = false;
  column5 = false;
  column6 = false;
  titlecColspan = 3;
  numberColumn = 3;
  addColumnBtn = true;
  prevIndexSelectTest: number;
  idEditedTest: string;
  patientId: number | string;

  constructor(
    public fb: FormBuilder,
    private dataService: DataService,
    private patientsService: PatientService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.reactiveForm();
    if (localStorage.getItem('language') == 'en') {
      this.langEN = true;
    } else {
      this.langUA = true;
    }

    if (this.getDoctorAndPatientIds().patientId) {
      this.getTestInfo();
    }
    this.patientsService.patient$.subscribe(
      (patient) => (this.patientId = patient?._id)
    );
  }

  saveData() {
    this.myForm.patchValue({
      signal_before: '-',
      signal_after: '-',
      signal_hr: '-',
      signal_3min: '-',
      signal_4min: '-',
      signal_5min: '-',
      signal_6min: '-',
      staff_id: this.getDoctorAndPatientIds().doctorId,
      patient_id: this.getDoctorAndPatientIds().patientId,
    });
    this.dataService.postFunctionTests(this.myForm.value).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success('Saved');
        this.myForm.reset();
        this.getTestInfo();
        this.saveInfo = !this.saveInfo;
      },
      (err: any) => console.log(err)
    );
  }

  deleteTest(id) {
    const question = confirm('Ви дійно хочете видалити пробу?');
    if (question) {
      this.dataService.deleteFunctionTests(id).subscribe(
        (res) => {
          this.toastr.success('Deleted');
          console.log(res);
          this.getTestInfo();
        },
        (err: any) => console.log(err)
      );
    }
  }

  editTestStart(test) {
    this.saveEditInfo = !this.saveEditInfo;
    this.idEditedTest = test._id;
    this.myForm.patchValue({
      test_name: test.test_name,
      type_load: test.type_load,
      signal_hr: test.signal_hr,
      signal_before: test.signal_before,
      signal_after: test.signal_after,
      signal_3min: test.signal_3min,
      signal_4min: test.signal_4min,
      signal_5min: test.signal_5min,
      signal_6min: test.signal_6min,
      hr10: test.hr10,
      hr20: test.hr20,
      hr30: test.hr30,
      hr10s_after_1min: test.hr10s_after_1min,
      hr10s_after_2min: test.hr10s_after_2min,
      hr20s_after_2min: test.hr20s_after_2min,
      hr30s_after_2min: test.hr30s_after_2min,
      hr40s_after_2min: test.hr40s_after_2min,
      hr50s_after_2min: test.hr50s_after_2min,
      hr60s_after_2min: test.hr60s_after_2min,
      hr10s_after_3min: test.hr10s_after_3min,
      hr20s_after_3min: test.hr20s_after_3min,
      hr30s_after_3min: test.hr30s_after_3min,
      hr40s_after_3min: test.hr40s_after_3min,
      hr50s_after_3min: test.hr50s_after_3min,
      hr60s_after_3min: test.hr60s_after_3min,
      hr10s_after_4min: test.hr10s_after_4min,
      hr20s_after_4min: test.hr20s_after_4min,
      hr30s_after_4min: test.hr30s_after_4min,
      hr40s_after_4min: test.hr40s_after_4min,
      hr50s_after_4min: test.hr50s_after_4min,
      hr60s_after_4min: test.hr60s_after_4min,
      hr10s_after_5min: test.hr10s_after_5min,
      hr20s_after_5min: test.hr20s_after_5min,
      hr30s_after_5min: test.hr30s_after_5min,
      hr40s_after_5min: test.hr40s_after_5min,
      hr50s_after_5min: test.hr50s_after_5min,
      hr60s_after_5min: test.hr60s_after_5min,
      hr10s_after_6min: test.hr10s_after_6min,
      hr20s_after_6min: test.hr20s_after_6min,
      hr30s_after_6min: test.hr30s_after_6min,
      hr40s_after_6min: test.hr40s_after_6min,
      hr50s_after_6min: test.hr50s_after_6min,
      hr60s_after_6min: test.hr60s_after_6min,

      negative_phaze_puls: test.negative_phaze_puls,
      reaction_blood_pressure: test.reaction_blood_pressure,
      time_reset_blood_pressure: test.time_reset_blood_pressure,
      type_reaction: test.type_reaction,
      respiratory_rate: test.respiratory_rate,

      breath_HR: test.breath_HR,
      breath_1min: test.breath_1min,
      breath_2min: test.breath_2min,
      breath_3min: test.breath_3min,
      breath_4min: test.breath_4min,
      breath_5min: test.breath_5min,
      breath_6min: test.breath_6min,
      ASP_hr: test.ASP_hr,
      reaction_ASP: test.reaction_ASP,
      reaction_ADP: test.reaction_ADP,
      reaction_AP: test.reaction_AP,
      RQI: test.RQI,
      ASP_before: test.ASP_before,
      ASP_after: test.ASP_after,
      ASP_3min: test.ASP_3min,
      ASP_4min: test.ASP_4min,
      ASP_5min: test.ASP_5min,
      ASP_6min: test.ASP_6min,
      ADP_hr: test.ADP_hr,
      ADP_before: test.ADP_before,
      ADP_after: test.ADP_after,
      ADP_3min: test.ADP_3min,
      ADP_4min: test.ADP_4min,
      ADP_5min: test.ADP_5min,
      ADP_6min: test.ADP_6min,

      recomendation: test.recomendation,
      reset_time_puls: test.reset_time_puls,
      conclusions_after: test.conclusions_after,
      result_after: test.result_after,

      date: test.date,
      staff_id: test.staff_id._id,
      patient_id: test.patient_id,
    });
  }

  getTestInfo() {
    const obj = {
      test_name: 'martine_test',
      patient_id: this.getDoctorAndPatientIds().patientId,
    };

    this.dataService.getFunctionTests(obj).subscribe(
      (res) => {
        this.testData = res;
        this.formTestsForViewTable();
        console.log('testData');
        console.log(this.testData);
      },
      (err: any) => console.log(err)
    );
  }

  saveEditTest() {
    this.myForm.patchValue({
      signal_before: '-',
      signal_after: '-',
      signal_hr: '-',
      signal_3min: '-',
      signal_4min: '-',
      signal_5min: '-',
      signal_6min: '-',
    });

    this.dataService
      .updateFunctionTests(this.idEditedTest, this.myForm.value)
      .subscribe(
        (res) => {
          this.saveEditInfo = !this.saveEditInfo;
          this.toastr.success('Updated');
          this.myForm.reset();
          this.getTestInfo();
        },
        (err: any) => console.log(err)
      );
  }

  viewFullStaffInfo(index) {
    // ------------зміна висоти рядка ----------------------
    if (this.prevIndexSelectTest == undefined) {
      this.prevIndexSelectTest = index;
    }

    if (this.testData[index].bigWindow || this.testData[index].showFullInfo) {
      this.testData[index].showFullInfo = false;
    } else {
      this.testData[index].showFullInfo = true;
    }

    if (index != this.prevIndexSelectTest) {
      this.testData[this.prevIndexSelectTest].showFullInfo = false;
      this.prevIndexSelectTest = index;
    }

    // ----------------------------------------------------------
  }

  formTestsForViewTable() {
    this.testData.forEach((elem) => {
      if (
        elem.result_before != '' &&
        elem.result_after == '' &&
        elem.result_5min == ''
      ) {
        elem['resultTableView'] = elem.result_before;
        elem['conclusionsTableView'] = elem.conclusions_before;
        if (elem.signal_before_file) {
          elem[
            'signalTableView'
          ] = `${elem.path_file}${elem.signal_before_file}`;
        } else {
          elem['signalTableView'] = '';
        }
      } else if (
        elem.result_before != '' &&
        elem.result_after != '' &&
        elem.result_5min == ''
      ) {
        elem['resultTableView'] = elem.result_after;
        elem['conclusionsTableView'] = elem.conclusions_after;
        if (elem.signal_after_file) {
          elem[
            'signalTableView'
          ] = `${elem.path_file}${elem.signal_after_file}`;
        } else {
          elem['signalTableView'] = '';
        }
      } else if (
        elem.result_before == '' &&
        elem.result_after == '' &&
        elem.result_5min != ''
      ) {
        elem['resultTableView'] = elem.result_5min;
        elem['conclusionsTableView'] = elem.conclusions_5min;
        if (elem.signal_5min_file) {
          elem['signalTableView'] = `${elem.path_file}${elem.signal_5min_file}`;
        } else {
          elem['signalTableView'] = '';
        }
      } else if (
        elem.result_before != '' &&
        elem.result_after != '' &&
        elem.result_5min != ''
      ) {
        elem['resultTableView'] = elem.result_after;
        elem['conclusionsTableView'] = elem.conclusions_after;
        if (elem.signal_after_file) {
          elem[
            'signalTableView'
          ] = `${elem.path_file}${elem.signal_after_file}`;
        } else {
          elem['signalTableView'] = '';
        }
      } else if (
        elem.result_before == '' &&
        elem.result_after != '' &&
        elem.result_5min == ''
      ) {
        elem['resultTableView'] = elem.result_after;
        elem['conclusionsTableView'] = elem.conclusions_after;
        if (elem.signal_after_file) {
          elem[
            'signalTableView'
          ] = `${elem.path_file}${elem.signal_after_file}`;
        } else {
          elem['signalTableView'] = '';
        }
      }
    });
  }

  addColumn() {
    switch (this.numberColumn) {
      case 3:
        this.column4 = true;
        this.numberColumn = 4;
        this.titlecColspan = 4;
        break;
      case 4:
        this.column5 = true;
        this.numberColumn = 5;
        this.titlecColspan = 5;
        break;
      case 5:
        this.column6 = true;
        this.numberColumn = 6;
        this.titlecColspan = 6;
        this.addColumnBtn = false;
        break;
      default:
        break;
    }
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      test_name: ['martine_test'],
      type_load: [''],
      signal_hr: [''],
      signal_before: [''],
      signal_after: [''],
      signal_3min: [''],
      signal_4min: [''],
      signal_5min: [''],
      signal_6min: [''],
      date: [new Date()],

      hr10: ['', [Validators.required]],
      hr20: ['', [Validators.required]],
      hr30: ['', [Validators.required]],
      hr10s_after_1min: ['', [Validators.required]],
      hr10s_after_2min: ['', [Validators.required]],
      hr20s_after_2min: ['', [Validators.required]],
      hr30s_after_2min: ['', [Validators.required]],
      hr40s_after_2min: ['', [Validators.required]],
      hr50s_after_2min: [''],
      hr60s_after_2min: [''],
      hr10s_after_3min: [''],
      hr20s_after_3min: [''],
      hr30s_after_3min: [''],
      hr40s_after_3min: [''],
      hr50s_after_3min: [''],
      hr60s_after_3min: [''],
      hr10s_after_4min: [''],
      hr20s_after_4min: [''],
      hr30s_after_4min: [''],
      hr40s_after_4min: [''],
      hr50s_after_4min: [''],
      hr60s_after_4min: [''],
      hr10s_after_5min: [''],
      hr20s_after_5min: [''],
      hr30s_after_5min: [''],
      hr40s_after_5min: [''],
      hr50s_after_5min: [''],
      hr60s_after_5min: [''],
      hr10s_after_6min: [''],
      hr20s_after_6min: [''],
      hr30s_after_6min: [''],
      hr40s_after_6min: [''],
      hr50s_after_6min: [''],
      hr60s_after_6min: [''],

      negative_phaze_puls: [''],
      reaction_blood_pressure: [''],
      time_reset_blood_pressure: [''],
      type_reaction: [''],
      respiratory_rate: [''],

      breath_HR: ['', [Validators.required]],
      breath_1min: ['', [Validators.required]],
      breath_2min: [''],
      breath_3min: [''],
      breath_4min: [''],
      breath_5min: [''],
      breath_6min: [''],
      ASP_hr: ['', [Validators.required]],

      reaction_ASP: [''],
      reaction_ADP: [''],
      reaction_AP: [''],
      RQI: [''],

      ASP_before: ['', [Validators.required]],
      ASP_after: [''],
      ASP_3min: [''],
      ASP_4min: [''],
      ASP_5min: [''],
      ASP_6min: [''],
      ADP_hr: ['', [Validators.required]],
      ADP_before: ['', [Validators.required]],
      ADP_after: [''],
      ADP_3min: [''],
      ADP_4min: [''],
      ADP_5min: [''],
      ADP_6min: [''],
      recomendation: [''],
      reset_time_puls: [''],
      conclusions_after: [''],
      result_after: [''],
      staff_id: [''],
      patient_id: [''],
    });
  }

  calculateColumns() {
    if (this.myForm.invalid) {
      this.myForm.get('hr10').markAllAsTouched();
      this.myForm.get('hr20').markAllAsTouched();
      this.myForm.get('hr30').markAllAsTouched();
      this.myForm.get('hr10s_after_1min').markAllAsTouched();
      this.myForm.get('hr10s_after_2min').markAllAsTouched();
      this.myForm.get('hr20s_after_2min').markAllAsTouched();
      this.myForm.get('hr30s_after_2min').markAllAsTouched();
      this.myForm.get('hr40s_after_2min').markAllAsTouched();
      this.myForm.get('breath_HR').markAllAsTouched();
      this.myForm.get('breath_1min').markAllAsTouched();
      this.myForm.get('ASP_hr').markAllAsTouched();
      this.myForm.get('ASP_before').markAllAsTouched();
      this.myForm.get('ADP_hr').markAllAsTouched();
      this.myForm.get('ADP_before').markAllAsTouched();
    } else {
      // -------збудливість пульсу-----
      if (!this.saveEditInfo) {
        this.saveInfo = true;
      }
      const hrEverage =
        (this.myForm.get('hr10').value +
          this.myForm.get('hr20').value +
          this.myForm.get('hr30').value) /
        3;
      const excitabilityPulse = +(
        ((this.myForm.get('hr10s_after_1min').value - hrEverage) * 100) /
        10
      ).toFixed(); //збудливість пульсу
      this.myForm.get('result_after').setValue(excitabilityPulse);
      this.myForm
        .get('conclusions_after')
        .setValue(this.conclusion(this.myForm.get('result_after').value));
      // this.myForm.get('result_after').setValue(`${excitabilityPulse}%`);
      // ------------------

      // -------час відновлення пульсу------
      const obj = this.myForm.value;
      let HRarr = [];
      let recoveryPulsTime: any;

      // tslint:disable-next-line: forin
      for (const key in obj) {
        const buf = {};
        buf['key'] = key;
        buf['value'] = obj[key];
        // buf[key] = obj[key];
        HRarr.push(buf);
      }
      HRarr = HRarr.slice(10, 44);

      for (let i = 0; i < HRarr.length - 2; i++) {
        let var1: any;
        let var2: any;
        let var3: any;

        var1 = HRarr[i];
        var2 = HRarr[i + 1];
        var3 = HRarr[i + 2];

        if (
          var1.value == var2.value &&
          var2.value == var3.value &&
          var1.value != ''
        ) {
          this.myForm.get('reset_time_puls').setValue(this.resetpuls(i));
          recoveryPulsTime = i;
          // break;
        }
      }
      // ---------------------

      // ---------Негативна фаза пульсу-----
      let counter = 0;
      for (let i = 3; i <= 15; i++) {
        if (HRarr[i].value < hrEverage && HRarr[i].value != '') {
          counter++;
        }
      }

      this.myForm
        .get('negative_phaze_puls')
        .setValue(this.negativePhazepuls(counter));

      // -------------------------------
      // --------реакція артеріального тиску----
      const persentASP = (
        ((this.myForm.get('ASP_before').value -
          this.myForm.get('ASP_hr').value) *
          100) /
        this.myForm.get('ASP_hr').value
      ).toFixed();
      this.myForm.get('reaction_ASP').setValue(this.reactionASP(persentASP));

      // tslint:disable-next-line: max-line-length
      const persentADP = (
        ((this.myForm.get('ADP_before').value -
          this.myForm.get('ADP_hr').value) *
          100) /
        this.myForm.get('ADP_hr').value
      ).toFixed();
      this.myForm.get('reaction_ADP').setValue(this.reactionADP(persentADP));

      const APbefore =
        this.myForm.get('ASP_hr').value - this.myForm.get('ADP_hr').value;
      const APafter =
        this.myForm.get('ASP_before').value -
        this.myForm.get('ADP_before').value;
      const differenceAP = (+APafter - +APbefore).toFixed();
      this.myForm.get('reaction_AP').setValue(this.resultAP(differenceAP));

      const rqi = (
        +differenceAP /
        (this.myForm.get('hr10s_after_1min').value - hrEverage)
      ).toFixed(1);
      // this.myForm.get('RQI').setValue(`${rqi} у.о.`);
      this.myForm.get('RQI').setValue(rqi);

      // -------------------
      // ---------відновлення артеріального тиску
      const ASPdefault = this.myForm.get('ASP_hr').value;
      const ADPdefault = this.myForm.get('ADP_hr').value;
      const ASParr = [
        'ASP_before',
        'ASP_after',
        'ASP_3min',
        'ASP_4min',
        'ASP_5min',
        'ASP_6min',
      ];
      const ADParr = [
        'ADP_before',
        'ADP_after',
        'ADP_3min',
        'ADP_4min',
        'ADP_5min',
        'ADP_6min',
      ];
      let recoveryTimeBlood: number;

      for (let i = 0; i < ASParr.length; i++) {
        const ASPValue = this.myForm.get(`${ASParr[i]}`).value;
        const ADPValue = this.myForm.get(`${ADParr[i]}`).value;
        if (
          ASPValue >= ASPdefault - 5 &&
          ASPValue <= ASPdefault + 5 &&
          ADPValue >= ADPdefault - 5 &&
          ADPValue <= ADPdefault + 5
        ) {
          this.myForm
            .get('time_reset_blood_pressure')
            .setValue(this.recoveryBloodPressure(i));
          recoveryTimeBlood = i;

          break;
        }
      }
      if (!this.myForm.get('time_reset_blood_pressure').value) {
        this.myForm
          .get('time_reset_blood_pressure')
          .setValue(this.recoveryBloodPressure(3));
      }

      // --------------------------
      // ------------ Визначення типу реакції ------------------
      const diferenceASP =
        this.myForm.get('ASP_before').value - this.myForm.get('ASP_hr').value;
      const diferenceADP =
        this.myForm.get('ADP_before').value - this.myForm.get('ADP_hr').value;
      let ASPup2min: boolean;
      let ADPup2min: boolean;
      if (
        this.myForm.get('ASP_after').value > ASPdefault &&
        this.myForm.get('ASP_before').value < this.myForm.get('ASP_after').value
      ) {
        ASPup2min = true;
      }
      if (
        this.myForm.get('ADP_after').value > ADPdefault &&
        this.myForm.get('ADP_before').value < this.myForm.get('ADP_after').value
      ) {
        ADPup2min = true;
      }

      if (
        excitabilityPulse < 80 &&
        recoveryPulsTime >= 4 &&
        recoveryPulsTime <= 13 &&
        diferenceASP <= 40 &&
        diferenceADP > -10 &&
        diferenceADP < 10 &&
        recoveryTimeBlood <= 2
      ) {
        this.myForm.get('type_reaction').setValue(this.typeReaction('value1'));
      } else if (
        excitabilityPulse > 80 &&
        recoveryPulsTime > 13 &&
        diferenceASP > 40 &&
        diferenceADP > 10 &&
        recoveryTimeBlood > 2
      ) {
        this.myForm.get('type_reaction').setValue(this.typeReaction('value2'));
      } else if (
        excitabilityPulse > 80 &&
        recoveryPulsTime > 13 &&
        diferenceASP > 40 &&
        diferenceADP < -10 &&
        recoveryTimeBlood > 2
      ) {
        this.myForm.get('type_reaction').setValue(this.typeReaction('value3'));
      } else if (
        excitabilityPulse > 80 &&
        recoveryPulsTime > 13 &&
        diferenceASP <= 40 &&
        diferenceADP > -10 &&
        diferenceADP < 10 &&
        recoveryTimeBlood > 2
      ) {
        this.myForm.get('type_reaction').setValue(this.typeReaction('value4'));
      } else if (
        excitabilityPulse > 80 &&
        recoveryPulsTime > 13 &&
        ADPup2min == true &&
        ASPup2min == true &&
        recoveryTimeBlood > 2
      ) {
        this.myForm.get('type_reaction').setValue(this.typeReaction('value5'));
      } else {
        this.myForm
          .get('type_reaction')
          .setValue(this.typeReaction('not_specified'));
      }

      // -----------------------------------
      // --------- частота диханян -----------
      let HRsumm2min = 0;
      let HRsumm3min = 0;
      let HRsumm4min = 0;
      let HRsumm5min = 0;
      let HRsumm6min = 0;

      for (let i = 3; i <= HRarr.length; i++) {
        if (i >= 3 && i <= 8) {
          HRsumm2min += HRarr[i].value;
        }
        if (i >= 9 && i <= 14) {
          HRsumm3min += HRarr[i].value;
        }
        if (i >= 15 && i <= 20) {
          HRsumm4min += HRarr[i].value;
        }
        if (i >= 21 && i <= 26) {
          HRsumm5min += HRarr[i].value;
        }
        if (i >= 27 && i <= 32) {
          HRsumm6min += HRarr[i].value;
        }
      }
      const HRsumObj = [
        { summ: HRsumm2min },
        { summ: HRsumm3min },
        { summ: HRsumm4min },
        { summ: HRsumm5min },
        { summ: HRsumm6min },
      ];
      const arrHRminutes = [
        'breath_2min',
        'breath_3min',
        'breath_4min',
        'breath_5min',
        'breath_6min',
      ];
      let goodBreath = false;
      HRsumObj.forEach((elem, index) => {
        const min = elem.summ / 4;
        const max = elem.summ / 3;
        if (
          this.myForm.get(`${arrHRminutes[index]}`).value > min &&
          this.myForm.get(`${arrHRminutes[index]}`).value < max
        ) {
          goodBreath = true;
        }
      });
      if (goodBreath) {
        this.myForm
          .get('respiratory_rate')
          .setValue(this.respiratoryRate('good'));
      } else {
        this.myForm
          .get('respiratory_rate')
          .setValue(this.respiratoryRate('bad'));
      }

      // ------------------------------
    }
  }

  respiratoryRate(value) {
    if (!value) {
      return 'not specified';
    }
    if (this.langUA) {
      switch (value) {
        case 'good':
          return 'Нормальна реакція на пробу';
          break;
        case 'bad':
          return 'Незадовільна реакція';
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case 'good':
          return 'Normal reaction to the sample';
          break;
        case 'bad':
          return 'Unsatisfactory reaction';
          break;
        default:
          break;
      }
    }
  }

  typeReaction(value) {
    if (this.langUA) {
      if (value == 'value1') {
        return 'Нормотонічний';
      } else if (value == 'value2') {
        return 'Гіпертонічний';
      } else if (value == 'value3') {
        return 'Дистонічний';
      } else if (value == 'value4') {
        return 'Астенічний';
      } else if (value == 'value5') {
        return 'Східчастий';
      } else if (value == 'not_specified') {
        return 'Перехідний тип';
      }
    } else {
      if (value == 'value1') {
        return 'Normotonic';
      } else if (value == 'value2') {
        return 'Hypertensive';
      } else if (value == 'value3') {
        return 'Dystonic';
      } else if (value == 'value4') {
        return 'Asthenic';
      } else if (value == 'value5') {
        return 'Stepped';
      } else if (value == 'not_specified') {
        return 'Transitional type';
      }
    }
  }

  recoveryBloodPressure(min) {
    if (this.langUA) {
      if (min > 0 && min <= 2) {
        return 'Нормальна реакція на пробу';
      } else {
        return 'Незадовільна реакція';
      }
    } else {
      if (min <= 2) {
        return 'Normal reaction to the sample';
      } else {
        return 'Unsatisfactory reaction';
      }
    }
  }

  resultAP(value) {
    if (this.langUA) {
      if (value < 30 || value > 50) {
        return 'Несприятливий тип реакції';
      } else if (value >= 30 && value <= 50) {
        return 'Нормальна реакція на пробу';
      }
    } else {
      if (value < 30 && value > 50) {
        return 'Adverse type of reaction';
      } else if (value >= 30 && value <= 50) {
        return 'Normal reaction to the sample';
      }
    }
  }

  reactionADP(persent) {
    if (this.langUA) {
      if (persent >= -10 && persent <= 0) {
        return 'Нормальна реакція на пробу';
      } else if (persent < -10 || persent > 0) {
        return 'Незадовільна реакція';
      }
    } else {
      if (persent >= -10 && persent <= 0) {
        return 'Normal reaction to the sample';
      } else if (persent < -10) {
        return 'Unsatisfactory reaction';
      }
    }
  }

  reactionASP(persent) {
    if (this.langUA) {
      if (persent >= 0 && persent < 30) {
        return 'Нормальна реакція на пробу';
      } else if (persent <= 0 || persent >= 30) {
        return 'Незадовільна реакція';
      }
    } else {
      if (persent >= 0 && persent < 30) {
        return 'Normal reaction to the sample';
      } else if (persent >= 30) {
        return 'Unsatisfactory reaction';
      }
    }
  }

  negativePhazepuls(counter) {
    if (this.langUA) {
      if (counter === 0) {
        return 'Відсутня ';
      } else if (counter > 0 && counter < 12) {
        return 'Наявна';
      } else if (counter >= 12) {
        return 'Незадовільна  ';
      }
    } else {
      if (counter > 0 && counter < 12) {
        return 'Available';
      } else if (counter === 0) {
        return 'Missing ';
      } else if (counter >= 12) {
        return 'Unsatisfactory  ';
      }
    }
  }

  resetpuls(value) {
    if (!value) {
      return '';
    }
    if (this.langUA) {
      if (value >= 4 && value <= 13) {
        return 'Нормальна швидкість відновлення пульсу';
      } else if (value > 13) {
        return 'Сповільнення відновних процесів';
      }
    } else {
      if (value > 8 && value <= 13) {
        return 'Normal heart rate recovery rate';
      } else if (value > 13) {
        return 'Slowing down the recovery process';
      }
    }
  }

  conclusion(value) {
    if (!value) {
      return '';
    }
    if (this.langUA) {
      if (value < 25) {
        return 'Відмінний рівень адаптації ';
      } else if (value >= 25 && value < 50) {
        return 'Хороший рівень адаптації';
      } else if (value >= 50 && value < 80) {
        return 'Задовільний рівень адаптації';
      } else if (value >= 80) {
        return 'Незадовільний (Несприятливий) тип адаптації на фізичного навантаження';
      }
    } else {
      if (value < 25) {
        return 'Excellent level of adaptation';
      } else if (value >= 25 && value < 50) {
        return 'Good level of adaptation';
      } else if (value >= 50 && value < 80) {
        return 'Satisfactory level of adaptation';
      } else if (value >= 80) {
        return 'Unsatisfactory (Adverse) type of adaptation to physical activity';
      }
    }
  }

  //  file select signal + parser file and set value ASP and HR --------------
  onFileSelectSignal(e, key) {
    const reader = new FileReader();
    switch (key) {
      case 'signal_hr':
        this.myForm.get('signal_hr').setValue(e.target.files[0]);
        this.signalHRFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_hr').setValue(result[0]);
          // this.myForm.get('HR_hr').setValue(result[1]);
          this.myForm.get('ADP_hr').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_before':
        this.myForm.get('signal_before').setValue(e.target.files[0]);
        this.signalBeforeFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_before').setValue(result[0]);
          // this.myForm.get('hr10s_after_1min').setValue((+result[1] / 6).toFixed());
          this.myForm.get('ADP_before').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_after':
        this.myForm.get('signal_after').setValue(e.target.files[0]);
        this.signalAfterFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_after').setValue(result[0]);
          // this.myForm.get('HR_after').setValue(result[1]);
          this.myForm.get('ADP_after').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_3min':
        this.myForm.get('signal_3min').setValue(e.target.files[0]);
        this.signal3minFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_3min').setValue(result[0]);
          // this.myForm.get('HR_3min').setValue(result[1]);
          this.myForm.get('ADP_3min').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_4min':
        this.myForm.get('signal_4min').setValue(e.target.files[0]);
        this.signal4minFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_4min').setValue(result[0]);
          this.myForm.get('ADP_4min').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_5min':
        this.myForm.get('signal_5min').setValue(e.target.files[0]);
        this.signal5minFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_5min').setValue(result[0]);
          this.myForm.get('ADP_5min').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      case 'signal_6min':
        this.myForm.get('signal_6min').setValue(e.target.files[0]);
        this.signal6minFileName = e.target.files[0].name;
        reader.onload = () => {
          const result = this.setValueFromParseDoc(reader.result);
          this.myForm.get('ASP_6min').setValue(result[0]);
          this.myForm.get('ADP_6min').setValue(result[2]);
        };
        reader.readAsText(e.target.files[0]);
        break;
      // case 'signal_other_min':
      //   this.myForm.get('signal_other_min').setValue(e.target.files[0]);
      //   this.signalOtherminFileName = e.target.files[0].name;
      //   reader.onload = () => {
      //     const result = this.setValueFromParseDoc(reader.result);
      //     this.myForm.get('ASP_other_min').setValue(result[0]);
      //     this.myForm.get('ADP_other_min').setValue(result[2]);
      //   };
      //   reader.readAsText(e.target.files[0]);
      //   break;
    }
  }

  setValueFromParseDoc(file) {
    const valueArray = file.split('\n', 5);
    valueArray.forEach((element, index) => {
      const wordArray = element.split('');
      if (wordArray[0] == ' ') {
        valueArray[index] = element.slice(3, -1);
      }
    });
    const valueASP = valueArray[0];
    let degree = valueASP.slice(-1);
    let indicator = valueASP.slice(0, 4);
    const resultASP = this.setDegree(degree, indicator);

    const valueHR = valueArray[2];
    degree = valueHR.slice(-1);
    indicator = valueHR.slice(0, 4);
    const resultHR = this.setDegree(degree, indicator);

    const valueADP = valueArray[1];
    degree = valueADP.slice(-1);
    indicator = valueADP.slice(0, 4);
    const resultADP = this.setDegree(degree, indicator);
    return [resultASP, resultHR, resultADP];
  }

  setDegree(degree, indicator) {
    if (degree == 2) {
      return (indicator * 100).toFixed();
    } else if (degree == 1) {
      return (indicator * 10).toFixed();
    }
  }

  // -----------------------------------------------
  getDoctorAndPatientIds() {
    let doctorId, patientId;
    if (this.authService.isConsultant() || this.authService.isDoctor()) {
      doctorId = this.authService.getId();
      patientId = this.patientId;
    } else if (this.authService.isPatient())
      patientId = this.authService.getId();
    return { doctorId, patientId };
  }
}
