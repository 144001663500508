import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routes';
import { GUARDS, SERVICES } from './services';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalculationsComponent } from './components/calculations/calculations.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { TokenInterceptor } from './services/auth/token.interceptor';
import { MeasurementsDataComponent } from './components/measurements-data/measurements-data.component';

import { MeasurementsDataModule } from './components/measurements-data/measurements-data.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { CalculationsModule } from './components/calculations/calculations.module';
import { PipesModule } from './pipes/pipe.module';
import { AddMedicalInstitutionDialogComponent } from './components/modals/add-medical-institution-dialog/add-medical-institution-dialog.component';
import { PasswordRecoveryComponent } from './components/auth/password-recovery/password-recovery.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LangModule } from './components/global-components/lang/lang.module';
import { MaterialModule } from './material.module';
import { SvgModule } from './svg.module';
import { HomeModule } from './components/home/home.module';

export function tokenGetter(): string {
  return localStorage.getItem('token');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CalculationsComponent,
    MeasurementsDataComponent,
    AddMedicalInstitutionDialogComponent,
    PasswordRecoveryComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    FormsModule,
    NgbModule,
    NgxChartsModule,
    CalculationsModule,
    MeasurementsDataModule,
    HomeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ua',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    ChartsModule,
    PipesModule,
    LangModule,
    MaterialModule,
    SvgModule,
  ],
  providers: [
    ...SERVICES,
    ...GUARDS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'uk-UA' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
