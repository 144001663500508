<nav
  class="expert-system-nav expert-system-nav_left-margin navbar-expand-lg py-0 navbar-dark"
  id="subnav"
>
  <div
    class="active-tab"
    [ngClass]="{ open: showNav }"
    (click)="showNav = !showNav"
  >
    {{ navMenu[active]?.name | lang }}
    <div class="expand"></div>
  </div>
  <ul
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-fill w-100"
    ngbNav
    [ngClass]="{ show: showNav }"
  >
    <li
      *ngIf="rightsService.isHasRight(Rights.PARS)"
      (click)="showNav = false"
      [ngbNavItem]="0"
    >
      <a ngbNavLink>
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[0].name | lang }}
        </ng-container>
      </a>
      <ng-template ngbNavContent>
        <app-pars-aoi-tab [biosignalsData]="biosignalsRange"></app-pars-aoi-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.IFSP)"
      (click)="showNav = false"
      [ngbNavItem]="1"
    >
      <a ngbNavLink>
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[1].name | lang }}
        </ng-container>
      </a>
      <ng-template ngbNavContent>
        <app-ifsp-tab [biosignalsData]="biosignalsRange"></app-ifsp-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.ANS)"
      (click)="showNav = false"
      [ngbNavItem]="2"
    >
      <a ngbNavLink>
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[2].name | lang }}
        </ng-container>
      </a>
      <ng-template ngbNavContent>
        <app-ans-tab [biosignalsData]="biosignalsRange"></app-ans-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.Heart)"
      (click)="showNav = false"
      [ngbNavItem]="3"
    >
      <a ngbNavLink>
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[3].name | lang }}
        </ng-container>
      </a>
      <ng-template ngbNavContent>
        <app-heart-tab [biosignalsData]="biosignalsRange"></app-heart-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.Vessels)"
      (click)="showNav = false"
      [ngbNavItem]="4"
    >
      <a ngbNavLink>
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[4].name | lang }}
        </ng-container>
      </a>
      <ng-template ngbNavContent let-active>
        <app-vessels-tab
          [initData]="active"
          [biosignalsData]="biosignalsRange"
        ></app-vessels-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.NervousSystem)"
      (click)="showNav = false"
      [ngbNavItem]="5"
    >
      <a ngbNavLink
        ><ng-container *ngIf="!(reloading$ | async)">{{
          navMenu[5].name | lang
        }}</ng-container></a
      >
      <ng-template ngbNavContent>
        <app-nervous-system-tab
          [biosignalsData]="biosignalsRange"
        ></app-nervous-system-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.Rufier)"
      (click)="showNav = false"
      [ngbNavItem]="6"
    >
      <a ngbNavLink
        ><ng-container *ngIf="!(reloading$ | async)">{{
          navMenu[6].name | lang
        }}</ng-container></a
      >
      <ng-template ngbNavContent>
        <app-rufier-test-tab
          [biosignalsValue]="biosignals"
        ></app-rufier-test-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.ExpertSystem)"
      (click)="showNav = false"
      [ngbNavItem]="7"
    >
      <a ngbNavLink
        ><ng-container *ngIf="!(reloading$ | async)">{{
          navMenu[7].name | lang
        }}</ng-container></a
      >
      <ng-template ngbNavContent>
        <app-expert-tab [biosignalsData]="biosignalsRange"></app-expert-tab>
      </ng-template>
    </li>
    <li
      *ngIf="rightsService.isHasRight(Rights.Martine)"
      (click)="showNav = false"
      [ngbNavItem]="8"
    >
      <a ngbNavLink
        ><ng-container *ngIf="!(reloading$ | async)">{{
          navMenu[8].name | lang
        }}</ng-container></a
      >
      <ng-template ngbNavContent>
        <app-martine-test></app-martine-test>
      </ng-template>
    </li>
  </ul>
</nav>
<div
  class="expert-system-content expert-system-content_left-margin"
  [ngbNavOutlet]="nav"
></div>
