import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AddMedicalInstitutionDialogComponent } from 'src/app/components/modals/add-medical-institution-dialog/add-medical-institution-dialog.component';
import { Doctor } from 'src/app/models/doctor';
import { MedicalInstitution } from 'src/app/models/medical-institution';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DegreeService } from 'src/app/services/dictionaries/degree.service';
import { DocumentTypeService } from 'src/app/services/dictionaries/document-type.service';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { PhonesTypeService } from 'src/app/services/dictionaries/phones-type.service';
import { QualificationTypeService } from 'src/app/services/dictionaries/qualification-type.service';
import { SpecializationLevelsService } from 'src/app/services/dictionaries/specialization-levels.service';
import { SpecialityLevelsService } from 'src/app/services/dictionaries/specialty-level.service';
import { ToastrMessageService } from 'src/app/services/dictionaries/toastr-message.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { StaffService } from 'src/app/services/staff.service';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';
import { ChangePasswordDialogComponent } from '../../profile/change-password-dialog/change-password-dialog.component';
import { ChangeLangDetectorComponent } from '../change-lang-detector/change-lang-detector.component';
import { TelegramMessageBoxComponent } from './telegram-message-box/telegram-message-box.component';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultantComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  myForm: FormGroup = new FormGroup({});
  statusRegisterStaff: boolean = false;
  staffData: any = {
    diploma_file: [],
    qualifications_file: [],
    specialisation_file: [],
    scince_degree: [],
    scientific_file: [],
    consent_file: [],
  };

  educationFileNames: any;
  qualificationFileNames: any;
  specialitiesFileNames: any;
  scinceDegreeFileNames: any;
  scientificTitleFileNames: any;
  courseFileNames: any;
  documentsFileNames: any;
  insuranceFileNames: any;
  staff_file: any;
  staff_file_name: any;
  positionsList: any;
  positionsRoles = [];
  eHealthDivisions = [];
  deleteFiles = [];
  staff_file_decode: any;
  environmentUrl: any;
  education_degree: any = [];
  qualification_type: any = [];
  speciality_type: any = [];
  speciality_level: any = [];
  spec_qualification_type: any = [];
  country: any = [];
  science_degree_list: any = [];
  consentFileNames: string = '';

  hideGroups: boolean = false;
  hideFields: boolean = false;
  showIndicatorDescription: boolean = false;
  updatedFormValues: any;
  consultant: Doctor;
  medicalInstitutions: MedicalInstitution[] = [];
  isVisiblePassword: boolean = false;
  formData: FormData;
  file;
  isDisabled: boolean = false;
  uploadPhoto: any = '';
  photoEmitter = new BehaviorSubject<any>(this.uploadPhoto);

  isLoading: boolean = false;
  photoExist: boolean = true;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  isStaff: boolean = false;

  routerId: string = '';

  @Input() set data(value) {
    if (value) {
      this.myForm.patchValue(value);
      if (value.medicalInstitution) {
        const medicalInstitution = value.medicalInstitution._id;
        this.myForm.get('medicalInstitution').setValue(medicalInstitution);
      }
      this.consultant = value;
      this.isProfile() ? this.getPhoto() : this.getPhotoStaff();
    }
  }

  constructor(
    public fb: FormBuilder,
    private toastr: ToastrService,
    private staffService: StaffService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private registrationService: RegistrationService,
    public dialog: MatDialog,
    private authService: AuthService,
    public phoneService: PhonesTypeService,
    public documentService: DocumentTypeService,
    public genderService: GenderService,
    public specializationLevelsService: SpecializationLevelsService,
    public qualificationTypeService: QualificationTypeService,
    public specialtyLevelService: SpecialityLevelsService,
    public degreeService: DegreeService,
    public toastrMessageService: ToastrMessageService,
    public cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super(cd);
    this.isAdmin = this.auth.isAdmin();
    this.isStaff = this.auth.isDoctor() || this.auth.isConsultant();
  }

  public isProfile() {
    return this.router.url.includes('profile');
  }

  ngOnInit(): void {
    this.reactiveForm();
    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params: any) => {
        if (!localStorage.getItem('doctorProfileId') && params.id) {
          localStorage.setItem('doctorProfileId', params.id);
        }
        this.routerId = localStorage.getItem('doctorProfileId');
        this.getStaffData();
      },
    });
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
    this.getStaffData();
    this.staffService
      .getMedicalInstitutions()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: any) => {
        this.medicalInstitutions = data;
      });
  }

  ngOnDestroy() {
    this.photoEmitter.next('');
    this.photoExist = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TelegramMessageBoxComponent, {
      data: { email: this.consultant.email },
    });
  }

  getStaffData() {
    this.staffService
      .getDoctor(this.routerId)
      // .getDoctor(this.auth.getId())
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        this.data = resp.doctor;
      });
  }

  onSelectFiles(e: any) {
    this.file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.photoExist = true;
      this.uploadPhoto = reader.result;
      this.photoEmitter.next(this.uploadPhoto);
      this.isDisabled = true;
    };
  }

  edit() {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.uploadPhoto = null;
    }
  }

  saveStaff() {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.translate
        .get(['alerts'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((t) => {
          this.toastr.warning(t.alerts.warning_1);
        });
      return;
    }
    this.myForm.get('userType').patchValue(RoleTypeEnum.Consultant);
    this.getUpdatedValues();
    this.myForm.get('id').patchValue(this.consultant._id);
    if (this.myForm.value.id) {
      this.staffService
        .updateDoctor(this.myForm.value.id, this.updatedFormValues)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (res) => {
            this.getStaffData();
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_5);
              });
            if (!this.isProfile()) {
              this.router.navigate(['/home/consultants']);
            }
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err.message);
          }
        );
    } else {
      this.updatedFormValues.userType = RoleTypeEnum.Consultant;
      this.updatedFormValues.language = this.translate.currentLang;
      this.registrationService
        .sendRegistrationData(this.updatedFormValues)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (res) => {
            this.getStaffData();
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_4);
              });
            if (!this.isProfile()) {
              this.router.navigate(['/home/consultants']);
            }
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err.message);
          }
        );
    }
  }

  // --------Phone----------
  displayDelBtn(phones_step: FormArray): boolean {
    if (!this.hideFields) {
      if (phones_step.controls.length == 1) return false;
      else return true;
    } else return true;
  }

  deletedItem(item: FormArray, i: number) {
    item.removeAt(i);
  }

  addPhone(phones_step: FormArray) {
    if (phones_step.controls.length < 2) {
      phones_step.push(this.createPhoneFormGroup());
    }
  }

  addEducation() {
    this.educations.push(this.createEducationsFormGroup());
  }

  addQualification() {
    this.qualifications.push(this.createQualificationsFormGroup());
  }

  addCourses() {
    this.courses.push(this.createCoursesFormGroup());
  }

  addScientific() {
    this.scientifics.push(this.createScientificsFormGroup());
  }

  addScinceDegree() {
    this.science_degree.push(this.createScienceDegreeFormGroup());
  }

  addSpecialities() {
    this.specialities.push(this.createSpecialitiesFormGroup());
  }

  // --------Reactive form----------
  createCoursesFormGroup() {
    return this.fb.group({
      name_course: [''],
      course_date: [''],
      points: [''],
      // file: [''],
    });
  }

  createScientificsFormGroup() {
    return this.fb.group({
      scientific: [''],
      scientific_date: [''],
      // file: [''],
    });
  }

  createScienceDegreeFormGroup() {
    return this.fb.group({
      institution_name: [''],
      issued_date: [''],
      country: [''],
      city: [''],
      degree: [''],
      speciality: [''],
      diploma_number: [''],
      // file: [''],
    });
  }

  createSpecialitiesFormGroup() {
    return this.fb.group({
      speciality: [''],
      speciality_officio: [false],
      level: [''],
      qualification_type: [''],
      attestation_name: [''],
      attestation_date: [''],
      valid_to_date: [''],
      certificate_number: [''],
      // file: [''],
    });
  }

  createQualificationsFormGroup() {
    return this.fb.group({
      type: [''],
      institution_name: [''],
      speciality: [''],
      issued_date: [''],
      certificate_number: [''],
      valid_to: [''],
      additional_info: [''],
      // file: [''],
    });
  }

  createEducationsFormGroup() {
    return this.fb.group({
      institution_name: [''],
      diploma_number: [''],
      issued_date: [''],
      country: [''],
      speciality: [''],
      degree: [''],
      city: [''],
      // file: [''],
    });
  }

  createPhoneFormGroup() {
    return this.fb.group({
      type: [''],
      number: [''],
    });
  }

  getUpdatedValues() {
    this.updatedFormValues = {};
    this.myForm['_forEachChild']((control, name) => {
      if (control.dirty) {
        this.updatedFormValues[name] = control.value;
      }
    });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      id: [],
      userType: [],
      first_name: ['', Validators.required],
      second_name: [''],
      last_name: ['', Validators.required],
      birth_date: ['1973-04-09T21:00:00.000Z', Validators.required],
      no_tax_id: [false],
      tax_id: [''],
      staffId: [''],
      documents: [''],
      medicalInstitution: [''],
      phones: this.fb.array([this.createPhoneFormGroup()]),
      educations: this.fb.array([this.createEducationsFormGroup()]),
      qualifications: this.fb.array([this.createQualificationsFormGroup()]),
      specialities: this.fb.array([this.createSpecialitiesFormGroup()]),
      science_degree: this.fb.array([this.createScienceDegreeFormGroup()]),
      scientifics: this.fb.array([this.createScientificsFormGroup()]),
      courses: this.fb.array([this.createCoursesFormGroup()]),
      start_date: [new Date()],
      end_date: [''],
      gender: [''],
      address: [''],
      email: ['', [Validators.email]],
      working_experience: [''],
      about_myself: [''],
      insurance_policy_name: [''],
      insurance_policy_date: [''],
      type_doc: [''],
      number_doc: [''],
      issued_by: [''],
      issued_at: [''],
      login: ['', Validators.required],
      password: ['', [Validators.required]],
      consent_text: [''],
      consent: [''],
      // fileForm: this.fb.group({
      // insurance_policy_file: [''],
      // specialisation_file: [''],
      // scientific_file: [''],
      // course_file: [''],
      // consent_file: [''],
      // qualifications_file: [''],
      // diploma_file: [''],
      // degree_file: [''],
      // documents_file: [''],

      // }),
    });
  }

  get phones(): FormArray {
    return (<FormArray>this.myForm.get('phones')) as FormArray;
  }

  get qualifications(): FormArray {
    return (<FormArray>this.myForm.get('qualifications')) as FormArray;
  }

  get specialities(): FormArray {
    return (<FormArray>this.myForm.get('specialities')) as FormArray;
  }

  get educations(): FormArray {
    return (<FormArray>this.myForm.get('educations')) as FormArray;
  }

  get science_degree(): FormArray {
    return (<FormArray>this.myForm.get('science_degree')) as FormArray;
  }

  get scientifics(): FormArray {
    return (<FormArray>this.myForm.get('scientifics')) as FormArray;
  }

  get courses(): FormArray {
    return (<FormArray>this.myForm.get('courses')) as FormArray;
  }

  addMedicalInstitution() {
    const modal = this.modalService.open(AddMedicalInstitutionDialogComponent);
    modal.result
      .then((result) => {
        const data = {};
        console.log(data, 'data');
      })
      .catch(() => console.log('error'));
  }

  getPhoto() {
    this.photoExist = false;
    this.auth.photo$
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);
          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }

  getPhotoStaff() {
    this.photoExist = false;
    this.staffService
      .getPhoto(this.consultant._id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.auth.photo = resp;
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);

          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }

  photoUpdate() {
    if (this.file) {
      this.formData = new FormData();
      this.formData.append('photo', this.file);
      this.staffService
        .postPhoto(this.consultant._id, this.formData)
        .pipe(untilComponentDestroyed(this))
        .subscribe((resp) => {
          this.getPhotoStaff();
          this.translate
            .get(['alerts'])
            .pipe(untilComponentDestroyed(this))
            .subscribe((t) => {
              this.toastr.success(t.alerts.warning_11);
            });
          this.isDisabled = false;
        });
    }
  }

  changePassword() {
    const modal = this.dialog.open(ChangePasswordDialogComponent, {});
    modal.componentInstance.role = RoleTypeEnum.Patient;
    modal.componentInstance.password = this.consultant.password;
    modal.componentInstance.update
      .pipe(untilComponentDestroyed(this))
      .subscribe((password) => {
        modal.close();
        this.myForm.get('password').setValue(password);
        this.updatePassword(this.myForm.get('password').value);
      });
    modal.componentInstance.close
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => {
        modal.close();
      });
  }

  updatePassword(password: string) {
    this.staffService
      .updatePassword(this.consultant._id, { password: password })
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res) => {
          this.toastr.success(
            new TranslatePipe(this.authService).transform(
              this.toastrMessageService.password_change_success
            )
          );
          this.auth.setUser(null, res);
          this.getStaffData();
          // this.consultant = this.auth.getUser() as Doctor;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error('error!');
          this.myForm.get('password').setValue(this.consultant.password);
        }
      );
  }
}
