const greenColor = '#66CC99';
const yellowColor = '#EDB900';
const redColor = '#FF3300';
export const HISTOGRAM_AREA = {
  Ms: [
    {
      name: '0-1',
      color: greenColor,
      startNum: 0,
      endNum: 1,
    },
    {
      name: '1-2',
      color: greenColor,
      startNum: 1.01,
      endNum: 2,
    },
    {
      name: '2-3',
      color: yellowColor,
      startNum: 2.01,
      endNum: 3,
    },
    {
      name: '3-4',
      color: yellowColor,
      startNum: 3.01,
      endNum: 4,
    },
    {
      name: '4-5',
      color: redColor,
      startNum: 4.01,
      endNum: 5,
    },
  ],
  Systola: [
    {
      name: '90-100',
      color: yellowColor,
      startNum: 90,
      endNum: 100,
    },
    {
      name: '101-140',
      color: greenColor,
      startNum: 101,
      endNum: 140,
    },
    {
      name: '141-180',
      color: yellowColor,
      startNum: 141,
      endNum: 180,
    },
    {
      name: '181-200',
      color: redColor,
      startNum: 181,
      endNum: 200,
    },
    {
      name: '201-220',
      color: redColor,
      startNum: 201,
      endNum: 220,
    },
    {
      name: '221-250',
      color: redColor,
      startNum: 221,
      endNum: 250,
    },
  ],
  Diastola: [
    {
      name: '50-70',
      color: yellowColor,
      startNum: 50,
      endNum: 70,
    },
    {
      name: '71-100',
      color: greenColor,
      startNum: 71,
      endNum: 100,
    },
    {
      name: '101-119',
      color: yellowColor,
      startNum: 101,
      endNum: 119,
    },
    {
      name: '120-...',
      color: redColor,
      startNum: 120,
      endNum: 300,
    },
  ],
  HeartRate: [
    {
      name: '...-40',
      color: redColor,
      startNum: 1,
      endNum: 40,
    },
    {
      name: '41-60',
      color: yellowColor,
      startNum: 41,
      endNum: 59,
    },
    {
      name: '60-80',
      color: greenColor,
      startNum: 60,
      endNum: 79,
    },
    {
      name: '80-100',
      color: yellowColor,
      startNum: 80,
      endNum: 99,
    },
    {
      name: '100-...',
      color: redColor,
      startNum: 100,
      endNum: 200,
    },
  ],
  MAP: [
    {
      name: '0-65',
      color: greenColor,
      startNum: 0,
      endNum: 65,
    },
    {
      name: '66-110',
      color: yellowColor,
      startNum: 66,
      endNum: 110,
    },
    {
      name: '111-140',
      color: yellowColor,
      startNum: 111,
      endNum: 140,
    },
    {
      name: '141+',
      color: redColor,
      startNum: 141,
      endNum: 1000,
    },
  ],
  PP: [
    {
      name: '0-30',
      color: greenColor,
      startNum: 0,
      endNum: 30,
    },
    {
      name: '31-50',
      color: yellowColor,
      startNum: 31,
      endNum: 50,
    },
    {
      name: '51-70',
      color: yellowColor,
      startNum: 51,
      endNum: 70,
    },
    {
      name: '71-100',
      color: redColor,
      startNum: 71,
      endNum: 100,
    },
    {
      name: '100+',
      color: redColor,
      startNum: 100,
      endNum: 1000,
    },
  ],
  PARSint: [
    {
      name: '1-2',
      color: greenColor,
      startNum: 1,
      endNum: 2,
    },
    {
      name: '3-4',
      color: yellowColor,
      startNum: 3,
      endNum: 4,
    },
    {
      name: '5-6',
      color: yellowColor,
      startNum: 5,
      endNum: 6,
    },
    {
      name: '7-8',
      color: redColor,
      startNum: 7,
      endNum: 8,
    },
    {
      name: '9-10',
      color: redColor,
      startNum: 9,
      endNum: 10,
    },
  ],
  IFSP: [
    {
      name: '1-1.5',
      color: greenColor,
      startNum: 1,
      endNum: 1.49,
    },
    {
      name: '1.5-2.5',
      color: yellowColor,
      startNum: 1.5,
      endNum: 2.49,
    },
    {
      name: '2.5-3.5',
      color: yellowColor,
      startNum: 2.5,
      endNum: 3.49,
    },
    {
      name: '3.5-4.5',
      color: redColor,
      startNum: 3.5,
      endNum: 4.49,
    },
    {
      name: '4.5-5',
      color: redColor,
      startNum: 4.5,
      endNum: 5,
    },
  ],
  HeartDiseases: [
    {
      name: '0-20%',
      color: greenColor,
      startNum: 0,
      endNum: 0.2,
    },
    {
      name: '20-40%',
      color: yellowColor,
      startNum: 0.21,
      endNum: 0.4,
    },
    {
      name: '40-60%',
      color: yellowColor,
      startNum: 0.41,
      endNum: 0.6,
    },
    {
      name: '60-80%',
      color: redColor,
      startNum: 0.61,
      endNum: 0.8,
    },
    {
      name: '80-100%',
      color: redColor,
      startNum: 0.81,
      endNum: 1,
    },
  ],
  LungDiseases: [
    {
      name: '0-20%',
      color: greenColor,
      startNum: 0,
      endNum: 0.2,
    },
    {
      name: '20-40%',
      color: yellowColor,
      startNum: 0.21,
      endNum: 0.4,
    },
    {
      name: '40-60%',
      color: yellowColor,
      startNum: 0.41,
      endNum: 0.6,
    },
    {
      name: '60-80%',
      color: redColor,
      startNum: 0.61,
      endNum: 0.8,
    },
    {
      name: '80-100%',
      color: redColor,
      startNum: 0.81,
      endNum: 1,
    },
  ],
  PsychicalDiseases: [
    {
      name: '0-20%',
      color: greenColor,
      startNum: 0,
      endNum: 0.2,
    },
    {
      name: '20-40%',
      color: yellowColor,
      startNum: 0.21,
      endNum: 0.4,
    },
    {
      name: '40-60%',
      color: yellowColor,
      startNum: 0.41,
      endNum: 0.6,
    },
    {
      name: '60-80%',
      color: redColor,
      startNum: 0.61,
      endNum: 0.8,
    },
    {
      name: '80-100%',
      color: redColor,
      startNum: 0.81,
      endNum: 1,
    },
  ],
  ArrhythmiaDegree: [
    {
      name: '0-20%',
      color: greenColor,
      startNum: 0,
      endNum: 0.2,
    },
    {
      name: '20-40%',
      color: yellowColor,
      startNum: 0.21,
      endNum: 0.4,
    },
    {
      name: '40-60%',
      color: yellowColor,
      startNum: 0.41,
      endNum: 0.6,
    },
    {
      name: '60-80%',
      color: redColor,
      startNum: 0.61,
      endNum: 0.8,
    },
    {
      name: '80-100%',
      color: redColor,
      startNum: 0.81,
      endNum: 1,
    },
  ],
};
