import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';
import { INTERPRETATIONS_DATA } from '../../../services/dictionaries/constants/interpretations-data';
import { ChangeLangDetectorComponent } from '../change-lang-detector/change-lang-detector.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-general-conclusion-box',
  templateUrl: './general-conclusion-box.component.html',
  styleUrls: ['./general-conclusion-box.component.scss'],
})
export class GeneralConclusionBoxComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  biosignal: Biosignal;
  biosignals: Biosignal[] = [];
  averageIndex: number;
  indexDescription: any;
  interpretation: Array<any> = INTERPRETATIONS_DATA;

  PARS: number = null;
  IFSP: number = null;
  ANS: number = null;

  @Input() set setBiosignalsRange(v) {
    if (v?.rangeBiosignals?.length) {
      setTimeout(() => {
        this.biosignals = v.rangeBiosignals;
        if (this.biosignals && this.biosignals.length) {
          this.biosignals.length > 1
            ? ''
            : (this.biosignal = this.biosignals[0]);
        }
        this.expertSystemService.pars$.subscribe((resp) => {
          this.PARS = resp;
        });
        this.expertSystemService.ifsp$.subscribe((resp) => {
          this.IFSP = +resp;
        });
        this.expertSystemService.ans$.subscribe((resp) => {
          this.ANS = resp;
        });
        this.setAverageValuesAndInterpretations();
      }, 0);
    }
  }
  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
  }

  setAverageValuesAndInterpretations() {
    if (this.PARS && this.IFSP && this.ANS) {
      this.averageIndex = Math.round((this.PARS + this.IFSP + this.ANS) / 3);
      if (+this.averageIndex >= 1 && +this.averageIndex <= 5) {
        this.indexDescription = this.interpretation.find(
          (obj) => +this.averageIndex === obj.value
        );
        this.cd.detectChanges();
        this.setClassInterpretations();
      }
    } else {
      this.averageIndex = null;
      this.cd.detectChanges();
      this.setClassInterpretations();
    }
  }
  setClassInterpretations() {
    let className = '';
    switch (this.averageIndex) {
      case 1:
        className = 'success';
        break;
      case 2:
      case 3:
        className = 'warning';
        break;
      case 4:
      case 5:
        className = 'error';
        break;
    }
    return className;
  }
}
