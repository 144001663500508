import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { WeekDaysService } from 'src/app/services/dictionaries/week-days.service';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { StaffPatientsService } from 'src/app/services/staff-patients/staff-patients.service';
import { PatientUpdateService } from 'src/app/services/patient-update.service';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { PatientService } from 'src/app/components/measurements-data/services/patient.service';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { Biosignal } from 'src/app/models/biosignal';
import { ChangeLangDetectorComponent } from '../../change-lang-detector/change-lang-detector.component';
import {PatientListMode} from '../../../../enums/patient-list.enum';
import {Patient} from "../../../../models/patient";

const DEFAULT_DURATION = 150;

@Component({
  selector: 'app-patients-list-item',
  templateUrl: './patients-list-item.component.html',
  styleUrls: ['./patients-list-item.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class PatientsListItemComponent
  extends ChangeLangDetectorComponent
  implements OnInit, OnChanges
{
  @Input() patient: any = {};
  @Input() routerId: string = '';
  @Input() mode: PatientListMode = PatientListMode.MEDIUM;
  @Input() isChosen = false;

  @Output() getPatients = new EventEmitter();
  @Output() chosenPatient: EventEmitter<Patient> = new EventEmitter();

  patients: Array<any> = [];

  photoExist: boolean = false;
  photoEmitter = new BehaviorSubject<any>({});

  photoArray: any = [];
  diagnosis: string = '';
  createDiagnosis: boolean = false;

  collapsed: boolean = true;
  tableBusy: boolean = false;

  lastDiagnos: any;
  lastBiosignal: Biosignal;
  editedDiagnosis: any;
  editItem: boolean = false;
  readonly PatientListMode = PatientListMode;

  constructor(
    public weekDayDict: WeekDaysService,
    public patientService: PatientService,
    public sanitizer: DomSanitizer,
    public genderDict: GenderService,
    public auth: AuthService,
    private router: Router,
    private staffPatientsService: StaffPatientsService,
    private patientUpdateService: PatientUpdateService,
    private biosignalsService: BiosignalsService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
    this.searchLastDiagnos();
    this.getBiosignalsData(this.patient._id, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isChosen']) {
      console.log(this.isChosen);
    }
  }

  ngOnDestroy(): void {
    this.staffPatientsService.confirm = true;
  }

  getBiosignalsData(patient?, number?): void {
    let startDate = null;
    let endDate = null;
    if (number == 0) {
      startDate = new Date('1990-08-24').toISOString();
      let date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      endDate = new Date(date).toISOString();
    }
    this.biosignalsService
      .getMeasurementData(startDate, endDate, patient, number)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        if (Object.keys(data).length) {
          this.lastBiosignal = data.measurementResults[0];
        }
      });
  }

  searchLastDiagnos() {
    if (this.patient.diagnosis_array?.length) {
      this.lastDiagnos = this.patient.diagnosis_array.reduce((a, b) => {
        return new Date(a.MeasureDate) > new Date(b.MeasureDate) ? a : b;
      });
    }
  }

  changeCreateStatus() {
    this.createDiagnosis = !this.createDiagnosis;
    this.staffPatientsService.confirm = !this.createDiagnosis;
  }

  saveDiagnosis(patientId: any) {
    if (this.diagnosis) {
      this.tableBusy = true;
      if (!this.editItem)
        this.patientUpdateService
          .setDiagnosis(patientId, { diagnosis: this.diagnosis })
          .pipe(untilComponentDestroyed(this))
          .subscribe((v: any) => {
            this.patient.diagnosis_array.push(v);
            this.createDiagnosis = false;
            this.staffPatientsService.confirm = !this.createDiagnosis;
            this.diagnosis = '';
            // this.getPatients.emit();
            this.searchLastDiagnos();
            this.tableBusy = false;
          });
      else {
        this.editedDiagnosis.diagnosis = this.diagnosis;
        this.patientUpdateService
          .updateDiagnosis(this.editedDiagnosis._id, this.editedDiagnosis)
          .pipe(untilComponentDestroyed(this))
          .subscribe((v: any) => {
            this.editItem = false;
            this.createDiagnosis = false;
            this.staffPatientsService.confirm = !this.createDiagnosis;
            this.diagnosis = '';
            this.tableBusy = false;
          });
      }
    }
  }

  editDiagnosis(item: any) {
    this.editItem = true;
    this.createDiagnosis = true;
    this.editedDiagnosis = item;
    this.diagnosis = item.diagnosis;
  }

  viewPatientInfo(patient) {
    this.patient = patient;
    this.selectPatient(patient);
  }

  selectPatient(data: any) {
    this.patientService.setPatient = data;
    localStorage.setItem('selectedPatient', JSON.stringify(data));
    if (localStorage.getItem('rufierTest') !== null) {
      localStorage.removeItem('rufierTest');
    }
    this.router.navigate(['/home/calculations']);
  }

  getPhotoPatient(id) {
    this.photoExist = false;
    this.patientService
      .getPhoto(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);
          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }
}
