import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Biosignal } from '../models/biosignal';
import { OscillogramData } from '../models/oscillogram-data';
import { BiosignalsService } from './biosignals.service';
import { BiosignalsRange } from '../models/biosignals-range';
import * as ecg_test_data from '../../assets/json/ecg_response_sample.json';
// import * as ecg_test_data from '../../assets/json/ecg_response_example.json';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsDataUpdateService {
  ecg_test = (ecg_test_data as any).default;
  ecgData: any = { valuePoints: [], timePoints: [] };

  public selectedEcgLead$: Observable<string>; //enum LeadID
  public isOscillogram$: Observable<boolean>;
  public selectedMeasurement$: Observable<Biosignal>;
  public selectedOscillogram$: Observable<OscillogramData>;
  public selectedComparisonOscillogram$: Observable<OscillogramData>;
  public newMeasurements$: Observable<Biosignal[]>;
  public updateMeasurementsRange$: Observable<BiosignalsRange>;
  public measurementsNumber$: Observable<number>;
  public measurementsRange$: Observable<{ startDate: Date; endDate: Date }>;

  public selectedEcgLeadSubject: Subject<string>;
  public isOscillogramSubject: Subject<boolean>;
  private selectedMeasurementSubject: Subject<Biosignal>;
  private selectedOscillogramSubject: Subject<OscillogramData>;
  private selectedComparisonOscillogramSubject: Subject<OscillogramData>;
  private newMeasurementsSubject: Subject<Biosignal[]>;
  private updateMeasurementsRangeSubject: Subject<BiosignalsRange>;
  private measurementsNumberSubject: Subject<number>;
  private measurementsRangeSubject: Subject<{ startDate: Date; endDate: Date }>;

  constructor(
    private http: HttpClient,
    private biosignalsService: BiosignalsService
  ) {
    this.ecgData = this.ecg_test.Records.x1.RawSignal;
    this.selectedEcgLeadSubject = new Subject<'I'>();
    this.selectedEcgLead$ = this.selectedEcgLeadSubject.asObservable();
    this.isOscillogramSubject = new Subject<true>();
    this.isOscillogram$ = this.isOscillogramSubject.asObservable();
    this.selectedMeasurementSubject = new Subject<Biosignal>();
    this.selectedMeasurement$ = this.selectedMeasurementSubject.asObservable();
    this.selectedOscillogramSubject = new Subject<OscillogramData>();
    this.selectedOscillogram$ = this.selectedOscillogramSubject.asObservable();
    this.selectedComparisonOscillogramSubject = new Subject<OscillogramData>();
    this.selectedComparisonOscillogram$ =
      this.selectedComparisonOscillogramSubject.asObservable();
    this.newMeasurementsSubject = new Subject<Biosignal[]>();
    this.newMeasurements$ = this.newMeasurementsSubject.asObservable();
    this.updateMeasurementsRangeSubject = new Subject<BiosignalsRange>();
    this.updateMeasurementsRange$ =
      this.updateMeasurementsRangeSubject.asObservable();
    this.measurementsNumberSubject = new Subject<number>();
    this.measurementsNumber$ = this.measurementsNumberSubject.asObservable();
    this.measurementsRangeSubject = new Subject<{
      startDate: Date;
      endDate: Date;
    }>();
    this.measurementsRange$ = this.measurementsRangeSubject.asObservable();
  }

  changeEcgLead(LeadID): void {
    this.selectedEcgLeadSubject.next(LeadID);
  }

  changeMeasurements(biosignals: Biosignal[]): void {
    this.newMeasurementsSubject.next(biosignals);
  }

  changeSelectedMeasurement(biosignals: Biosignal[], id?: string): void {
    let currentBiosignal;
    if (id) {
      currentBiosignal = biosignals.find((biosignal) => biosignal._id == id);
    } else {
      if (biosignals && biosignals.length > 0) {
        currentBiosignal = biosignals[biosignals.length - 1];
      }
    }
    if (currentBiosignal) {
      this.selectedMeasurementSubject.next(currentBiosignal);
    }
  }

  changeMeasurementsNumber(measurementsNumber): void {
    this.measurementsNumberSubject.next(measurementsNumber);
  }

  changeMeasurementsRangeDate(startDate, endDate): void {
    this.measurementsRangeSubject.next({ startDate, endDate });
  }

  initSelectedOscillogram(oscillogramData: OscillogramData): void {
    this.selectedOscillogramSubject.next(oscillogramData);
  }

  // changeSelectedOscillogram(id: string): void {
  //   this.biosignalsService.getOscillogram(id).subscribe((oscillogramData) => {
  //     console.log(oscillogramData);
  //     this.selectedOscillogramSubject.next(oscillogramData);
  //   });
  // }

  changeSelectedOscillogram(id: string, type: string): void {
    this.biosignalsService
      .getRawSignal(id, type)
      .subscribe((oscillogramData) => {
        this.selectedOscillogramSubject.next(oscillogramData);
      });
  }

  changeSelectedComparisonOscillogram(id: string): void {
    this.biosignalsService.getOscillogram(id).subscribe((oscillogramData) => {
      this.selectedComparisonOscillogramSubject.next(oscillogramData);
    });
  }

  changeMeasurementsRange(
    origin: string,
    biosignals: Biosignal[],
    beginIndex: number,
    endIndex: number,
    excludedBiosignalsIds: Set<string>
  ): void {
    let biosignalsRange = biosignals.slice(beginIndex, endIndex + 1);
    biosignalsRange = biosignalsRange.filter(
      (biosignal) => !excludedBiosignalsIds.has(biosignal._id)
    );
    this.updateMeasurementsRangeSubject.next(
      new BiosignalsRange(
        origin,
        biosignalsRange,
        beginIndex,
        endIndex,
        excludedBiosignalsIds
      )
    );
  }

  setComment(commentDB, id): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/biosignals/${id}/comment`,
      commentDB
    );
  }
}
