import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordStrengthValidator = function (
  control: AbstractControl
): ValidationErrors | null {
  let value: string = control.value || '';

  if (!value) {
    return null;
  }

  let upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити велику літеру',
        pl: 'Hasło musi zawierać wielką literę',
        en: `Password has to contain Uppercase characters`,
      },
    };
  }

  let lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити малу літеру',
        pl: 'Hasło musi zawierać małą literę',
        en: `Password has to contain Lowercase characters`,
      },
    };
  }

  let numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити цифру',
        pl: 'Hasło musi zawierać cyfrę',
        en: `Password has to contain number characters`,
      },
    };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharacters.test(value) === false) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити символ',
        pl: 'Hasło musi zawierać znak',
        en: `Password has to contain special character`,
      },
    };
  }

  if (value.length < 8) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити не менше 8 символів',
        pl: 'Hasło musi zawierać co najmniej 8 znaków',
        en: `The password must contain at least 8 characters`,
      },
    };
  }

  let latinAlphabet = /[А-Яа-яЁё]+/;
  if (latinAlphabet.test(value) === true) {
    return {
      passwordStrength: {
        ua: 'Пароль має містити лише літери латинського алфавіту',
        pl: 'Hasło musi zawierać wyłącznie litery alfabetu łacińskiego',
        en: `The password must contain only letters of the Latin alphabet`,
      },
    };
  }

  return null;
};
