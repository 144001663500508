import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SignUpPatient } from '../models/patient';
import { RoleTypeEnum } from '../components/auth/model/enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  sendRegistrationData(
    signUpPatient: SignUpPatient
  ): Observable<SignUpPatient> {
    return this.http.post<SignUpPatient>(
      `${environment.apiUrl}/signup`,
      signUpPatient
    );
  }

  restorePassword(data: any, role: RoleTypeEnum): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', `${data.email}`);
    params = params.append('role', `${role}`);
    return this.http.get(`${environment.apiUrl}/restore-password`, {
      params,
      responseType: 'text',
    });
  }
}
