export const MEASUREMENTS_NUMBERS = [
  {
    id: 15,
    label: {
      ua: '15',
      en: '15',
      pl: '15',
    },
  },
  {
    id: 30,
    label: {
      ua: '30',
      en: '30',
      pl: '30',
    },
  },
  {
    id: 50,
    label: {
      ua: '50',
      en: '50',
      pl: '50',
    },
  },
  {
    id: 100,
    label: {
      ua: '100',
      en: '100',
      pl: '100',
    },
  },
  {
    id: 0,
    label: {
      ua: 'Всі',
      en: 'All',
      pl: 'Wszyscy',
    },
  },
];
