export const NAV_MENU = [
  {
    id: 1,
    name: {
      ua: 'Завантажити дані',
      en: 'Download data',
      pl: 'Pobrać dane',
    },
  },
  {
    id: 2,
    name: {
      ua: 'Налаштування',
      en: 'Settings',
      pl: 'Ustawienia',
    },
  },
  {
    id: 3,
    name: {
      ua: 'Медіапайп',
      en: 'Mediapipe',
      pl: 'Ustawienia',
    },
  },
  {
    id: 4,
    name: {
      ua: 'Похибка вимірювань',
      en: 'Measurement error',
      pl: 'błąd pomiaru',
    },
  },
];

export const NAV_MENU_ADMIN = [
  {
    id: 1,
    name: {
      ua: 'Панель адміністратора',
      en: 'Admin Panel',
      pl: 'Panelu administracyjnego',
    },
  },
  {
    id: 2,
    name: {
      ua: 'Налаштування',
      en: 'Settings',
      pl: 'Ustawienia',
    },
  },
  {
    id: 3,
    name: {
      ua: 'Похибка вимірювань',
      en: 'Measurement error',
      pl: 'Błąd pomiaru',
    },
  },
  {
    id: 4,
    name: {
      ua: 'Лікарі',
      en: 'Doctors',
      pl: 'Lekarze',
    },
  },
  {
    id: 5,
    name: {
      ua: 'Налаштування пристроїв',
      en: 'Device setting',
      pl: 'Ustawienie urządzenia',
    },
  },
];

export const NAV_MENU_DOCTOR = [
  {
    id: 1,
    name: {
      ua: 'Пацієнти',
      en: 'Patients',
      pl: 'Pacjentów',
    },
  },
  {
    id: 2,
    name: {
      ua: 'Загальна інформація',
      en: 'General info',
      pl: 'Informacje ogólne',
    },
  },
  {
    id: 3,
    name: {
      ua: 'Розклад',
      en: 'Schedule',
      pl: 'Harmonogram',
    },
  },
];

export const NAV_MENU_CALCULATIONS = [
  {
    id: 1,
    name: {
      ua: 'AO',
      en: 'AO',
      pl: 'OT',
    },
  },
  {
    id: 2,
    name: {
      ua: 'ЕКГ',
      en: 'ECG',
      pl: 'EKG',
    },
  },
  {
    id: 3,
    name: {
      ua: "Пульсація зап'ястка",
      en: 'Wrist pulsations',
      pl: 'Pulsacje nadgarstka',
    },
  },
  {
    id: 4,
    name: {
      ua: 'Пульсація головного мозку',
      en: 'Cranial pulsation',
      pl: 'Pulsacja mózgu',
    },
  },
];

export const NAV_MENU_CALCULATIONS_2 = [
  {
    id: 1,
    name: {
      ua: 'Домашня',
      en: 'Home',
      pl: 'Główna',
    },
  },
  {
    id: 2,
    name: {
      ua: 'Уточнення ектремумiв',
      en: 'Specification of extremes',
      pl: 'Specyfikacja skrajności',
    },
  },
  {
    id: 3,
    name: {
      ua: 'Експертна система',
      en: 'Expert System',
      pl: 'System ekspercki',
    },
  },
  {
    id: 4,
    name: {
      ua: 'Звiт',
      en: 'Report',
      pl: 'Sprawozdanie',
    },
  },
];

export const NAV_MENU_EQUIPMENT_SETTINGS = [
  {
    id: 1,
    name: {
      ua: 'Загальні',
      en: 'Global',
      pl: 'Globalny',
    },
  },
  {
    id: 2,
    name: {
      ua: 'Поточні пристрої',
      en: 'Current devices',
      pl: 'Aktualne urządzenia',
    },
  },
  {
    id: 3,
    name: {
      ua: 'Діапазон пристроїв',
      en: 'Device range',
      pl: 'Zasięg urządzenia',
    },
  },
];
