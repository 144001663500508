<div class="contentWrapper">
  <app-header></app-header>
  <main>
    <div class="container">
      <router-outlet></router-outlet>
      <div class="sign-up-text-wrap text-center mt-5">
        <p>
          <span>{{ "login.reg_text" | translate }}</span>
          <a href="javascript:" (click)="registration('registration')">
            {{ "login.register" | translate }}
          </a>
        </p>
        <p>
          <span>{{ "login.log_text" | translate }}</span>
          <a href="javascript:" (click)="registration('login')">
            {{ "login.header" | translate }}
          </a>
        </p>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
