<div class="main-wrapper">
  <div class="container">
    <div class="row d-flex justify-content-end">
      <div class="col-auto">
        <a routerLink="add" class="btn-default">{{
          "form.register_new_consultant" | translate
        }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="staff-items">
          <ng-container *ngFor="let consultant of consultants">
            <app-item-consultant
              [data]="consultant"
              (change)="check($event)"
            ></app-item-consultant>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end">
      <div class="col-auto">
        <a (click)="addConsultant()" class="btn-default">{{
          "form.choose_consultant" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
