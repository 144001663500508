export const VALUES_HEADER = {
  average: {
    ua: 'Середнє знач.',
    en: 'Average value',
    pl: 'Średnia wartość',
  },
  soleValue: {
    ua: 'Знач. показника',
    en: 'Indicator value',
    pl: 'Wartość wskaźnika',
  },
};
