import { Component, Injector, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-download-dialog',
  templateUrl: './confirm-download-dialog.component.html',
  styleUrls: ['./confirm-download-dialog.component.scss']
})
export class ConfirmDownloadDialogComponent implements OnInit {
  format = 'txt';

  constructor(
    public modal: NgbActiveModal) {
  }

  ngOnInit(): void {}

  submit() {
    this.modal.close(this.format)
  }
}
