<div class="row">
  <div class="col">
    <h1
      class="slide-title"
      [ngClass]="{ show: showIndicatorDescription }"
      (click)="showIndicatorDescription = !showIndicatorDescription"
    >
      {{ "measurement.download_data" | translate }}
      <i class="plus"></i>
    </h1>
  </div>
</div>
<div
  class="slide-wrapper"
  [ngClass]="{ 'loader-section': busy }"
  *ngIf="showIndicatorDescription"
>
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-toggle">
        <a (click)="buttonClick($event)" class="itemActive">
          {{ "measurement.from_comp" | translate }}
        </a>
        <a> {{ "measurement.from_meter" | translate }} </a>
      </div>
    </div>
  </div>

  <form [formGroup]="myForm" [ngClass]="patientData">
    <div class="row">
      <mat-form-field class="col-md-4">
        <mat-label>
          {{ "measurement.patient" | translate }}
        </mat-label>
        <mat-select
          required
          formControlName="patientId"
          (selectionChange)="selectPatient($event.value)"
        >
          <mat-option>-</mat-option>
          <mat-option *ngFor="let patient of patients" [value]="patient._id">
            {{ patient.last_name }}
            {{ patient.first_name }}
            {{ patient.second_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-4">
        <mat-form-field class="col-md-12" *ngIf="patientData">
          <mat-label>{{ "measurement.age" | translate }}</mat-label>
          <input
            matInput
            type="text"
            readonly
            [value]="patientData.birth_date | age"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="col-md-12" *ngIf="patientData">
          <mat-label>{{ "measurement.gender" | translate }}</mat-label>
          <input
            matInput
            type="text"
            readonly
            [value]="
              patientData.gender
                | valueById : genderService.gender : 'value'
                | lang
            "
          />
        </mat-form-field>
      </div>
      <mat-form-field class="col-md-4" *ngIf="patientData">
        <mat-label>{{ "measurement.doctor" | translate }}</mat-label>
        <input matInput id="doctor" readonly [value]="patientData.doctors" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-12" *ngIf="patientData">
        <mat-label>{{ "measurement.diagnosis" | translate }}</mat-label>
        <input
          matInput
          id="diagnosis"
          readonly
          [value]="patientData.diagnosis"
        />
      </mat-form-field>
    </div>
    <mat-tab-group (selectedTabChange)="yourFn($event)">
      <mat-tab class="row" label="{{ 'admin.ao' | translate }}">
        <app-ao-upload-form
          [shortDataService]="shortDataService"
          (change)="getFormValue($event)"
        ></app-ao-upload-form>
      </mat-tab>
      <mat-tab class="row" label="{{ 'admin.ecg' | translate }}">
        <app-ecg-upload-form
          [shortDataService]="shortDataService"
          (change)="getFormValue($event)"
        ></app-ecg-upload-form>
      </mat-tab>
      <mat-tab class="row" label="{{ 'admin.wp' | translate }}">
        <app-wp-upload-form
          (change)="getFormValue($event)"
          [shortDataService]="shortDataService"
        ></app-wp-upload-form>
      </mat-tab>
      <mat-tab label="{{ 'admin.cp' | translate }}">
        <app-cranial-upload-form
          [shortDataService]="shortDataService"
          (change)="getFormValue($event)"
        ></app-cranial-upload-form>
      </mat-tab>
    </mat-tab-group>
    <div class="col-md-4">
      <div class="form-group">
        <label>&nbsp;</label>
        <div class="form-gr-inline">
          <input
            [hidden]="true"
            type="file"
            (change)="setFiles($event)"
            accept=".txt,.json,.ini,.mat"
            multiple
            #fileUpload
          />
          <input
            readonly
            class="form-control"
            (click)="fileUpload.click()"
            value="{{ 'measurement.select_files' | translate }}"
          />
          <span class="input-group-text" (click)="fileUpload.click()">
            <i class="bi bi-file-earmark-arrow-down"></i>
          </span>
        </div>

        <div *ngIf="files">
          <ul class="list-files">
            <li
              style="font-size: small; margin-block-end: 5px"
              *ngFor="let file of files; let i = index"
            >
              {{ file.name }}
              <button
                (click)="deletedFile(i)"
                class="delBtn"
                placement="top"
                ngbTooltip="{{ 'form.del_file' | translate }}"
              >
                <i class="bi bi-trash"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button
          type="submit"
          class="btn btn-primary"
          (click)="onSaveBtnClick()"
        >
          {{ "form.save" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="row">
  <div class="col">
    <div class="expert-table-wrapper">
      <h4>{{ "measurement.data_medical" | translate }}</h4>
      <p class="expand-table" (click)="expand()">
        <ng-container *ngIf="expandRow">{{
          "measurement.hide" | translate
        }}</ng-container>
        <ng-container *ngIf="!expandRow">{{
          "measurement.expand" | translate
        }}</ng-container>
        <i class="bi bi-chevron-bar-contract contract" *ngIf="expandRow"></i>
        <i class="bi bi-chevron-bar-expand expand" *ngIf="!expandRow"></i>
      </p>
      <div class="table-wrapper" [ngClass]="{ 'loader-section': tableBusy }">
        <div class="loader" *ngIf="tableBusy">
          <div class="spinner-border" role="status"></div>
        </div>
        <table
          id="myTable"
          class="expert-table-style-3"
          matSort
          (matSortChange)="sortData($event)"
        >
          <thead>
            <tr>
              <th></th>
              <th mat-sort-header="patient" *ngIf="!patientData">
                {{ "measurement.patient" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="date">
                {{ "measurement.date" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th>
                {{ "measurement.name_group" | translate }}
              </th>
              <th *ngIf="expandRow">
                {{ "measurement.medical_institution" | translate }}
              </th>
              <th *ngIf="expandRow">{{ "measurement.doctor" | translate }}</th>
              <th>{{ "measurement.type" | translate }}</th>
              <th *ngIf="expandRow">
                {{ "measurement.calc_status" | translate }}
              </th>
              <th *ngIf="expandRow">{{ "measurement.status" | translate }}</th>
              <th>{{ "measurement.phase" | translate }}</th>
              <th mat-sort-header="Systola">
                {{ "calculation.ats" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="Diastola">
                {{ "calculation.atd" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="HeartRate">
                {{ "calculation.chss" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="pars">
                {{ "calculation.pars_ao" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="ifsp">
                {{ "calculation.IFVP" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="msr">
                {{ "calculation.msr" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th>
                {{ "measurement.blood_glucose" | translate }}
              </th>
              <th>
                <label>
                  <input
                    [(ngModel)]="allCheck"
                    (change)="selectCheckBox()"
                    class="customCheckBox"
                    type="checkbox"
                  />
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let element of tableSortedData
                  | paginate
                    : {
                        currentPage: pageN,
                        itemsPerPage: itemsPerPage,
                        id: 'pagination1'
                      };
                let i = index
              "
            >
              <app-item-row
                [itemsPerPage]="itemsPerPage"
                [page]="pageN"
                [data]="element"
                [dataIndex]="i"
                [dataExpand]="expandRow"
                (selected)="onSelect($event)"
                (changed)="updateBiosignal($event)"
                (loaded)="loaded($event)"
              ></app-item-row>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <paging-controls
      id="pagination1"
      (pageChange)="pageN = $event"
      [isItemsPerPage]="false"
      maxSize="9"
      directionLinks="true"
      autoHide="true"
      responsive="true"
    ></paging-controls>
  </div>
</div>
<div class="row d-flex justify-content-start">
  <div class="col-auto">
    <button
      type="button"
      [disabled]="isDisabled"
      class="btn btn-danger"
      (click)="remove()"
    >
      {{ "form.del_file" | translate }}
    </button>
  </div>
  <div class="col-auto">
    <button
      type="submit"
      [disabled]="isDisabled"
      class="btn btn-primary"
      (click)="download()"
    >
      {{ "measurement.download" | translate }}
    </button>
  </div>
  <div class="col-auto" *ngIf="!auth.isPatient()">
    <button
      type="button"
      [disabled]="isDisabled"
      class="btn btn-primary"
      (click)="redirect()"
    >
      {{ "measurement.redirection_data" | translate }}
    </button>
  </div>
  <div class="col-auto" *ngIf="auth.isAdmin()">
    <button
      type="button"
      [disabled]="isDisabled"
      class="btn btn-primary"
      (click)="recalculate()"
    >
      {{ "measurement.recalculate" | translate }}
    </button>
  </div>
</div>
