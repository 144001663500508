import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataTypeService } from 'src/app/services/data-type.service';
import { EXPERT_SYSTEM_THRESHOLDS } from '../../../constants';
import {
  Diastola,
  Systola,
} from 'src/app/services/dictionaries/constants/time-analize-data';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';

@Component({
  selector: 'app-disease-chance-table-ao',
  templateUrl: './disease-chance-table-ao.component.html',
  styleUrls: ['./disease-chance-table-ao.component.scss'],
})
export class DiseaseChanceTableAoComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  biosignals: Biosignal[];

  averageValues: any = {
    HeartDiseases: 0,
    LungDiseases: 0,
    PsychicalDiseases: 0,
    ArrhythmiaDegree: 0,
    ATs: 0,
    ATd: 0,
  };
  pressureClass: any = '';
  pressureDescription: any = '';

  @Input() set biosignalsRange(v) {
    if (v?.length) {
      this.biosignals = v;
      this.setAverageValuesAndInterpretations();
    }
  }

  constructor(
    public dataTypeService: DataTypeService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
  }

  setAverageValuesAndInterpretations(): void {
    this.averageValues.HeartDiseases = this.calculateBiosignalsAverageValue(
      'ExpertSystem',
      'HeartDiseases'
    );
    this.averageValues.ATs = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Systola'
    );
    let systolaInt = this.calculateInterpretation(
      Systola,
      this.averageValues.ATs
    );
    this.averageValues.ATd = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Diastola'
    );
    let diastolaInt = this.calculateInterpretation(
      Diastola,
      this.averageValues.ATd
    );
    this.calculateDescription(systolaInt, diastolaInt);
    this.averageValues.LungDiseases = this.calculateBiosignalsAverageValue(
      'ExpertSystem',
      'LungDiseases'
    );
    this.averageValues.PsychicalDiseases = this.calculateBiosignalsAverageValue(
      'ExpertSystem',
      'PsychicalDiseases'
    );
    this.averageValues.ArrhythmiaDegree = this.calculateBiosignalsAverageValue(
      'ExpertSystem',
      'ArrhythmiaDegree'
    );
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    let sum = 0,
      count = 0;
    this.biosignals.forEach((biosignal) => {
      if (biosignal[object]) {
        sum += biosignal[object][property];
        count += 1;
      }
    });
    return sum ? (sum / count).toFixed(2) : '0';
  }

  displayRiskColor(diseaseChance): string {
    return diseaseChance >= EXPERT_SYSTEM_THRESHOLDS.critical
      ? 'redClass'
      : diseaseChance >= EXPERT_SYSTEM_THRESHOLDS.medium
      ? 'yellowClass'
      : 'whiteClass';
  }

  calculateInterpretation(values: any, comparisonValue: any): any {
    let idx = [];
    values.forEach((el) => {
      const minValue = el.minValue;
      const maxValue = el.maxValue;
      const descr = el.description;
      if (+comparisonValue >= minValue && +comparisonValue <= maxValue) {
        idx.push(descr);
      } else {
        idx.push('');
      }
    });
    return idx;
  }

  calculateDescription(systola, diastola) {
    this.pressureDescription =
      systola.find((item) => item) || diastola.find((item) => item);

    const sIndex = systola.findIndex((item) => item);
    const dIndex = diastola.findIndex((item) => item);

    if (this.pressureDescription) {
      sIndex == 0 && dIndex == 0
        ? (this.pressureClass = 'greenClass')
        : sIndex == 1 || dIndex == 1
        ? (this.pressureClass = 'yellowClass')
        : sIndex == 2 || dIndex == 2
        ? (this.pressureClass = 'redClass')
        : '';
    } else {
      if (sIndex == -1) {
        if (sIndex === dIndex) {
          this.pressureClass = 'redClass';
        }
      }
    }
  }
}
