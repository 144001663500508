import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrMessageService } from 'src/app/services/dictionaries/toastr-message.service';
import { PasswordStrengthValidator } from 'src/app/services/validators/password-strength.validators';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  myForm: FormGroup;
  photoStaffLink;
  isVisiblePassword: Array<boolean> = [false, false, false];

  @Input() role!: RoleTypeEnum;
  @Input() password: string = '';
  @Output() update = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private toastrMessageService: ToastrMessageService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.fb.group({
      old_password: ['', Validators.required],
      password: ['', [Validators.required, PasswordStrengthValidator]],
      password_confirmation: ['', Validators.required],
    });
  }

  onSubmit() {
    if (
      this.myForm.get('old_password').value !== this.password ||
      this.myForm.get('password').value !==
        this.myForm.get('password_confirmation').value ||
      !this.myForm.valid
    ) {
      this.toastr.warning(
        new TranslatePipe(this.authService).transform(
          this.toastrMessageService.incorrect_data
        )
      );
      return;
    }
    this.update.emit(this.myForm.get('password').value);
  }
}
