import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RightsService } from 'src/app/services/rights.service';
import { Rights } from '../../admin/constants/rights.enum';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';
import { TABS_DATA } from './constants/tabs-data';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { Biosignal } from 'src/app/models/biosignal';

@Component({
  selector: 'app-expert-system',
  templateUrl: './expert-system.component.html',
  styleUrls: ['./expert-system.component.scss'],
})
export class ExpertSystemComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() biosignals: Biosignal[] = [];
  @Input() biosignalsRange;
  active: number = 0;
  navMenu: Array<any> = TABS_DATA;
  showNav: boolean = false;
  Rights: typeof Rights = Rights;

  @Input() set initData(value) {
    if (value) {
      this.isInit = true;
    }
  }
  isInit: boolean = false;
  constructor(
    public rightsService: RightsService,
    public auth: AuthService,
    public cd: ChangeDetectorRef,
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
  }
}
