<div class="disease-table-block">
  <div class="disease-table-block-header">
    <h4 class="disease-table-block-header-title">
      {{ "calculation.disease_title" | translate }}
    </h4>
    <div class="disease-table-block-header-subtitle">
      {{ "calculation.disease_subtitle" | translate }}
    </div>
  </div>
  <table class="table m-0">
    <thead>
      <tr class="table-title">
        <th class="table-title-text">
          {{ "calculation.disease_name" | translate }}
        </th>
        <th class="table-title-text">
          {{ "calculation.disease_probability" | translate }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="biosignals?.length">
      <tr [ngClass]="displayRiskColor(averageValues.HeartDiseases)">
        <td class="table-cell">
          {{ "calculation.disease_cardiovascular" | translate }}
        </td>
        <td class="table-cell">
          {{ averageValues.HeartDiseases * 100 | number : "1.0" }}%
        </td>
      </tr>
      <tr [ngClass]="displayRiskColor(averageValues.LungDiseases)">
        <td class="table-cell">
          {{ "calculation.disease_pulmonary" | translate }}
        </td>
        <td class="table-cell">
          {{ averageValues.LungDiseases * 100 | number : "1.0" }}%
        </td>
      </tr>
      <tr [ngClass]="displayRiskColor(averageValues.PsychicalDiseases)">
        <td class="table-cell">
          {{ "calculation.disease_mental" | translate }}
        </td>
        <td class="table-cell">
          {{ averageValues.PsychicalDiseases * 100 | number : "1.0" }}%
        </td>
      </tr>
      <tr [ngClass]="pressureClass ? pressureClass : ''">
        <td class="table-cell">
          <div
            class="alarm-icon"
            *ngIf="pressureDescription"
            placement="left"
            [closeDelay]="1000"
            [ngbTooltip]="tipContent"
          >
            <img src="assets/img/alarm_icon.svg" />
          </div>
          {{ "calculation.hypertension_status" | translate }}
        </td>
        <td class="table-cell">
          <ng-container *ngIf="!(reloading$ | async)">
            {{ pressureDescription | lang }}
          </ng-container>
        </td>
      </tr>
      <tr [ngClass]="displayRiskColor(averageValues.ArrhythmiaDegree / 3.2)">
        <td class="table-cell">
          {{ "calculation.disease_arrhythmia" | translate }}
        </td>
        <td class="table-cell">
          {{
            +averageValues.ArrhythmiaDegree == 1
              ? 0
              : +averageValues.ArrhythmiaDegree
              ? +(
                  (averageValues.ArrhythmiaDegree - 1) * 20 | number : "1.0-0"
                ) > 0
                ? ((averageValues.ArrhythmiaDegree - 1) * 20 | number : "1.0-0")
                : 0
              : 0
          }}%
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #tipContent let-greeting="greeting">
  <p class="m-0">{{ "calculation.report_recomm" | translate }}</p>
  <!-- https://web.archive.org/web/20120520105829/ -->
  <a href="http://hyper.ahajournals.org/content/42/6/1206.long" target="_blank"
    >Перейти за посиланням</a
  >
</ng-template>
