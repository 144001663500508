<div class="rufier-container">
  <h1>{{ "calculation.rufier_ttl" | translate }}</h1>
  <div class="row">
    <div class="col">
      <h5>{{ "calculation.rufier_subttl" | translate }}:</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 col-12">
      <ng-select
        [(ngModel)]="beforeTest"
        [placeholder]="'calculation.to' | translate"
        (change)="changeBeforeTest()"
      >
        <ng-option
          *ngFor="let biosignal of biosignals; let i = index"
          [value]="i"
        >
          {{ biosignalsFmtDates[i] }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-md-4 col-12">
      <ng-select
        [(ngModel)]="afterTest"
        [placeholder]="'calculation.after' | translate"
        [readonly]="!isDisabledAfter"
        (change)="changeAfterTest()"
      >
        <ng-option
          *ngFor="let biosignal of biosignals; let i = index"
          [value]="i"
        >
          {{ biosignalsFmtDates[i] }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-md-4 col-12">
      <ng-select
        [(ngModel)]="afterTwoMinutes"
        [placeholder]="'calculation.after_2' | translate"
        [readonly]="!isDisabledAfterTwoMinutes"
        (change)="changeAfterTwoMinutes()"
      >
        <ng-option
          *ngFor="let biosignal of biosignals; let i = index"
          [value]="i"
        >
          {{ biosignalsFmtDates[i] }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <button class="btn btn-primary my-3" (click)="selectLastTree($event)">
    {{ "calculation.select_last_three_measurements" | translate }}
  </button>
  <ng-container *ngIf="values.IFSP3 && RufierTestData">
    <div class="row">
      <div class="col">
        <h1>{{ "calculation.adpt_index_v" | translate }}</h1>
      </div>
    </div>
    <div class="block-info-box space-between">
      <app-accordion [title]="'calculation.explanation_ai' | translate">
        <table class="expert-table-style-2">
          <tbody>
            <tr *ngFor="let item of RufierTestData.AI">
              <td>
                <strong style="white-space: nowrap"
                  >{{ item.minValue }} - {{ item.maxValue }}</strong
                >
              </td>
              <td>
                <ng-container *ngIf="!(reloading$ | async)">
                  {{ item.description | lang }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </app-accordion>
      <div class="block-info h-a" [ngClass]="className.AI">
        <div class="block-info_ttl">
          {{ "calculation.adpt_index" | translate }}
        </div>
        <div class="block-info_descr">
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.AI | lang }}
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1>{{ "calculation.rufier_test" | translate }} = {{ values.P }}</h1>
        <p>
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.RUFIER_TEST | lang }}
          </ng-container>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1>{{ "calculation.pars_ao" | translate }}</h1>
      </div>
    </div>
    <app-accordion [title]="'calculation.explanation_pars' | translate">
      <table class="expert-table-style-2">
        <tbody>
          <tr *ngFor="let item of RufierTestData.PARS">
            <td>
              <strong style="white-space: nowrap"
                >{{ item.minValue }} - {{ item.maxValue }}</strong
              >
            </td>
            <td>
              <ng-container *ngIf="!(reloading$ | async)">
                {{ item.description | lang }}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </app-accordion>
    <div class="block-info-box">
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.pars_ao" | translate }} {{ "form.to" | translate }}
        </div>
        <div class="block-info_descr">
          {{ values.PARS1 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.PARS1 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.pars_ao" | translate }}
          {{ "calculation.after" | translate | lowercase }}
        </div>
        <div class="block-info_descr">
          {{ values.PARS2 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.PARS2 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.pars_ao" | translate }}
          {{ "calculation.after_2" | translate | lowercase }}
        </div>
        <div class="block-info_descr">
          {{ values.PARS3 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.PARS3 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info" [ngClass]="className.PARS_R">
        <div class="block-info_ttl">
          {{ "calculation.pars_ao" | translate }}
          {{ "calculation.rufier" | translate }}
        </div>
        <div class="block-info_descr">
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.PARS_R | lang }}
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1>{{ "calculation.IFVP" | translate }}</h1>
      </div>
    </div>
    <div class="expert-table-wrapper" class="slide-wrapper">
      <app-accordion [title]="'calculation.explanation_ifvp' | translate">
        <table class="expert-table-style-2">
          <tbody>
            <tr *ngFor="let item of RufierTestData.IFSP">
              <td>
                <strong>{{ item.minValue }} - {{ item.maxValue }}</strong>
              </td>
              <td>
                <ng-container *ngIf="!(reloading$ | async)">
                  {{ item.description | lang }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </app-accordion>
    </div>
    <div class="block-info-box">
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.IFVP" | translate }} {{ "form.to" | translate }}
        </div>
        <div class="block-info_descr">
          {{ values.IFSP1 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.IFSP1 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.IFVP" | translate }}
          {{ "calculation.after" | translate | lowercase }}
        </div>
        <div class="block-info_descr">
          {{ values.IFSP2 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.IFSP2 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info">
        <div class="block-info_ttl">
          {{ "calculation.IFVP" | translate }}
          {{ "calculation.after_2" | translate | lowercase }}
        </div>
        <div class="block-info_descr">
          {{ values.IFSP3 }} -
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.IFSP3 | lang }}
          </ng-container>
        </div>
      </div>
      <div class="block-info" [ngClass]="className.IFSP_R">
        <div class="block-info_ttl">
          {{ "calculation.IFVP" | translate }}
          {{ "calculation.rufier" | translate | lowercase }}
        </div>
        <div class="block-info_descr">
          <ng-container *ngIf="!(reloading$ | async)">
            {{ description.IFSP_R | lang }}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
