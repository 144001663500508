<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="form-container card-box">
    <ng-container *ngIf="!sendEmail">
      <div class="row">
        <div class="col">
          <h1 class="text-center">{{ "login.recovery" | translate }}</h1>
          <p class="text-center">
            {{ "login.restore_text3" | translate }}
          </p>
          <div class="select-role-wrap">
            <div
              class="item"
              [ngClass]="{ active: inner?.role === RoleTypeEnum.Patient }"
              (click)="selectRole(RoleTypeEnum.Patient)"
            >
              {{ "form.patient" | translate }}
            </div>
            <div
              class="item"
              [ngClass]="{ active: inner?.role === RoleTypeEnum.Doctor }"
              (click)="selectRole(RoleTypeEnum.Doctor)"
            >
              {{ "measurement.doctor" | translate }}
            </div>
          </div>
          <p class="text-center">{{ "login.recovery_text" | translate }}</p>
        </div>
      </div>

      <form [formGroup]="myForm" (ngSubmit)="onSubmitForm()">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="input-group form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder="{{ 'registration.email' | translate }}"
                />
                <label for="email">{{
                  "registration.email" | translate
                }}</label>
              </div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary fill w-100"
                type="submit"
                [disabled]="!inner?.role || myForm.get('email').invalid"
              >
                {{ "login.send" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="sendEmail">
      <div *ngIf="!isHasError">
        <div class="icon-box">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="assets/img/icons/icon-3.svg#IconCheck"></use>
          </svg>
        </div>
        <p class="text-center">
          {{ "login.restore_text1" | translate }}:
          {{ myForm.get("email").value }}
        </p>
      </div>
      <div *ngIf="isHasError">
        <div class="icon-box">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="assets/img/icons/icon-4.svg#IconSmile"></use>
          </svg>
        </div>
        <p class="text-center">
          {{ "login.restore_text2" | translate }}:
          {{ myForm.get("email").value }}
        </p>
        <div class="d-flex justify-content-center flex-wrap">
          <button class="btn btn-primary fill m-1" (click)="tryAgain()">
            {{ "login.again" | translate }}
          </button>
          <button class="btn btn-primary no-border m-1" routerLink="auth">
            {{ "login.home" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
