import { ECGamplitude, ECGspeed } from 'src/app/enums/ecg.enum';

export const ECGspeedWidth = [
  { pixel: ECGspeed.mm10, mm: 10, value: 1 },
  { pixel: ECGspeed.mm25, mm: 25, value: 2.5 },
  { pixel: ECGspeed.mm50, mm: 50, value: 5 },
  { pixel: ECGspeed.mm75, mm: 75, value: 7.5 },
  { pixel: ECGspeed.mm100, mm: 100, value: 10 },
];

export const ECGamplitudeHeight = [
  { pixel: ECGamplitude.mm10, mm: 10, value: 1 },
  { pixel: ECGamplitude.mm5, mm: 5, value: 0.5 },
  { pixel: ECGamplitude.mm20, mm: 20, value: 2 },
];
