import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import html2pdf from 'html2pdf.js';
import { ButtonType } from './models/button';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Patient } from 'src/app/models/patient';
import { PatientService } from '../../measurements-data/services/patient.service';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { BUTTON_DATA } from 'src/app/services/dictionaries/constants/report-button-data';
import { MedicalInstitution } from 'src/app/models/medical-institution';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { StaffService } from 'src/app/services/staff.service';
import { DataTypeService } from 'src/app/services/data-type.service';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { ToastrService } from 'ngx-toastr';
import { BiosignalsService } from 'src/app/services/biosignals.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @ViewChild('container') container: ElementRef;
  @ViewChild('modeContainer') modeContainer: ElementRef;
  @ViewChild('sections') sections: ElementRef;

  isInit: boolean = false;
  biosignal: Biosignal;
  biosignals: Biosignal[] = [];
  buttonList: ButtonType[] = BUTTON_DATA;
  medicalInstitutions: MedicalInstitution[] = [];
  expanded: boolean = false;
  busy: boolean = false;
  editConclusion: boolean = false;
  comment: string = '';
  user;
  patient: string;
  patients: Patient[] = [];
  startDate: Date;
  endDate: Date;
  hideBlock: boolean = false;

  section = {
    PARS: false,
    IFSP: false,
    ANS: false,
    Heart: false,
    Vessels: false,
    Nervous: false,
    Rufier: false,
  };
  biosignalsRange: Biosignal[] = [];
  @Input() set setBiosignals(biosignals) {
    if (biosignals) {
      this.biosignalsRange = biosignals;
      this.biosignals = biosignals.rangeBiosignals;
      if (this.biosignals && this.biosignals.length) {
        if (this.biosignals.length > 1) {
          this.startDate = this.biosignals[0].SignalRegistrationDateTime;
          this.startDate =
            this.biosignals[
              this.biosignals.length - 1
            ].SignalRegistrationDateTime;
        } else {
          this.biosignal = this.biosignals[0];
          this.endDate = this.biosignal.SignalRegistrationDateTime;
          this.comment = this.biosignal.comment;
        }
      }
    }
  }

  @Input() set initData(value) {
    if (value) this.isInit = true;
  }

  @Input() allBiosignals: Biosignal[] = [];
  ACTIVE_GRAPH: GraphType = GraphType.AO;
  constructor(
    public toastr: ToastrService,
    public authService: AuthService,
    private patientService: PatientService,
    public cd: ChangeDetectorRef,
    public genderService: GenderService,
    public staffService: StaffService,
    private dataTypeService: DataTypeService,
    private biosignalsService: BiosignalsService,
    private measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {
    super(cd);
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
    this.staffService
      .getMedicalInstitutions()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: any) => {
        this.medicalInstitutions = data;
      });

    this.authService.language$.subscribe((v) => this.changLang());
    if (this.authService.isPatient()) {
      this.user = this.authService.getUser();
    } else {
      this.patientService.getDoctorPatients().subscribe((data) => {
        this.patients = data;
        this.patientService.patient$.subscribe((patient) => {
          if (patient) {
            this.patient = patient._id;
            this.user = patient;
          } else {
            this.patient = null;
            this.user = null;
          }
        });
      });
    }
  }

  editConclusionBtn() {
    if (this.editConclusion) {
      const commentDB = { comment: this.comment };
      this.measurementsDataUpdateService
        .setComment(commentDB, this.biosignal._id)
        .subscribe((v) => {
          this.toastr.success(v.message);
        });
    }
    this.editConclusion = !this.editConclusion;
  }

  getMedicalInstitutionName = (id, medicalInstitutions) =>
    medicalInstitutions?.find((val) => val?._id == id)?.name;

  selectPatient(e) {
    // console.log("🚀 ~ file: report.component.ts ~ line 70 ~ ReportComponent ~ selectPatient ~ e", e)
  }

  onCheckButtonClick(event, button): void {
    event.target.classList.toggle('active');
    this.section[button.sectionName] = !this.section[button.sectionName];
  }

  onCheckMode(event): void {
    this.expanded = !this.expanded;
  }

  download() {
    this.hideBlock = true;
    const content = document.getElementById('print-wrapper');
    const opt = {
      margin: [0, 0, 0, 0],
      enableLinks: true,
      pagebreak: {
        after: ['.html2pdf__page-break'],
        mode: ['legacy'],
      },
      image: { type: 'jpeg', quality: 1 },
      filename: `${
        this.user.last_name +
        '-' +
        this.user.first_name +
        '-' +
        this.user.second_name +
        '_' +
        this.biosignals[this.biosignals.length - 1]['updatedAt'].slice(0, 10)
      }.pdf`,
      html2canvas: {
        useCORS: true,
        allowTaint: true,
        letterRendering: true,
        logging: true,
        scale: 1.5,
        scrollX: 0,
        scroolY: 0,
        removeContainer: true,
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf()
      .set(opt)
      .from(content)
      .save()
      .then(() => {
        this.hideBlock = false;
        Object.keys(this.section).forEach((key) => (this.section[key] = false));
        this.expanded = !this.expanded;
      })
      .catch((err) => {
        console.log('🚀 err', err);
        this.busy = false;
      });
  }

  downloadEcgPdf() {
    this.biosignalsService
      .getFile(this.biosignal._id, this.biosignal.signalType, 'pdf')
      .subscribe((response) => {
        const buffer = new Uint8Array(response.data);
        const blob = new Blob([buffer], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        a.download = `${this.biosignal.originFileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
