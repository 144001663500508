import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nervous-system-tab',
  templateUrl: './nervous-system-tab.component.html',
  styleUrls: ['./nervous-system-tab.component.scss']
})

export class NervousSystemTabComponent {
  @Input() biosignalsData = [];
  constructor() { }
}
