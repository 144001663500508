import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ECGamplitude } from 'src/app/enums/ecg.enum';

@Component({
  selector: 'app-multi-ten-seconds-charts',
  templateUrl: './multi-ten-seconds-charts.component.html',
  styleUrls: ['./multi-ten-seconds-charts.component.scss'],
})
export class MultiTenSecondsChartsComponent implements OnInit {
  yAxisTicks: any = [];
  xAxisTicks: any = [];
  @Input() item;
  @Input() data;
  @Input() ecgWidth;
  @Input() ecgHeight = 1;
  scrollHeight = ECGamplitude.mm5;
  @Input() i;

  @Input() set yAxis(v) {
    this.yAxisTicks = v;
  }
  @Input() set yMin(v) {
    this.timePointsInterval.minY = v;
  }
  @Input() set yMax(v) {
    this.timePointsInterval.maxY = v;
  }

  @ViewChild('chart') chart: any;
  min;
  max;

  timePointsInterval: any = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  isLoading: boolean = false;
  constructor(public cd: ChangeDetectorRef) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    let series = this.item[0].series;
    this.min = Math.floor(series[0].name);
    this.max = Math.floor(series[0].name) + 10;
    for (
      let i = Math.floor(series[0].name);
      i <= Math.ceil(series[series.length - 1].name);
      i += 0.04
    )
      this.xAxisTicks.push(i.toFixed(2));
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.getVerticalGridCoord();
    this.getHorizontalGridCoord();
    this.getXTicks();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  getGraphWidth(i: number, series: Array<any>): string {
    const max: number = series[series.length - 1].name;
    return (max - i * 10) * this.ecgWidth + 'px';
  }

  formatAxisTickDateX = (i) => {
    return i[i.length - 1] == 0 && i[i.length - 2] == 0 ? Math.round(i) : '';
  };

  formatAxisTickDateY = (i) => {
    return (i / 10) % 0.5 == 0 ? (i / 10).toFixed(1) : '';
  };

  formatAxisTickDate = (i) => {
    return '';
  };

  drawGridBoldLine(
    arr: Array<any>,
    borderLeft: number,
    borderRight: number,
    remainder: number,
    elongated_line: boolean,
    attrName?: string,
    shift?: number
  ) {
    const temp = 5 - (borderLeft % 5);
    for (var i = borderLeft; i < borderRight; i++) {
      const selector = arr[i].querySelectorAll('g')[0].children[0];
      if ((i + temp) % 5 == remainder) {
        if (elongated_line)
          if (i % 25 == remainder) {
            let attr = Number(
              arr[i].querySelectorAll('g')[0].children[0].getAttribute(attrName)
            );
            arr[i]
              .querySelectorAll('g')[0]
              .children[0].setAttribute(attrName, attr + shift);
          }
        i == borderLeft
          ? (selector.style['stroke-width'] = 0.6)
          : (selector.style['stroke-width'] = 0.4);
      } else selector.style['stroke-width'] = 0.1;
      selector.style['stroke'] = 'grey';
    }
  }

  findReapiningElementsIndex(arr: any) {
    let left = 0;
    let right = 0;
    for (let n = 0; n < arr.length - 1; n++)
      for (let j = n + 1; j < arr.length; j++)
        if (
          arr[n].getAttribute('transform') == arr[j].getAttribute('transform')
        )
          (left = n), (right = j);
    this.drawGridBoldLine(arr, left, right, 0, false);
    this.drawGridBoldLine(arr, right, arr.length, 0, false);
  }

  getHorizontalGridCoord() {
    if (this.chart) {
      const btnElement = this.chart.chartElement.nativeElement
        .querySelector('.y')
        .querySelectorAll('g')[0].children;
      var arr = Array.prototype.slice.call(btnElement).slice(1);
      this.findReapiningElementsIndex(arr);
    }
  }

  getVerticalGridCoord() {
    if (this.chart) {
      const btnElement = this.chart.chartElement.nativeElement
        .querySelector('.x')
        .querySelectorAll('g')[0].children;
      const arr = Array.prototype.slice.call(btnElement).slice(1);
      this.drawGridBoldLine(arr, 0, arr.length, 0, false);
    }
  }

  getXTicks() {
    if (this.chart) {
      const tickArrayX = this.chart.chartElement.nativeElement
        .querySelector('.x')
        .querySelectorAll('.tick');

      const arr = Array.prototype.slice.call(tickArrayX).slice();
      arr.forEach((element) => {
        let attr = element.getAttribute('transform').split(',');
        element.setAttribute('transform', attr[0] + ',-8)');
        element
          .querySelector('text')
          .setAttribute('style', 'font-size:8px; fill:black');
      });

      this.chart.chartElement.nativeElement
        .querySelector('.line-series')
        .querySelector('path')
        .setAttribute('stroke-width', '0.9px');

      // let attr1 = this.chart.chartElement.nativeElement
      //   .querySelector('.chart')
      //   .getAttribute('transform')
      //   .split(',');

      // this.chart.chartElement.nativeElement
      //   .querySelector('.chart')
      //   .setAttribute('transform', 'translate(20,' + attr1[1]);
    }
  }

  onResize(e) {
    setTimeout(() => {
      this.getXTicks();
    }, 1000);
  }
}
