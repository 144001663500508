import { Injectable } from '@angular/core';
import { SubjectService } from '../subject.service';

@Injectable({
  providedIn: 'root',
})
export class StaffPatientsService extends SubjectService {
  protected subjects: any = {};
  confirm: boolean = true;
  constructor() {
    super();
  }

  isConfirm(): boolean {
    return this.confirm;
  }
}
