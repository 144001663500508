<div ngb-modal-draggable [ngbModalDraggableHandle]="draggableHandle">
  <div #draggableHandle class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Withings panel</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [ngClass]="{ 'loader-section': busy }">
      <div class="loader" *ngIf="busy">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-primary"
            (click)="redirectToWithings()"
          >
            {{
              withings
                ? "Change WITHINGS profile"
                : "Connect WITHINGS the current profile"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
