
<!-- <div [ngClass]="{ 'loader-section': !closed }">
  <div class="loader">
    <div class="message" *ngIf="!closed">
      <span [innerHTML]="'login.technical_works' | translate"></span>
      <button class="close-btn" (click)="closed = true">
        {{ "login.close" | translate }}
      </button>
    </div>
  </div> -->
<router-outlet></router-outlet>
<!-- </div> -->
