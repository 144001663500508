<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{"measurement.redirection_data"
    | translate}}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('CLOSE')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul class="list-group list-group-flush">
    <ng-container *ngFor="let item of patients">
      <li class="list-group-item" *ngIf="patientId !== item._id">
      <label for="patient-{{item._id}}">
        <input type="radio"
                   [(ngModel)]="patient"
                   [value]="item._id"
                   name="patients"
                   class="form-check-input"
                   id="patient-{{item._id}}">
        {{item.last_name}} {{item.first_name}} {{item.second_name}}
      </label>
    </li>
    </ng-container>
  </ul>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss('CANCEL')">{{'login.cancel' | translate}}</button>
  <button class="btn btn-danger" (click)="submit()">{{'measurement.confirm' | translate}}</button>
</div>
