<div
  class="active-tab"
  [ngClass]="{ open: showNav }"
  (click)="showNav = !showNav"
>
  <ng-container *ngIf="!(reloading$ | async)">
    {{ navMenu[active]?.name | lang }}
  </ng-container>
  <div class="expand"></div>
</div>
<nav
  class="navbar navbar-expand-lg py-0 navbar-dark"
  id="subnav"
  [ngClass]="{ show: showNav }"
>
  <ul
    #nav="ngbNav"
    class="nav-tabs nav-fill w-100 ml-5 mr-5"
    ngbNav
    [(activeId)]="active"
  >
    <li ngbNavItem [ngbNavItem]="0">
      <a ngbNavLink routerLink="/home/admin/panel" routerLinkActive="active">
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[0]?.name | lang }}
        </ng-container>
      </a>
    </li>
    <li ngbNavItem [ngbNavItem]="1">
      <a
        ngbNavLink
        routerLink="/home/admin/measurements-settings"
        routerLinkActive="active"
      >
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[1]?.name | lang }}
        </ng-container>
      </a>
    </li>
    <li ngbNavItem [ngbNavItem]="2">
      <a
        ngbNavLink
        routerLink="/home/admin/measurements-error"
        routerLinkActive="active"
      >
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[2]?.name | lang }}
        </ng-container>
      </a>
    </li>
    <li ngbNavItem [ngbNavItem]="3">
      <a
        ngbNavLink
        routerLink="/home/admin/doctors-info"
        routerLinkActive="active"
      >
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[3]?.name | lang }}
        </ng-container>
      </a>
    </li>
    <li ngbNavItem [ngbNavItem]="4">
      <a
        ngbNavLink
        routerLink="/home/admin/device-settings"
        routerLinkActive="active"
      >
        <ng-container *ngIf="!(reloading$ | async)">
          {{ navMenu[4]?.name | lang }}
        </ng-container>
      </a>
    </li>
  </ul>
</nav>

<router-outlet></router-outlet>
