import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DegreeService {
  degree = [
    {
      value: 'CANDIDATE_OF_SCIENCE',
      ua: 'Кандидат наук',
      en: 'Phd',
      pl: 'KANDYDAT NAUKI',
    },
    {
      value: 'DOCTOR_OF_SCIENCE',
      ua: 'Доктор наук',
      en: 'DOCTOR OF SCIENCE',
      pl: 'DOKTOR NAUKI',
    },
    // { value: 'PHD', ua: 'к.н.', en: 'Phd', pl:'к.n.' },
  ];
}

