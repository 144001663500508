import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
})
export class CountDownComponent implements OnInit {
  countDown: Subscription;
  @Input() set timeBefore(value: number) {
    this.counter = value;
    this.innerCounter = value;
  }

  counter: number;
  innerCounter: number;

  ngOnInit() {
    this.innerCounter = this.counter;
  }

  startCountDown() {
    this.innerCounter = this.counter + 1;
    const innerTimer = timer(0, 1000).subscribe(() => {
      if (this.innerCounter > 0) this.innerCounter--;
      if (this.innerCounter === 0) innerTimer.unsubscribe();
    });
  }
}
