<div class="row">
  <div class="col">
    <h1 class="slide-title">
      {{ "measurement.meter_data" | translate }}
    </h1>
  </div>
</div>
<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <div class="form-gr-inline">
          <input
            [hidden]="true"
            type="file"
            (change)="equipmentParse($event)"
            accept=".csv"
            #fileUpload
          />
          <input
            readonly
            class="form-control"
            (click)="fileUpload.click()"
            value="{{ 'measurement.select_files' | translate }}"
          />
          <span class="input-group-text" (click)="fileUpload.click()">
            <i class="bi bi-file-earmark-arrow-down"></i>
          </span>
        </div>
        <div *ngIf="files">
          <ul class="list-files">
            <li
              style="font-size: small; margin-block-end: 5px"
              *ngFor="let file of files; let i = index"
            >
              {{ file.name }}
              <button
                (click)="deletedFile(i)"
                class="delBtn"
                placement="top"
                ngbTooltip="{{ 'form.del_file' | translate }}"
              >
                <i class="bi bi-trash"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>{{ "measurement.selected_columns" | translate }}</label>
        <ng-select
          [readonly]="busy"
          [closeOnSelect]="false"
          multiple="true"
          [clearable]="true"
          placeholder="{{ 'measurement.select_columns' | translate }}"
          [(ngModel)]="selectedCols"
          (change)="generateTable()"
        >
          <ng-template ng-header-tmp>
            <button (click)="selectAll()" class="btn btn-sm btn-secondary mr-2">
              {{ "measurement.all_columns" | translate }}
            </button>
          </ng-template>
          <ng-option *ngFor="let item of cols" [value]="item">
            <ng-container *ngIf="!(reloading$ | async)">
              {{ item | lang }}
            </ng-container>
          </ng-option>
          <ng-template ng-footer-tmp>
            {{ "measurement.selected_count" | translate }}:
            {{ selectedCols.length }}
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="expert-table-wrapper">
        <h4>{{ "measurement.data_medical" | translate }}</h4>
        <div class="table-wrapper" [ngClass]="{ 'loader-section': tableBusy }">
          <table class="expert-table-style-3" *ngIf="selectedCols.length">
            <thead>
              <tr>
                <ng-container *ngFor="let item of selectedCols">
                  <th
                    *ngIf="item.sortable"
                    sortable="true"
                    [sortable]="item.sortable"
                    (sort)="onSort($event)"
                  >
                    <ng-container *ngIf="!(reloading$ | async)">
                      {{ item | lang }}
                    </ng-container>

                    <i class="bi bi-sort-alpha-down down"></i>
                    <i class="bi bi-sort-alpha-up-alt up"></i>
                  </th>
                  <th *ngIf="!item.sortable">
                    <ng-container *ngIf="!(reloading$ | async)">
                      {{ item | lang }}
                    </ng-container>
                  </th>
                </ng-container>
                <th>{{ "admin.disabled" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let element of tableData; let i = index">
                <app-item-row-setting
                  class="row-setting"
                  [childRow]="expandRow.includes(element.childId)"
                  *ngIf="
                    element.patientId || expandRow.includes(element.childId)
                  "
                  [data]="element"
                  [dataCols]="selectedCols"
                  [index]="i"
                  (expand)="onSelect($event)"
                  (createField)="createFieldEquipment($event)"
                ></app-item-row-setting>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex mt-3 justify-content-start">
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="generateCSV()">
        {{ "measurement.download" | translate }}
      </button>
    </div>
  </div>
</div>
