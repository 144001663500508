<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <main *ngIf="(authService.isPatient() || patient) && displayNav?.length">
    <div
      class="active-tab"
      [ngClass]="{ open: showNav }"
      (click)="showNav = !showNav"
    >
      <ng-container *ngIf="!(reloading$ | async)">
        {{ navMenu[active]?.name | lang }}
      </ng-container>
      <div class="expand"></div>
    </div>
    <nav
      class="navbar navbar-expand-lg py-0 navbar-dark"
      id="subnav"
      [ngClass]="{ show: showNav }"
    >
      <ul
        ngbNav
        #navGraph="ngbNav"
        [(activeId)]="active"
        class="nav-tabs nav-fill w-100"
        (navChange)="tabChange($event)"
      >
        <ng-container *ngFor="let item of navMenu; let i = index">
          <li [ngbNavItem]="i" *ngIf="displayNav?.includes(i)">
            <a ngbNavLink>
              <ng-container *ngIf="!(reloading$ | async)">
                {{ item?.name | lang }}
              </ng-container>
            </a>
          </li>
        </ng-container>
      </ul>
    </nav>
    <app-left-btn-menu
      [measurementsNumber]="measurementsNumber"
      [SET_ACTIVE_GRAPH]="ACTIVE_GRAPH"
      [biosignalsRange]="biosignalsRange"
      [biosignals]="biosignals"
    ></app-left-btn-menu>

    <div
      class="active-tab"
      [ngClass]="{ open: showNav_2 }"
      (click)="showNav_2 = !showNav_2"
    >
      <ng-container *ngIf="!(reloading$ | async)">
        {{ navMenu_2[active_2]?.name | lang }}
      </ng-container>
      <div class="expand"></div>
    </div>
    <nav
      id="subnav"
      class="navbar navbar-expand-lg py-0 navbar-dark"
      [ngClass]="{ show: showNav_2 }"
    >
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="active_2"
        class="nav-tabs nav-fill w-100"
      >
        <li [ngbNavItem]="0">
          <a ngbNavLink>
            <mat-icon svgIcon="home"></mat-icon>
          </a>
          <ng-template ngbNavContent>
            <app-main
              [biosignals]="biosignals"
              [biosignalsRange]="biosignalsRange"
            ></app-main>
          </ng-template>
        </li>
        <li
          [ngbNavItem]="1"
          [hidden]="!rightsService.isHasRight(Rights.ExtremumConform)"
        >
          <a ngbNavLink class="hide-on-mobile">
            {{ "calculation.menu_item_1" | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-extremum-conform
              [setBiosignals]="biosignals"
              [biosignalsRange]="biosignalsRange"
            ></app-extremum-conform>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>{{ "calculation.menu_item_2" | translate }}</a>
          <ng-template ngbNavContent let-active>
            <app-expert-system
              [initData]="active"
              [biosignals]="biosignals"
              [biosignalsRange]="biosignalsRange"
            ></app-expert-system>
          </ng-template>
        </li>
        <!-- [hidden]="!rightsService.isHasRight(Rights.Report)" -->
        <li [ngbNavItem]="3" id="report">
          <a ngbNavLink>{{ "calculation.menu_item_3" | translate }}</a>
          <ng-template ngbNavContent let-active>
            <app-report
              [initData]="active"
              [allBiosignals]="biosignals"
              [setBiosignals]="biosignalsRange"
            ></app-report>
          </ng-template>
        </li>
      </ul>
    </nav>
    <div [ngbNavOutlet]="nav"></div>
  </main>
</div>
