import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { Biosignal } from 'src/app/models/biosignal';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() ACTIVE_GRAPH: GraphType = GraphType.AO;
  @Input() sdpDateUnits: boolean = false;
  @Input() biosignals: Biosignal[];
  @Input() biosignalsCopy: Biosignal[];
  @Output() date = new EventEmitter();

  fromDate;
  toDate;
  fromTime;
  toTime;
  isOpenCalendar: boolean = false;
  constructor(
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {}

  ngOnInit(): void {}

  onDateSelection() {
    this.measurementsDataUpdateService.changeMeasurementsRangeDate(
      moment(
        new Date(
          moment(this.fromDate).year(),
          moment(this.fromDate).month(),
          moment(this.fromDate).date(),
          this.fromTime.slice(0, 2),
          this.fromTime.slice(3, 5),
          this.fromTime.slice(6, 8)
        )
      ).toISOString(),
      moment(
        new Date(
          moment(this.toDate).year(),
          moment(this.toDate).month(),
          moment(this.toDate).date(),
          this.toTime.slice(0, 2),
          this.toTime.slice(3, 5),
          this.toTime.slice(6, 8)
        )
      ).toISOString()
    );
  }

  setLimitsDateFilter() {
    if (this.biosignals) {
      let min: Biosignal;
      let max: Biosignal;
      if (this.biosignals[1] >= this.biosignals[0]) {
        max = this.biosignals[1];
        min = this.biosignals[0];
      } else {
        max = this.biosignals[0];
        min = this.biosignals[1];
      }
      this.biosignals.map((v: any) => {
        if (this.SRDT(max) < this.SRDT(v)) max = v;
        if (this.SRDT(min) > this.SRDT(v)) min = v;
      });
      this.fromDate = this.SRDTtoMoment(min);
      this.fromTime = new DatePipe('en-US').transform(
        this.SRDTtoMoment(min).toISOString(),
        'HH:mm:ss'
      );
      this.toDate = this.SRDTtoMoment(max);
      this.toTime = new DatePipe('en-US').transform(
        this.SRDTtoMoment(max).toISOString(),
        'HH:mm:ss'
      );
    }
  }

  SRDT(biosignal) {
    return new Date(biosignal.SignalRegistrationDateTime);
  }

  SRDTtoMoment(biosignal) {
    return moment(biosignal.SignalRegistrationDateTime);
  }
}
