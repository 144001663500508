<div class="main scrollbar mt-4" *ngIf="doctors.length">
  <aside class="sidebar">
    <mat-form-field class="show-mobile w-100">
      <mat-select
        [(ngModel)]="selectedDoctorId"
        placeholder="{{ 'admin.doctor' | translate }}"
      >
        <mat-option
          *ngFor="let doctor of doctors; let i = index"
          [value]="doctor._id"
        >
          <div
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <app-doctor-photo
              class="select-avatar"
              [doctorId]="doctor._id"
            ></app-doctor-photo>
            <span>
              {{ doctor.last_name }}
              {{ doctor.first_name }}
              {{ doctor.second_name }};&nbsp;
              <ng-container
                *ngIf="
                  doctor.medicalInstitution;
                  else withoutMedicalInstitution
                "
              >
                {{ doctor.medicalInstitution.name }}
              </ng-container>
              <ng-template #withoutMedicalInstitution>
                {{ "admin.without_medical_institution" | translate }}
              </ng-template>
            </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-doctor-item
      *ngIf="selectedDoctorId"
      [selectedId]="selectedDoctorId"
      class="show-mobile"
      [doctor]="findDoctor()"
    ></app-doctor-item>

    <ul class="w3-ul show-desktop">
      <h1 class="title-role">{{ "admin.doctor" | translate }}</h1>
      <ng-container *ngFor="let doctor of doctors; let i = index">
        <app-doctor-item
          [selectedId]="selectedDoctorId"
          [doctor]="doctor"
          (setDoctor)="getDoctorId($event)"
        ></app-doctor-item>
      </ng-container>
    </ul>
  </aside>

  <div class="contents">
    <app-patients-list [doctorId]="selectedDoctorId"> </app-patients-list>
  </div>
</div>
