<div *ngIf="parsAoiTableData">
  <h1 class="expert-system_title">
    <ng-container *ngIf="!(reloading$ | async)">
      {{ parsAoiTableData.indicatorFullName | lang }}
    </ng-container>
  </h1>
  <div>
    <h1>
      <ng-container *ngIf="!(reloading$ | async)">
        {{ parsAoiTableData.indicatorName | lang }}
      </ng-container>
      = {{ averageValues.parsAoi }}
    </h1>
    <ng-container *ngIf="!(reloading$ | async)">
      {{ indicatorDescription | lang }}
    </ng-container>
  </div>
  <div *ngIf="parsAoiTableData.indicatorDescription" class="slide-wrapper">
    <div class="expert-table-wrapper">
      <app-accordion [title]="'calculation.explanation_pars' | translate">
        <table class="expert-table-style-2">
          <tbody>
            <tr *ngFor="let item of parsAoiTableData.indicatorDescription">
              <td>
                <strong style="white-space: nowrap"
                  >{{ item.minValue }} - {{ item.maxValue }}</strong
                >
              </td>
              <td>
                <ng-container *ngIf="!(reloading$ | async)">
                  {{ item.description | lang }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </app-accordion>
    </div>
  </div>
  <div class="expert-table-wrapper">
    <table class="expert-table-style-2">
      <thead>
        <ng-container *ngIf="!(reloading$ | async)">
          <tr>
            <th>{{ parsAoiTableData.table.Points.header | lang }}</th>
            <th>{{ parsAoiTableData.table.Mo.header | lang }}</th>
            <th>{{ parsAoiTableData.table.VPR.header | lang }}</th>
            <th colspan="2">
              {{ parsAoiTableData.table.AMo_IN.header | lang }}
            </th>
            <th>{{ parsAoiTableData.table.HVR_V.header | lang }}</th>
            <th>{{ parsAoiTableData.table.fPercent.header | lang }}</th>
          </tr>
        </ng-container>
      </thead>
      <tbody>
        <tr>
          <td>{{ parsAoiTableData.table.Points.row[0] }}</td>
          <td>{{ parsAoiTableData.table.Mo.row[0] }}</td>
          <td>{{ parsAoiTableData.table.VPR.row[0] }}</td>
          <td>{{ parsAoiTableData.table.AMo_IN.row[0] }}</td>
          <td>{{ parsAoiTableData.table.IN.row[0] }}</td>
          <td>{{ parsAoiTableData.table.HVR_V.row[0] }}</td>
          <td>{{ parsAoiTableData.table.fPercent.row[0] }}</td>
        </tr>

        <tr>
          <td></td>
          <td>
            <strong>{{ averageValues.Mo }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.VPR }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.AMo_IN }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.IN }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.HVR_V }}; {{ averageValues.V }}</strong>
          </td>
          <td>
            <strong
              >{{ averageValues.VLF }}; {{ averageValues.LF }};
              {{ averageValues.HF }}</strong
            >
          </td>
        </tr>
        <ng-container *ngIf="!(reloading$ | async)">
          <tr
            *ngFor="let row of parsAoiTableData.table.Points.row; let i = index"
          >
            <ng-container *ngIf="i > 1">
              <td>
                <strong>{{ parsAoiTableData.table.Points.row[i] }}</strong>
              </td>

              <td
                [ngClass]="
                  parsAoiTableData?.table.Mo.className
                    ? parsAoiTableData?.table.Mo.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.Mo.row[i].description | lang
                "
              ></td>

              <td
                [ngClass]="
                  parsAoiTableData?.table.VPR.className
                    ? parsAoiTableData?.table.VPR.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.VPR.row[i].description | lang
                "
              ></td>
              <td
                [ngClass]="
                  parsAoiTableData?.table.AMo_IN.className
                    ? parsAoiTableData?.table.AMo_IN.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.AMo_IN.row[i].description | lang
                "
              ></td>
              <td
                [ngClass]="
                  parsAoiTableData?.table.IN.className
                    ? parsAoiTableData?.table.IN.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.IN.row[i].description | lang
                "
              ></td>
              <td
                [ngClass]="
                  parsAoiTableData?.table.HVR_V.className
                    ? parsAoiTableData?.table.HVR_V.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.HVR_V.row[i].description | lang
                "
              ></td>
              <td
                [ngClass]="
                  parsAoiTableData?.table.fPercent.className
                    ? parsAoiTableData?.table.fPercent.className[i]
                    : ''
                "
                [innerHTML]="
                  parsAoiTableData.table.fPercent.row[i].description | lang
                "
              ></td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
