import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ErrorMeasurementsComponent } from './error-measurements/error-measurements.component';
import { MeasurementsSettingsComponent } from './measurements-settings/measurements-settings.component';
import { ItemRowSettingComponent } from './measurements-settings/item-row/item-row.component';
import { ParseDialogComponent } from './measurements-settings/dialogs/confirm-download-dialog/parse-dialog.component';
import { ConfirmDisabledDialogComponent } from './measurements-settings/dialogs/confirm-disabled-dialog/confirm-disabled-dialog.component';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { DoctorsInfoComponent } from './doctors-info/doctors-info.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DoctorItemComponent } from './doctors-info/doctor-item/doctor-item.component';
import { DoctorPhotoComponent } from './doctors-info/doctor-photo/doctor-photo.component';
import { PatientsListModule } from '../global-components/patients-list/patients-list.module';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { VAT41SettingComponent } from './device-settings/device-global-setting/vat41-setting/vat41-setting.component';
import { DuoEKSettingComponent } from './device-settings/device-global-setting/duo-ek-setting/duo-ek-setting.component';
import { Er1Er2VisualBeatSettingComponent } from './device-settings/device-global-setting/er1-er2-visual-beat-setting/er1-er2-visual-beat-setting.component';
import { Bp2Bp2aSettingComponent } from './device-settings/device-global-setting/bp2-bp2a-setting/bp2-bp2a-setting.component';
import { ItemRowComponent } from './device-settings/device-current-settings/item-row/item-row.component';
import { DeviceCurrentSettingsComponent } from './device-settings/device-current-settings/device-current-settings.component';
import { DeviceGlobalSettingComponent } from './device-settings/device-global-setting/device-global-setting.component';
import { DeviceRangeSettingComponent } from './device-settings/device-range-setting/device-range-setting.component';
import { ItemRowDeviceRangeSettingComponent } from './device-settings/device-range-setting/item-row-device-range-setting/item-row-device-range-setting.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminPanelComponent,
    ErrorMeasurementsComponent,
    MeasurementsSettingsComponent,
    ItemRowSettingComponent,
    ParseDialogComponent,
    ConfirmDisabledDialogComponent,
    DoctorsInfoComponent,
    DoctorItemComponent,
    DoctorPhotoComponent,
    DeviceSettingsComponent,
    VAT41SettingComponent,
    DuoEKSettingComponent,
    Er1Er2VisualBeatSettingComponent,
    Bp2Bp2aSettingComponent,
    ItemRowComponent,
    DeviceCurrentSettingsComponent,
    DeviceGlobalSettingComponent,
    DeviceRangeSettingComponent,
    ItemRowDeviceRangeSettingComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    PipesModule,
    NgbNavModule,
    PatientsListModule,
  ],

  exports: [],
  entryComponents: [],
  providers: [],
})
export class AdminModule {}
