import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { AdminComponent } from './admin.component';
import { DoctorsInfoComponent } from './doctors-info/doctors-info.component';
import { ErrorMeasurementsComponent } from './error-measurements/error-measurements.component';
import { MeasurementsSettingsComponent } from './measurements-settings/measurements-settings.component';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { DeviceCurrentSettingsComponent } from './device-settings/device-current-settings/device-current-settings.component';
import { DeviceGlobalSettingComponent } from './device-settings/device-global-setting/device-global-setting.component';
import { DeviceRangeSettingComponent } from './device-settings/device-range-setting/device-range-setting.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'panel', pathMatch: 'full' },
      { path: 'panel', component: AdminPanelComponent },
      { path: 'measurements-error', component: ErrorMeasurementsComponent },
      {
        path: 'measurements-settings',
        component: MeasurementsSettingsComponent,
      },
      {
        path: 'doctors-info',
        component: DoctorsInfoComponent,
      },
      {
        path: 'device-settings',
        component: DeviceSettingsComponent,
        children: [
          { path: '', redirectTo: 'global', pathMatch: 'full' },
          {
            path: 'global',
            component: DeviceGlobalSettingComponent,
          },
          {
            path: 'current',
            component: DeviceCurrentSettingsComponent,
          },
          {
            path: 'range',
            component: DeviceRangeSettingComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
