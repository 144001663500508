<div class="expert-table-wrapper">
  <ng-container *ngIf="!(reloading$ | async)">
    <table class="expert-table-style-2">
      <thead>
        <tr>
          <th>{{ data?.intervals.header | lang }}</th>
          <th>{{ data?.normalValues.header | lang }}</th>
          <th>{{ averageValues?.header | lang }}</th>
          <th>{{ data?.interpretation.header | lang }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ignore of data?.interpretation.row; let i = index">
          <td>{{ data?.intervals.row[i] | lang }}</td>
          <td>{{ data?.normalValues.row[i].description }}</td>
          <td [ngClass]="data?.className ? data?.className[i] : ''">
            {{ averageValues?.row[i] }}
          </td>
          <td [ngClass]="data?.className ? data?.className[i] : ''">
            {{ data?.interpretation.row[i] }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
