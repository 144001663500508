import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-disabled-dialog',
  templateUrl: './confirm-disabled-dialog.component.html',
  styleUrls: ['./confirm-disabled-dialog.component.scss']
})
export class ConfirmDisabledDialogComponent implements OnInit {
  @Input() public data;
  equipments = new SelectionModel<number>(true);
  allCheck: boolean = true;
  constructor(
    public modal: NgbActiveModal) {
  }

  ngOnInit(): void {

  }

  submit() {
    this.modal.close(true);
  }

  close() {
    this.modal.close();
  }
}
