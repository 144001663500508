import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MeasurementsUploadComponent } from './measurements-upload/measurements-upload.component';
import {
  NgbButtonsModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDownloadDialogComponent } from './measurements-upload/dialogs/confirm-download-dialog/confirm-download-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { SortableHeaderDirectiveModule } from 'src/app/directives/sortable-header/sortable-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ItemRowComponent } from './measurements-upload/item-row/item-row.component';
import { RedirectDialogComponent } from './measurements-upload/dialogs/redirect-dialog/redirect-dialog.component';
import { RecalculateDialogComponent } from './measurements-upload/dialogs/recalculate-dialog/recalculate-dialog.component';
import { MaterialModule } from 'src/app/material.module';

import { MeasurementsSettingComponent } from './measurements-setting/measurements-setting.component';
import { UserDeviceSettingsComponent } from './user-device-settings/user-device-settings.component';
import { AoUploadFormComponent } from './measurements-upload/upload-forms-data/ao-upload-form/ao-upload-form.component';
import { WpUploadFormComponent } from './measurements-upload/upload-forms-data/wp-upload-form/wp-upload-form.component';
import { CranialUploadFormComponent } from './measurements-upload/upload-forms-data/cranial-upload-form/cranial-upload-form.component';
import { EcgUploadFormComponent } from './measurements-upload/upload-forms-data/ecg-upload-form/ecg-upload-form.component';
import { MediapipeComponent } from './mediapipe/mediapipe.component';
import { CountDownComponent } from './mediapipe/count-down/count-down.component';
import { SvgModule } from 'src/app/svg.module';

export const W_MEASUREMENTS_ROUTES: Routes = [
  { path: 'measurements-upload', component: MeasurementsUploadComponent },
  { path: 'user-device-setting', component: UserDeviceSettingsComponent },
  { path: 'measurements-setting', component: MeasurementsSettingComponent },
  { path: 'mediapipe', component: MediapipeComponent },
];

@NgModule({
  declarations: [
    MediapipeComponent,
    CountDownComponent,
    MeasurementsUploadComponent,
    ConfirmDownloadDialogComponent,
    ItemRowComponent,
    RedirectDialogComponent,
    RecalculateDialogComponent,
    AoUploadFormComponent,
    WpUploadFormComponent,
    CranialUploadFormComponent,
    EcgUploadFormComponent,
    MeasurementsSettingComponent,
    UserDeviceSettingsComponent,
  ],
  exports: [
    CountDownComponent,
    MediapipeComponent,
    MeasurementsUploadComponent,
    ItemRowComponent,
    AoUploadFormComponent,
    WpUploadFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    SortableHeaderDirectiveModule,
    MaterialModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbButtonsModule,
    SvgModule,
  ],
  bootstrap: [MeasurementsUploadComponent],
})
export class MeasurementsDataModule {}
