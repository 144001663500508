<div class="row">
  <div class="col mt-3">
    <mat-form-field class="col-md-4">
      <mat-label>
        {{ "measurement.name_meter" | translate }}
      </mat-label>
      <mat-select
        disabled
        [(ngModel)]="equipmentName"
        (selectionChange)="selectEquipment($event.value)"
      >
        <mat-option>-</mat-option>
        <mat-option *ngFor="let item of equipmentList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col mt-3">
      <div class="expert-table-wrapper">
        <div class="table-wrapper" [ngClass]="{ 'loader-section': tableBusy }">
          <div class="loader" *ngIf="tableBusy">
            <div class="spinner-border" role="status"></div>
          </div>
          <table id="myTable" class="expert-table-style-3">
            <thead>
              <tr>
                <th></th>
                <th sortable="date" (sort)="onSort($event)">
                  {{
                    "measurement.error_measurements_header.EquipmentID"
                      | translate
                  }}
                  <i class="bi bi-sort-alpha-down down"></i>
                  <i class="bi bi-sort-alpha-up-alt up"></i>
                </th>
                <th>
                  {{
                    "measurement.error_measurements_header.EquipmentMacId"
                      | translate
                  }}
                </th>
                <th>
                  {{
                    "measurement.error_measurements_header.EquipmentName"
                      | translate
                  }}
                </th>
                <th>{{ "measurement.package_size" | translate }}</th>
                <th>{{ "measurement.pumping_speed" | translate }}</th>
                <th>{{ "measurement.cuff_type" | translate }}</th>
                <th>
                  <!-- shouldTransmitSignal -->
                  {{ "measurement.should_transmit_signal" | translate }}
                </th>
                <th>telegram</th>
                <th>graph</th>
                <!-- <th>
                  <label>
                    <input
                      [(ngModel)]="allCheck"
                      (change)="selectCheckBox()"
                      class="customCheckBox"
                      type="checkbox"
                    />
                  </label>
                </th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let element of tableData; let i = index">
                <app-item-row [element]="element" [index]="i"></app-item-row>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
