import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QualificationTypeService {
  qualification_type = [
    {
      value: 'CLINICAL_RESIDENCY',
      ua: 'Клінічна ординатура',
      en: 'CLINICAL_RESIDENCY',
      pl: 'Rezydencja kliniczna',
    },
    {
      value: 'INTERNSHIP',
      ua: 'Інтернатура',
      en: 'INTERNSHIP',
      pl: 'Staż',
    },
    {
      value: 'REATTESTATION',
      ua: 'Передатестаційний цикл',
      en: 'REATTESTATION',
      pl: 'Cykl precertyfikacji',
    },
    {
      value: 'SPECIALIZATION',
      ua: 'Курси спеціалізації',
      en: 'SPECIALIZATION',
      pl: 'Kursy specjalizacyjne',
    },
    {
      value: 'STAZHUVANNYA',
      ua: 'Стажування',
      en: 'STAZHUVANNYA',
      pl: 'Staż',
    },
    {
      value: 'POSTGRADUATE',
      ua: 'Аспірантура',
      en: 'POSTGRADUATE',
      pl: 'Studia podyplomowe',
    },
    {
      value: 'TOPIC_IMPROVEMENT',
      ua: 'Курси удосконалення',
      en: 'TOPIC_IMPROVEMENT',
      pl: 'Kursy doskonalące',
    },
  ];
}
