<li [ngClass]="{'w3-bar': true, 'mode-small': mode === PatientListMode.SMALL, 'chosen': isChosen}"
    (click)="chosenPatient.emit(patient)">
  <div>
    <div class="photo">
      <app-patient-photo [patientId]="patient._id"></app-patient-photo>
    </div>
    <div class="title title--common mt-2">
      <span
        ><ng-container *ngIf="!(reloading$ | async)">
          {{
            patient.gender | valueById : genderDict.gender : "value" | lang
          }}</ng-container
        >, {{ patient.birth_date | age }} {{ "form.years_old" | translate }}
      </span>
    </div>
  </div>
  <div class="w3-bar-item">
    <div class="level-1">
      <div class="w3-large" *ngIf="mode !== PatientListMode.SMALL; else patientName">
        <span *ngIf="patient.last_name.toLowerCase().includes('patient')">Patient:</span>
        {{ patient.last_name | cutPatient }}
        {{ patient.first_name | cutPatient }}
        {{ patient.second_name | cutPatient }}
      </div>
      <span class="subtitle color-link" (click)="viewPatientInfo(patient)">
        <b> {{ "form.go_to_calculations" | translate }} </b>
        <i class="bi bi-arrow-right"></i>
      </span>
    </div>

    <div *ngIf="mode !== PatientListMode.SMALL" class="d-flex container-box justify-content-between">
      <div>
        <div class="subtitle mt-1">
          <b>{{ "measurement.signal_upload_date" | translate }}:</b>
          {{ lastBiosignal?.uploadDateTime | date : "dd-MM-yyyy, HH:mm" }}
        </div>

        <div class="subtitle mt-1">
          <b>{{ "measurement.date_signal_registration" | translate }}:</b>
          {{
            lastBiosignal?.SignalRegistrationDateTime
              | date : "dd-MM-yyyy, HH:mm"
          }}
        </div>

        <div class="subtitle mt-1" *ngIf="lastDiagnos">
          <b>{{ "measurement.diagnosis" | translate }}:</b>
          {{ lastDiagnos?.diagnosis }} ({{
            lastDiagnos?.createdAt | date : "dd-MM-yyyy, HH:mm"
          }})
        </div>
      </div>
      <div class="d-flex flex-column contact-box">
        <span class="subtitle mt-1"
          ><b>Email:</b>&nbsp;{{ patient.email }};&nbsp;</span
        >
        <span class="subtitle mt-1">
          <b> Phone:</b>
          <ng-container *ngFor="let item of patient.phones"
            >&nbsp;{{ item.number ? item.number : "-" }} <br />
          </ng-container>
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center mt-2">
      <div (click)="collapsed = !collapsed" class="d-flex align-items-center">
        <div class="subtitle mt-1 color-link">
          <span> {{ "form.list_of_diagnosis" | translate }}</span>
        </div>
        <button
          class="ml-2 addBtn"
          matTooltip="{{
            collapsed
              ? ('measurement.expand_list' | translate)
              : ('measurement.collapse_list' | translate)
          }}"
          [matTooltipPosition]="'above'"
        >
          <i
            [ngClass]="{
              'bi-arrows-expand': collapsed,
              'bi-arrows-collapse': !collapsed
            }"
            class="bi"
          ></i>
        </button>
      </div>
      <button
        (click)="changeCreateStatus()"
        class="ml-2 addBtn"
        matTooltip="{{
          createDiagnosis
            ? ('login.cancel' | translate)
            : ('form.add_diagnosis' | translate)
        }}"
        [matTooltipPosition]="'above'"
      >
        <i
          [ngClass]="{
            'bi-plus': !createDiagnosis,
            'bi-dash': createDiagnosis
          }"
          class="bi bi-plus"
        ></i>
      </button>
    </div>

    <div class="d-flex align-items-center row" [@collapse]="!createDiagnosis">
      <mat-form-field class="col-md mt-3">
        <mat-label>{{ "measurement.diagnosis" | translate }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="diagnosis"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
        ></textarea>
      </mat-form-field>
      <div class="col-md-auto">
        <button
          (click)="saveDiagnosis(patient._id)"
          class="addBtn"
          matTooltip="{{ 'form.save' | translate }}"
          [matTooltipPosition]="'above'"
        >
          <i class="bi bi-save"></i>
        </button>
      </div>
    </div>

    <div [@collapse]="collapsed">
      <div
        class="table-wrapper"
        style="width: fit-content"
        [ngClass]="{ 'loader-section': tableBusy }"
        *ngIf="!collapsed"
      >
        <table class="expert-table-style-3 my-2">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ "measurement.diagnosis" | translate }}</th>
              <th>{{ "efficiency.date" | translate }}</th>
              <th></th>
              <!-- <th>{{ "form.edit" | translate }}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of patient.diagnosis_array; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.diagnosis }}</td>
              <td>
                {{ item.createdAt | date : "dd&#45;MM&#45;yyyy, HH:mm" }}
              </td>
              <td>
                <button
                  (click)="editDiagnosis(item)"
                  class="addBtn"
                  matTooltip="{{ 'form.edit' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <i class="bi bi-pencil-square"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</li>

<ng-template #patientName>
  <div class="w3-large">
    {{ patient.last_name | cutPatient }}
    {{ patient.first_name | cutPatient }}
  </div>
  <div class="w3-large">
    {{ patient.second_name | cutPatient }}
  </div>
</ng-template>
