<div class="vessels-wrapper">
  <h1>{{ "calculation.vessels" | translate }}</h1>
  <div class="q">
    <div class="chart-wrapper">
      <app-raw-signal-chart [initData]="isInit"></app-raw-signal-chart>
    </div>
  </div>
  <p class="expand-table" (click)="expand()">
    <ng-container *ngIf="expandRow">
      {{ "measurement.hide" | translate }}
    </ng-container>
    <ng-container *ngIf="!expandRow">
      {{ "measurement.expand" | translate }}
    </ng-container>
    <i class="bi bi-chevron-bar-contract contract" *ngIf="expandRow"></i>
    <i class="bi bi-chevron-bar-expand expand" *ngIf="!expandRow"></i>
  </p>
  <div class="expert-table-wrapper">
    <ng-container *ngIf="!(reloading$ | async)">
      <table class="expert-table-style-2">
        <thead>
          <tr>
            <th>
              <strong>{{ vesselsTableData?.table.names.header | lang }}</strong>
            </th>
            <th>
              <strong>{{ averageHeader | lang }}</strong>
            </th>
            <th>
              <strong>{{
                vesselsTableData?.table.rating.header | lang
              }}</strong>
            </th>
            <th *ngIf="expandRow">
              <strong>{{
                vesselsTableData.table.characteristic.header | lang
              }}</strong>
            </th>
            <th>
              <strong>{{
                vesselsTableData?.table.rationale.header | lang
              }}</strong>
            </th>
            <th>
              <strong>{{ vesselsTableData?.table.level.header | lang }}</strong>
            </th>
            <th>
              <strong>{{
                vesselsTableData?.table.recommendations.header | lang
              }}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let vesselsName of vesselsTableData?.table.rowName;
              let i = index
            "
          >
            <td>
              <strong
                >{{ vesselsTableData?.table.names.row[i] | lang }} :
                {{ vesselsName }}</strong
              >
            </td>
            <td [ngClass]="middleValueClassName[vesselsName]">
              {{ middleValue[vesselsName] }}
            </td>
            <td [ngClass]="middleValueClassName[vesselsName]">
              {{ middleValueDescription[vesselsName] | lang }}
            </td>
            <td *ngIf="expandRow">
              {{ middleCharacteristicDescription[vesselsName] | lang }}
            </td>
            <td>{{ middleRationaleDescription[vesselsName] | lang }}</td>
            <td>{{ middleLevelDescription[vesselsName] | lang }}</td>
            <td>{{ middleRecommendationsDescription[vesselsName] | lang }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
