import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-morphological-analysis',
  templateUrl: './morphological-analysis.component.html',
  styleUrls: ['./morphological-analysis.component.scss']
})
export class MorphologicalAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
