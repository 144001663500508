import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartNameService {
  chart_name = {
    systola: {
      pl: 'CTs',
      en: 'BPs',
      ua: 'АТс',
    },
    diastola: {
      pl: 'CTr',
      en: 'BPd',
      ua: 'АТд',
    },
    heartRate: { pl: 'CAS', en: 'HR', ua: 'ЧСС' },
    pars: { pl: 'WASR-AO', en: 'PARS-AOI', ua: 'ПАРС-АОI' },
    ifsp: { pl: 'ZFPN', en: 'IFVP', ua: 'IФСП' },
    msr: { pl: 'Msr', en: 'Mavr', ua: 'Мср' },
    heartDiseases: {
      pl: 'układu krążenia',
      en: 'cardiovascular',
      ua: 'серц-суд',
    },
    lungDiseases: { pl: 'płuc', en: 'pulmonary', ua: 'легеневi' },
    psychicalDiseases: { pl: 'psychiczne', en: 'mental', ua: 'психiчнi' },
    arrhythmiaDiseases: { pl: 'arytmia', en: 'arrhythmia', ua: 'аритмія' },
  };
}
