import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OscillogramData } from '../../../models/oscillogram-data';
import { MeasurementsDataUpdateService } from '../../../services/measurements-data-update.service';
import { ChartDataSets, ChartPoint, Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import { BiosignalsService } from '../../../services/biosignals.service';
import { Biosignal } from '../../../models/biosignal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmChangesDialogComponent } from './confirm-changes-dialog/confirm-changes-dialog.component';
import { DataTypeService } from 'src/app/services/data-type.service';
import { GraphType } from 'src/app/enums/graph_type.enum';
@Component({
  selector: 'app-extremum-conform',
  templateUrl: './extremum-conform.component.html',
  styleUrls: ['./extremum-conform.component.scss'],
})
export class ExtremumConformComponent implements OnInit, AfterViewInit {
  static readonly SIGNAL_STEP = 4;
  @ViewChild('chart') chartElem: ElementRef;
  mainDataSet = 0;
  minDataSet = 1;
  maxDataSet = 2;
  qDataSet = 1;
  rDataSet = 2;
  sDataSet = 3;
  tDataSet = 4;

  biosignals: Biosignal[];
  biosignalsFmtDates: string[] = [];
  selectedBiosignalIndex = 0;
  currBiosignalIndex = 0;
  oscillogramData: OscillogramData;
  changesWereMade = false;

  minExtremesSet: Set<number> = new Set();
  maxExtremesSet: Set<number> = new Set();
  minExtremesArray: number[] = [];
  maxExtremesArray: number[] = [];

  qExtremesSet: Set<number> = new Set();
  rExtremesSet: Set<number> = new Set();
  sExtremesSet: Set<number> = new Set();
  tExtremesSet: Set<number> = new Set();
  qExtremesArray: number[] = [];
  rExtremesArray: number[] = [];
  sExtremesArray: number[] = [];
  tExtremesArray: number[] = [];

  lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Raw Signal',
      order: 2,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#246B8F',
      borderWidth: 1,
      pointRadius: 0,
    },
    {
      data: [],
      label: 'Minimum',
      order: 1,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#DB9770',
      borderWidth: 1,
      pointRadius: 4,
    },
    {
      data: [],
      label: 'Maximum',
      order: 0,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#70B8DB',
      borderWidth: 1,
      pointRadius: 4,
    },
  ];

  lineChartData_QRST: ChartDataSets[] = [
    {
      data: [],
      label: 'Raw Signal',
      order: 4,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#246B8F',
      borderWidth: 1,
      pointRadius: 0,
    },
    {
      data: [],
      label: 'Q',
      order: 3,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#f5b618',
      borderWidth: 1,
      pointRadius: 4,
    },
    {
      data: [],
      label: 'R',
      order: 2,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#f70207',
      borderWidth: 1,
      pointRadius: 4,
    },
    {
      data: [],
      label: 'S',
      order: 1,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#b882ff',
      borderWidth: 1,
      pointRadius: 4,
    },
    {
      data: [],
      label: 'T',
      order: 0,
      lineTension: 0,
      backgroundColor: 'transparent',
      borderColor: '#18d4f5',
      borderWidth: 1,
      pointRadius: 4,
    },
  ];
  lineChartLabels: Label[] = [];

  chart: Chart;

  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType = GraphType.AO;

  @Input() set setBiosignals(v) {
    const dateOptions: any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const dateFormat = Intl.DateTimeFormat('uk-UA', dateOptions);
    this.biosignals = v;
    v?.forEach((biosignal) => {
      const date = new Date(biosignal.SignalRegistrationDateTime);
      this.biosignalsFmtDates.push(dateFormat.format(date));
    });
  }

  constructor(
    public biosignalsService: BiosignalsService,
    public measurementsDataUpdateService: MeasurementsDataUpdateService,
    public modalService: NgbModal,
    public dataTypeService: DataTypeService,
    public cd: ChangeDetectorRef
  ) {
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      if (this.chartElem)
        this.chart = new Chart(this.chartElem.nativeElement, {
          type: 'line',
          data: {
            labels: this.lineChartLabels,
            datasets:
              this.ACTIVE_GRAPH == GraphType.ECG
                ? this.lineChartData_QRST
                : this.lineChartData,
          },
          options: {
            responsive: true,
            scales: {
              xAxes: [
                {
                  type: 'linear',
                  position: 'bottom',
                },
              ],
            },
          },
        });
    });
  }

  ngOnInit(): void {
    this.measurementsDataUpdateService.selectedMeasurement$.subscribe(
      (biosignal) => {
        if (this.biosignals?.length) {
          const index = this.biosignals.findIndex(
            (b) => b._id == biosignal._id
          );
          this.tryChangeBiosignal(index, null);
        }
      }
    );
  }

  ngAfterViewInit(): void {
    if (this.chartElem)
      this.chart = new Chart(this.chartElem?.nativeElement, {
        type: 'line',
        data: {
          labels: this.lineChartLabels,
          datasets: this.lineChartData,
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                type: 'linear',
                position: 'bottom',
              },
            ],
          },
        },
      });
  }

  updateData(): void {
    if (
      this.biosignals[this.currBiosignalIndex] &&
      this.biosignals[this.currBiosignalIndex]._id
    ) {
      this.biosignalsService
        .getOscillogramExtremes(this.biosignals[this.currBiosignalIndex]._id)
        .subscribe((oscillogramExtremesData) => {
          if (
            oscillogramExtremesData?.extremumsData.negativeExtremums &&
            oscillogramExtremesData?.extremumsData.positiveExtremums
          ) {
            this.oscillogramData = oscillogramExtremesData.oscillogramData;
            this.minExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.negativeExtremums.indices
            );
            this.maxExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.positiveExtremums.indices
            );
            this.update();
            this.updateExtremesChart();
          }

          if (
            oscillogramExtremesData?.extremumsData.qExtremums &&
            oscillogramExtremesData?.extremumsData.rExtremums &&
            oscillogramExtremesData?.extremumsData.sExtremums &&
            oscillogramExtremesData?.extremumsData.tExtremums
          ) {
            this.oscillogramData = oscillogramExtremesData.oscillogramData;
            this.qExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.qExtremums.indices
            );
            this.sExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.sExtremums.indices
            );
            this.rExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.rExtremums.indices
            );
            this.tExtremesSet = new Set(
              oscillogramExtremesData?.extremumsData.tExtremums.indices
            );
            this.update();
            this.updateExtremesChart();
          }
        });
    }
  }

  update(): void {
    this.lineChartData[this.mainDataSet].data = [];
    this.lineChartData_QRST[this.mainDataSet].data = [];
    for (
      let i = 0;
      i < this.oscillogramData.timePoints.length;
      i += ExtremumConformComponent.SIGNAL_STEP
    ) {
      const p: any = {
        x: this.oscillogramData.timePoints[i],
        y: this.oscillogramData.valuePoints[i],
      };
      this.lineChartData[this.mainDataSet].data.push(p);
      this.lineChartData_QRST[this.mainDataSet].data.push(p);
    }
    if (this.chartElem) this.chart.update();
  }

  updateExtremesChart(): void {
    if (this.ACTIVE_GRAPH == GraphType.ECG) {
      this.qExtremesArray = this.setExtremesArray(this.qExtremesSet);
      this.rExtremesArray = this.setExtremesArray(this.rExtremesSet);
      this.sExtremesArray = this.setExtremesArray(this.sExtremesSet);
      this.tExtremesArray = this.setExtremesArray(this.tExtremesSet);
      this.lineChartData_QRST[this.qDataSet].data = this.setLineChartData(
        this.qExtremesArray
      );
      this.lineChartData_QRST[this.rDataSet].data = this.setLineChartData(
        this.rExtremesArray
      );
      this.lineChartData_QRST[this.sDataSet].data = this.setLineChartData(
        this.sExtremesArray
      );
      this.lineChartData_QRST[this.tDataSet].data = this.setLineChartData(
        this.tExtremesArray
      );
    } else {
      this.minExtremesArray = this.setExtremesArray(this.minExtremesSet);
      this.maxExtremesArray = this.setExtremesArray(this.maxExtremesSet);

      this.lineChartData[this.minDataSet].data = this.setLineChartData(
        this.minExtremesArray
      );
      this.lineChartData[this.maxDataSet].data = this.setLineChartData(
        this.maxExtremesArray
      );
    }
    if (this.chartElem) this.chart.update();
  }

  setExtremesArray = (extremesSet: Set<number>): number[] =>
    Array.from(extremesSet.values()).sort((a: any, b: any) => a - b);

  setLineChartData = (extremesArray) =>
    extremesArray.map((id) => {
      const p: ChartPoint = {
        x: this.oscillogramData.timePoints[id],
        y: this.oscillogramData.valuePoints[id],
      };
      return p;
    });

  tryChangeBiosignal(
    newIndex: number,
    onResult?: (success: boolean) => void
  ): void {
    if (this.changesWereMade) {
      this.modalService
        .open(ConfirmChangesDialogComponent)
        .result.then(() => {
          this.currBiosignalIndex = newIndex;
          this.selectedBiosignalIndex = newIndex;
          this.changesWereMade = false;
          this.updateData();
          onResult?.(true);
        })
        .catch(() => onResult?.(false));
    } else {
      this.currBiosignalIndex = newIndex;
      this.selectedBiosignalIndex = newIndex;
      this.updateData();
      onResult?.(true);
    }
  }

  onBiosignalChange(event): void {
    this.tryChangeBiosignal(event, (success) => {
      if (!success) {
        event.target.selectedIndex = this.currBiosignalIndex;
      }
    });
  }

  onChartLeftButton(event: MouseEvent): void {
    const elems: any = this.chart.getElementAtEvent(event);

    if (elems.length > 0) {
      const elem = elems[0];
      const realId = elem._index * ExtremumConformComponent.SIGNAL_STEP;

      if (elem._datasetIndex == this.mainDataSet) {
        if (this.oscillogramData.valuePoints[realId] > 0) {
          this.maxExtremesSet.add(realId);
        } else {
          this.minExtremesSet.add(realId);
        }

        this.changesWereMade = true;
        this.updateExtremesChart();
      }
    }
  }

  onChartRightButton(event: MouseEvent): boolean {
    const elems: any = this.chart.getElementAtEvent(event);
    if (elems.length > 0) {
      const elem = elems[0];
      if (elem._datasetIndex == this.minDataSet) {
        this.minExtremesSet.delete(this.minExtremesArray[elem._index]);
      } else if (elem._datasetIndex == this.maxDataSet) {
        this.maxExtremesSet.delete(this.maxExtremesArray[elem._index]);
      }
      this.changesWereMade = true;
      this.updateExtremesChart();
    }
    return false;
  }

  setExtremums(extremesData, extremums, extremesArray) {
    for (const i of extremesArray) {
      extremesData[extremums].timePoints.push(
        this.oscillogramData.timePoints[i]
      );
      extremesData[extremums].valuePoints.push(
        this.oscillogramData.valuePoints[i]
      );
      extremesData[extremums].indices.push(i);
    }
  }

  saveChanges(): void {
    const extremesData = {
      negativeExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
      positiveExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
    };
    const extremesData_QRST = {
      qExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
      rExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
      sExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
      tExtremums: {
        timePoints: [],
        valuePoints: [],
        indices: [],
      },
    };
    if (this.ACTIVE_GRAPH == GraphType.ECG) {
      this.setExtremums(extremesData_QRST, 'qExtremums', this.qExtremesArray);
      this.setExtremums(extremesData_QRST, 'rExtremums', this.rExtremesArray);
      this.setExtremums(extremesData_QRST, 'sExtremums', this.sExtremesArray);
      this.setExtremums(extremesData_QRST, 'tExtremums', this.tExtremesArray);
    } else {
      this.setExtremums(
        extremesData,
        'negativeExtremums',
        this.minExtremesArray
      );
      this.setExtremums(
        extremesData,
        'positiveExtremums',
        this.maxExtremesArray
      );
    }

    this.biosignalsService
      .changeExtremes(
        this.biosignals[this.currBiosignalIndex]._id,
        this.ACTIVE_GRAPH == GraphType.ECG ? extremesData_QRST : extremesData
      )
      .subscribe(() => {
        this.changesWereMade = false;
      });
  }
}
