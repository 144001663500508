import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleTypeEnum } from '../../model/enums/role.enum';
@Component({
  selector: 'app-role-login',
  templateUrl: './role-login.component.html',
  styleUrls: ['./role-login.component.scss'],
})
export class RoleLoginComponent extends OnDestroyMixin implements OnInit {
  error = '';
  signInForm: FormGroup;
  isVisiblePassword: boolean = false;
  isAdmin: boolean = false;
  isLoading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params) => {
        if (params.admin) {
          this.isAdmin = true;
          this.initForm();
        } else {
          this.isAdmin = false;
          this.initForm();
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  initForm() {
    if (this.isAdmin) {
      this.signInForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        userType: [''],
      });
    } else {
      this.signInForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
      });
    }
  }

  onSubmitForm(form): void {
    this.isLoading = true;
    if (this.isAdmin) {
      this.signInForm.get('userType').patchValue(RoleTypeEnum.Admin);
    }
    this.authService
      .authUser(this.signInForm.value)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.authService.setUser(res.token, res.user);
          this.router.navigate(['/home/calculations']);
        },
        (err) => {
          this.isLoading = false;
          this.error = err.error.message;
          console.log(err);
        }
      );
  }

  get username(): AbstractControl {
    return this.signInForm.get('username');
  }

  get password(): AbstractControl {
    return this.signInForm.get('password');
  }
}
