import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { Biosignal } from 'src/app/models/biosignal';
import { DataTypeService } from 'src/app/services/data-type.service';

@Component({
  selector: 'app-disease-chance-table',
  templateUrl: './disease-chance-table.component.html',
  styleUrls: ['./disease-chance-table.component.scss'],
})
export class DiseaseChanceTableComponent implements OnInit {
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;
  biosignals: Biosignal[];

  @Input() set setBiosignalsRange(v) {
    if (v?.rangeBiosignals?.length) {
      this.biosignals = v.rangeBiosignals;
    }
  }

  constructor(
    public dataTypeService: DataTypeService,
    public cd: ChangeDetectorRef
  ) {
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {}
}
