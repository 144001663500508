<div id="print-wrapper" class="print-wrapper" #pdfTable>
  <div class="report-wrapper">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <h1 class="mb-1">{{ "calculation.report" | translate }}</h1>
      <span class="report-info_ttl" *ngIf="!authService.isPatient()">
        {{
          getMedicalInstitutionName(
            user?.medicalInstitution,
            medicalInstitutions
          )
        }}
      </span>
    </div>
    <hr class="mt-0" />
    <div class="report-info" [ngClass]="{ hideInput: hideBlock }">
      <div class="row" *ngIf="user">
        <div class="col-auto" *ngIf="user?.doctor">
          <div class="report-info_label">
            {{ "measurement.doctor" | translate }}
          </div>
          <div class="report-info_ttl">{{ user.doctor }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <div class="report-info_label">
            {{ "measurement.patient" | translate }}
          </div>
          <div class="report-info_ttl">
            {{
              user?.last_name + " " + user?.first_name + " " + user?.second_name
            }}
          </div>
        </div>
        <div class="col">
          <div class="report-info_label">
            {{ "registration.birthDate" | translate }}
          </div>
          <div class="report-info_ttl">
            {{ user?.birth_date | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="col">
          <div class="report-info_label">
            {{ "measurement.age" | translate }}
          </div>
          <div class="report-info_ttl">{{ user?.birth_date | age }}</div>
        </div>
        <div class="col">
          <div class="report-info_label">
            {{ "measurement.gender" | translate }}
          </div>
          <div class="report-info_ttl">
            <ng-container *ngIf="!(reloading$ | async)">
              {{
                user?.gender | valueById : genderService.gender : "value" | lang
              }}
            </ng-container>
          </div>
        </div>
        <div class="col">
          <div class="report-info_label">
            {{ "calculation.date_refueling" | translate }}
          </div>
          <div class="report-info_ttl">
            <ng-container *ngIf="startDate">
              {{ startDate | date : "dd/MM/yyyy" }} -
            </ng-container>
            {{ endDate | date : "dd/MM/yyyy" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="user">
    <div class="report-wrapper pt-0">
      <div class="row align-items-center">
        <mat-form-field class="col-md-11" *ngIf="editConclusion && !hideBlock">
          <mat-label>{{ "calculation.comment" | translate }}</mat-label>
          <textarea
            [(ngModel)]="comment"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </mat-form-field>
        <div class="col-md-11" *ngIf="!editConclusion">
          <div class="report-info_label">
            {{ "calculation.comment" | translate }}
          </div>
          <div class="report-info_ttl">
            {{ comment }}
          </div>
        </div>
        <div class="col-md-1" *ngIf="!hideBlock">
          <button
            class="svgBtn"
            (click)="editConclusionBtn()"
            placement="top"
            ngbTooltip="{{
              (!editConclusion ? 'form.edit' : 'form.save') | translate
            }}"
          >
            <mat-icon
              svgIcon="{{ !editConclusion ? 'edit' : 'save' }}"
            ></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="report-wrapper shadow" *ngIf="!hideBlock">
      <div class="row">
        <div class="col-auto">
          <div class="report-info_ttl">
            {{ "calculation.protocol" | translate }}:
          </div>
        </div>
        <div class="col-auto">
          <div #modeContainer class="select-btn-wrap">
            <button
              type="button"
              (click)="onCheckMode($event)"
              class="select-btn"
              [ngClass]="{ active: !expanded }"
            >
              <span>{{ "calculation.simplified" | translate }}</span>
            </button>
            <button
              type="button"
              (click)="onCheckMode($event)"
              class="select-btn"
              [ngClass]="{ active: expanded }"
            >
              <span>{{ "calculation.advanced" | translate }}</span>
            </button>
          </div>
        </div>
        <div class="col-auto">
          <button
            *ngIf="
              biosignal.signalType == 'ecg' &&
              biosignal.EquipmentName == 'SOLVAIG' &&
              biosignals.length == 1
            "
            class="btn btn-danger"
            type="button"
            (click)="downloadEcgPdf()"
            placement="top"
            ngbTooltip="{{ 'calculation.download' | translate }}"
          >
            SOLVAIG PDF
          </button>
        </div>
        <div class="col">
          <button
            class="svgBtn"
            type="button"
            (click)="download()"
            placement="top"
            ngbTooltip="{{ 'calculation.print' | translate }}"
          >
            <mat-icon svgIcon="print"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="report-wrapper html2pdf__page-break pt-0">
      <div class="row">
        <div class="col">
          <div class="report-block-item">
            <div class="chart-example">
              <div class="text-example">
                {{ "calculation.normal" | translate }}
              </div>
              <div class="img-example">
                <img
                  src="../../../../assets/img/normal_{{ ACTIVE_GRAPH }}.jpg"
                  alt="Oscilogram example"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="report-block-item">
            <div class="chart">
              <app-raw-signal-chart [initData]="isInit"></app-raw-signal-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-general-conclusion-box
            [setBiosignalsRange]="biosignalsRange"
          ></app-general-conclusion-box>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="report-block-item white">
            <app-disease-chance-table
              [setBiosignalsRange]="biosignalsRange"
            ></app-disease-chance-table>
          </div>
        </div>
        <div class="col-md-6">
          <app-complex-values-cards
            class="report-block-item"
          ></app-complex-values-cards>
        </div>
      </div>
    </div>
    <div [hidden]="!expanded">
      <div class="report-wrapper shadow" *ngIf="!hideBlock">
        <div class="report-info_ttl">
          {{ "calculation.select_components" | translate }}:
        </div>
        <div #container class="select-btn-wrap">
          <button
            type="button"
            id="{{ button.sectionName }}"
            *ngFor="let button of buttonList"
            (click)="onCheckButtonClick($event, button)"
            class="select-btn"
          >
            <ng-container *ngIf="!(reloading$ | async)">
              {{ button.name | lang }}
            </ng-container>
          </button>
        </div>
      </div>
      <div #sections class="report-wrapper">
        <app-pars
          id="PARS"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.PARS"
          class="section html2pdf__page-break"
        ></app-pars>
        <app-ifsp
          id="IFSP"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.IFSP"
          class="section html2pdf__page-break"
        ></app-ifsp>
        <app-ans
          id="ANS"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.ANS"
          class="section html2pdf__page-break"
        ></app-ans>
        <app-heart
          id="Heart"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.Heart"
          class="section html2pdf__page-break"
        ></app-heart>
        <app-vessels
          id="Vessels"
          [initData]="isInit"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.Vessels"
          class="section html2pdf__page-break"
        ></app-vessels>
        <app-nervous
          id="Nervous"
          [biosignalsData]="biosignalsRange"
          *ngIf="section.Nervous"
          class="section html2pdf__page-break"
        ></app-nervous>
        <app-rufier-test-tab
          id="Rufier"
          [isInit]="isInit"
          [biosignalsValue]="allBiosignals"
          *ngIf="section.Rufier"
          class="section"
        ></app-rufier-test-tab>
      </div>
    </div>
  </ng-container>
</div>
