import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegistrationService } from '../../../../services/registration.service';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { RoleTypeEnum } from '../../model/enums/role.enum';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-role-registration',
  templateUrl: './role-registration.component.html',
  styleUrls: ['./role-registration.component.scss'],
})
export class RoleRegistrationComponent implements OnInit {
  myForm: FormGroup;
  userType: string = RoleTypeEnum.Patient;
  RoleTypeEnum: typeof RoleTypeEnum = RoleTypeEnum;
  consultantId;
  doctorId;
  roles;
  isVisiblePassword: boolean = false;
  isLoading: boolean = false;
  successRegistration: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    public roleService: RoleService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe({
      next: (params) => {
        if (params.role) {
          this.userType = params.role;
          this.roleService.setRole = this.userType;
        }
      },
    });
  }

  onSubmitForm(myForm: FormGroup): void {
    this.isLoading = true;
    this.registrationService.sendRegistrationData(myForm.value).subscribe(
      (res) => {
        this.isLoading = false;
        this.successRegistration = true;
        this.toastr.success(
          new TranslatePipe(this.authService).transform({
            ua: 'Успішно',
            en: 'Success',
          })
        );
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.isLoading = false;
        this.successRegistration = false;
      }
    );
  }
}
