import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvNewService {
  row = '';
  line = '';
  row0 = '';
  m_M_last = '';

  headers2 = ['SignalRegistrationDateTime'];
  headers4_1 = ['MoPos', 'BPpos', 'AMoPos', 'INpos', 'V'];
  headers4_2 = ['Systola', 'Diastola', 'HeartRate', 'INneg', 'PARSint'];
  headers5 = ['M1', 'M2', 'M4', 'M5', 'M8', 'M9'];
  headers_HilbertHuang_pos_neg_interval = [
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'Total04',
  ];
  headers_FFT_pos_ned_interval = [
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'Total04',
    'K',
  ];
  headers_HilbertHuang = [
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'Total04',
    'Total025',
  ];
  headers_FFT = [
    'perULF',
    'perVLF',
    'perLF',
    'perHF',
    'Total04',
    'K',
    'perBeta',
    'perAlpha',
    'perDelta',
    'perTeta',
  ];
  headers8 = [
    'pNN50pos',
    'SDSDpos',
    'MoPos',
    'AMoPos',
    'BPpos',
    'IVRpos',
    'VPRpos',
    'INpos',
    'HVRindexPos',
    'INneg',
  ];
  headers9 = ['IFSP', 'IVHH', 'ICHH', 'IVVH', 'ICVH'];

  constructor() {}

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  headers(header: Array<any>) {
    header.forEach((v) => {
      this.row += v + ';';
      this.row0 += ';';
    });
  }

  info(header: Array<any>, obj, toString?: boolean) {
    header.forEach((v) => {
      if (obj[v] != null && obj[v] != 'undefined') {
        if (toString) this.line += obj[v]?.toString().replace('.', ',') + ';';
        else this.line += obj[v] + ';';
      } else this.line += '-;';
    });
  }

  last(header: Array<any>, objs, str, toString?: boolean) {
    let array = [];
    objs.map((v) => {
      let value = v;
      str.split('.').forEach((s) => {
        value = value[s];
      });
      array.push(value);
    });
    header.forEach((v) => {
      let M = 0;
      let errCounter = 0;
      array.forEach((a) => {
        if (a[v]) M += a[v];
        else errCounter++;
      });
      let currArrLength = array.length - errCounter;
      let stdev = 0;

      array.forEach((a) => {
        if (a[v])
          stdev += (a[v] - M / currArrLength) * (a[v] - M / currArrLength);
      });
      this.m_M_last +=
        (M / currArrLength).toFixed(2)?.toString().replace('.', ',') +
        '±' +
        Math.sqrt(stdev / (currArrLength - 1) / currArrLength)
          .toFixed(2)
          ?.toString()
          .replace('.', ',') +
        ';';
    });
  }

  ConvertToCSV(objArray) {
    if (objArray?.length > 1) {
      this.m_M_last = 'M ± m;;';
      this.last(this.headers4_1, objArray, `Records.x1.Results.TimeAnalysis`);
      this.m_M_last += ';';
      this.last(
        this.headers_FFT_pos_ned_interval,
        objArray,
        `Records.x1.Results.SpectralAnalysis.FFT_pos_interval`
      );
      this.last(this.headers4_2, objArray, `Records.x1.Results.TimeAnalysis`);
      this.last(
        this.headers5,
        objArray,
        `Records.x1.Results.MorphologicalAnalysis`
      );
      this.m_M_last += ';';
      this.last(
        this.headers_FFT_pos_ned_interval,
        objArray,
        `Records.x1.Results.SpectralAnalysis.FFT_neg_interval`
      );
      this.m_M_last += ';';
      this.last(
        this.headers_FFT,
        objArray,
        `Records.x1.Results.SpectralAnalysis.FFT`
      );
      this.last(this.headers8, objArray, `Records.x1.Results.TimeAnalysis`);
      this.m_M_last += ';';
      this.last(
        this.headers_HilbertHuang,
        objArray,
        `Records.x1.Results.SpectralAnalysis.HilbertHuang`
      );
      this.m_M_last += ';';
      this.last(
        this.headers_HilbertHuang_pos_neg_interval,
        objArray,
        `Records.x1.Results.SpectralAnalysis.HilbertHuang_pos_interval`
      );
      this.m_M_last += ';';
      this.last(
        this.headers_HilbertHuang_pos_neg_interval,
        objArray,
        `Records.x1.Results.SpectralAnalysis.HilbertHuang_neg_interval`
      );
      this.last(this.headers9, objArray, `Records.x1.Results.TimeAnalysis`);
    }

    this.row0 = ';;';
    this.row = `;${this.headers2[0]};`;
    this.headers(this.headers4_1);
    this.row0 += 'FFT_pos_interval;';
    this.row += ';';
    this.headers(this.headers_FFT_pos_ned_interval);
    this.headers(this.headers4_2);
    this.headers(this.headers5);
    this.row0 += 'FFT_neg_interval;';
    this.row += ';';
    this.headers(this.headers_FFT_pos_ned_interval);
    this.row0 += 'FFT;';
    this.row += ';';
    this.headers(this.headers_FFT);
    this.headers(this.headers8);
    this.row0 += 'HilbertHuang;';
    this.row += ';';
    this.headers(this.headers_HilbertHuang);
    this.row0 += 'HilbertHuang_pos_interval;';
    this.row += ';';
    this.headers(this.headers_HilbertHuang_pos_neg_interval);
    this.row0 += 'HilbertHuang_neg_interval;';
    this.row += ';';
    this.headers(this.headers_HilbertHuang_pos_neg_interval);
    this.headers(this.headers9);

    this.line = '';
    objArray.forEach((obj) => {
      this.line += ';';
      this.info(this.headers2, obj.Records.x1.MeasurementInformation, true);
      this.info(this.headers4_1, obj.Records.x1.Results.TimeAnalysis, true);
      this.line += ';';
      this.info(
        this.headers_FFT_pos_ned_interval,
        obj.Records.x1.Results.SpectralAnalysis.FFT_pos_interval,
        true
      );
      this.info(this.headers4_2, obj.Records.x1.Results.TimeAnalysis, true);
      this.info(
        this.headers5,
        obj.Records.x1.Results.MorphologicalAnalysis,
        true
      );
      this.line += ';';
      this.info(
        this.headers_FFT_pos_ned_interval,
        obj.Records.x1.Results.SpectralAnalysis.FFT_neg_interval,
        true
      );
      this.line += ';';
      this.info(
        this.headers_FFT,
        obj.Records.x1.Results.SpectralAnalysis.FFT,
        true
      );
      this.info(this.headers8, obj.Records.x1.Results.TimeAnalysis, true);
      this.line += ';';
      this.info(
        this.headers_HilbertHuang,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang,
        true
      );
      this.line += ';';
      this.info(
        this.headers_HilbertHuang_pos_neg_interval,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang_pos_interval,
        true
      );
      this.line += ';';
      this.info(
        this.headers_HilbertHuang_pos_neg_interval,
        obj.Records.x1.Results.SpectralAnalysis.HilbertHuang_neg_interval,
        true
      );
      this.info(this.headers9, obj.Records.x1.Results.TimeAnalysis, true);
      this.line += '\r\n';
    });

    return (
      this.row0 +
      '\r\n' +
      this.row +
      '\r\n' +
      this.line +
      this.m_M_last +
      '\r\n'
    );
  }
}
