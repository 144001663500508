<table id="myTable" class="expert-table-style-2">
  <thead>
    <tr>
      <th>{{ "calculation.indicator" | translate }}</th>

      <th>{{ "calculation.chss" | translate }}</th>
      <th>{{ "calculation.pars" | translate }}</th>
      <th>{{ "calculation.IFVP" | translate }}І</th>
      <th>{{ "calculation.af" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <!-- <tr *ngFor="let element of tableData">
        <td>{{ element.indicator }}</td>
        <td>{{ element.Systola }}</td>
        <td>{{ element.Diastola }}</td>
        <td>{{ element.HeartRate }}</td>
        <td>{{ element.PARSint }}</td>
        <td>{{ element.IFSP }}</td>
        <td>{{ element.Ms }}</td>
        <td>{{ element.ICHH }}</td>
        <td>{{ element.ICVH }}</td>
        <td>{{ element.IVHH }}</td>
        <td>{{ element.IVVH }}</td>
        <td>{{ element.MAP }}</td>
        <td>{{ element.PP }}</td>
      </tr> -->
  </tbody>
</table>
