<div class="form-container card-box" *ngIf="!role">
  <div class="row">
    <div class="col">
      <h1 class="text-center">{{ "registration.title" | translate }}</h1>
      <p>{{ "registration.text" | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="select-role-wrap">
        <div
          class="item"
          [ngClass]="{ active: inner?.role === RoleTypeEnum.Patient }"
          (click)="selectRole(RoleTypeEnum.Patient)"
        >
          {{ "form.patient" | translate }}
        </div>
        <div
          class="item"
          [ngClass]="{ active: inner?.role === RoleTypeEnum.Doctor }"
          (click)="selectRole(RoleTypeEnum.Doctor)"
        >
          {{ "measurement.doctor" | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group text-center">
        <button
          class="btn btn-primary fill"
          [disabled]="!inner?.role"
          type="button"
          (click)="nextStep()"
        >
          {{ "registration.next" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<router-outlet *ngIf="role"></router-outlet>
