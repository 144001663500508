<form [formGroup]="aoForm">
  <mat-form-field class="col-md-12 mt-3">
    <mat-label>{{ "measurement.name_group" | translate }}</mat-label>
    <input matInput formControlName="measurementGroup" id="group" />
  </mat-form-field>
  <mat-form-field class="col-md-4">
    <mat-label>
      {{ "measurement.type_equipment" | translate }}
    </mat-label>
    <mat-select
      required
      formControlName="EquipmentName"
      (selectionChange)="chooseExactly($event.value)"
    >
      <mat-option>-</mat-option>
      <mat-option
        *ngFor="let elem of shortDataService.equipment_ao"
        [value]="elem.value"
      >
        {{ elem | lang }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-md-4">
    <mat-label>
      {{ "measurement.select_phase" | translate }}
    </mat-label>
    <mat-select
      required
      formControlName="phase"
      (selectionChange)="chooseFases($event.value)"
    >
      <mat-option>-</mat-option>
      <mat-option *ngFor="let elem of phase" [value]="elem.id">
        {{ elem.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-md-4">
    <mat-label>
      {{ "measurement.type_phase" | translate }}
    </mat-label>
    <input
      matInput
      type="text"
      readonly
      [value]="
        aoForm.get('phaseType').value | valueById : shortDataService.phases
      "
      formControlName="phaseType"
    />
  </mat-form-field>
  <mat-form-field class="col-md-4" *ngIf="oscillogram">
    <mat-label>
      {{ "measurement.select_oscillogram" | translate }}
    </mat-label>
    <mat-select formControlName="signalType" required>
      <mat-option>-</mat-option>
      <mat-option *ngFor="let elem of oscillograms" [value]="elem.id">
        {{ elem.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
