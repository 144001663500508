import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import { BiosignalsRange } from 'src/app/models/biosignals-range';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-measurement-table-ao',
  templateUrl: './measurement-table-ao.component.html',
  styleUrls: ['./measurement-table-ao.component.scss'],
})
export class MeasurementTableAoComponent implements OnInit {
  @ViewChildren('trInTable') trInTable: QueryList<any>;
  tableData = [];
  myComment: string;
  tempId;
  currentBiosignal: Biosignal;

  @Input() blockFollowingUncheck: boolean = true;
  expandRow: boolean = false;
  allChecked = true;

  _biosignals: Biosignal[];
  @Input() public set biosignals(v) {
    if (v) {
      this._biosignals = v;
      this.biosignalsRange = new BiosignalsRange(
        'MeasurementTableComponent',
        v,
        0,
        v.length - 1,
        new Set<string>()
      );
      this.updateTableData();
    }
  }
  public get biosignals() {
    return this._biosignals;
  }

  _biosignalsRange: BiosignalsRange;
  @Input() public set biosignalsRange(v) {
    if (v) {
      if (v?.origin !== 'MeasurementTableComponent') {
        this._biosignalsRange = v;
        this.currentBiosignal = null;
        for (let i = 0; i < this.biosignals?.length; i++) {
          this.biosignals[i].checked = i >= v.beginIndex && i <= v.endIndex;
        }
      }
    }
  }
  public get biosignalsRange() {
    return this._biosignalsRange;
  }

  masterSelected: boolean;
  checkedList: any;

  constructor(
    private measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {}

  ngOnInit(): void {}

  selectRowByTable(event, id): void {
    const trEl = event.target;
    if (trEl.localName === 'td') {
      if (trEl.parentNode.classList.contains('checkedTR')) {
      } else {
        this.tempId = id;
        this.measurementsDataUpdateService.changeSelectedMeasurement(
          this.biosignals,
          id
        );
        const type = this.biosignals.find(
          (biosignal) => biosignal._id == id
        ).signalType;
        this.measurementsDataUpdateService.changeSelectedOscillogram(id, type);
        const trList = trEl.parentNode.parentNode.children;
        for (const tr of trList) {
          tr.classList.remove('checkedTR');
        }
      }
      trEl.parentNode.classList.toggle('checkedTR');
    }
  }

  checkUncheckAll() {
    for (var i = 0; i < this.biosignals.length; i++)
      this.biosignals[i].checked = this.masterSelected;
    if (!this.masterSelected) {
      this.biosignals[this.biosignals.length - 1].checked = true;
      this.measurementsDataUpdateService.changeMeasurementsRange(
        'MeasurementTableComponent',
        this.biosignals,
        this.biosignals.length - 1,
        this.biosignals.length - 1,
        new Set<string>()
      );
    } else if (this.masterSelected)
      this.measurementsDataUpdateService.changeMeasurementsRange(
        'MeasurementTableComponent',
        this.biosignals,
        0,
        this.biosignals.length - 1,
        new Set<string>()
      );

    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.biosignals.every(function (item: any) {
      return item.checked == true;
    });
    if (this.masterSelected) {
      this.measurementsDataUpdateService.changeMeasurementsRange(
        'MeasurementTableComponent',
        this.biosignals,
        0,
        this.biosignals.length - 1,
        new Set<string>()
      );
      return;
    }
    for (let i = 0; i < this.biosignals.length; i++) {
      if (this.biosignals[i].checked) {
        this.biosignalsRange.beginIndex = i;
        break;
      }
    }
    for (let i = this.biosignals.length - 1; i >= 0; i--) {
      if (this.biosignals[i].checked) {
        this.biosignalsRange.endIndex = i;
        break;
      }
    }
    this.biosignalsRange.excludedBiosignalsIds = new Set<string>();
    for (
      let i = this.biosignalsRange.beginIndex + 1;
      i <= this.biosignalsRange.endIndex - 1;
      i++
    ) {
      if (!this.biosignals[i].checked) {
        this.biosignalsRange.excludedBiosignalsIds.add(this.biosignals[i]._id);
      }
    }
    this.measurementsDataUpdateService.changeMeasurementsRange(
      'MeasurementTableComponent',
      this.biosignals,
      this.biosignalsRange.beginIndex,
      this.biosignalsRange.endIndex,
      this.biosignalsRange.excludedBiosignalsIds
    );
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.biosignals.length; i++)
      if (this.biosignals[i].checked) this.checkedList.push(this.biosignals[i]);
    this.checkedList = JSON.parse(JSON.stringify(this.checkedList));
  }

  updateTableData(): void {
    this.tableData = [];
    this.biosignals.forEach((biosignal) => {
      const date = new Date(biosignal.SignalRegistrationDateTime);
      const name =
        date.getDate().toString().padStart(2, '0') +
        '.' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        date.getFullYear().toString() +
        ' ' +
        date.getHours().toString().padStart(2, '0') +
        ':' +
        date.getMinutes().toString().padStart(2, '0') +
        ':' +
        date.getSeconds().toString().padStart(2, '0');
      const tempData = {
        id: biosignal._id,
        date: name,
        name: biosignal.fileName,
        systola: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Systola : 0,
        diastola: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Diastola : 0,
        heartRate: biosignal.TimeAnalysis
          ? biosignal.TimeAnalysis.HeartRate
          : 0,
        pars: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.PARSint : 0,
        ifsp: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.IFSP : 0,
        msr: biosignal.MorphologicalAnalysis
          ? biosignal.MorphologicalAnalysis.Ms
          : 0,
        comment: biosignal.comment ? biosignal.comment : '',
        biosignal,
        computeStatus: biosignal.ComputeStatus,
        status: biosignal.Status,
        signalType: biosignal.signalType,
      };
      tempData.biosignal.checked = true;
      this.tableData.push(tempData);
    });

    this.getCheckedItemList();
  }

  sortTable(n): void {
    let table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById('myTable');
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName('TR');
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];
        if (dir == 'asc') {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == 'desc') {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  }

  onBlurMethod(comment, id): void {
    const commentDB = { comment };
    this.measurementsDataUpdateService.setComment(commentDB, id).subscribe();
  }

  expand() {
    this.expandRow = !this.expandRow;
  }
}
