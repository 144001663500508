import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { OscillogramData } from 'src/app/models/oscillogram-data';

@Component({
  selector: 'app-ao-raw-signal-chart',
  templateUrl: './ao-raw-signal-chart.component.html',
  styleUrls: ['./ao-raw-signal-chart.component.scss'],
})
export class AoRawSignalChartComponent implements OnInit {
  @Input() set setOscillogramData(value: OscillogramData) {
    if (value) {
      this.oscillogramData = value;
      this.update();
    }
  }
  oscillogramData: OscillogramData;
  rawSignalData: any;
  yScaleMin;
  yScaleMax;
  xScaleMax;
  yScaleNormal;
  view: any = true;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.view = false;
    // setTimeout(() => {
    //   this.view = true;
    // }, 0);
  }
  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  update(): void {
    if (this.oscillogramData) {
      const timePoints: number[] = this.oscillogramData.timePoints;
      const valuePoints: number[] = this.oscillogramData.valuePoints;
      const series = [];

      this.yScaleMin = Math.min(...valuePoints);
      this.yScaleMax = Math.max(...valuePoints);
      this.xScaleMax = Math.max(...timePoints);
      this.yScaleNormal = Math.max(
        Math.abs(this.yScaleMin),
        Math.abs(this.yScaleMax)
      );

      for (let i = 0; i < timePoints.length; i += 1) {
        series.push({
          name: timePoints[i],
          value: valuePoints[i],
        });
      }
      this.rawSignalData = [
        {
          name: 'signal1',
          series,
        },
      ];
    }
  }

  formatAxisTickDateY = (i) => {
    if (Math.trunc(this.yScaleNormal / 1000) > 0) return (i / 1000).toFixed(1);
    else return i.toFixed(1);
  };
}
