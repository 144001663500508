import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-parse-dialog',
  templateUrl: './parse-dialog.component.html',
  styleUrls: ['./parse-dialog.component.scss'],
})
export class ParseDialogComponent implements OnInit {
  @Input() public data;
  equipments = new SelectionModel<number>(true);
  allCheck: boolean = true;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.data.forEach((element) => {
      this.equipments.select(element);
    });
  }

  submit() {
    this.modal.close(this.equipments.selected);
  }

  close() {
    this.modal.close();
  }

  selectCheckBox(): void {
    if (
      this.equipments.selected.length !== 3 &&
      this.equipments.selected.length !== 0
    ) {
      this.data.forEach((item) => {
        this.equipments.select(item);
      });
    }
    this.data.forEach((item) => {
      this.equipments.toggle(item);
    });
  }

  isChecked(item) {
    if (this.equipments.selected.indexOf(item) !== -1) {
      return true;
    } else {
      return false;
    }
  }
}
