import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Doctor } from 'src/app/models/doctor';
import { MedicalInstitution } from 'src/app/models/medical-institution';
import { AgePipe } from 'src/app/pipes/age.pipe';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { ToastrMessageService } from 'src/app/services/dictionaries/toastr-message.service';
import { PatientUpdateService } from 'src/app/services/patient-update.service';
import { StaffService } from 'src/app/services/staff.service';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';
import { PatientService } from '../../measurements-data/services/patient.service';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  providers: [DatePipe, AgePipe],
})
export class PatientComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  myForm: FormGroup = new FormGroup({});
  user;
  isEdit: boolean = false;
  consultants: Array<Doctor> = [];
  doctors: Array<Doctor> = [];
  Role: typeof RoleTypeEnum = RoleTypeEnum;
  userType;

  medicalInstitutions: MedicalInstitution[] = [];
  formData: FormData;
  file;
  uploadPhoto: any = '';
  photoEmitter = new BehaviorSubject<any>(this.uploadPhoto);
  isVisiblePassword: boolean = false;
  isPatient: boolean = false;
  isAdmin: boolean = false;
  isDisabled: boolean = false;
  isLoading: boolean = false;

  photoExist: boolean = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private staffService: StaffService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private agePipe: AgePipe,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private authservice: AuthService,
    public genderService: GenderService,
    private toastrMessageService: ToastrMessageService,
    public cd: ChangeDetectorRef,
    public patientService: PatientService,
    public patientUpdateService: PatientUpdateService,
    private router: Router
  ) {
    super(cd);
    this.isAdmin = this.auth.isAdmin();
  }

  ngOnInit(): void {
    this.initForm();
    this.patientService.patient$.subscribe((resp) => {
      if (resp && this.router.url.slice(0, 21) == '/home/profile/patient')
        this.router.navigate(['/home/profile/patient', resp]);
      this.getPatient(resp?._id);
    });

    this.route.params.pipe(untilComponentDestroyed(this)).subscribe({
      next: (params) => {
        if (params.id) this.getPatient(params.id);
      },
    });
    this.auth.language$
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => this.changLang());
  }

  ngOnDestroy() {
    this.photoEmitter.next('');
    this.photoExist = false;
  }

  getPatient(id?) {
    this.photoEmitter = new BehaviorSubject<any>('');
    if (id) {
      this.patientUpdateService
        .get(id)
        .pipe(untilComponentDestroyed(this))
        .subscribe((resp) => {
          this.user = resp;
          this.dataUser();
          this.getPhotoPatient(id);
        });
    } else {
      this.user = this.auth.getUser();
      this.getPhoto();
      this.dataUser();
    }
  }

  dataUser() {
    if (this.user) {
      this.myForm.patchValue(this.user);
      this.age.patchValue(this.agePipe.transform(this.user.birth_date));
      this.role.patchValue(this.auth.getRole());
      this.staffService
        .getDoctors()
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => {
          this.consultants = data.filter((i) => (i.isConsultant ? i : null));
          this.doctors = data.filter((i) => (i.isConsultant ? null : i));
          const c = this.consultants
            .filter((c) => (this.doctorsForm.value.includes(c._id) ? c : null))
            .map((data) => data._id);
          const d = this.doctors
            .filter((d) => (this.doctorsForm.value.includes(d._id) ? d : null))
            .map((data) => data._id);
          this.consultantId.setValue(c.length ? c[0] : '');
          this.doctorId.setValue(d.length ? d[0] : '');
        });
    }

    this.staffService
      .getMedicalInstitutions()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: any) => {
        this.medicalInstitutions = data;
      });
  }

  initForm() {
    this.myForm = this.fb.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      second_name: [''],
      birth_date: ['', Validators.required],
      gender: [''],
      email: ['', [Validators.required, Validators.email]],
      phones: this.fb.array([this.createPhoneFormGroup()]),
      doctors: [''],
      medicalInstitution: '',
      login: ['', Validators.required],
      password: ['', Validators.required],
      age: [''],
      role: [''],
      withings: [''],
      // avatar: ['']

      doctorId: [''],
      consultantId: [''],
    });
  }

  createPhoneFormGroup() {
    return this.fb.group({
      // type: [''],
      number: [''],
    });
  }

  get birth_date(): FormControl {
    return this.myForm.get('birth_date') as FormControl;
  }

  get doctorsForm(): FormControl {
    return this.myForm.get('doctors') as FormControl;
  }

  get password(): FormControl {
    return this.myForm.get('password') as FormControl;
  }

  get role(): FormControl {
    return this.myForm.get('role') as FormControl;
  }
  get age(): FormControl {
    return this.myForm.get('age') as FormControl;
  }
  get phones(): FormArray {
    return (<FormArray>this.myForm.get('phones')) as FormArray;
  }

  get doctorId(): FormControl {
    return this.myForm.get('doctorId') as FormControl;
  }

  get consultantId(): FormControl {
    return this.myForm.get('consultantId') as FormControl;
  }

  getPhoto() {
    this.photoExist = false;
    this.auth.photo$
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);
          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }

  getPhotoPatient(id) {
    this.photoExist = false;
    this.patientService
      .getPhoto(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.auth.photo = resp;
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);
          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }

  onSelectFiles(e: any) {
    this.file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.uploadPhoto = reader.result;
      this.photoEmitter.next(this.uploadPhoto);
      this.isDisabled = true;
    };
  }

  edit() {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.uploadPhoto = null;
    }
  }

  changePassword() {
    const modal = this.dialog.open(ChangePasswordDialogComponent, {});
    modal.componentInstance.role = RoleTypeEnum.Patient;
    modal.componentInstance.password = this.user.password;
    modal.componentInstance.update
      .pipe(untilComponentDestroyed(this))
      .subscribe((password) => {
        modal.close();
        this.password.setValue(password);
        this.updatePassword(this.password.value);
      });
    modal.componentInstance.close
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => {
        modal.close();
      });
  }

  updatePassword(password: string) {
    this.patientUpdateService
      .updatePassword(this.user._id, { password: password })
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res) => {
          this.toastr.success(
            new TranslatePipe(this.authservice).transform(
              this.toastrMessageService.password_change_success
            )
          );
          this.auth.setUser(null, res);
          this.user = this.auth.getUser();
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error('error!');
          this.password.setValue(this.user.password);
        }
      );
  }

  save() {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.translate
        .get(['alerts'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((t) => {
          this.toastr.warning(t.alerts.warning_1);
        });
      return;
    }
    this.isLoading = true;
    const doctors = this.consultantId.value?.concat(this.doctorId.value);

    this.doctorsForm.patchValue(
      doctors == null ||
        doctors == 'undefined' ||
        doctors == undefined ||
        !doctors
        ? []
        : doctors
    );

    delete this.myForm.value['doctorId'];
    delete this.myForm.value['consultantId'];

    this.patientUpdateService
      .update(this.user._id, this.myForm.value)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (res) => {
          if (res) {
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_5);
              });
            this.auth.setUser(null, res);
            this.isEdit = false;
            this.getPatient(res._id);
            this.uploadPhoto = null;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error('error!');
          this.getPatient();
        }
      );
  }

  photoUpdate() {
    if (this.file) {
      this.isLoading = true;
      this.formData = new FormData();
      this.formData.append('photo', this.file);
      this.patientUpdateService
        .postPhoto(this.user._id, this.formData)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (resp) => {
            this.getPhotoPatient(this.user._id);
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_11);
              });
            this.isDisabled = false;
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.toastr.error('error!');
          }
        );
    }
  }
}
