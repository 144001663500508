import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthService } from '../services/auth/auth.service';
import { StaffPatientsService } from '../services/staff-patients/staff-patients.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StaffPatientsGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(
    private staffPatientsService: StaffPatientsService,
    private router: Router,
    private authService: AuthService
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.staffPatientsService.confirm) {
      let isBoss = confirm(
        new TranslatePipe(this.authService).transform({
          ua: 'Скасувати зміни?',
          en: 'Cancel changes?',
          pl: '',
        })
      );
      return isBoss ? true : false;
    } else {
      return true;
    }
  }
}
