<div class="staff">
  <div class="staff-item">
    <div class="form-check">
      <input class="form-check-input" type="radio" [value]="consultant._id" [(ngModel)]="doctorId"
        (change)="checkConsultant()" id="flexCheckDefault">
    </div>
    <div class="photo">
      <img width="120" height="120" alt=""
        [src]="consultant.photoStaff && consultant.photoStaff.length ? consultant.photoStaff : '/assets/img/noavatar.png'" />
    </div>
    <div class="label">
      <h2>{{consultant.last_name}} {{consultant.first_name}}
        {{consultant.second_name}}</h2>
      <p *ngIf="consultant.start_date">{{'form.start_work' | translate}}:
        <strong>{{consultant.start_date | date: 'dd/MM/yy'}}</strong>
      </p>
    </div>
    <button class="ml-1" [routerLink]="'edit/' + consultant._id" class="addBtn" ngbTooltip="{{'form.edit' | translate}}"
      placement="left">
      <i class="bi bi-pencil"></i>
    </button>
  </div>
  <div class="staff-more-info" *ngIf="moreInfo">
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="consultant.educations && consultant.educations.length">
          <div class="row">
            <div class="col">
              <strong>{{'form.education' | translate}}</strong>
            </div>
          </div>
          <div class="row" *ngFor="let item of consultant.educations">
            <div class="col">
              <p>
                <ng-container *ngIf="item.institution_name">
                  {{'form.name_education_institution' | translate}}:
                  <strong>{{item.institution_name}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.degree">
                  {{'form.degree' | translate}}: <strong>{{item.degree | lang}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.speciality">
                  {{'form.specialization' | translate }}: <strong>{{item.speciality}}</strong>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="consultant.qualifications && consultant.qualifications.length">
          <div class="row">
            <div class="col">
              <strong>{{'form.qualification' | translate}}</strong>
            </div>
          </div>
          <div class="row" *ngFor="let item of consultant.qualifications">
            <div class="col">
              <p>
                <ng-container *ngIf="item.institution_name">
                  {{'form.name_education_institution' | translate}}:
                  <strong>{{item.institution_name}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.speciality">
                  {{'form.specialization' | translate}}: <strong>{{item.speciality}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.additional_info">
                  {{'form.additional_info' | translate}}: <strong>{{item.additional_info | lang}}</strong>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="consultant.specialities && consultant.specialities.length">
          <div class="row">
            <div class="col">
              <strong>{{'form.specialization' | translate}}</strong>
            </div>
          </div>
          <div class="row" *ngFor="let item of consultant.specialities">
            <div class="col">
              <p>
                <ng-container *ngIf="item.qualification_type">
                  {{'form.qualification_type' | translate}}:
                  <strong>{{item.qualification_type}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.attestation_name">
                  {{'form.attestation_name' | translate}}: <strong>{{item.attestation_name}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.speciality">
                  {{'form.speciality' | translate}}: <strong>{{item.speciality | lang}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.level">
                  {{'form.level' | translate}}: <strong>{{item.level | lang}}</strong>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="consultant.science_degree && consultant.science_degree.length">
          <div class="row">
            <div class="col">
              <strong>{{'form.degree' | translate}}</strong>
            </div>
          </div>
          <div class="row" *ngFor="let item of consultant.science_degree">
            <div class="col">
              <p>
                <ng-container *ngIf="item.institution_name">
                  {{'form.name_education_institution' | translate}}:
                  <strong>{{item.institution_name}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.degree">
                  {{'form.degree' | translate}}: <strong>{{item.degree}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.speciality">
                  {{'form.speciality' | translate}}: <strong>{{item.speciality | lang}}</strong>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="consultant.courses && consultant.courses.length">
          <div class="row">
            <div class="col">
              <strong>{{'form.training_courses' | translate}}</strong>
            </div>
          </div>
          <div class="row" *ngFor="let item of consultant.courses">
            <div class="col">
              <p>
                <ng-container *ngIf="item.name_course">
                  {{'form.name_course' | translate}}: <strong>{{item.name_course}}</strong>,
                </ng-container>
                <ng-container *ngIf="item.points">
                  {{'form.points' | translate}}: <strong>{{item.points}}</strong>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-6" *ngIf="schedule">
        <div class="row">
          <div class="col">
            <strong>{{'form.work_schedule' | translate}}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row mt-1">
              <div class="col-md">
                <div class="row mt-2">
                  <div class="col-md">
                    <p>{{'form.days_of_week' | translate}}: <strong class="ml-1"
                        *ngFor="let day of schedule.dayOfWeekWorkHours | weekDay">
                        <span>{{day | lang}}, </span>
                      </strong></p>
                    <p>{{'form.reception_times' | translate}} <strong>{{schedule.time_from}}</strong>
                      {{'form.to' | translate}} <strong>{{schedule.time_to}}</strong>
                    </p>
                    <p>{{'form.lunch_break_from' | translate}} <strong>{{schedule.lunch_from}}</strong>
                      {{'form.to' | translate}} <strong>{{schedule.lunch_to}}</strong>
                    </p>
                    <p>{{'form.vacation' | translate}} {{'form.from' | translate}} <strong>{{schedule.vacation_date_from | date: 'dd/MM/yy'}}</strong>
                      {{'form.to' | translate}} <strong>{{schedule.vacation_date_to | date: 'dd/MM/yy'}}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="staff-btn">
    <div class="details" placement="top" ngbTooltip="Розгорнути" *ngIf="!moreInfo" (click)="moreInfo = true">
      <i class="bi bi-arrow-down"></i>
      {{'form.read_more' | translate}}
      <i class="bi bi-arrow-down"></i>
    </div>
    <div *ngIf="moreInfo" class="details" placement="top" ngbTooltip="Згорнути" (click)="moreInfo = false">
      <i class="bi bi-arrow-up"></i>
      {{'form.read_more' | translate}}
      <i class="bi bi-arrow-up"></i>
    </div>
  </div>
</div>
