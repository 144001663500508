import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectService } from './subject.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends SubjectService {
  constructor() {
    super();
  }

  public get role$(): Observable<any> {
    return this.getSubject$('role');
  }

  public set setRole(value: string) {
    this.getSubject('role').next(value);
  }
}
