import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NAV_MENU_DOCTOR } from 'src/app/services/dictionaries/constants/nav-menu';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.scss'],
})
export class DoctorProfileComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  RoleType: typeof RoleTypeEnum = RoleTypeEnum;
  showNav: boolean = false;
  navMenu: Array<any> = [];
  active: number = 0;
  doctorId: string;

  constructor(
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    public router: Router
  ) {
    super(cd);
    this.navMenu = NAV_MENU_DOCTOR;
  }

  ngOnInit(): void {
    this.authService.language$.subscribe((v) => {
      this.changLang();
    });
    const url = this.router.url;
    const fragment = url.split('/');
    switch (fragment[fragment.length - 1]) {
      case 'patients':
        this.active = 0;
        break;
      case 'view':
        this.active = 1;
        break;
      case 'schedule':
        this.active = 2;
        break;
    }
  }

  navigateTo() {
    if (localStorage.getItem('doctorProfileId')) {
      this.doctorId = localStorage.getItem('doctorProfileId');
      this.router.navigate([
        '/home/profile/staff/patients',
        { id: this.doctorId },
      ]);
    }
  }

  ngOnDestroy(): void {
    // localStorage.removeItem('doctorProfileId');
  }
}
