import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class SvgModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'error-wrapper',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icons/icon-7.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'icon-lock',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icons/icon-2.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'icon-person',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/icons/icon-1.svg'
      )
    );

    matIconRegistry.addSvgIcon(
      'close',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/cross.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'tick',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/tick.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'telegram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/telegram_logo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'copy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/copy.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'print',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/print.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/home.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ion_calendar-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'attach',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ion_attach.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'save',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/save.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/trash.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'spiner',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/spiner.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/edit.svg'
      )
    );
  }
}
