import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomLinerChartServiceService {
  line_btn: any = {
    ao: [
      [
        {
          class: 'button-1-green',
          id: 'ats_cb',
          line_name: 'systola',
          label: 'calculation.ats',
        },
        {
          class: 'button-1-blue',
          id: 'atd_cb',
          line_name: 'diastola',
          label: 'calculation.atd',
        },
        {
          class: 'button-1-orange',
          id: 'chss_cb',
          line_name: 'heartRate',
          label: 'calculation.chss',
        },
      ],
      [
        {
          class: 'button-1-red',
          id: 'pars_cb',
          line_name: 'pars',
          label: 'calculation.pars_ao',
        },
        {
          class: 'button-1-purple',
          id: 'ifsp_cb',
          line_name: 'ifsp',
          label: 'calculation.IFVP',
        },
        {
          class: 'button-1-green',
          id: 'msr_cb',
          line_name: 'msr',
          label: 'calculation.msr',
        },
      ],
      [
        {
          class: 'button-1-green',
          id: 'heart_vasc_cb',
          line_name: 'heartDiseases',
          label: 'calculation.card_vasc',
        },
        {
          class: 'button-1-purple',
          id: 'lungs_cb',
          line_name: 'lungDiseases',
          label: 'calculation.pulmonary',
        },
        {
          class: 'button-1-blue',
          id: 'psych_cb',
          line_name: 'psychicalDiseases',
          label: 'calculation.mental',
        },
        {
          class: 'button-1-darkblue',
          id: 'heart_d_cb',
          line_name: 'arrhythmiaDiseases',
          label: 'calculation.arryhythmia',
        },
      ],
      [
        {
          class: 'button-1-green',
          id: 'ats_cb',
          line_name: 'systola',
          label: 'calculation.ats',
        },
        {
          class: 'button-1-blue',
          id: 'atd_cb',
          line_name: 'diastola',
          label: 'calculation.atd',
        },
      ],
    ],
    ecg: [
      [
        {
          class: 'button-1-red',
          id: 'pars_cb',
          line_name: 'pars',
          label: 'calculation.pars',
        },
        {
          class: 'button-1-purple',
          id: 'ifsp_cb',
          line_name: 'ifsp',
          label: 'calculation.IFVP',
        },
        {
          class: 'button-1-green',
          id: 'msr_cb',
          line_name: 'msr',
          label: 'calculation.msr',
        },
      ],
    ],
    wp: [
      [
        {
          class: 'button-1-red',
          id: 'pars_cb',
          line_name: 'pars',
          label: 'calculation.pars',
        },
        {
          class: 'button-1-purple',
          id: 'ifsp_cb',
          line_name: 'ifsp',
          label: 'calculation.IFVP',
        },
        {
          class: 'button-1-green',
          id: 'msr_cb',
          line_name: 'msr',
          label: 'calculation.msr',
        },
      ],
    ],
    cp: [
      [
        {
          class: 'button-1-red',
          id: 'pars_cb',
          line_name: 'pars',
          label: 'calculation.pars',
        },
        {
          class: 'button-1-purple',
          id: 'ifsp_cb',
          line_name: 'ifsp',
          label: 'calculation.IFVP',
        },
        {
          class: 'button-1-green',
          id: 'msr_cb',
          line_name: 'msr',
          label: 'calculation.msr',
        },
      ],
    ],
  };

  graph_y_label: any = {
    ao: [
      'calculation.chart_SDP_PAGE_1_label',
      'calculation.chart_SDP_PAGE_2_label',
      'calculation.chart_SDP_PAGE_2_label',
    ],
    ecg: ['calculation.chart_SDP_PAGE_2_label'],
    wp: ['calculation.chart_SDP_PAGE_2_label'],
    cp: ['calculation.chart_SDP_PAGE_2_label'],
  };

  showDots(chart, color) {
    if (chart) {
      let index = 0;
      const paths =
        chart?.chartElement.nativeElement.getElementsByClassName('line-series');
      setTimeout(() => {
        for (let path of paths) {
          const pathElement = path.getElementsByTagName('path')[0];
          const pathAttributes = {
            'marker-start': `url(#dot${index})`,
            'marker-mid': `url(#dot${index})`,
            'marker-end': `url(#dot${index})`,
          };
          this.createMarker(chart, color[index], index);
          this.setAttributes(pathElement, pathAttributes);
          index += 1;
        }
      }, 100);
    }
  }

  createMarker(chart, color, index) {
    const svg = chart?.chartElement.nativeElement.getElementsByTagName('svg');
    var marker = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'marker'
    );
    var circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
    );
    document.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'circle');

    svg[0].getElementsByTagName('defs')[0].append(marker);

    marker.append(circle);
    const m = svg[0].getElementsByTagName('marker')[index];
    const c = svg[0].getElementsByTagName('circle')[index];

    const markerAttributes = {
      id: `dot${index}`,
      viewBox: '0 0 10 10',
      refX: 5,
      refY: 5,
      markerWidth: 5,
      markerHeight: 5,
    };

    const circleAttributes = {
      cx: 5,
      cy: 5,
      r: 5,
      fill: color,
    };
    m.append(circle);

    this.setAttributes(m, markerAttributes);
    this.setAttributes(c, circleAttributes);
  }

  /**
   * set multiple attributes
   */
  setAttributes(element, attributes) {
    for (const key in attributes) {
      element.setAttribute(key, attributes[key]);
    }
  }

  removeDots(chart) {
    if (chart) {
      const svg = chart?.chartElement.nativeElement.getElementsByTagName('svg');
      if (svg[0].getElementsByTagName('marker'))
        Array.prototype.slice
          .call(svg[0].getElementsByTagName('marker'))
          .slice()
          .forEach((element) => {
            element.remove();
          });
    }
  }
}
