<div class="modal-header">
  <h2 class="modal-title" id="exampleModalLabel">
    {{ "form.change_password" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="close.emit()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-container">
    <form [formGroup]="myForm">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="form-group">
            <div class="input-group input-group-icon form-floating">
              <input
                [type]="isVisiblePassword[0] ? 'text' : 'password'"
                class="form-control"
                formControlName="old_password"
                placeholder="{{ 'form.old_password' | translate }}"
              />
              <label class="without-icon-left">
                {{ "form.old_password" | translate }}
              </label>
              <span
                class="pass-visible"
                (click)="isVisiblePassword[0] = !isVisiblePassword[0]"
              >
                <i
                  class="bi"
                  [ngClass]="isVisiblePassword[0] ? 'bi-eye' : 'bi-eye-slash'"
                ></i>
              </span>
            </div>
            <mat-error
              *ngIf="
                myForm.controls['old_password'].touched &&
                myForm.controls['old_password'].value !== password
              "
            >
              {{ "form.incorrect_password" | translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="form-group">
            <div class="input-group input-group-icon form-floating">
              <input
                [type]="isVisiblePassword[1] ? 'text' : 'password'"
                class="form-control"
                formControlName="password"
                placeholder="{{ 'login.password' | translate }}"
              />
              <label class="without-icon-left">
                {{ "form.new_password" | translate }}
              </label>
              <span
                class="pass-visible"
                (click)="isVisiblePassword[1] = !isVisiblePassword[1]"
              >
                <i
                  class="bi"
                  [ngClass]="isVisiblePassword[1] ? 'bi-eye' : 'bi-eye-slash'"
                ></i>
              </span>
            </div>
            <mat-error
              *ngIf="myForm.get('password').hasError('passwordStrength')"
            >
              {{ myForm.get("password").errors["passwordStrength"] | lang }}
            </mat-error>
            <mat-hint *ngIf="myForm.get('password').hasError('required')">
              {{ "registration.password_validation" | translate }}
            </mat-hint>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="form-group">
            <div class="input-group input-group-icon form-floating">
              <input
                [type]="isVisiblePassword[2] ? 'text' : 'password'"
                class="form-control"
                formControlName="password_confirmation"
                placeholder="{{ 'form.password_confirmation' | translate }}"
              />
              <label class="without-icon-left">
                {{ "form.password_confirmation" | translate }}
              </label>
              <span
                class="pass-visible"
                (click)="isVisiblePassword[2] = !isVisiblePassword[2]"
              >
                <i
                  class="bi"
                  [ngClass]="isVisiblePassword[2] ? 'bi-eye' : 'bi-eye-slash'"
                ></i>
              </span>
            </div>
            <mat-error
              *ngIf="
                myForm.value['password'] !=
                myForm.value['password_confirmation']
              "
            >
              {{ "form.passwords_no_matched" | translate }}
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="close.emit()"
    >
      {{ "login.cancel" | translate }}
    </button>
    <button type="button" (click)="onSubmit()" class="btn btn-primary">
      {{ "form.save" | translate }}
    </button>
  </div>
</div>
