import { Component, Input, OnInit } from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { PatientService } from '../../../measurements-data/services/patient.service';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-patient-photo',
  templateUrl: './patient-photo.component.html',
  styleUrls: ['./patient-photo.component.scss'],
})
export class PatientPhotoComponent extends OnDestroyMixin implements OnInit {
  @Input() typeUser;
  @Input() set patientId(value) {
    if (value) {
      this.getPhotoPatient(value);
    }
  }

  photoExist: boolean = false;
  photoEmitter = new BehaviorSubject<any>('');

  constructor(
    public patientService: PatientService,
    public sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {}

  getPhotoPatient(id) {
    this.photoExist = false;
    this.patientService
      .getPhoto(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          let objectURL = URL.createObjectURL(resp);
          const photo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.photoEmitter.next(photo);
        }
      });
  }
}
