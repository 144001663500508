import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ECGamplitude, ECGspeed } from 'src/app/enums/ecg.enum';
import {
  ECGamplitudeHeight,
  ECGspeedWidth,
} from 'src/app/services/dictionaries/constants/ecg';

@Component({
  selector: 'app-single-scrollable-chart',
  templateUrl: './single-scrollable-chart.component.html',
  styleUrls: ['./single-scrollable-chart.component.scss'],
})
export class SingleScrollableChartComponent implements OnInit {
  @ViewChild('chart') chart: any;
  @ViewChild('xTicks') xTicks: any;

  ECGspeedObj: Array<any> = ECGspeedWidth;
  ECGamplitudeObj: Array<any> = ECGamplitudeHeight;
  ecgWidth: ECGspeed = ECGspeed.mm25;
  ecgHeight: any = 1;

  @Input() set yAxis(v) {
    this.yAxisTicks = v;
    this.yAxisTicksCopy = v;
    setTimeout(() => {
      this.setAxisX();
    }, 0);
  }

  @Input() set yMin(v) {
    this.timePointsInterval.minY = v;
  }
  @Input() set yMax(v) {
    this.timePointsInterval.maxY = v;
  }
  @Input() set points_values(v) {
    this.oscillogramData = v.oscillogramData;
    this.timePointsCopy = v.oscillogramData[0].timePoints;
    this.timePoints = [...this.timePointsCopy];
    this.setContainerWidth(this.ecgWidth);
    this.valuePointsCopy = v.oscillogramData[0].valuePoints;
    this.valuePoints = [...this.valuePointsCopy];
    this.setContainerHeight(ECGamplitude.mm10);
    this.updateSingleChart();
  }
oscillogramData = [];
  timePoints: Array<number> = [];
  valuePoints: Array<number> = [];
  timePointsCopy: Array<number> = [];
  valuePointsCopy: Array<number> = [];

  start_time: number = 0;
  end_time: number = 0;

  series: Array<{ name: number; value: number }> = [];

  rawSignalData1: any = [];
  xAxisTicks: any[] = [];
  yAxisTicks: any[] = [];
  xAxisTicksCopy: any[] = [];
  yAxisTicksCopy: any[] = [];

  timePointsInterval: any = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  axisIsSeted: boolean = false;

  scrollWidth: number = 0;
  scrollHeight: number = 0;

  isLoading: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    // this.getMinMaxTimePoints();
  }


  ngAfterViewInit() {
    // this.getVerticalGridCoord();
    // this.getHorizontalGridCoord();
    // this.getYTicks();
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 1000);

    // setTimeout(() => {
    //   this.getLabelsY();
    // }, 10);
  }

  getMinMaxTimePoints() {
    this.timePointsInterval.minX = Math.min(...this.timePointsCopy).toFixed(2);
    this.timePointsInterval.maxX = Math.max(...this.timePointsCopy).toFixed(2);
    this.start_time = this.timePointsInterval.minX;
    this.end_time = this.timePointsInterval.maxX;
  }

  setAxisX() {
    this.xAxisTicks = [];
    for (
      let i: any = Math.floor(this.start_time);
      i < this.timePoints[this.timePoints.length - 1];
      i += 0.04
    )
      this.xAxisTicks.push(i.toFixed(2));
    this.xAxisTicksCopy = [...this.xAxisTicks];
  }

  formatAxisTickDateX = (i) => {
    return i[i.length - 1] == 0 && i[i.length - 2] == 0 ? Math.round(i) : '';
  };

  formatAxisTickDateY = (i) => {
    if (this.ecgHeight == 1) return i % 0.5 == 0 ? Number(i).toFixed(1) : '';
    else if (this.ecgHeight == 2)
      return (i / 2) % 0.5 == 0 ? (i / 2).toFixed(1) : '';
    else if (this.ecgHeight == 0.5)
      return (i * 2) % 0.5 == 0 ? (i * 2).toFixed(1) : '';
  };

  updateSingleChart(): void {
    if (this.timePointsCopy && this.valuePointsCopy) {
      this.series = [];
      this.valuePoints = this.valuePoints.map((val) => val * this.ecgHeight);
      for (let i = 0; i < this.timePoints.length; i += 1) {
        this.series.push({
          name: this.timePoints[i],
          value: this.valuePoints[i],
        });
      }

      if (!this.axisIsSeted) {
        this.setAxisX();
        this.axisIsSeted = true;
      }

      this.rawSignalData1 = [];
      this.rawSignalData1 = [...[{ name: 'ECG', series: this.series }]];
    }
  }

  getTempValueY(v) {
    if (this.timePointsCopy && this.valuePointsCopy) {
      this.series = [];
      let tempTime = [...this.timePoints];
      let tempValue = [...this.valuePoints];
      tempValue = tempValue.map((val) => val * v);
      for (let i = 0; i < tempTime.length; i += 1) {
        this.series.push({
          name: tempTime[i],
          value: tempValue[i],
        });
      }
      this.rawSignalData1 = [];
      this.rawSignalData1 = [...[{ name: 'ECG', series: this.series }]];
      setTimeout(() => {
        this.getLabelsY();
      }, 10);
    }
  }

  setContainerWidth(width: ECGspeed) {
    this.scrollWidth =
      (Math.max(...this.timePoints) - Math.min(...this.timePoints)) * width +
      15;
    // this.isLoading = true;
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 10);
  }

  setContainerHeight(height: ECGamplitude) {
    this.scrollHeight =
      (Math.max(...this.valuePoints) * height +
        Math.abs(Math.min(...this.valuePoints)) * height) /
        2 +
      15;
  }

  cropTimePoints(start: number, end: number) {
    this.timePoints = [...this.timePointsCopy];
    this.valuePoints = [...this.valuePointsCopy];
    const indexStart = this.timePoints.findIndex((x) => x >= Number(start));
    let indexEnd = this.timePoints.findIndex((x) => x > Number(end));
    if (indexEnd < 0) indexEnd = this.timePoints.length - 1;
    this.timePoints = this.timePoints.slice(indexStart, indexEnd + 1);
    this.valuePoints = this.valuePoints.slice(indexStart, indexEnd + 1);
    this.setAxisX();
    this.series = [];
    for (let i = 0; i < this.timePoints.length; i += 1) {
      this.series.push({
        name: this.timePoints[i],
        value: this.valuePoints[i],
      });
    }
    this.rawSignalData1 = [];
    this.rawSignalData1 = [...[{ name: 'ECG', series: this.series }]];
  }

  changeTimePointsInterval(point: 'start' | 'end', target: any) {
    if (point == 'start') {
      if (
        !(
          Number(target.value) >= Number(target.min) &&
          Number(target.value) < Number(target.max)
        )
      )
        this.start_time = this.timePointsInterval.minX;
      else {
        this.cropTimePoints(this.start_time, this.end_time);
        this.setContainerWidth(this.ecgWidth);

        setTimeout(() => {
          // this.getVerticalGridCoord();
          this.getHorizontalGridCoord();
        }, 1000);
      }
    } else if (point == 'end') {
      if (
        !(
          Number(target.value) > Number(target.min) &&
          Number(target.value) <= Number(target.max)
        )
      )
        this.end_time = this.timePointsInterval.maxX;
      else {
        this.cropTimePoints(this.start_time, this.end_time);
        this.setContainerWidth(this.ecgWidth);

        setTimeout(() => {
          // this.getVerticalGridCoord();
          this.getHorizontalGridCoord();
        }, 1000);
      }
    }
  }

  findReapiningElementsIndex(arr: any) {
    let left = 0;
    let right = 0;
    for (let n = 0; n < arr.length - 1; n++)
      for (let j = n + 1; j < arr.length; j++)
        if (
          arr[n].getAttribute('transform') == arr[j].getAttribute('transform')
        )
          (left = n), (right = j);
    this.drawGridBoldLine(arr, left, right, 0, false);
    this.drawGridBoldLine(arr, right, arr.length, 0, false);
  }

  drawGridBoldLine(
    arr: Array<any>,
    borderLeft: number,
    borderRight: number,
    remainder: number,
    elongated_line: boolean,
    attrName?: string,
    shift?: number
  ) {
    const temp = 5 - (borderLeft % 5);
    for (var i = borderLeft; i < borderRight; i++) {
      const selector = arr[i].querySelectorAll('g')[0].children[0];
      if ((i + temp) % 5 == remainder) {
        if (elongated_line)
          if (i % 25 == remainder) {
            let attr = Number(
              arr[i].querySelectorAll('g')[0].children[0].getAttribute(attrName)
            );
            arr[i]
              .querySelectorAll('g')[0]
              .children[0].setAttribute(attrName, attr + shift);
          }
        i == borderLeft
          ? (selector.style['stroke-width'] = 0.6)
          : (selector.style['stroke-width'] = 0.4);
      } else selector.style['stroke-width'] = 0.1;
      selector.style['stroke'] = 'grey';
    }
  }

  getHorizontalGridCoord() {
    if (this.chart) {
      const btnElement = this.chart.chartElement.nativeElement
        .querySelector('.y')
        .querySelectorAll('g')[0].children;
      var arr = Array.prototype.slice.call(btnElement).slice(1);
      this.findReapiningElementsIndex(arr);
    }
  }

  // getVerticalGridCoord() {
  //   if (this.chart) {
  //     const btnElement = this.chart.chartElement.nativeElement
  //       .querySelector('.x')
  //       .querySelectorAll('g')[0].children;
  //     const arr = Array.prototype.slice.call(btnElement).slice(1);
  //     this.drawGridBoldLine(arr, 0, arr.length, 0, false);
  //   }
  // }

  getYTicks() {
    if (this.chart) {
      const y = this.chart.chartElement.nativeElement.querySelector('.y');
      const tickArrayY = y.querySelectorAll('.tick');
      const arr = Array.prototype.slice.call(tickArrayY).slice();

      arr.forEach((element) => {
        let attr = element.getAttribute('transform').split(',');
        element.setAttribute('transform', 'translate(10,' + attr[1]);
      });

      y.querySelectorAll('g')
        [y.querySelectorAll('g').length - 1].querySelector('text')
        .setAttribute('y', '-25');
      let attr = this.chart.chartElement.nativeElement
        .querySelector('.chart')
        .getAttribute('transform')
        .split(',');

      this.chart.chartElement.nativeElement
        .querySelector('.chart')
        .setAttribute('transform', 'translate(45,' + attr[1]);
    }
  }

  getLabelsY() {
    const y = this.chart.chartElement.nativeElement.querySelector('.y');
    const tickArrayY = y.querySelector('g').querySelector('g').children;
    const arr = Array.prototype.slice.call(tickArrayY).slice();
    this.xTicks.nativeElement.replaceChildren();
    for (let i = 0, im = arr.length; im > i; i++) {
      this.xTicks.nativeElement.appendChild(arr[i].cloneNode(true));
    }
    let stringWithHtmlTobeSavedInTextArea: string = '';
    Array.prototype.slice
      .call(this.xTicks.nativeElement.children)
      .slice()
      .forEach((element) => {
        const title = element.querySelector('title').textContent;
        if (title) {
          let attr = element
            .getAttribute('transform')
            .split(',')[1]
            .split(')')[0];
          const text = Number(element.querySelector('text').getAttribute('x'));
          let temp = 0;
          if (Number(attr) >= 0) temp = Number(attr) + text + 9;
          else if (Number(attr) < 0) temp = Number(attr) - text + 3;
          stringWithHtmlTobeSavedInTextArea += `<div  style='position:absolute; padding-right:5px; top:${temp}px; right:0px; font-size:12px; width:max-content;'>${title}</div>`;
        }
      });
    this.xTicks.nativeElement.innerHTML = stringWithHtmlTobeSavedInTextArea;
    this.isLoading = false;
  }

  onResize(e) {
    // this.isLoading = true;
    // setTimeout(() => {
    //   // this.getYTicks();
    //   this.isLoading = false;
    // }, 1000);
  }
}
