import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ExpertSystemService } from '../../../../../services/expert-system.service';
import { Vessels } from '../../../../../models/vessels';
import { MeasurementTableComponent } from '../../../measurement-table/measurement-table.component';
import { Biosignal } from '../../../../../models/biosignal';
import { MeasurementsDataUpdateService } from '../../../../../services/measurements-data-update.service';
import { VALUES_HEADER } from '../../constants/expert-system-constants';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-vessels',
  templateUrl: './vessels.component.html',
  styleUrls: ['./vessels.component.scss'],
})
export class VesselsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @ViewChild(MeasurementTableComponent, { static: true })
  measurementTable: MeasurementTableComponent;
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.vesselsTableData) {
        this.calculateAverageValue();
        this.setAverageHeader();
        this.calculateDescription();
      }
    }
  }

  vesselsTableData: Vessels;
  isLoaded = false;
  averageHeader = VALUES_HEADER.average;
  biosignals: Biosignal[];
  middleValue: number[] = [];
  middleValueDescription: string[] = [];
  middleCharacteristicDescription: string[] = [];
  middleValueClassName: string[] = [];
  middleRationaleDescription: string[] = [];
  middleLevelDescription: string[] = [];
  middleRecommendationsDescription: string[] = [];
  isInit: boolean = false;
  expandRow: boolean = false;
  constructor(
    private expertSystemService: ExpertSystemService,
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.getVesselsData();
  }

  getVesselsData() {
    this.expertSystemService.getVessels().subscribe((vesselData: Vessels) => {
      this.vesselsTableData = vesselData;
      if (this.biosignals && this.biosignals.length) {
        this.calculateAverageValue();
        this.setAverageHeader();
        this.calculateDescription();
      }
      this.isLoaded = true;
    });
  }

  setAverageHeader(): void {
    if (this.biosignals.length > 1) {
      this.averageHeader = VALUES_HEADER.average;
    } else {
      this.averageHeader = VALUES_HEADER.soleValue;
    }
  }

  calculateAverageValue(): void {
    if (this.biosignals[0]?.MorphologicalAnalysis) {
      if (!this.biosignals[0].MorphologicalAnalysis.M10) {
        this.biosignals[0].MorphologicalAnalysis.M10 = 1;
      }
      Object.entries(this.biosignals[0]['MorphologicalAnalysis']).forEach(
        (item, i) => {
          let sum = 0,
            count = 0;
          this.biosignals.forEach((biosignal) => {
            if (biosignal['MorphologicalAnalysis']) {
              sum += biosignal['MorphologicalAnalysis'][item[0]];
              count += 1;
            }
          });
          this.middleValue[item[0]] = Number(
            sum ? (sum / count).toFixed(2) : 0
          );
        }
      );
    }
  }

  calculateDescription(): void {
    if (this.biosignals && this.biosignals[0]?.MorphologicalAnalysis) {
      Object.entries(this.biosignals[0]?.MorphologicalAnalysis).forEach(
        (item) => {
          if (
            this.middleValue[item[0]] >= 0 &&
            this.middleValue[item[0]] <= 6
          ) {
            this.middleValueDescription[item[0]] =
              this.vesselsTableData.table.rating.row.find(
                (obj) =>
                  this.middleValue[item[0]] >= obj.minValue &&
                  this.middleValue[item[0]] <= obj.maxValue
              ).description;

            this.middleValueClassName[item[0]] =
              this.vesselsTableData.table.rating.row.find(
                (obj) =>
                  this.middleValue[item[0]] >= obj.minValue &&
                  this.middleValue[item[0]] <= obj.maxValue
              ).className;

            if (this.vesselsTableData.table.characteristic.row[item[0]]) {
              this.middleCharacteristicDescription[item[0]] =
                this.vesselsTableData.table.characteristic.row[item[0]].find(
                  (obj) =>
                    this.middleValue[item[0]] >= obj.minValue &&
                    this.middleValue[item[0]] <= obj.maxValue
                ).description;
              this.middleRationaleDescription[item[0]] =
                this.vesselsTableData.table.rationale.row[item[0]].find(
                  (obj) =>
                    this.middleValue[item[0]] >= obj.minValue &&
                    this.middleValue[item[0]] <= obj.maxValue
                ).description;
            }

            this.middleLevelDescription[item[0]] =
              this.vesselsTableData.table.level.row.find(
                (obj) =>
                  this.middleValue[item[0]] >= obj.minValue &&
                  this.middleValue[item[0]] <= obj.maxValue
              ).description;

            this.middleRecommendationsDescription[item[0]] =
              this.vesselsTableData.table.recommendations.row.find(
                (obj) =>
                  this.middleValue[item[0]] >= obj.minValue &&
                  this.middleValue[item[0]] <= obj.maxValue
              ).description;
          }
        }
      );
    }
  }

  expand() {
    this.expandRow = !this.expandRow;
  }
}
