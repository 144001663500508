import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../models/patient';

@Injectable({
  providedIn: 'root',
})
export class PatientUpdateService {
  constructor(private http: HttpClient) {}

  get(id: string): Observable<Patient> {
    return this.http.get<Patient>(`${environment.apiUrl}/patients/${id}`);
  }

  update(id: string, data: Patient): Observable<Patient> {
    return this.http.put<Patient>(`${environment.apiUrl}/patients/${id}`, data);
  }

  setDiagnosis(id: string, data: any): Observable<Patient> {
    return this.http.post<any>(
      `${environment.apiUrl}/patients/${id}/diagnosis`,
      data
    );
  }

  updateDiagnosis(id: string, data: any): Observable<Patient> {
    return this.http.put<any>(
      `${environment.apiUrl}/patients/diagnosis/${id}`,
      data
    );
  }

  postPhoto(id, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/patients/${id}/photo`, data);
  }

  getPhoto(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/patients/${id}/photo`, {
      responseType: 'blob',
    });
  }

  updatePassword(id: string, data: { password: string }): Observable<Patient> {
    return this.http.put<Patient>(
      `${environment.apiUrl}/patients/update-password/${id}`,
      data
    );
  }
}
