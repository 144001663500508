import { Component, OnInit } from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-doctors-info',
  templateUrl: './doctors-info.component.html',
  styleUrls: ['./doctors-info.component.scss'],
})
export class DoctorsInfoComponent extends OnDestroyMixin implements OnInit {
  doctors: Array<any> = [];
  selectedDoctorId: any = '';

  constructor(public staffService: StaffService) {
    super();
  }

  ngOnInit(): void {
    this.getDoctors();
    if (localStorage.getItem('doctorProfileId')) {
      this.selectedDoctorId = localStorage.getItem('doctorProfileId');
    }
  }

  getDoctorId(doctorId: any) {
    this.selectedDoctorId = doctorId;
  }

  getDoctors() {
    this.staffService
      .getDoctors()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.doctors = data;
      });
  }

  findDoctor(): any {
    return this.doctors.find((d) => d._id == this.selectedDoctorId);
  }
}
