import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
    protected inner: { isExpanded?: boolean } = {isExpanded: false};

    @Input() public title: string;
    @Input() public src: string;
    @Input() public icon: string;

    @Input()
    public set isExpanded(value: boolean) {
        this.inner.isExpanded = value;
    }

    public get isExpanded(): boolean {
        return this.inner.isExpanded;
    }

    public toggle() {
        this.inner.isExpanded = !this.inner.isExpanded;
    }

    constructor() {}
}
