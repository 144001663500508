import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Langs } from '../../auth/model/enums/role.enum';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
  Langs = Langs;
  currentLang: any = localStorage.getItem('language');
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private adapter: DateAdapter<any>
  ) {}

  ngOnInit(): void {
    !this.currentLang || this.currentLang == 'undefined'
      ? this.changeLang(Langs.UA)
      : this.changeLang(this.currentLang);
  }

  changeLang(lang: any) {
    this.currentLang = lang;
    localStorage.setItem('language', lang);
    this.translate.use(lang);
    this.authService.setLanguage(lang);
    this.adapter.setLocale(lang === 'ua' ? 'uk' : lang);
  }
}
