import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { EquipmentName } from 'src/app/enums/equipment.enum';
import { EquipmentGeneralSetting } from 'src/app/models/equipment';
import { EquipmentService } from 'src/app/services/equipment.service';

@Component({
  selector: 'app-device-global-setting',
  templateUrl: './device-global-setting.component.html',
  styleUrls: ['./device-global-setting.component.scss'],
})
export class DeviceGlobalSettingComponent
  extends OnDestroyMixin
  implements OnInit
{
  settingForm: FormGroup = new FormGroup({});

  equipmentGeneralSetting!: EquipmentGeneralSetting;
  equipmentList: EquipmentName[] = [];
  equipmentName: EquipmentName = EquipmentName.VAT;
  EquipmentName = EquipmentName;

  constructor(
    private equipmentService: EquipmentService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    for (const item of Object.keys(EquipmentName))
      this.equipmentList.push(EquipmentName[item]);
    this.reactiveForm();
    this.selectEquipment(this.equipmentName);
  }

  reactiveForm() {
    this.settingForm = this.fb.group({
      EquipmentName: [''],
    });
  }

  selectEquipment(name) {
    this.equipmentService
      .getEquipmentGeneralSetting(`${name}`)
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => {
        this.settingForm.reset();
        this.reactiveForm();
        this.equipmentGeneralSetting = v;
      });
  }

  getDeviceSettingForm(formValue) {
    if (formValue)
      for (const field of Object.keys(formValue.controls))
        this.settingForm.addControl(field, formValue.controls[field]);
  }

  saveSetting() {
    this.settingForm.get('EquipmentName').setValue(this.equipmentName);
    let obj = {};
    for (const field of Object.keys(this.settingForm.controls))
      obj = Object.assign(obj, {
        [field]: this.settingForm.controls[field].value,
      });

    if (!this.equipmentGeneralSetting)
      this.equipmentService.createEquipmentGeneralSetting(obj).subscribe(
        (resp) => {
          this.translate
            .get(['alerts'])
            .pipe(untilComponentDestroyed(this))
            .subscribe((t) => {
              this.toastr.success(t.alerts.warning_4);
            });
          this.selectEquipment(this.equipmentName);
        },
        (err: any) => {
          this.toastr.error(err.message);
        }
      );
    else
      this.equipmentService
        .updateEquipmentGeneralSetting(this.equipmentGeneralSetting._id, obj)
        .subscribe(
          (resp) => {
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_5);
              });
            this.selectEquipment(this.equipmentName);
            this.equipmentService.setEquipment();
          },
          (err: any) => {
            this.toastr.error(err.message);
          }
        );
  }
}
