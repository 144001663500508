<h1>
  <ng-container *ngIf="!(reloading$ | async)">
    {{ firstIndicatorName | lang }}
  </ng-container>
</h1>
<app-nervous-system-table
  [biosignalsData]="biosignals"
></app-nervous-system-table>
<div class="expert-system_line"></div>
<h1>
  <ng-container *ngIf="!(reloading$ | async)">
    {{ secondIndicatorName | lang }}
  </ng-container>
</h1>
<app-nervous-system-table
  [biosignalsData]="biosignals"
  [byHilbertHuang]="true"
></app-nervous-system-table>
