import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { Biosignal } from 'src/app/models/biosignal';
import { BiosignalsRange } from 'src/app/models/biosignals-range';
import { CustomLinerChartServiceService } from '../custom-liner-chart-service.service';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PatientService } from 'src/app/components/measurements-data/services/patient.service';
import { ChartNameService } from 'src/app/services/dictionaries/chart-name.service';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { OscillogramData } from 'src/app/models/oscillogram-data';
import { Patient } from 'src/app/models/patient';
import { DIPPERRS_THRESHOLDS, EXPERT_SYSTEM_THRESHOLDS } from '../../constants';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DateFilterComponent } from './date-filter/date-filter.component';

@Component({
  selector: 'app-main-graph',
  templateUrl: './main-graph.component.html',
  styleUrls: ['./main-graph.component.scss'],
})
export class MainGraphComponent extends OnDestroyMixin implements OnInit {
  biosignals: Biosignal[];
  biosignalsCopy: Biosignal[];
  oscillogramData: OscillogramData;
  biosignalsRange: BiosignalsRange;

  patients: Patient[] = [];
  patient;

  @ViewChild(DateFilterComponent) dateFilter!: DateFilterComponent;

  @ViewChild('chart') chart: any;
  @ViewChild('sdp_single_selection') sdpSingleSelection: ElementRef;
  @ViewChild('sdp_range_selection') sdpRangeSelection: ElementRef;
  @ViewChild('sdp_chart_thresholds') sdpChartThresholds: ElementRef;
  @ViewChild('sdp_chart_thresholds_dippers')
  sdpChartThresholdsDippers: ElementRef;
  @ViewChild('sdp_thresh_1') sdpThresh1: ElementRef;
  @ViewChild('sdp_thresh_2') sdpThresh2: ElementRef;
  @ViewChild('sdp_thresh_3') sdpThresh3: ElementRef;

  @ViewChild('sdp_thresh_dipper_1') sdpDipper1: ElementRef;
  @ViewChild('sdp_thresh_dipper_2') sdpDipper2: ElementRef;
  @ViewChild('sdp_thresh_dipper_3') sdpDipper3: ElementRef;
  @ViewChild('sdp_thresh_dipper_4') sdpDipper4: ElementRef;
  @ViewChild('sdp_thresh_dipper_5') sdpDipper5: ElementRef;

  @Input() chartsDataIndex: number = 0;
  @Input() measurementsNumber: number = 15;

  bioIndexToChartElemIndexMap = new Map();
  chartElemIndexToBioIndexMap = new Map();

  selectionVisible = true;

  show: boolean = false;
  static readonly SEL_COLOR_TRANS_DARK_BLACK = '#00000077';
  static readonly SDP_LINE_COLORS = {
    ao: [
      ['#66CC99', '#70B8DB', '#DB9770'],
      ['#CC6666', '#7970DB', '#66CC99'],
      ['#66CC99', '#7970DB', '#70B8DB', '#246B8F'],
      ['#66CC99', '#70B8DB'],
    ],
    ecg: [['#CC6666', '#7970DB', '#66CC99']],
    wp: [['#CC6666', '#7970DB', '#66CC99']],
    cp: [['#CC6666', '#7970DB', '#66CC99']],
  };

  colorScheme = [];
  colorDots = [];

  private static getEmptySysDiaPulData(type): Map<string, any> {
    let temp = {
      ao: new Map([
        ['systola', { key: 'systola', name: 'systola', series: [] }],
        ['diastola', { key: 'diastola', name: 'diastola', series: [] }],
        ['heartRate', { key: 'heartRate', name: 'heartRate', series: [] }],
        ['pars', { key: 'pars', name: 'pars', series: [] }],
        ['ifsp', { key: 'ifsp', name: 'ifsp', series: [] }],
        ['msr', { key: 'msr', name: 'msr', series: [] }],
        [
          'heartDiseases',
          { key: 'heartDiseases', name: 'heartDiseases', series: [] },
        ],
        [
          'lungDiseases',
          { key: 'lungDiseases', name: 'lungDiseases', series: [] },
        ],
        [
          'psychicalDiseases',
          { key: 'psychicalDiseases', name: 'psychicalDiseases', series: [] },
        ],
        [
          'arrhythmiaDiseases',
          { key: 'arrhythmiaDiseases', name: 'arrhythmiaDiseases', series: [] },
        ],
      ]),
      ecg: new Map([
        ['pars', { key: 'pars', name: 'pars', series: [] }],
        ['ifsp', { key: 'ifsp', name: 'ifsp', series: [] }],
        ['msr', { key: 'msr', name: 'msr', series: [] }],
      ]),
      wp: new Map([
        ['pars', { key: 'pars', name: 'pars', series: [] }],
        ['ifsp', { key: 'ifsp', name: 'ifsp', series: [] }],
        ['msr', { key: 'msr', name: 'msr', series: [] }],
      ]),
      cp: new Map([
        ['pars', { key: 'pars', name: 'pars', series: [] }],
        ['ifsp', { key: 'ifsp', name: 'ifsp', series: [] }],
        ['msr', { key: 'msr', name: 'msr', series: [] }],
      ]),
    };
    return temp[type];
  }

  xAxisGridCoordinates: Array<number> = [];
  yAxisGridCoordinates: Array<number> = [];
  systola = { min: 0, max: 0 };
  diastola = { min: 0, max: 0 };
  yDippersTicks: Array<any> = [];
  yTicksArr: Array<any> = [];

  data = [];
  dataCopy = [];
  ACTIVE_GRAPH = GraphType.AO;
  GraphType = GraphType;
  initSysDiaPulData: Map<string, any>;
  @Input() set SET_ACTIVE_GRAPH(v) {
    if (v) {
      this.ACTIVE_GRAPH = v;
      this.colorScheme = [];
      this.colorDots = [];
      this.customLinerChartService.line_btn[this.ACTIVE_GRAPH].forEach(
        (v, i) => {
          this.colorScheme.push({
            domain: MainGraphComponent.SDP_LINE_COLORS[this.ACTIVE_GRAPH][i],
          });
          this.colorDots.push(
            MainGraphComponent.SDP_LINE_COLORS[this.ACTIVE_GRAPH][i]
          );
        }
      );
      this.updateSdpData();
      setTimeout(() => this.chartInit(), 1000);
    }
  }

  sdpDateUnits: boolean = false;
  sdpSelectedSingle: number = null;
  sdpMouseX: number = 0;
  sdpSelectedRange = { start: 0, end: 0 };
  sdpMouseDown: boolean = false;
  sdpTouchStart: boolean = false;
  sdpTouchMove: boolean = false;

  constanta!: number;
  initMulti = [];
  view: any;
  offsetX: any;

  @Input() set setBiosignalsRange(v: BiosignalsRange) {
    if (v) {
      if (v?.origin !== 'CalculationsComponent') this.biosignalsRange = v;
      if (v?.origin == 'MeasurementTableComponent') {
        if (v?.rangeBiosignals.length > 1) {
          this.clearArea();
          this.rangeSelectionStyle(
            0,
            this.xAxisGridCoordinates[v.beginIndex] + this.constanta,
            this.xAxisGridCoordinates[v.endIndex] -
              this.xAxisGridCoordinates[v.beginIndex]
          );
        }
        if (
          v?.rangeBiosignals.length == 1 ||
          v?.endIndex - v?.beginIndex == 0
        ) {
          this.clearArea();
          this.singleSelectionStyle(
            0,
            this.xAxisGridCoordinates[v.beginIndex] + this.constanta,
            '2px solid' + MainGraphComponent.SEL_COLOR_TRANS_DARK_BLACK
          );
        }
      }
    }
  }

  @Input() set setBiosignals(v) {
    if (v) {
      this.biosignals = v;
      this.biosignalsCopy = JSON.parse(JSON.stringify(v));
      this.biosignalsRange = new BiosignalsRange(
        'CalculationsComponent',
        this.biosignals
      );
      this.updateSdpData();
      this.getVerticalGridCoord();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartView(event.target.innerWidth);
    setTimeout(() => this.updateChartAlignments(), 0);
    if (this.ACTIVE_GRAPH == GraphType.AO && this.chartsDataIndex == 3)
      setTimeout(() => this.getVerticalGridCoord(), 0);
    setTimeout(() => this.getHorizontalGridCoord(), 0);

    if (this.sdpSelectedSingle)
      setTimeout(() => this.drawVerticalLineAndSelectData('current'), 0);
    else
      setTimeout(
        () =>
          this.rangeSelectionStyle(
            0,
            this.xAxisGridCoordinates[this.sdpSelectedRange.start] +
              this.constanta,
            this.xAxisGridCoordinates[this.sdpSelectedRange.end] -
              this.xAxisGridCoordinates[this.sdpSelectedRange.start]
          ),
        0
      );
  }

  get EXPERT_SYSTEM_THRESHOLDS(): any {
    return EXPERT_SYSTEM_THRESHOLDS;
  }

  get DIPPERRS_THRESHOLDS(): any {
    return DIPPERRS_THRESHOLDS;
  }

  constructor(
    public customLinerChartService: CustomLinerChartServiceService,
    public biosignalsService: BiosignalsService,
    public authService: AuthService,
    public patientService: PatientService,
    public measurementsDataUpdateService: MeasurementsDataUpdateService,
    private renderer: Renderer2,
    public chartNameService: ChartNameService
  ) {
    super();
    this.initSysDiaPulData = MainGraphComponent.getEmptySysDiaPulData(
      this.ACTIVE_GRAPH
    );
    this.setChartView(innerWidth);
  }

  ngOnInit(): void {}

  resetData() {
    this.measurementsDataUpdateService.changeMeasurementsNumber(15);
    this.drawVerticalLineAndSelectData('end');
  }

  chartInit() {
    let interval = setInterval(() => {
      if (this.chart?.chartElement?.nativeElement) {
        this.sdpSelectedSingle = this.chart?.xSet.length - 1;
        setTimeout(() => this.drawVerticalLineAndSelectData('current'), 0);
        this.showDots();
        clearInterval(interval);
      }
    }, 1000);
  }

  showDots() {
    this.customLinerChartService.removeDots(this.chart);
    this.customLinerChartService.showDots(
      this.chart,
      this.colorDots[this.chartsDataIndex]
    );
  }

  calculateDipperLine(sdpDipper, lineVal, step, maxVal, maxCoordVal) {
    this.renderer.setStyle(
      sdpDipper?.nativeElement,
      'top',
      `${
        maxCoordVal +
        (maxVal - this.DIPPERRS_THRESHOLDS[lineVal]) * step +
        this.chart.margin[0]
      }px`
    );
  }

  getVerticalGridCoord() {
    if (this.chart) {
      this.yDippersTicks = [...[]];
      setTimeout(() => {
        let systola = this.initSysDiaPulData
          ?.get('systola')
          ?.series.map((v) => v.value);
        let diastola = this.initSysDiaPulData
          ?.get('diastola')
          ?.series.map((v) => v.value);
        if (systola)
          this.systola = {
            min: Math.min(...systola),
            max: Math.max(...systola),
          };
        if (diastola)
          this.diastola = {
            min: Math.min(...diastola),
            max: Math.max(...diastola),
          };
        const width = this.chart?.width;
        const dimsWidth = this.chart?.dims?.width;
        const margin = this.chart?.margin;
        this.constanta = width - dimsWidth - margin[1] + 5;
        this.yTicksArr = [];
        this.yAxisGridCoordinates = [];
        const btnElement = this.chart.chartElement.nativeElement
          .querySelector('.y')
          .querySelectorAll('.tick');
        var arr = Array.prototype.slice.call(btnElement);
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].getAttribute('transform')) {
            this.yTicksArr.push(
              Number(arr[i].querySelector('title').innerHTML)
            );
            this.yAxisGridCoordinates.push(
              Number(
                Number(
                  arr[i]
                    .getAttribute('transform')
                    .split('(')[1]
                    .split(')')[0]
                    .split(',')[1]
                ).toFixed(5)
              )
            );
          }
        }
        let step =
          Math.abs(
            this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 2] -
              this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
          ) /
          Math.abs(
            this.yTicksArr[this.yTicksArr.length - 1] -
              this.yTicksArr[this.yTicksArr.length - 2]
          );
        this.renderer.setStyle(
          this.sdpChartThresholdsDippers.nativeElement,
          'margin-left',
          `${width - dimsWidth - margin[3]}px`
        );
        this.renderer.setStyle(
          this.sdpChartThresholdsDippers.nativeElement,
          'width',
          `${dimsWidth + 5}px`
        );
        this.calculateDipperLine(
          this.sdpDipper1,
          's_min',
          step,
          this.yTicksArr[this.yTicksArr.length - 1],
          this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
        );
        this.calculateDipperLine(
          this.sdpDipper2,
          's_max',
          step,
          this.yTicksArr[this.yTicksArr.length - 1],
          this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
        );
        this.calculateDipperLine(
          this.sdpDipper3,
          'd_min',
          step,
          this.yTicksArr[this.yTicksArr.length - 1],
          this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
        );
        this.calculateDipperLine(
          this.sdpDipper4,
          'd_max',
          step,
          this.yTicksArr[this.yTicksArr.length - 1],
          this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
        );
        this.calculateDipperLine(
          this.sdpDipper5,
          's_val',
          step,
          this.yTicksArr[this.yTicksArr.length - 1],
          this.yAxisGridCoordinates[this.yAxisGridCoordinates.length - 1]
        );
        setTimeout(() => {
          this.setYDippersTicks();
        }, 500);
      }, 500);
    }
  }

  setYDippersTicks() {
    let tempMin = this.yTicksArr[0] < 60 ? this.yTicksArr[0] : 60;
    let tempMax =
      this.yTicksArr[this.yTicksArr.length - 1] <= 140
        ? 140
        : this.yTicksArr[this.yTicksArr.length - 1];
    this.yDippersTicks = [];
    for (let index = tempMin; index <= tempMax + 1; index += 20) {
      this.yDippersTicks.push(index);
    }
  }

  getHorizontalGridCoord() {
    if (this.chart) {
      this.constanta =
        this.chart?.width - this.chart?.dims?.width - this.chart?.margin[1] + 5;
      this.xAxisGridCoordinates = [];
      const btnElement = this.chart.chartElement.nativeElement
        .querySelector('.x')
        .querySelectorAll('g')[0].children;
      var arr = Array.prototype.slice.call(btnElement);
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].getAttribute('transform'))
          this.xAxisGridCoordinates.push(
            Number(
              Number(
                arr[i].getAttribute('transform').split('(')[1].split(',')[0]
              ).toFixed(5)
            )
          );
      }
    }
  }

  setChartView(width) {
    width > 767
      ? (this.view = [width - 170, 220])
      : (this.view = [width - 10, 220]);
  }

  updateChartAlignments(): void {
    if (this.chart) {
      this.renderer.setStyle(
        this.sdpChartThresholds.nativeElement,
        'margin-left',
        `${
          this.chart?.width - this.chart?.dims?.width - this.chart?.margin[1]
        }px`
      );
      this.renderer.setStyle(
        this.sdpChartThresholds.nativeElement,
        'width',
        `${this.chart.dims.width + 5}px`
      );
      this.renderer.setStyle(
        this.sdpThresh1.nativeElement,
        'top',
        `${
          (1.0 - this.EXPERT_SYSTEM_THRESHOLDS.critical) *
          this.chart.dims.height
        }px`
      );
      this.renderer.setStyle(
        this.sdpThresh2.nativeElement,
        'top',
        `${
          (1.0 - this.EXPERT_SYSTEM_THRESHOLDS.medium) * this.chart.dims.height
        }px`
      );
      this.renderer.setStyle(
        this.sdpThresh3.nativeElement,
        'top',
        `${
          (1.0 - this.EXPERT_SYSTEM_THRESHOLDS.low) * this.chart.dims.height
        }px`
      );
    }
  }

  clearArea() {
    this.renderer.removeStyle(this.sdpRangeSelection.nativeElement, 'border');
    this.renderer.removeStyle(this.sdpRangeSelection.nativeElement, 'left');
    this.renderer.removeStyle(this.sdpRangeSelection.nativeElement, 'width');
    this.renderer.removeStyle(this.sdpSingleSelection.nativeElement, 'border');
    this.renderer.removeStyle(this.sdpSingleSelection.nativeElement, 'width');
    this.renderer.removeStyle(this.sdpSingleSelection.nativeElement, 'left');
  }

  rangeSelectionStyle(border, left, width) {
    this.renderer.setStyle(
      this.sdpRangeSelection.nativeElement,
      'border',
      border + 'px'
    );
    this.renderer.setStyle(
      this.sdpRangeSelection.nativeElement,
      'left',
      left + 'px'
    );
    this.renderer.setStyle(
      this.sdpRangeSelection.nativeElement,
      'width',
      width + 'px'
    );
  }

  singleSelectionStyle(width, left, borderLeft) {
    this.renderer.setStyle(
      this.sdpSingleSelection.nativeElement,
      'width',
      width + 'px'
    );
    this.renderer.setStyle(
      this.sdpSingleSelection.nativeElement,
      'left',
      left + 'px'
    );
    this.renderer.setStyle(
      this.sdpSingleSelection.nativeElement,
      'border-left',
      borderLeft
    );
  }

  updateSdpData(): void {
    this.data = [...[]];
    this.dataCopy = [...[]];
    this.initSysDiaPulData = MainGraphComponent.getEmptySysDiaPulData(
      this.ACTIVE_GRAPH
    );
    let chartElemIndex = 0;
    this.bioIndexToChartElemIndexMap.clear();
    this.chartElemIndexToBioIndexMap.clear();
    if (this.biosignals?.length) {
      this.biosignals?.forEach((biosignal, i) => {
        if (!this.biosignalsRange.excludedBiosignalsIds.has(biosignal._id)) {
          const key = {
            id: biosignal._id,
            index: i + 1,
            date: biosignal.SignalRegistrationDateTime,
            EquipmentName: biosignal.EquipmentName,
            EquipmentID: biosignal.EquipmentID,
            signalType: biosignal.signalType,
          };
          this.bioIndexToChartElemIndexMap.set(i, chartElemIndex);
          this.chartElemIndexToBioIndexMap.set(chartElemIndex, i);
          chartElemIndex++;
          this.initSysDiaPulData?.get('systola')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Systola : 0,
          });
          this.initSysDiaPulData?.get('diastola')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Diastola : 0,
          });
          this.initSysDiaPulData.get('heartRate')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.TimeAnalysis
              ? biosignal.TimeAnalysis.HeartRate
              : 0,
          });
          this.initSysDiaPulData.get('pars')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.PARSint : 0,
          });
          this.initSysDiaPulData.get('ifsp')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.IFSP : 0,
          });
          this.initSysDiaPulData.get('msr')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.MorphologicalAnalysis
              ? biosignal.MorphologicalAnalysis.Ms
              : 0,
          });
          this.initSysDiaPulData.get('heartDiseases')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.ExpertSystem
              ? biosignal.ExpertSystem.HeartDiseases * 100
              : 0,
          });
          this.initSysDiaPulData.get('lungDiseases')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.ExpertSystem
              ? biosignal.ExpertSystem.LungDiseases * 100
              : 0,
          });
          this.initSysDiaPulData.get('psychicalDiseases')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.ExpertSystem
              ? biosignal.ExpertSystem.PsychicalDiseases * 100
              : 0,
          });
          this.initSysDiaPulData.get('arrhythmiaDiseases')?.series.push({
            key,
            name: `${i + 1}, ${new DatePipe('en-US').transform(
              key.date,
              'dd.MM.YYYY'
            )}`,
            value: biosignal.ExpertSystem
              ? biosignal.ExpertSystem.ArrhythmiaDegree == 1
                ? 0
                : (biosignal.ExpertSystem.ArrhythmiaDegree - 1) * 20
              : 0,
          });
        }
      });
      this.customLinerChartService.line_btn[this.ACTIVE_GRAPH].forEach((v) => {
        let temp = [];
        v.forEach((el) => {
          temp.push(this.copyData(el.line_name));
        });
        this.data.push(temp);
      });
      this.dataCopy = JSON.parse(JSON.stringify(this.data));
    }
  }

  copyData(value: string) {
    if (this.initSysDiaPulData.get(value))
      return JSON.parse(JSON.stringify(this.initSysDiaPulData.get(value)));
  }

  sdpGetCurrentPoint = (): string =>
    this.initSysDiaPulData.values().next().value.series[this.sdpSelectedSingle]
      ?.key.id;

  private performSelectedMeasurementLineDrawing(left: number): void {
    this.clearArea();
    this.singleSelectionStyle(
      0,
      left,
      '2px solid' + MainGraphComponent.SEL_COLOR_TRANS_DARK_BLACK
    );
  }

  calculatePoint(left) {
    this.getHorizontalGridCoord();
    let leftSide = 0;
    let rightSide = 0;
    let tempSide = 0;
    if (left - this.constanta <= this.xAxisGridCoordinates[0])
      tempSide = this.xAxisGridCoordinates[0];
    else if (
      left - this.constanta >
      this.xAxisGridCoordinates[this.xAxisGridCoordinates.length - 1]
    )
      tempSide =
        this.xAxisGridCoordinates[this.xAxisGridCoordinates.length - 1];
    else
      this.xAxisGridCoordinates.forEach((v, i) => {
        if (
          this.xAxisGridCoordinates[i] <= left - this.constanta &&
          this.xAxisGridCoordinates[i + 1] > left - this.constanta &&
          i < this.xAxisGridCoordinates.length
        ) {
          leftSide = this.xAxisGridCoordinates[i];
          rightSide = this.xAxisGridCoordinates[i + 1];
          if (left - this.constanta <= leftSide + (rightSide - leftSide) / 2) {
            tempSide = leftSide;
          } else tempSide = rightSide;
        }
      });
    return tempSide;
  }

  drawVerticalLineAndSelectData(setPointEnd, left?) {
    if (
      this.chart &&
      // this.sdpSelectedSingle != null &&
      this.biosignals?.length
    ) {
      // this.sdpSelectedRange = { start: null, end: null };
      let pointCoord = this.calculatePoint(left);
      setPointEnd == 'current'
        ? ((this.sdpSelectedSingle = this.sdpSelectedSingle),
          (pointCoord = this.xAxisGridCoordinates[this.sdpSelectedSingle]))
        : setPointEnd == 'end'
        ? ((this.sdpSelectedSingle = this.biosignals.length - 1),
          (pointCoord = this.xAxisGridCoordinates[this.sdpSelectedSingle]))
        : (this.sdpSelectedSingle = Math.round(
            Math.max(0, this.xAxisGridCoordinates.indexOf(pointCoord))
          ));
      this.performSelectedMeasurementLineDrawing(pointCoord + this.constanta);
      const id = this.sdpGetCurrentPoint();
      const index = this.biosignals.findIndex(
        (biosignal) => biosignal._id == id
      );
      const type = this.biosignals.find(
        (biosignal) => biosignal._id == id
      ).signalType;
      this.measurementsDataUpdateService.changeSelectedMeasurement(
        this.biosignals,
        id
      );

      this.measurementsDataUpdateService.changeMeasurementsRange(
        'CalculationsComponent',
        this.biosignals,
        index,
        index,
        new Set()
      );
      this.measurementsDataUpdateService.changeSelectedOscillogram(id, type);
    }
  }

  drawRectangulaArea(offsetX) {
    if (this.chart) {
      let left;
      let width;
      offsetX < this.sdpMouseX
        ? ((left = Math.max(offsetX, this.constanta)),
          (width = this.sdpMouseX - left))
        : ((left = Math.max(this.sdpMouseX, this.constanta)),
          (width = -left + offsetX));
      width === 0
        ? this.rangeSelectionStyle(0, left, width)
        : this.rangeSelectionStyle(1, left, width);
      this.sdpSelectedRange = {
        start: this.xAxisGridCoordinates.indexOf(this.calculatePoint(left)),
        end: this.xAxisGridCoordinates.indexOf(
          this.calculatePoint(left + width)
        ),
      };
    }
  }

  setStartSelectionTangeData(offsetX) {
    this.sdpMouseX = this.calculatePoint(offsetX) + this.constanta;
    this.sdpSelectedRange = {
      start: this.sdpMouseX,
      end: this.sdpMouseX,
    };
  }

  selectRangeData(width?) {
    this.sdpSelectedSingle = null;
    if (this.sdpSelectedRange.end == this.sdpSelectedRange.start)
      this.sdpSelectedRange = { start: 0, end: this.biosignals.length - 1 };
    width === 0 && width
      ? this.rangeSelectionStyle(
          0,
          this.xAxisGridCoordinates[this.sdpSelectedRange.start] +
            this.constanta,
          this.xAxisGridCoordinates[this.sdpSelectedRange.end] -
            this.xAxisGridCoordinates[this.sdpSelectedRange.start]
        )
      : this.rangeSelectionStyle(
          1,
          this.xAxisGridCoordinates[this.sdpSelectedRange.start] +
            this.constanta,
          this.xAxisGridCoordinates[this.sdpSelectedRange.end] -
            this.xAxisGridCoordinates[this.sdpSelectedRange.start]
        );

    this.measurementsDataUpdateService.changeMeasurementsRange(
      'CalculationsComponent',
      this.biosignals,
      this.sdpSelectedRange.start,
      this.sdpSelectedRange.end,
      new Set()
    );
  }

  //touch event
  touchmove(event: any) {
    if (this.sdpTouchStart) {
      this.clearArea();
      this.offsetX =
        event.targetTouches[0].pageX -
        event.target.getBoundingClientRect().left +
        (this.chart.width - this.chart.dims.width - this.chart.margin[1]);
      this.drawRectangulaArea(this.offsetX);
      this.sdpTouchMove = true;
    }
  }

  touchstart(event: any) {
    this.sdpTouchStart = true;
    this.offsetX =
      event.targetTouches[0].pageX -
      event.target.getBoundingClientRect().left +
      (this.chart.width - this.chart.dims.width - this.chart.margin[1]);
    const left = Math.max(
      this.offsetX,
      this.chart.width - this.chart.dims.width - this.chart.margin[1]
    );
    this.drawVerticalLineAndSelectData(false, left);
    this.setStartSelectionTangeData(this.offsetX);
  }

  touchend(event: TouchEvent) {
    if (this.sdpTouchStart && this.sdpTouchMove) {
      this.sdpTouchMove = false;
      this.sdpTouchStart = false;
      this.selectRangeData();
    }
  }

  //key events
  sdpOnContextMenu(event: MouseEvent) {
    if (event.button === 2 || event.button === 0) {
      const left = Math.max(event.offsetX, this.constanta);
      this.drawVerticalLineAndSelectData(false, left);
      return false;
    } else return true;
  }

  sdpOnMouseHover(event: MouseEvent): void {
    if (this.sdpMouseDown && !this.sdpTouchStart) {
      this.offsetX = event.offsetX;
      this.drawRectangulaArea(event.offsetX);
    }
  }

  sdpOnMouseDown(event: MouseEvent): void {
    if (event.button === 0 && !this.sdpTouchStart) {
      this.sdpMouseDown = true;
      this.setStartSelectionTangeData(event.offsetX);
      this.clearArea();
      this.rangeSelectionStyle(0, this.sdpMouseX, 0);
    }
  }

  sdpOnMouseUp(event: MouseEvent): void {
    if (event.button === 0 && !this.sdpTouchStart) {
      this.sdpMouseDown = false;
      this.selectRangeData();
    }
  }

  switchToDateUnits(v: boolean) {
    if (this.ACTIVE_GRAPH == GraphType.AO && this.chartsDataIndex == 3)
      setTimeout(() => this.getVerticalGridCoord(), 0);
    this.sdpDateUnits = v;
  }

  filterBiosignal(biosignalsFiltered: Biosignal[]) {
    this.biosignals = biosignalsFiltered;
    this.updateSdpData();
    setTimeout(() => {
      this.getHorizontalGridCoord();
      this.drawVerticalLineAndSelectData('end');
    }, 1000);
  }

  formatXAxisTick = (i) => i.split(',')[0];
  formatXAxisTickDate = (i) => i.split(',')[1];
}
