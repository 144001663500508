export enum Rights {
  MorphAnalysis = 'MorphAnalysis',
  Diagrams = 'Diagrams',
  Statistics = 'Statistics',
  PARS = 'PARS',
  IFSP = 'IFSP',
  ANS = 'ANS',
  Heart = 'Heart',
  Vessels = 'Vessels',
  NervousSystem = 'NervousSystem',
  Rufier = 'Rufier',
  Martine = 'Martine',
  ExpertSystem = 'ExpertSystem',
  ExtremumConform = 'ExtremumConform',
  Report = 'Report',
  Measurement = 'Measurement',
  Calculations = 'Calculations',
  Consultants = 'Consultants',
  Recalculate = 'Recalculate',
}
