import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ans-tab',
  templateUrl: './ans-tab.component.html',
  styleUrls: ['./ans-tab.component.scss'],
})
export class AnsTabComponent implements OnInit {
  @Input() biosignalsData = [];
  constructor() {}

  ngOnInit(): void {}
}
