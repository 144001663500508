import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { Doctor } from 'src/app/models/doctor';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss'],
})
export class ConsultantsComponent extends OnDestroyMixin implements OnInit {
  consultants: Doctor[] = [];
  doctorId: string;
  constructor(
    private staffService: StaffService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.staffService.getDoctors(true, true).subscribe((data) => {
      this.consultants = data;
    });
  }

  check(data: string) {
    if (typeof data === 'string') this.doctorId = data;
  }

  addConsultant() {
    const data = {
      doctorId: this.doctorId,
    };
    this.staffService.assignDoctor(data).subscribe((resp) => {
      this.translate
        .get(['alerts'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((t) => {
          this.toastr.success(t.alerts.warning_3);
        });
    });
  }
}
