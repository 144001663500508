<div *ngIf="oscillogramData && biosignalsRange?.length <= 1">
  <h4 class="text-center my-4">
    {{ "calculation.choose_several_measurements" | translate }}
  </h4>
</div>
<div
  class="w-100 h-100 position-relative"
  #oscContainer
  *ngIf="
    oscillogramData &&
    biosignalsRange.length > 1 &&
    oscillogramData.coefficient.systola.b != 'NaN'
  "
>
  <div class="text-center my-1 chart-header">
    <span>b = {{ oscillogramData.coefficient.systola.b }}</span>
  </div>

  <ngx-charts-line-chart
    [view]="[oscContainer.offsetWidth, oscContainer.offsetHeight]"
    [results]="rawSignalData"
    [xAxis]="true"
    [yAxis]="true"
    [timeline]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [roundDomains]="true"
    [tooltipDisabled]="true"
    [yScaleMin]="yScaleMin"
    [yScaleMax]="yScaleMax"
    [xScaleMax]="xScaleMax"
    yAxisLabel="{{ 'calculation.atd' | translate }}"
    xAxisLabel="{{ 'calculation.ats' | translate }}"
  >
  </ngx-charts-line-chart>
</div>
