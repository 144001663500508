import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../measurements-data/services/data.service';
import { PatientService } from '../../../measurements-data/services/patient.service';
import { ConfirmDisabledDialogComponent } from '../dialogs/confirm-disabled-dialog/confirm-disabled-dialog.component';

@Component({
  selector: 'app-item-row-setting',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.scss'],
})
export class ItemRowSettingComponent implements OnInit {
  @Output() expand: EventEmitter<any> = new EventEmitter();
  @Output() checked: EventEmitter<any> = new EventEmitter();
  @Output() createField: EventEmitter<any> = new EventEmitter();

  element: any;
  cols: any;
  @Input() childRow: boolean = false;
  @Input() index;
  @Input() set data(value) {
    if (value) {
      this.element = value;
      this.element.contactPerson
        ? (this.contactPerson = this.element.contactPerson)
        : (this.contactPerson = '');
      this.element.purchaseStatus
        ? (this.purchaseStatus = this.element.purchaseStatus)
        : (this.purchaseStatus = '');
    }
  }

  @Input() set dataCols(value) {
    if (value) {
      this.cols = value;
    }
  }
  contactPerson: string = '';
  purchaseStatus: string = '';
  active: boolean = false;
  isEdit: any = { contactPerson: false, purchaseStatus: false };

  arrowDown: boolean = true;
  doctorsExpandedList: number | null = null;

  constructor(
    public dataService: DataService,
    private modalService: NgbModal,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {}

  reactiveForm() {}

  showMoreInfo(id) {
    this.expand.emit(id);
    this.arrowDown = !this.arrowDown;
  }

  edit(key) {
    this.isEdit[key] = true;
  }

  save(key) {
    this.isEdit[key] = false;
    const data = {
      key,
      equipmentID: this.element.equipmentID,
      data: this[key],
    };
    this.createField.emit(data);
  }

  cancel(key) {
    this.isEdit[key] = false;
  }

  setContactPerson() {
    if (this.element.isContactPerson) {
      this.isEdit['contactPerson'] = true;
      this.contactPerson = this.element.patientName;
    } else {
      this.contactPerson = this.element.contactPerson || '';
    }
  }

  confirm() {
    const modal = this.modalService.open(ConfirmDisabledDialogComponent);
    modal.result.then((result) => {
      if (result) {
        const data = {
          key: 'active',
          equipmentID: this.element.equipmentID,
          data: !this.element.active,
        };
        this.createField.emit(data);
      }
    });
  }

  selectPatient(data: string) {
    this.patientService.getPatient(data).subscribe((p: any) => {
      this.patientService.setPatient = p;
      localStorage.setItem('selectedPatient', JSON.stringify(p));
    });
    if (localStorage.getItem('rufierTest') !== null) {
      localStorage.removeItem('rufierTest');
    }
  }
}
