import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { Ans } from 'src/app/models/ans';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';

@Component({
  selector: 'app-ans',
  templateUrl: './ans.component.html',
  styleUrls: ['./ans.component.scss'],
})
export class AnsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.AnsTableData) {
        this.calculateClassInterpretation(
          this.AnsTableData.cardiacFactorTable,
          true
        );
        this.calculateClassInterpretation(
          this.AnsTableData.vascularFactorTable
        );
        this.setInterpretation();
        this.setAverageValuesAndInterpretations();
      }
    }
  }
  AnsTableData: Ans;
  biosignals: Biosignal[];
  indexDescriptionIVHH: string;
  indexDescriptionICHH: string;

  indexDescriptionIVVH: string;
  indexDescriptionICVH: string;

  chartDescrption: any = {
    FFT_pos_interval: {
      perULF: '',
      perVLF: '',
      perLF: '',
      perHF: '',
    },
    FFT: {
      perHF: '',
      perLF: '',
      perULF: '',
      perVLF: '',
    },
  };

  averageValues: any = {
    FFT_pos_interval: {
      HF: '0',
      LF: '0',
      ULF: '0',
      VLF: '0',
      perULF: '0',
      perVLF: '0',
      perLF: '0',
      perHF: '0',
      total: '0',
    },

    FFT_neg_interval: {
      perHF: '0',
      perLF: '0',
      perULF: '0',
      perVLF: '0',
    },

    FFT: {
      HF: '0',
      LF: '0',
      ULF: '0',
      VLF: '0',
      perHF: '0',
      perLF: '0',
      perULF: '0',
      perVLF: '0',
      total: '0',
    },

    Mo: '0',
    Chss: '0',
    ATs: '0',
    ATd: '0',
    VPR: '0',
    IN: '0',
    HVR_V: '0',
    Diastola: '0',

    IVHH: '0',
    ICHH: '0',
    IVVH: '0',
    ICVH: '0',
  };

  public cardiacChartData: number[] = [];
  public vascularChartData: number[] = [];

  public pieChartColors = [
    {
      backgroundColor: [
        'rgb(255, 141, 116)',
        'rgb(86, 161, 94)',
        'rgb(178, 70, 116)',
        'rgb(254, 234, 128)',
      ],
    },
  ];
  public pieChartLabels: Label[] = ['%ULFint', '%VLFint', '%LFint', '%HFint'];

  chartLabels: Label[] = ['%ULFint', '%VLFint', '%LFint', '%HFint'];

  public pieChartOptions: ChartOptions = {
    responsive: false,
    legend: {
      display: false,
    },
  };

  public pieChartType: ChartType = 'pie';
  public width: '220';
  public height: '220';
  averageIndex: any;

  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.getAnsData();
  }

  getAnsData() {
    this.expertSystemService.getAns().subscribe((data: Ans) => {
      this.AnsTableData = data;
      if (this.biosignals && this.biosignals.length) {
        this.calculateClassInterpretation(
          this.AnsTableData.cardiacFactorTable,
          true
        );
        this.calculateClassInterpretation(
          this.AnsTableData.vascularFactorTable
        );
        this.setInterpretation();
        this.setAverageValuesAndInterpretations();
      }
    });
  }

  setAverageValuesAndInterpretations(): void {
    this.averageValues.FFT_pos_interval.perULF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'perULF');
    this.averageValues.FFT_pos_interval.ULF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'ULF');
    this.averageValues.FFT_pos_interval.perVLF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'perVLF');
    this.averageValues.FFT_pos_interval.VLF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'VLF');
    this.averageValues.FFT_pos_interval.perLF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'perLF');
    this.averageValues.FFT_pos_interval.LF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'LF');
    this.averageValues.FFT_pos_interval.perHF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'perHF');
    this.averageValues.FFT_pos_interval.HF =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'HF');
    this.averageValues.FFT_pos_interval.total =
      this.calculateBiosignalsAverageValue('FFT_pos_interval', 'Total04');

    this.averageValues.FFT_neg_interval.perULF =
      this.calculateBiosignalsAverageValue('FFT_neg_interval', 'perULF');
    this.averageValues.FFT_neg_interval.perVLF =
      this.calculateBiosignalsAverageValue('FFT_neg_interval', 'perVLF');
    this.averageValues.FFT_neg_interval.perLF =
      this.calculateBiosignalsAverageValue('FFT_neg_interval', 'perLF');
    this.averageValues.FFT_neg_interval.perHF =
      this.calculateBiosignalsAverageValue('FFT_neg_interval', 'perHF');

    this.averageValues.Mo = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'MoPos'
    );
    this.averageValues.Chss = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'HeartRate'
    );
    this.averageValues.ATs = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Systola'
    );
    this.averageValues.ATd = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Diastola'
    );
    this.averageValues.VPR = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'VPRpos'
    );
    this.averageValues.IN = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'INpos'
    );
    this.averageValues.HVR_V = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'HVRindexPos'
    );
    this.averageValues.ATd = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Diastola'
    );

    this.averageValues.FFT.perULF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perULF'
    );
    this.averageValues.FFT.ULF = this.calculateBiosignalsAverageValue(
      'FFT',
      'ULF'
    );
    this.averageValues.FFT.perVLF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perVLF'
    );
    this.averageValues.FFT.VLF = this.calculateBiosignalsAverageValue(
      'FFT',
      'VLF'
    );
    this.averageValues.FFT.perLF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perLF'
    );
    this.averageValues.FFT.LF = this.calculateBiosignalsAverageValue(
      'FFT',
      'LF'
    );
    this.averageValues.FFT.perHF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perHF'
    );
    this.averageValues.FFT.HF = this.calculateBiosignalsAverageValue(
      'FFT',
      'HF'
    );
    this.averageValues.FFT.total = this.calculateBiosignalsAverageValue(
      'FFT',
      'Total04'
    );

    this.averageValues.IVHH = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'IVHH')
    );
    this.averageValues.ICHH = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'ICHH')
    );
    this.averageValues.IVVH = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'IVVH')
    );
    this.averageValues.ICVH = Math.round(
      +this.calculateBiosignalsAverageValue('TimeAnalysis', 'ICVH')
    );
    this.averageIndex = Math.round(
      (this.averageValues.ICHH + this.averageValues.ICVH) / 2
    );
    this.expertSystemService.ans = this.averageIndex;

    this.cardiacChartData = [
      this.averageValues.FFT_pos_interval.perULF,
      this.averageValues.FFT_pos_interval.perVLF,
      this.averageValues.FFT_pos_interval.perLF,
      this.averageValues.FFT_pos_interval.perHF,
    ];
    this.vascularChartData = [
      this.averageValues.FFT.perULF,
      this.averageValues.FFT.perVLF,
      this.averageValues.FFT.perLF,
      this.averageValues.FFT.perHF,
    ];
    if (this.AnsTableData) {
      this.calculateClassInterpretation(
        this.AnsTableData.cardiacFactorTable,
        true
      );
      this.calculateClassInterpretation(this.AnsTableData.vascularFactorTable);
    }
  }

  setInterpretation() {
    if (+this.averageValues.IVHH >= -10 && +this.averageValues.IVHH <= 10) {
      this.indexDescriptionIVHH = this.AnsTableData.IVSChG.interpretation.find(
        (obj) =>
          +this.averageValues.IVHH >= obj.minValue &&
          +this.averageValues.IVHH <= obj.maxValue
      ).description;
    }
    if (+this.averageValues.ICHH >= 1 && +this.averageValues.ICHH <= 5) {
      this.indexDescriptionICHH = this.AnsTableData.ITsSChG.interpretation.find(
        (obj) => +this.averageValues.ICHH == obj.value
      )['description'];
    }
    if (+this.averageValues.IVVH >= -10 && +this.averageValues.IVVH <= 10) {
      this.indexDescriptionIVVH =
        this.AnsTableData.IVSudChG.interpretation.find(
          (obj) =>
            +this.averageValues.IVVH >= obj.minValue &&
            +this.averageValues.IVVH <= obj.maxValue
        )['description'];
    }
    if (+this.averageValues.ICVH >= 1 && +this.averageValues.ICVH <= 5) {
      this.indexDescriptionICVH =
        this.AnsTableData.ITsSudChG.interpretation.find(
          (obj) => +this.averageValues.ICVH === obj.value
        )['description'];
    }
    // this.chartDescrption.FFT_pos_interval.perULF = this.AnsTableData.cardiacChartInterpretation.ULF.find(obj =>
    //   +this.averageValues.FFT_pos_interval.perULF >= obj.minValue && +this.averageValues.FFT_pos_interval.perULF <= obj.maxValue);

    this.chartDescrption.FFT_pos_interval.perVLF =
      this.AnsTableData.cardiacChartInterpretation.VLF.find(
        (obj) =>
          +this.averageValues.FFT_pos_interval.perVLF >= obj.minValue &&
          +this.averageValues.FFT_pos_interval.perVLF <= obj.maxValue
      );

    this.chartDescrption.FFT_pos_interval.perLF =
      this.AnsTableData.cardiacChartInterpretation.LF.find(
        (obj) =>
          +this.averageValues.FFT_pos_interval.perLF >= obj.minValue &&
          +this.averageValues.FFT_pos_interval.perLF <= obj.maxValue
      );

    this.chartDescrption.FFT_pos_interval.perHF =
      this.AnsTableData.cardiacChartInterpretation.HF.find(
        (obj) =>
          +this.averageValues.FFT_pos_interval.perHF >= obj.minValue &&
          +this.averageValues.FFT_pos_interval.perHF <= obj.maxValue
      );

    // this.chartDescrption.FFT.perULF = this.AnsTableData.vascularChartInterpretation.ULF.find(obj =>
    //   +this.averageValues.FFT.perULF >= obj.minValue && +this.averageValues.FFT.perULF <= obj.maxValue);

    this.chartDescrption.FFT.perVLF =
      this.AnsTableData.vascularChartInterpretation.VLF.find(
        (obj) =>
          +this.averageValues.FFT.perVLF >= obj.minValue &&
          +this.averageValues.FFT.perVLF <= obj.maxValue
      );

    this.chartDescrption.FFT.perLF =
      this.AnsTableData.vascularChartInterpretation.LF.find(
        (obj) =>
          +this.averageValues.FFT.perLF >= obj.minValue &&
          +this.averageValues.FFT.perLF <= obj.maxValue
      );

    this.chartDescrption.FFT.perHF =
      this.AnsTableData.vascularChartInterpretation.HF.find(
        (obj) =>
          +this.averageValues.FFT.perHF >= obj.minValue &&
          +this.averageValues.FFT.perHF <= obj.maxValue
      );
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    let sum = 0,
      count = 0;

    this.biosignals.forEach((biosignal) => {
      if (biosignal[object]) {
        sum += biosignal[object][property];
        count += 1;
      }
    });
    return sum ? (sum / count).toFixed(2) : '0';
  }

  calculateClassInterpretation(table, cardiac?) {
    Object.entries(table).forEach((values) => {
      if (cardiac) {
        Object.entries(values[1]).forEach((val) => {
          if (val[1]['row']) {
            let averageValue;
            if (values[0] === 'spectralStructure') {
              averageValue = this.averageValues.FFT[`per${val[0]}`];
            } else if (values[0] === 'spectralStructureInterval') {
              averageValue =
                this.averageValues.FFT_pos_interval[`per${val[0]}`];
            } else {
              averageValue = this.averageValues[val[0]];
            }
            val[1]['className'] = [];
            this.setActiveClass(
              val[1]['row'],
              val[1]['className'],
              averageValue,
              true
            );
          }
        });
      } else {
        if (values[0] === 'fPercent') {
          if (values[1]['row']) {
            let averageValues = [
              { VLF: this.averageValues.FFT_pos_interval.perVLF },
              { LF: this.averageValues.FFT_pos_interval.perLF },
              { HF: this.averageValues.FFT_pos_interval.perHF },
            ];
            values[1]['className'] = [];
            this.setActiveClass(
              values[1]['row'],
              values[1]['className'],
              averageValues
            );
          }
          if (values[1]['rowNegativeIntervals']) {
            values[1]['classNameNegativeIntervals'] = [];
            let averageValues = [
              { VLF: this.averageValues.FFT_neg_interval.perVLF },
              { LF: this.averageValues.FFT_neg_interval.perLF },
              { HF: this.averageValues.FFT_neg_interval.perHF },
            ];
            this.setActiveClass(
              values[1]['rowNegativeIntervals'],
              values[1]['classNameNegativeIntervals'],
              averageValues
            );
          }
          if (values[1]['rowSignalValue']) {
            let averageValues = [
              { VLF: this.averageValues.FFT.perVLF },
              { LF: this.averageValues.FFT.perLF },
              { HF: this.averageValues.FFT.perHF },
            ];
            values[1]['classNameSignalValue'] = [];
            this.setActiveClass(
              values[1]['rowSignalValue'],
              values[1]['classNameSignalValue'],
              averageValues
            );
          }
        } else if (values[0] === 'Mo') {
          if (values[1]['row']) {
            let averageValues = [
              { Mo: this.averageValues.Mo },
              { Chss: this.averageValues.Chss },
              { ATs: this.averageValues.ATs },
            ];
            values[1]['className'] = [];
            this.setActiveClass(
              values[1]['row'],
              values[1]['className'],
              averageValues
            );
          }
        } else {
          let averageValue = this.averageValues[values[0]];
          values[1]['className'] = [];
          this.setActiveClass(
            values[1]['row'],
            values[1]['className'],
            averageValue
          );
        }
      }
    });
  }

  setActiveClass(cols, colsClasses, averageValues, cardiac?) {
    if (cols) {
      cols.forEach((col, i) => {
        let isEquals: any = [];
        if (cardiac) {
          if (i > 0) {
            colsClasses[i] = '';
            isEquals = this.calculateInterpretation(col, averageValues) || [];
            if (isEquals && isEquals.length) {
              switch (i) {
                case 1: {
                  if (
                    !colsClasses[2] &&
                    !colsClasses[3] &&
                    !colsClasses[4] &&
                    !colsClasses[5]
                  ) {
                    colsClasses[i] = 'success';
                  }
                  break;
                }
                case 2:
                case 3: {
                  if (!colsClasses[4] && !colsClasses[5]) {
                    colsClasses[1] = '';
                    colsClasses[i] = 'warning';
                  }
                  break;
                }
                case 4:
                  colsClasses[1] = '';
                  colsClasses[2] = '';
                  colsClasses[3] = '';
                  colsClasses[i] = 'error';
                case 5:
                  colsClasses[4] = '';
                  colsClasses[1] = '';
                  colsClasses[2] = '';
                  colsClasses[3] = '';
                  colsClasses[i] = 'error';
                  break;
              }
            }
          }
        } else {
          if (i > 1) {
            colsClasses[i] = '';
            isEquals = this.calculateInterpretation(col, averageValues) || [];
            if (isEquals && isEquals.length) {
              switch (i) {
                case 4: {
                  if (
                    !colsClasses[3] &&
                    !colsClasses[5] &&
                    !colsClasses[2] &&
                    !colsClasses[6]
                  ) {
                    colsClasses[i] = 'success';
                  }
                  break;
                }
                case 3:
                case 5: {
                  if (!colsClasses[2] && !colsClasses[6]) {
                    colsClasses[4] = '';
                    colsClasses[i] = 'warning';
                  }
                  break;
                }
                case 2:
                  colsClasses[3] = '';
                  colsClasses[4] = '';
                  colsClasses[5] = '';
                  colsClasses[i] = 'error';
                case 6:
                  colsClasses[2] = '';
                  colsClasses[3] = '';
                  colsClasses[4] = '';
                  colsClasses[5] = '';
                  colsClasses[i] = 'error';
                  break;
              }
            }
          }
        }
      });
    }
  }

  calculateInterpretation(values: any, comparisonValue: any): any {
    if (typeof values.maxValue === 'number') {
      const minValue = values.minValue;
      const maxValue = values.maxValue;
      let idx = [];
      if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
        idx.push(+comparisonValue);
        return idx;
      }
    } else if (Array.isArray(values.maxValue)) {
      if (Array.isArray(comparisonValue)) {
        let idx = [];
        comparisonValue.forEach((item) => {
          const [keys] = Object.entries(item);
          const minValue = values.minValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          const maxValue = values.maxValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          if (minValue && maxValue) {
            if (
              +item[keys[0]] >= +minValue[keys[0]] &&
              +item[keys[0]] <= +maxValue[keys[0]]
            ) {
              idx.push(item[keys[0]]);
            }
          }
        });
        return idx;
      } else {
        let idx = [];
        values.minValue.forEach((el, i) => {
          const minValue: any = Object.values(values.minValue[i])[0];
          const maxValue: any = Object.values(values.maxValue[i])[0];
          if (+comparisonValue > minValue && +comparisonValue <= maxValue) {
            idx.push(+comparisonValue);
          }
        });
        return idx;
      }
    }
  }
}
