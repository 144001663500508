import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Doctor } from '../models/doctor';
import { Schedule } from '../models/schedule';
import { MedicalInstitution } from '../models/medical-institution';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(private http: HttpClient) {}

  getDoctors(withSchedule?: boolean, isConsultant?: any): Observable<Doctor[]> {
    const data = {
      withSchedule,
      isConsultant,
    };
    let params = new HttpParams();
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        params = params.append(`${key}`, `${value}`);
      }
    }
    return this.http.get<Doctor[]>(`${environment.apiUrl}/doctors`, { params });
  }

  getDoctor(id: string): Observable<Doctor> {
    return this.http.get<Doctor>(`${environment.apiUrl}/doctors/${id}`);
  }

  postDoctor(data: Doctor): Observable<Doctor> {
    return this.http.post<Doctor>(`${environment.apiUrl}/doctors`, data);
  }

  updateDoctor(id: string, data: Doctor): Observable<Doctor> {
    return this.http.put<Doctor>(`${environment.apiUrl}/doctors/${id}`, data);
  }

  postSchedule(id: string, data: Schedule): Observable<Schedule> {
    return this.http.post<Schedule>(
      `${environment.apiUrl}/doctors/${id}/schedule`,
      data
    );
  }

  updateSchedule(
    consultantId: string,
    scheduleId: any,
    data: Schedule
  ): Observable<Schedule> {
    return this.http.put<Schedule>(
      `${environment.apiUrl}/doctors/${consultantId}/schedule/${scheduleId}`,
      data
    );
  }

  assignDoctor(data: any) {
    return this.http.patch<any>(
      `${environment.apiUrl}/patients/assign-doctor`,
      data
    );
  }

  getMedicalInstitutions() {
    return this.http.get<MedicalInstitution>(
      `${environment.apiUrl}/medical-institutions`
    );
  }

  postMedicalInstitution(data: MedicalInstitution) {
    return this.http.post<MedicalInstitution>(
      `${environment.apiUrl}/medical-institutions`,
      data
    );
  }

  postPhoto(id, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/doctors/${id}/photo`, data);
  }

  getPhoto(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/doctors/${id}/photo`, {
      responseType: 'blob',
    });
  }

  updatePassword(id: string, data: { password: string }): Observable<Doctor> {
    return this.http.put<Doctor>(
      `${environment.apiUrl}/doctors/update-password/${id}`,
      data
    );
  }
}
