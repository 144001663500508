<div class="row">
  <div class="col">
    <div class="expert-table-wrapper">
      <h4>{{ "measurement.data_medical" | translate }}</h4>
      <p class="expand-table" (click)="expand()">
        <ng-container *ngIf="expandRow">{{
          "measurement.hide" | translate
        }}</ng-container>
        <ng-container *ngIf="!expandRow">{{
          "measurement.expand" | translate
        }}</ng-container>
        <i class="bi bi-chevron-bar-contract contract" *ngIf="expandRow"></i>
        <i class="bi bi-chevron-bar-expand expand" *ngIf="!expandRow"></i>
      </p>
      <div class="table-wrapper" [ngClass]="{ 'loader-section': tableBusy }">
        <div class="loader" *ngIf="tableBusy">
          <div class="spinner-border" role="status"></div>
        </div>
        <table
          id="myTable"
          class="expert-table-style-3"
          matSort
          (matSortChange)="sortData($event)"
        >
          <thead>
            <tr>
              <th></th>
              <th mat-sort-header="patient" *ngIf="!patientData">
                {{ "measurement.patient" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="date">
                {{ "measurement.date" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th *ngIf="expandRow">
                {{ "measurement.name_group" | translate }}
              </th>
              <th *ngIf="expandRow">
                {{ "measurement.medical_institution" | translate }}
              </th>
              <th *ngIf="expandRow">{{ "measurement.doctor" | translate }}</th>
              <th>{{ "measurement.type" | translate }}</th>
              <th *ngIf="expandRow">
                {{ "measurement.calc_status" | translate }}
              </th>
              <th *ngIf="expandRow">{{ "measurement.status" | translate }}</th>
              <th *ngIf="expandRow">{{ "measurement.phase" | translate }}</th>
              <th mat-sort-header="Systola">
                {{ "calculation.ats" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="Diastola">
                {{ "calculation.atd" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="HeartRate">
                {{ "calculation.chss" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="pars">
                {{ "calculation.pars" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th mat-sort-header="ifsp">
                {{ "calculation.IFVP" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th *ngIf="expandRow" mat-sort-header="msr">
                {{ "calculation.msr" | translate }}
                <i class="bi bi-sort-alpha-down down"></i>
                <i class="bi bi-sort-alpha-up-alt up"></i>
              </th>
              <th *ngIf="expandRow">
                {{ "measurement.blood_glucose" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let element of tableSortedData
                  | paginate
                    : {
                        currentPage: pageN,
                        itemsPerPage: itemsPerPage,
                        id: 'pagination1'
                      };
                let i = index
              "
            >
              <app-item-row
                [itemsPerPage]="itemsPerPage"
                [page]="pageN"
                [data]="element"
                [dataIndex]="i"
                [dataExpand]="expandRow"
                (selected)="onSelect($event)"
                (changed)="updateBiosignal($event)"
                (loaded)="loaded($event)"
              ></app-item-row>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <paging-controls
      id="pagination1"
      (pageChange)="pageN = $event"
      [isItemsPerPage]="false"
      maxSize="9"
      directionLinks="true"
      autoHide="true"
      responsive="true"
    ></paging-controls>
  </div>
</div>
