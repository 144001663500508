<div class="row">
    <div class="col-md">
      {{ "efficiency.record_results_test" | translate }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md">
      <p>
        {{ "efficiency.task" | translate }} 1
        <br />
        {{ "efficiency.martine_test_task_1" | translate }}
      </p>
      <p>
        {{ "efficiency.task" | translate }} 2
        <br />
        {{ "efficiency.martine_test_task_2" | translate }}
      </p>
    </div>
  </div>
  <button class="m-2 buttonClick" (click)="calculateColumns()">
    {{ "efficiency.calculate" | translate }}
  </button>
  <button
    *ngIf="saveInfo"
    class="buttonClick m-2 buttonClickGreen"
    (click)="saveData()"
  >
    {{ "administrator.save" | translate }}
  </button>
  <button
    *ngIf="saveEditInfo"
    class="buttonClick m-2 buttonClickGreen"
    (click)="saveEditTest()"
  >
    {{ "efficiency.saveEdit" | translate }}
  </button>
  
  <button
    *ngIf="addColumnBtn"
    class="buttonClick m-2 buttonClickYellow"
    (click)="addColumn()"
  >
    {{ "efficiency.add_column" | translate }}
  </button>
  
  <div class="gwt-Table-Wrapper">
    <form [formGroup]="myForm" novalidate>
      <table class="table table-hover tableGrey">
        <thead>
          <tr>
            <th scope="col" style="text-align: center">
              {{ "efficiency.seconds" | translate }}
            </th>
            <th scope="col" style="text-align: center">
              {{ "efficiency.before" | translate }}
              {{ "efficiency.load" | translate }}
            </th>
            <th
              scope="col"
              [attr.colspan]="titlecColspan"
              style="text-align: center"
            >
              {{ "efficiency.after" | translate }}
              {{ "efficiency.load" | translate }} <br />
              {{ "efficiency.hr_pressure" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row"></th>
            <td style="font-weight: bold">{{ "efficiency.hr" | translate }}</td>
            <td style="font-weight: bold">
              1 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
            <td style="font-weight: bold">
              2 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
            <td style="font-weight: bold">
              3 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
            <td *ngIf="column4" style="font-weight: bold">
              4 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
            <td *ngIf="column5" style="font-weight: bold">
              5 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
            <td *ngIf="column6" style="font-weight: bold">
              6 {{ "efficiency.minute" | translate }} ({{
                "efficiency.hr" | translate
              }})
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.type_load" | translate }}</th>
            <td colspan="4">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="type_load"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.date" | translate }}</th>
            <td colspan="2">
              <mat-form-field style="width: 50%">
                <mat-label>{{ "efficiency.date" | translate }}</mat-label>
                <input
                  placeholder="mm/dd/yyyy"
                  matInput
                  [matDatepicker]="date"
                  formControlName="date"
                />
                <mat-datepicker-toggle matSuffix [for]="date">
                  <mat-icon
                    matDatepickerToggleIcon
                    svgIcon="calendar"
                    matTooltip="{{ 'hr_department.select_date' | translate }}"
                    [matTooltipPosition]="'above'"
                  >
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.signal" | translate }}</th>
            <td>
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_hr')"
                  type="file"
                  accept="all/*"
                  #signal_hr
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_hr"
                  [value]="signalHRFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_hr.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_before')"
                  type="file"
                  accept="all/*"
                  #signal_before
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_before"
                  [value]="signalBeforeFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_before.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_after')"
                  type="file"
                  accept="all/*"
                  #signal_after
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_after"
                  [value]="signalAfterFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_after.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_3min')"
                  type="file"
                  accept="all/*"
                  #signal_3min
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_3min"
                  [value]="signal3minFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_3min.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_4min')"
                  type="file"
                  accept="all/*"
                  #signal_4min
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_4min"
                  [value]="signal4minFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_4min.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_5min')"
                  type="file"
                  accept="all/*"
                  #signal_5min
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_5min"
                  [value]="signal5minFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_5min.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field floatPlaceholder="never">
                <input
                  [hidden]="true"
                  (change)="onFileSelectSignal($event, 'signal_6min')"
                  type="file"
                  accept="all/*"
                  #signal_6min
                />
                <input
                  readonly
                  matInput
                  formControlName="signal_6min"
                  [value]="signal6minFileName"
                  placeholder="{{ 'hr_department.select_file' | translate }}..."
                />
                <button
                  matSuffix
                  mat-icon-button
                  (click)="signal_6min.click()"
                  matTooltip="{{ 'hr_department.select_file' | translate }}"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon svgIcon="attach"></mat-icon>
                </button>
              </mat-form-field>
            </td>
          </tr>
  
          <tr>
            <th scope="row">10</th>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10"
                />
              </mat-form-field>
              <mat-error
                *ngIf="myForm.get('hr10').errors && myForm.get('hr10').touched"
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_1min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('hr10s_after_1min').errors &&
                  myForm.get('hr10s_after_1min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_2min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('hr10s_after_2min').errors &&
                  myForm.get('hr10s_after_2min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr10s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">20</th>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20"
                />
              </mat-form-field>
              <mat-error
                *ngIf="myForm.get('hr20').errors && myForm.get('hr20').touched"
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td></td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20s_after_2min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('hr20s_after_2min').errors &&
                  myForm.get('hr20s_after_2min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr20s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">30</th>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30"
                />
              </mat-form-field>
              <mat-error
                *ngIf="myForm.get('hr30').errors && myForm.get('hr30').touched"
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td></td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30s_after_2min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('hr30s_after_2min').errors &&
                  myForm.get('hr30s_after_2min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr30s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">40</th>
            <td></td>
            <td></td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr40s_after_2min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('hr40s_after_2min').errors &&
                  myForm.get('hr40s_after_2min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr40s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr40s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr40s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr40s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">50</th>
            <td></td>
            <td></td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr50s_after_2min"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr50s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr50s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr50s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr50s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">60</th>
            <td></td>
            <td></td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr60s_after_2min"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr60s_after_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr60s_after_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr60s_after_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="hr60s_after_6min"
                />
              </mat-form-field>
            </td>
          </tr>
  
          <tr>
            <th scope="row">{{ "efficiency.asp" | translate }}</th>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_hr" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('ASP_hr').errors && myForm.get('ASP_hr').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_before" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('ASP_before').errors &&
                  myForm.get('ASP_before').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_after" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_3min" />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_4min" />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_5min" />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input matInput type="number" formControlName="ASP_6min" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.adp" | translate }}</th>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_hr" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('ADP_hr').errors && myForm.get('ADP_hr').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_before" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('ADP_before').errors &&
                  myForm.get('ADP_before').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_after" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_3min" />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_4min" />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_5min" />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input matInput type="number" formControlName="ADP_6min" />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.breath" | translate }}</th>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_HR"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('breath_HR').errors &&
                  myForm.get('breath_HR').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_1min"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  myForm.get('breath_1min').errors &&
                  myForm.get('breath_1min').touched
                "
              >
                {{ "administrator.no_enter_data" | translate }}
              </mat-error>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_2min"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_3min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column4">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_4min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column5">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_5min"
                />
              </mat-form-field>
            </td>
            <td *ngIf="column6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="breath_6min"
                />
              </mat-form-field>
            </td>
          </tr>
          <!-- ---------------дальше нова таблиця з результатми обрахунків -->
          <tr>
            <th scope="row">
              {{ "efficiency.pulse_excitability" | translate }} (%)
            </th>
            <td>
              <mat-form-field>
                <input matInput formControlName="result_after" readonly="true" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <input
                  matInput
                  formControlName="conclusions_after"
                  readonly="true"
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.pulse_recovery_time" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="reset_time_puls"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.negative_phase_of_the_pulse" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="negative_phaze_puls"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.reaction" | translate }}
              {{ "efficiency.asp" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="reaction_ASP"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.reaction" | translate }}
              {{ "efficiency.adp" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="reaction_ADP"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.reaction" | translate }}
              {{ "efficiency.atp" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="reaction_AP"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.blood_pressure_recovery_time" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="time_reset_blood_pressure"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.respiratory_rate" | translate }}</th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="respiratory_rate"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "efficiency.reaction_quality_index" | translate }}
            </th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="RQI"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.type_reaction" | translate }}</th>
            <td colspan="2">
              <mat-form-field>
                <textarea
                  matInput
                  formControlName="type_reaction"
                  readonly="true"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ "efficiency.recomendations" | translate }}</th>
            <td colspan="3">
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ 'efficiency.write_here' | translate }}"
                  formControlName="recomendation"
                />
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  
  <!------------------------------------------------- table---------------------------- -->
  
  <table class="table tableGreen table-hover mt-5">
    <thead>
      <tr>
        <th scope="col">{{ "efficiency.corps" | translate }}</th>
        <th scope="col">{{ "efficiency.department" | translate }}</th>
        <th scope="col">{{ "efficiency.name" | translate }}</th>
        <th scope="col">{{ "efficiency.position" | translate }}</th>
        <th scope="col">{{ "efficiency.type_load" | translate }}</th>
        <th scope="col">{{ "efficiency.date" | translate }}</th>
        <th scope="col">{{ "efficiency.value" | translate }}</th>
        <th scope="col">{{ "efficiency.conclusions" | translate }}</th>
        <th scope="col">{{ "efficiency.recomendations" | translate }}</th>
        <th scope="col">{{ "efficiency.signal" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <mat-icon *ngIf="!testData" class="spiner" svgIcon="spiner"></mat-icon>
      <ng-container *ngFor="let item of testData; let i = index">
        <tr class="test" (click)="viewFullStaffInfo(i)">
          <td>{{ item.staff_id.attributed_corpuses[0] | lang }}</td>
          <td>{{ item.staff_id.attributed_departments[0] | lang }}</td>
          <td>{{ item.staff_id.last_name }} {{ item.staff_id.first_name }}</td>
          <td>{{ item.staff_id.positions_id.name }}</td>
          <td>{{ item.type_load }}</td>
          <td>{{ item.date | date : "shortDate" }}</td>
          <td>{{ item.resultTableView }}</td>
          <td>{{ item.conclusionsTableView }}</td>
          <td>{{ item.recomendation }}</td>
          <!-- <td>{{item.signal_after}}</td> -->
          <td><a href="#">view</a></td>
        </tr>
  
        <!-- <div *ngIf="!item.showFullInfo" class="details" matTooltip="Розгорнути" [matTooltipPosition]="'above'">
            <mat-icon svgIcon="doble_arrow_down"></mat-icon>
            Детальніше
            <mat-icon svgIcon="doble_arrow_down"></mat-icon>
          </div> -->
  
        <tr *ngIf="item.showFullInfo" style="background-color: #daf0e2">
          <td colspan="10">
            <table class="tableBlueBorder" style="width: 1000px">
              <thead>
                <tr>
                  <th scope="col">{{ "efficiency.seconds" | translate }}</th>
                  <th scope="col">
                    {{ "efficiency.before" | translate }}
                    {{ "efficiency.load" | translate }}
                  </th>
                  <th scope="col">
                    {{ "efficiency.after" | translate }}
                    {{ "efficiency.load" | translate }}
                    <br />
                    {{ "efficiency.hr_pressure" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"></th>
                  <td style="font-weight: bold">
                    {{ "efficiency.hr" | translate }}
                  </td>
                  <td style="font-weight: bold">
                    1 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                  <td style="font-weight: bold">
                    2 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                  <td style="font-weight: bold">
                    3 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                  <td *ngIf="column4" style="font-weight: bold">
                    4 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                  <td *ngIf="column5" style="font-weight: bold">
                    5 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                  <td *ngIf="column6" style="font-weight: bold">
                    6 {{ "efficiency.minute" | translate }} ({{
                      "efficiency.hr" | translate
                    }})
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.type_load" | translate }}</th>
                  <td colspan="3">{{ item.type_load }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.date" | translate }}</th>
                  <td colspan="3">{{ item.date | date : "shortDate" }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.signal" | translate }}</th>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
  
                <tr>
                  <th scope="row">10</th>
                  <td>{{ item.hr10 }}</td>
                  <td>{{ item.hr10s_after_1min }}</td>
                  <td>{{ item.hr10s_after_2min }}</td>
                  <td>{{ item.hr10s_after_3min }}</td>
                  <td>{{ item.hr10s_after_4min }}</td>
                  <td>{{ item.hr10s_after_5min }}</td>
                  <td>{{ item.hr10s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">20</th>
                  <td>{{ item.hr20 }}</td>
                  <td></td>
                  <td>{{ item.hr20s_after_2min }}</td>
                  <td>{{ item.hr20s_after_3min }}</td>
                  <td>{{ item.hr20s_after_4min }}</td>
                  <td>{{ item.hr20s_after_5min }}</td>
                  <td>{{ item.hr20s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">30</th>
                  <td>{{ item.hr30 }}</td>
                  <td></td>
                  <td>{{ item.hr30s_after_2min }}</td>
                  <td>{{ item.hr30s_after_3min }}</td>
                  <td>{{ item.hr30s_after_4min }}</td>
                  <td>{{ item.hr30s_after_5min }}</td>
                  <td>{{ item.hr30s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">40</th>
                  <td></td>
                  <td></td>
                  <td>{{ item.hr40s_after_2min }}</td>
                  <td>{{ item.hr40s_after_3min }}</td>
                  <td>{{ item.hr40s_after_4min }}</td>
                  <td>{{ item.hr40s_after_5min }}</td>
                  <td>{{ item.hr40s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">50</th>
                  <td></td>
                  <td></td>
                  <td>{{ item.hr50s_after_2min }}</td>
                  <td>{{ item.hr50s_after_3min }}</td>
                  <td>{{ item.hr50s_after_4min }}</td>
                  <td>{{ item.hr50s_after_5min }}</td>
                  <td>{{ item.hr50s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">60</th>
                  <td></td>
                  <td></td>
                  <td>{{ item.hr60s_after_2min }}</td>
                  <td>{{ item.hr60s_after_3min }}</td>
                  <td>{{ item.hr60s_after_4min }}</td>
                  <td>{{ item.hr60s_after_5min }}</td>
                  <td>{{ item.hr60s_after_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.asp" | translate }}</th>
                  <td>{{ item.ASP_hr }}</td>
                  <td>{{ item.ASP_before }}</td>
                  <td>{{ item.ASP_after }}</td>
                  <td>{{ item.ASP_3min }}</td>
                  <td>{{ item.ASP_4min }}</td>
                  <td>{{ item.ASP_5min }}</td>
                  <td>{{ item.ASP_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.adp" | translate }}</th>
                  <td>{{ item.ADP_hr }}</td>
                  <td>{{ item.ADP_before }}</td>
                  <td>{{ item.ADP_after }}</td>
                  <td>{{ item.ADP_3min }}</td>
                  <td>{{ item.ADP_4min }}</td>
                  <td>{{ item.ADP_5min }}</td>
                  <td>{{ item.ADP_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ "efficiency.breath" | translate }}</th>
                  <td>{{ item.breath_HR }}</td>
                  <td>{{ item.breath_1min }}</td>
                  <td>{{ item.breath_2min }}</td>
                  <td>{{ item.breath_3min }}</td>
                  <td>{{ item.breath_4min }}</td>
                  <td>{{ item.breath_5min }}</td>
                  <td>{{ item.breath_6min }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.pulse_excitability" | translate }} (%)
                  </th>
                  <td>{{ item.result_after }}</td>
                  <td>{{ item.conclusions_after }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.pulse_recovery_time" | translate }}
                  </th>
                  <td>{{ item.reset_time_puls }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.negative_phase_of_the_pulse" | translate }}
                  </th>
                  <td>{{ item.negative_phaze_puls }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.reaction" | translate }}
                    {{ "efficiency.asp" | translate }}
                  </th>
                  <td>{{ item.reaction_ASP }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.reaction" | translate }}
                    {{ "efficiency.adp" | translate }}
                  </th>
                  <td>{{ item.reaction_ADP }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.reaction" | translate }}
                    {{ "efficiency.atp" | translate }}
                  </th>
                  <td>{{ item.reaction_AP }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.blood_pressure_recovery_time" | translate }}
                  </th>
                  <td>{{ item.time_reset_blood_pressure }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.respiratory_rate" | translate }}
                  </th>
                  <td>{{ item.respiratory_rate }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.reaction_quality_index" | translate }}
                  </th>
                  <td>{{ item.RQI }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.type_reaction" | translate }}
                  </th>
                  <td>{{ item.type_reaction }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ "efficiency.recomendations" | translate }}
                  </th>
                  <td colspan="3">{{ item.recomendation }}</td>
                </tr>
                <tr>
                  <td>
                    <mat-icon
                      matTooltip="{{ 'administrator.edit' | translate }}"
                      [matTooltipPosition]="'above'"
                      class="ml-1"
                      svgIcon="edit"
                      (click)="editTestStart(item)"
                    ></mat-icon>
                  </td>
                  <td>
                    <mat-icon
                      matTooltip="{{ 'administrator.delete' | translate }}"
                      [matTooltipPosition]="'above'"
                      class="ml-1"
                      svgIcon="delete"
                      (click)="deleteTest(item._id)"
                    ></mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <!-- <div *ngIf="item.showFullInfo" class="details" matTooltip="Згорнути" [matTooltipPosition]="'above'">
            <mat-icon svgIcon="doble_arrow_up"></mat-icon>
            Згорнути
            <mat-icon svgIcon="doble_arrow_up"></mat-icon>
          </div> -->
      </ng-container>
    </tbody>
  </table>