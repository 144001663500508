import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { LangModule } from '../global-components/lang/lang.module';
import { MaterialModule } from 'src/app/material.module';
import { SvgModule } from 'src/app/svg.module';
import { ItemRowComponent } from './item-row/item-row.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MeasurementTableComponent } from './measurement-table/measurement-table.component';
import {PatientsListModule} from "../global-components/patients-list/patients-list.module";

@NgModule({
  declarations: [HomeComponent, ItemRowComponent, MeasurementTableComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    LangModule,
    MaterialModule,
    SvgModule,
    ReactiveFormsModule,
    FormsModule,
    PatientsListModule,
  ],
})
export class HomeModule {}
