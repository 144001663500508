import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { AdminService } from 'src/app/services/admin.service';
import { DataTypeService } from 'src/app/services/data-type.service';
import { RightsService } from 'src/app/services/rights.service';
import { StaffService } from 'src/app/services/staff.service';
import { AuthService } from '../../services/auth/auth.service';
import { Rights } from '../admin/constants/rights.enum';
import { PatientService } from '../measurements-data/services/patient.service';
import { WithingsService } from 'src/app/services/withings.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WithingPanelComponent } from '../modals/withing-panel/withing-panel.component';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoleRegistrationPatientComponent } from '../global-components/role-registration-patient/role-registration-patient.component';
import { RegistrationService } from 'src/app/services/registration.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Patient } from 'src/app/models/patient';
import {PatientListMode} from '../../enums/patient-list.enum';

@Component({
  selector: 'app-main-panel',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends OnDestroyMixin implements OnInit {
  showMenu: boolean = false;
  user;
  patients: Patient[];
  patient: Patient;
  patientId: string | null = null;
  photo;
  Rights: typeof Rights = Rights;
  photoExist: boolean = false;
  endRoute: string = '';

  ACTIVE_GRAPH: GraphType = GraphType.AO;

  busy: boolean = false;
  patientsSearchData = '';

  dialogRef?: MatDialogRef<RoleRegistrationPatientComponent, boolean>;
  chosenPatient?: Patient;

  constructor(
    public auth: AuthService,
    public patientService: PatientService,
    private sanitizer: DomSanitizer,
    private adminService: AdminService,
    public rightsService: RightsService,
    private staffService: StaffService,
    public dataTypeService: DataTypeService,
    public authService: AuthService,
    public withingsService: WithingsService,
    public router: Router,
    public modalService: NgbModal,
    private measurementsDataUpdateService: MeasurementsDataUpdateService,
    public biosignalsService: BiosignalsService,
    public dialog: MatDialog,
    private registrationService: RegistrationService,
    private toastr: ToastrService
  ) {
    super();
    this.router.events.pipe(untilComponentDestroyed(this)).subscribe((arg) => {
      if (arg instanceof NavigationEnd) {
        let url = arg.url.split('/');
        this.endRoute = url[url.length - 1];
      }
    });
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.init();

    this.adminService
      .get()
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        this.rightsService.setRightsAll(resp);
        this.setStartConfig();
      });

    this.auth.photo$.pipe(untilComponentDestroyed(this)).subscribe((resp) => {
      if (!this.auth.isAdmin()) {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          let objectURL = URL.createObjectURL(resp);
          this.photo =
            this.sanitizer.bypassSecurityTrustUrl(objectURL)[
              'changingThisBreaksApplicationSecurity'
            ];
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.rightsService.setRightsAll(null);
  }

  init() {
    if (!this.auth.isAdmin()) {
      if (!this.auth.isPatient()) {
        this.getPhotoDoctor();
        this.getPatients();
      } else {
        this.getPhoto(this.user._id);
      }
    } else {
      this.getPatients();
      if (this.patient && this.patient?._id) this.getPhoto(this.patient._id);
    }
  }

  addNewPatient() {
    this.dialogRef = this.dialog.open(RoleRegistrationPatientComponent, {
      width: '90%',
    });
    this.dialogRef.componentInstance.registration.subscribe((myForm) => {
      this.registrationService.sendRegistrationData(myForm.value).subscribe(
        (res) => {
          this.toastr.success(
            new TranslatePipe(this.authService).transform({
              ua: 'Успішно',
              en: 'Success',
            })
          );
          this.getPatients();
          this.dialogRef.close();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
    });
  }

  updateWithingsSignals() {
    this.busy = true;
    this.withingsService
      .updateListSignals(
        !this.auth.isPatient() ? this.patient._id : this.user._id
      )
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        (resp) => {
          this.busy = false;
          if (resp.length > 0)
            this.toastr.success(`Success! Added ${resp.length} new signals.`);
          else {
            this.toastr.warning(`No new data`);
          }
        },
        (err: any) => {
          this.busy = false;
          this.toastr.error(err.message);
        }
      );
  }

  setStartConfig() {
    this.dataTypeService.changeType(GraphType.AO);
    this.rightsService.setRights(GraphType.AO);
  }

  getPatients() {
    this.patientService
      .getDoctorPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.patients = data;
      });
    this.patientService.patient$
      .pipe(untilComponentDestroyed(this))
      .subscribe((patient) => {
        if (patient) {
          this.patient = patient;
          this.patientId = patient._id;
        } else {
          this.patient = null;
          this.patientId = null;
        }
      });
  }

  getPhotoDoctor() {
    this.photoExist = false;
    this.staffService
      .getPhoto(this.user._id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          this.auth.photo = resp;
          let objectURL = URL.createObjectURL(resp);
          this.photo =
            this.sanitizer.bypassSecurityTrustUrl(objectURL)[
              'changingThisBreaksApplicationSecurity'
            ];
        }
      });
  }

  getPhoto(id: any) {
    this.photoExist = false;
    this.patientService
      .getPhoto(id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        if (resp && resp.size !== 0 && resp.type !== 'application/json') {
          this.photoExist = true;
          this.auth.photo = resp;
          let objectURL = URL.createObjectURL(resp);
          this.photo =
            this.sanitizer.bypassSecurityTrustUrl(objectURL)[
              'changingThisBreaksApplicationSecurity'
            ];
        }
      });
  }

  selectPatient(data: string) {
    let patientObj = this.patients.find((p) => p?._id == data);

    if (patientObj) {
      this.patientService.setPatient = patientObj;
      localStorage.setItem('selectedPatient', JSON.stringify(patientObj));
      if (localStorage.getItem('rufierTest') !== null) {
        localStorage.removeItem('rufierTest');
      }
      if (!this.auth.isDoctor() && !this.auth.isConsultant())
        this.getPhoto(data);
    } else {
      this.patientService.setPatient = null;
      localStorage.removeItem('selectedPatient');
      if (localStorage.getItem('rufierTest') !== null) {
        localStorage.removeItem('rufierTest');
      }
    }
  }

  logout(): void {
    this.auth.logout();
    this.patientService.setPatient = null;
    localStorage.clear();
  }

  redirectToWithings() {
    this.withingsService
      .redirectToAuth(this.user._id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((url) => {
        this.generateRedirectUri(url);
      });
  }

  generateRedirectUri(url) {
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', `_blank`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  openWithingPanel() {
    let idUser = this.auth.isPatient() ? this.user._id : this.patient._id;
    let idUserWithings = this.auth.isPatient()
      ? this.user?.withings_access?.userid
      : this.patient?.withings_access?.userid;
    const modal = this.modalService.open(WithingPanelComponent, {
      backdrop: false,
    });
    modal.componentInstance.patientId = idUser;
    modal.componentInstance.withings = idUserWithings;
    modal.result
      .then((result) =>
        this.measurementsDataUpdateService.changeMeasurementsNumber(15)
      )
      .catch(() =>
        this.measurementsDataUpdateService.changeMeasurementsNumber(15)
      );
  }

  protected readonly PatientListMode = PatientListMode;
}
