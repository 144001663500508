<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Equipment</h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ "admin.text_confirm" | translate }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="close()">
    {{ "login.cancel" | translate }}
  </button>
  <button class="btn btn-danger" (click)="submit()">
    {{ "measurement.confirm" | translate }}
  </button>
</div>
