import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { DataTypeService } from 'src/app/services/data-type.service';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-raw-signal-chart',
  templateUrl: './raw-signal-chart.component.html',
  styleUrls: ['./raw-signal-chart.component.scss'],
})
export class RawSignalChartComponent implements OnInit {
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;
  @Input() set data(v) {
    this.oscillogramData = v;

  }
  @Input() initData;

  oscillogramData: any;
  oscillogramDataFirst: any;

  constructor(
    public dataTypeService: DataTypeService,
    private cd: ChangeDetectorRef,
    public measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
      this.cd.detectChanges();
    });
  }
  ngOnInit(): void {
    this.measurementsDataUpdateService.selectedOscillogram$.subscribe(
      (oscillogramData) => {
        this.oscillogramData = oscillogramData;
        let keys = Object.keys(this.oscillogramData);

        if (keys[0] == 'timePoints' || 'valuePoints') {
          this.oscillogramDataFirst = this.oscillogramData;
        } else this.oscillogramDataFirst = this.oscillogramData[keys[0]];

      }
    );
  }
}
