<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="form-container card-box">
    <div class="row">
      <div class="col">
        <h1 class="text-center">
          {{ (isAdmin ? "login.header_super" : "login.header") | translate }}
        </h1>
      </div>
    </div>

    <form
      name="form"
      #f="ngForm"
      [formGroup]="signInForm"
      (ngSubmit)="onSubmitForm(signInForm)"
      [ngClass]="error ? 'form-error' : ''"
    >
      <mat-form-field class="col p-0">
        <mat-label>{{ "login.login" | translate }}</mat-label>
        <input matInput type="text" id="email" formControlName="username" />
        <mat-icon matPrefix svgIcon="icon-person" class="icon mr-1"></mat-icon>
      </mat-form-field>
      <mat-form-field class="col p-0">
        <mat-label>{{ "login.password" | translate }}</mat-label>
        <input
          id="password"
          matInput
          formControlName="password"
          [type]="isVisiblePassword ? 'text' : 'password'"
        />
        <mat-icon matPrefix svgIcon="icon-lock" class="icon mr-1"> </mat-icon>
        <mat-icon
          (click)="isVisiblePassword = !isVisiblePassword"
          matSuffix
          class="icon mr-1"
          fontIcon="{{ isVisiblePassword ? 'bi-eye' : 'bi-eye-slash' }}"
        >
        </mat-icon>
      </mat-form-field>
      <div class="form-group" *ngIf="error">
        <small class="error-wrapper">
          <mat-icon
            matPrefix
            svgIcon="error-wrapper"
            class="icon mr-2"
          ></mat-icon>
          <span>{{ "login.error" | translate }}</span>
        </small>
      </div>
      <div class="form-group">
        <button id="login" class="btn btn-primary fill w-100" type="submit">
          {{ "login.enter" | translate }}
        </button>
      </div>
      <a
        routerLink="/auth/password-recovery"
        *ngIf="!isAdmin"
        class="link small-link"
        >{{ "login.recovery" | translate }}?</a
      >
    </form>
  </div>
</div>
