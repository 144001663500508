<table id="myTable" class="expert-table-style-2">
  <thead>
    <tr>
      <th>
        {{ "calculation.indicator" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.ats_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.ats" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.atd_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.atd" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.chss_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.chss" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.MAP_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        MAP
      </th>
      <th
        matTooltip="{{ 'calculation.PAP_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        PAP
      </th>
      <th
        matTooltip="{{ 'calculation.pars_ao_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.pars_ao" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.IFVP_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.IFVP" | translate }}І
      </th>
      <th
        matTooltip="{{ 'calculation.msr_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.msr" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.ITsSChG_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.ITsSChG" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.ITsSudChG_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.ITsSudChG" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.IVSChG_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.IVSChG" | translate }}
      </th>
      <th
        matTooltip="{{ 'calculation.IVSudChG_full' | translate }}"
        [matTooltipPosition]="'above'"
      >
        {{ "calculation.IVSudChG" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let element of tableData"
      [style.background]="dipperClass(element.indicator)"
    >
      <td
        matTooltip="{{ 'calculation.table_message.' + element.indicator | translate }}"
        [matTooltipPosition]="'left'"
      >
        {{ element.indicator }}
      </td>
      <td>{{ element.Systola }}</td>
      <td>{{ element.Diastola }}</td>
      <td>{{ element.HeartRate }}</td>
      <td>{{ element.MAP }}</td>
      <td>{{ element.PP }}</td>
      <td>{{ element.PARSint }}</td>
      <td>{{ element.IFSP }}</td>
      <td>{{ element.Ms }}</td>
      <td>{{ element.ICHH }}</td>
      <td>{{ element.ICVH }}</td>
      <td>{{ element.IVHH }}</td>
      <td>{{ element.IVVH }}</td>
    </tr>
  </tbody>
</table>
