import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { merge } from 'rxjs';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-oscillogram-dialog',
  templateUrl: './oscillogram-dialog.component.html',
  styleUrls: ['./oscillogram-dialog.component.scss'],
})
export class OscillogramnDialogComponent implements OnInit {
  @Input() public biosignals;
  selectedBiosignalIndex;
  biosignalsFmtDates: string[] = [];
  oscilogramData: any;

  form = new FormGroup({
    source_switch_id: new FormControl(),
  });

  source_switch_id_copy = new Set<string>();
  oscilogramB = new Set<any>();
  constructor(
    public modal: NgbActiveModal,
    private measurementsDataUpdateService: MeasurementsDataUpdateService
  ) {}

  ngOnInit(): void {
    const dateOptions: any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const dateFormat = Intl.DateTimeFormat('uk-UA', dateOptions);
    this.biosignals.forEach((biosignal, i) => {
      const date = new Date(biosignal.SignalRegistrationDateTime);
      biosignal['dataLabel'] = `${i + 1}. ${dateFormat.format(date)}`;
    });
  }

  onClose() {
    this.modal.close();
  }

  deleteOsc(id: string) {
    let source_switch_id = this.form.get('source_switch_id').value;
    const idx = Array.from(this.oscilogramB).findIndex((obj) => obj._id == id);
    if (idx !== -1) {
      this.oscilogramB.delete(Array.from(this.oscilogramB)[idx]);
    }
    const idx2 = source_switch_id.findIndex((_id) => _id == id);
    if (idx2 !== -1) {
      source_switch_id.splice(idx2, 1);
      this.form.get('source_switch_id').setValue(source_switch_id);
    }
  }

  onBiosignalChange(arr) {
    const newIds = new Set<string>(
      arr.map((v) => (typeof v === 'string' ? v : v._id))
    );
    const oldIds = this.source_switch_id_copy;
    const addedIds = [...newIds].filter((id) => !oldIds.has(id));
    const removedIds = [...oldIds].filter((id) => !newIds.has(id));
    this.source_switch_id_copy = newIds;
    addedIds.forEach((id) => {
      this.measurementsDataUpdateService.changeSelectedComparisonOscillogram(
        id
      );
      this.measurementsDataUpdateService.selectedComparisonOscillogram$.subscribe(
        (resp) => {
          if (!this.hasId(this.oscilogramB, id)) {
            this.oscilogramB.add({
              _id: id,
              oscilogramData: resp,
              item: arr.find((v) => v._id === id),
            });
          }
        }
      );
    });

    removedIds.forEach((id) => this.deleteOsc(id));
  }

  hasId = (setId: Set<any>, idToCheck: string): boolean => {
    for (const item of setId) {
      if (item._id === idToCheck) {
        return true;
      }
    }
    return false;
  };
}
