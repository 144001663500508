import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  protected subjects: any = {};

  constructor() {}
  cols = [
    { id: 1, field: 'number', pl: '№', ua: '№', en: '№' },
    {
      id: 2,
      field: 'numberFull',
      pl: '№ (pełny)',
      ua: '№ (повна)',
      en: '№ (full)',
    },
    { id: 3, field: 'equipmentID', pl: 'ID', ua: 'ID', en: 'ID' },
    { id: 4, field: 'equipmentMacId', pl: 'MacId', ua: 'MacId', en: 'MacId' },
    {
      id: 5,
      field: 'equipmentName',
      pl: 'Nazwa licznika',
      ua: 'Назва вимірювача',
      en: 'The name of the meter',
    },
    {
      id: 6,
      field: 'count',
      pl: 'liczba pomiarów',
      ua: 'Кількість проведених вимірювань',
      en: 'Number of measurements',
    },
    {
      id: 7,
      field: 'firstMeasurementDate',
      pl: 'Data rozpoczęcia użytkowania',
      ua: 'Початкова дата користування',
      en: 'Start date of use',
      sortable: 'firstMeasurementDate',
    },
    {
      id: 8,
      field: 'lastMeasurementDate',
      pl: 'Ostatnia data użycia',
      ua: 'Остання дата користування',
      en: 'Last date of use',
      sortable: 'lastMeasurementDate',
    },
    {
      id: 9,
      field: 'patientName',
      pl: 'Imię i nazwisko pacjenta',
      ua: 'ПІБ пацієнта',
      en: 'Patient Full Name',
    },
    { id: 10, field: 'patientAge', pl: 'Wiek', ua: 'Вік', en: 'Age' },
    { id: 11, field: 'patientGender', pl: 'Seks', ua: 'Стать', en: 'Gender' },
    {
      id: 12,
      field: 'doctorName',
      pl: 'Doktor Imię i nazwisko',
      ua: 'ПІБ лікаря',
      en: 'Doctor Full name',
    },
    // {id: 11, pl:'',ua: 'Дата реестрації Лікаря', en: 'Date registration of the Doctor'},
    // {id: 12, pl:'',ua: 'Завершення реестрації Лікаря', en: 'Completing Doctor Registration'},
    {
      id: 13,
      field: 'doctorAge',
      pl: 'Wiek lekarza',
      ua: 'Вік лікаря',
      en: 'Age of the doctor',
    },
    {
      id: 14,
      field: 'doctorGender',
      pl: 'Wiek lekarza',
      ua: 'Стать лікаря',
      en: 'Gender of the doctor',
    },
    {
      id: 15,
      field: 'doctorEducation',
      pl: 'Płeć lekarza',
      ua: 'Освіта лікаря',
      en: 'Doctor`s education',
    },
    {
      id: 16,
      field: 'doctorQualifications',
      pl: 'Kwalifikacja lekarza',
      ua: 'Кваліфікація Лікаря',
      en: 'Qualification of the Doctor',
    },
    {
      id: 17,
      field: 'doctorSpecialities',
      pl: 'Specjalizacja lekarza',
      ua: 'Спеціалізація Лікаря',
      en: 'Specialization of the Doctor',
    },
    {
      id: 18,
      field: 'successfulComputationCount',
      pl: 'Liczba udanych pomiarów',
      ua: 'Кількість успішних вимірювань',
      en: 'Number of successful measurements',
    },
    {
      id: 19,
      field: 'unsuccessfulComputationCount',
      pl: 'Liczba pomiarów błędów',
      ua: 'Кількість помилкових вимірювань',
      en: 'Number of error measurements',
    },
    {
      id: 20,
      field: 'contactPerson',
      pl: 'Osoba kontaktowa',
      ua: 'Контактна особа',
      en: 'Contact Person',
    },
    {
      id: 21,
      field: 'isContactPerson',
      pl: 'Czy jest osoba kontaktowa?',
      ua: 'Чи є контактною особою?',
      en: 'Is there a contact person?',
    },
    {
      id: 22,
      field: 'purchaseStatus',
      pl: 'Status zakupu',
      ua: 'Статус придбання',
      en: 'Purchase Status',
    },

    // { id: 22, field: 'active', pl:'',ua: 'Заборонити', en: 'Disabled' }
  ];
}
