<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul>
          <li>
            <a
              href="./../../../assets/pdf/{{
                'login.user_manual_file' | translate
              }}.pdf"
              target="_blank"
              download
            >
              {{ "login.user_manual" | translate }}
            </a>
          </li>
          <li>
            <a href="">{{ "login.support_service" | translate }}</a>
          </li>
          <li>
            <div class="logo">
              <img src="./../../../assets/img/cartification.svg" alt="Oranta" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
