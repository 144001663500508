import {Biosignal} from './biosignal';

export class BiosignalsRange {
  origin: string;
  rangeBiosignals: Biosignal[];
  beginIndex: number;
  endIndex: number;
  excludedBiosignalsIds: Set<string>;

  constructor(origin: string, rangeBiosignals: Biosignal[], beginIndex?: number, endIndex?: number, excludedBiosignalsIds?: Set<string>) {
    this.origin = origin;
    this.rangeBiosignals = rangeBiosignals;
    this.beginIndex = beginIndex ? beginIndex : 0;
    this.endIndex = endIndex ? endIndex : rangeBiosignals.length - 1;
    this.excludedBiosignalsIds = excludedBiosignalsIds ? excludedBiosignalsIds : new Set();
  }
}
