import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';
import { UserRights, UserRightsFiltered } from 'src/app/models/user-rights';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent extends OnDestroyMixin implements OnInit {
  permissions: Array<UserRightsFiltered> = [];
  permissionsMobile: Array<UserRights> = [];

  updatedPermissions: Array<any> = [];

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.adminService
      .get()
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp: any) => {
        this.permissions.push(resp);
        this.permissionsMobile = resp.ao;
      });
  }

  switch(type) {
    this.permissionsMobile = [];
    if (type == 'ao') {
      this.permissionsMobile = this.permissions[0].ao;
    } else {
      this.permissionsMobile = this.permissions[0].ecg;
    }
  }

  heckButtonClick(event, type): void {
    const buttons = document.querySelectorAll('.btn-default');
    const buttonEl = event.target;
    if (buttonEl.classList.contains('button_active')) {
      return;
    }
    buttons.forEach((button) => {
      button.classList.remove('button_active');
    });
    buttonEl.classList.toggle('button_active');
    this.permissionsMobile = [];
    if (type == 'ao') {
      this.permissionsMobile = this.permissions[0].ao;
    } else if (type == 'ecg') {
      this.permissionsMobile = this.permissions[0].ecg;
    } else if (type == 'wp') {
      this.permissionsMobile = this.permissions[0].wp;
    } else if (type == 'cp') {
      this.permissionsMobile = this.permissions[0].cp;
    }
  }

  select(e, data, type) {
    if (e.target.checked) {
      data.rights.push(type);
    } else {
      data.rights.splice(data.rights.indexOf(type), 1);
    }
    if (this.updatedPermissions.length) {
      const index = this.updatedPermissions.findIndex(
        (item) => item._id === data._id
      );
      const item = {
        _id: data._id,
        rights: data.rights,
      };
      if (index !== -1) {
        this.updatedPermissions[index] = item;
      } else {
        this.updatedPermissions.push(item);
      }
    } else {
      this.updatedPermissions.push(data);
    }
  }

  submit() {
    this.adminService
      .update(this.updatedPermissions)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.translate
          .get(['alerts'])
          .pipe(untilComponentDestroyed(this))
          .subscribe((t) => {
            this.toastr.success(t.alerts.warning_2);
          });
      });
  }

  isPermissions(rights, role) {
    return rights.indexOf(role) !== -1;
  }
}
