import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-vat41-setting',
  templateUrl: './vat41-setting.component.html',
  styleUrls: ['./vat41-setting.component.scss'],
})
export class VAT41SettingComponent extends OnDestroyMixin implements OnInit {
  myForm: FormGroup = new FormGroup({});

  @Output() changeSetting = new EventEmitter();
  @Input() set setting(s) {
    if (s) this.myForm.patchValue(s);
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.reactiveForm();
    for (const field of Object.keys(this.myForm.controls))
      this.myForm.controls[field].valueChanges
        .pipe(untilComponentDestroyed(this))
        .subscribe((v) => {
          this.changeSetting.emit(this.myForm);
        });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      PackageSize: ['', Validators.required],
      InfSp: [],
      CuffSize: [],
      DayStart: [],
      NightStart: [],
      DayPeriod: [],
      NightPeriod: [],
      shouldTransmitSignal: [],
      EquipmentName: ['', Validators.required],
    });
  }
}
