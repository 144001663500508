<div class="header-wrap">
  <header>
    <div class="logo">
      <img [src]="'registration.logo_header_src' | translate" alt="Oranta" />
    </div>
    <div class="header-right-wrap">
      <div class="user-wrap mobile">
        <div
          class="photo"
          routerLink="/home/profile"
          [ngStyle]="{
            'background-image':
              'url(' + (photoExist ? photo : '/assets/img/noavatar.png') + ')'
          }"
        ></div>
      </div>
      <nav class="nav-menu" [ngClass]="{ show: showMenu }">
        <ul>
          <li>
            <div
              *ngIf="
                (auth.isPatient() || auth.isAdmin()) &&
                (auth.getUser().withings ||
                  (patientService.patient$ | async)?.withings)
              "
              style="display: flex; cursor: pointer; align-items: center"
              (click)="openWithingPanel()"
            >
              <mat-icon
                class="mr-2"
                svgIcon="{{
                  !auth.getUser().withings_access?.userid &&
                  !patientService.patientObj?.withings_access?.userid
                    ? 'cross'
                    : 'tick'
                }}"
                matTooltip="{{
                  !auth.getUser().withings_access?.userid
                    ? 'Connect the profile to WITHINGS'
                    : 'The profile is connected to WITHINGS'
                }}"
                [matTooltipPosition]="'above'"
              >
              </mat-icon>
              <div>
                <img
                  style="width: 7vw; height: auto"
                  src="/assets/img/withings.png"
                  alt="withings logo"
                  class="logo-withings-sidebar"
                />
              </div>
            </div>
          </li>
          <li><app-lang></app-lang></li>
          <li *ngIf="rightsService.isHasRight(Rights.Measurement)">
            <a
              routerLink="/home/measurements/measurements-upload"
              class="rout_style"
              routerLinkActive="rout_style_active"
              (click)="showMenu = false"
            >
              {{ "menu.measurements" | translate }}</a
            >
          </li>
          <li *ngIf="rightsService.isHasRight(Rights.Calculations)">
            <a
              routerLink="/home/calculations"
              class="rout_style"
              routerLinkActive="rout_style_active"
              (click)="showMenu = false"
            >
              {{ "menu.calculations" | translate }}
            </a>
          </li>
          <li *ngIf="rightsService.isHasRight(Rights.Consultants)">
            <a
              routerLink="/home/consultants"
              class="rout_style"
              routerLinkActive="rout_style_active"
              (click)="showMenu = false"
            >
              {{ "menu.consultants" | translate }}
            </a>
          </li>
          <li *ngIf="auth.isAdmin()">
            <a
              routerLink="/home/admin"
              class="rout_style"
              routerLinkActive="rout_style_active"
              (click)="showMenu = false"
            >
              {{ "menu.admin" | translate }}</a
            >
          </li>
          <li class="user-wrap">
            <div
              [routerLink]="
                auth.isPatient()
                  ? ['/home/profile/patient']
                  : auth.isAdmin()
                  ? ['/home/profile/patient', patient?._id]
                  : ['/home/profile/staff/patients', { id: user._id }]
              "
              (click)="showMenu = false"
            >
              <div
                class="photo"
                [ngStyle]="{
                  'background-image':
                    'url(' +
                    (photoExist ? photo : '/assets/img/noavatar.png') +
                    ')'
                }"
              ></div>
              <div>
                <a class="username">
                  {{ user?.last_name | firstLetter }}
                  {{ user?.first_name | firstLetter }}
                </a>
              </div>
            </div>
          </li>

          <li>
            <ng-select
              *ngIf="!auth.isPatient()"
              [(ngModel)]="patientId"
              (change)="selectPatient($event)"
              [searchable]="false"
              [clearable]="true"
              placeholder="{{ 'measurement.select_patient' | translate }}"
            >
              <ng-option *ngFor="let patient of patients" [value]="patient._id">
                <span
                  *ngIf="patient.last_name.toLowerCase().includes('patient')"
                >
                  Patient:</span
                >

                {{ patient.last_name | cutPatient }}
                {{ patient.first_name | cutPatient }}
                {{ patient.second_name | cutPatient }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="
                auth.getUser().withings_access?.userid ||
                (patientService.patient$ | async)?.withings_access?.userid
              "
              (click)="updateWithingsSignals()"
            >
              <img
                matTooltip="Update the WITHINGS measurements data"
                [matTooltipPosition]="'above'"
                src="/assets/img/withings_logo.png"
                alt="withings logo"
                class="m-0"
              />
            </div>
            <button
              class="addBtn ml-2"
              (click)="addNewPatient()"
              [matTooltip]="'registration.register_new_patient' | translate"
            >
              <i class="bi bi-person-plus-fill"></i>
            </button>
          </li>
          <li>
            <a class="logout" href="javascript:" (click)="logout()">
              <img src="assets/img/log_out.svg" />
            </a>
          </li>
        </ul>
      </nav>
      <div class="menu-btn" (click)="showMenu = !showMenu">
        <div></div>
      </div>
    </div>
  </header>
</div>
<main *ngIf="!authService.isPatient() && !patient && endRoute == 'calculations'">
  <div class="content-wrapper">
    <div class="patients-wrapper">
      <input matInput type="text" class="search-input"
             [placeholder]="'admin.search' | translate" [(ngModel)]="patientsSearchData">
      <app-patients-list [mode]="PatientListMode.SMALL"
                         [searchData]="patientsSearchData"
                         (onPatient)="chosenPatient = $event">
      </app-patients-list>
    </div>
    <div class="measurement-wrapper">
      <app-measurement-table [chosenPatient]="chosenPatient" class="measurement-table"></app-measurement-table>
    </div>
  </div>
</main>
<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <router-outlet></router-outlet>
</div>
