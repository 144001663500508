import { Component, Input, OnInit } from '@angular/core';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { Biosignal } from 'src/app/models/biosignal';
import { BiosignalsRange } from 'src/app/models/biosignals-range';
import { DataTypeService } from 'src/app/services/data-type.service';

@Component({
  selector: 'app-measurement-table',
  templateUrl: './measurement-table.component.html',
  styleUrls: ['./measurement-table.component.scss'],
})
export class MeasurementTableComponent implements OnInit {
  GraphType = GraphType;
  ACTIVE_GRAPH: GraphType;
  biosignals: Biosignal[];
  biosignalsRange: BiosignalsRange;
  blockFollowingUncheck: boolean = true;

  @Input() set setBiosignals(v) {
    if (v) {
      this.biosignals = v;
    }
  }

  @Input() set setBiosignalsRange(v) {
    if (v) {
      this.blockFollowingUncheck = v.rangeBiosignals.length == 1;
      if (v?.origin !== 'MeasurementTableComponent') {
        this.biosignalsRange = v;
      }
    }
  }

  constructor(public dataTypeService: DataTypeService) {
    this.dataTypeService.selectedType$.subscribe((v: GraphType) => {
      this.ACTIVE_GRAPH = v;
    });
  }

  ngOnInit(): void {}
}
