<div class="w-100 h-100">
  <ngx-charts-line-chart
    *ngIf="oscillogramData"
    [xAxis]="true"
    [yAxis]="true"
    [timeline]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [roundDomains]="true"
    [tooltipDisabled]="true"
    [xAxisLabel]="'t, s'"
    [results]="rawSignalData"
  >
  </ngx-charts-line-chart>
</div>
<!-- [yAxisLabel]="'calculation.mmhg' | translate" -->
