<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Незбереженi змiни</h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="modal.dismiss('CLOSE')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    <strong>{{ "calculation.lose_change_question" | translate }}</strong>
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modal.dismiss('CANCEL')">
    {{ "calculation.no_go_back" | translate }}
  </button>
  <button class="btn btn-danger" (click)="modal.close('OK')">
    {{ "calculation.go_to_second_biosignal" | translate }}
  </button>
</div>
