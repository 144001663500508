import { GraphType } from 'src/app/enums/graph_type.enum';

export const BUTTON_DATA = [
  {
    graphType: [GraphType.AO, GraphType.WP, GraphType.CP],
    histogramName: 'Ms',
    histogramCaption: {
      ua: 'Мср',
      en: 'Mavr',
      pl: 'Msr',
    },
    fullCaptionHistogram: {
      ua: 'Мср',
      en: 'Mavr',
      pl: 'Msr',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'Systola',
    histogramCaption: {
      ua: 'АТс',
      en: 'BPs',
      pl: 'CTs',
    },
    fullCaptionHistogram: {
      ua: 'АТс',
      en: 'ATs',
      pl: 'CTs',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'Diastola',
    histogramCaption: {
      ua: 'АТд',
      en: 'BPd',
      pl: 'CTr',
    },
    fullCaptionHistogram: {
      ua: 'АТд',
      en: 'BPd',
      pl: 'CTr',
    },
  },
  {
    graphType: [GraphType.AO, GraphType.ECG, GraphType.WP, GraphType.CP],
    histogramName: 'HeartRate',
    histogramCaption: {
      ua: 'ЧСС',
      en: 'HR',
      pl: 'CAS',
    },
    fullCaptionHistogram: {
      ua: 'Частота сердечних скорочень',
      en: 'HR',
      pl: 'CAS',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'MAP',
    histogramCaption: {
      ua: 'MAP',
      en: 'MAP',
      pl: 'MAP',
    },
    fullCaptionHistogram: {
      ua: 'Середній тиск',
      en: 'MAP',
      pl: 'Average pressure',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'PP',
    histogramCaption: {
      ua: 'PАP',
      en: 'PАP',
      pl: 'PАP',
    },
    fullCaptionHistogram: {
      ua: 'Пульсовий тиск',
      en: 'PАP',
      pl: 'Pulse pressure',
    },
  },
  {
    graphType: [GraphType.AO, GraphType.ECG, GraphType.WP, GraphType.CP],
    histogramName: 'PARSint',
    histogramCaption: {
      ua: 'ПАРС-АОІ',
      en: 'PARS-AOI',
      pl: 'WASR-AO',
    },
    fullCaptionHistogram: {
      ua: 'ПАРС-АОІ',
      en: 'PARS-AOI',
      pl: 'WASR-AO',
    },
  },
  {
    graphType: [GraphType.AO, GraphType.ECG, GraphType.WP, GraphType.CP],
    histogramName: 'IFSP',
    histogramCaption: {
      ua: 'ІФСП',
      en: 'IFVP',
      pl: 'ZFPN',
    },
    fullCaptionHistogram: {
      ua: 'ІФСП',
      en: 'IFVP',
      pl: 'ZFPN',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'HeartDiseases',
    histogramCaption: {
      ua: 'Серцево-судинні захворювання',
      en: 'Cardiovascular diseases',
      pl: 'Choroby układu krążenia',
    },
    fullCaptionHistogram: {
      ua: 'Серцево-судинні захворювання',
      en: 'Cardiovascular diseases',
      pl: 'Choroby układu krążenia',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'LungDiseases',
    histogramCaption: {
      ua: 'Захворювання легеневої с-ми',
      en: 'Diseases of the pulmonary system',
      pl: 'Choroby układu płucnego',
    },
    fullCaptionHistogram: {
      ua: 'Захворювання легеневої с-ми',
      en: 'Diseases of the pulmonary system',
      pl: 'Choroby układu płucnego',
    },
  },
  {
    graphType: [GraphType.AO],
    histogramName: 'PsychicalDiseases',
    histogramCaption: {
      ua: 'Психічні захворювання',
      en: 'Mental illness',
      pl: 'Choroba psychiczna',
    },
    fullCaptionHistogram: {
      ua: 'Психічні захворювання',
      en: 'Mental illness',
      pl: 'Choroba psychiczna',
    },
  },
  {
    graphType: [GraphType.AO, GraphType.ECG],
    histogramName: 'ArrhythmiaDegree',
    histogramCaption: {
      ua: 'Порушення ритмічної д-сті серця',
      en: 'Violation of the rhythmic activity of the heart',
      pl: 'Naruszenie rytmicznego rytmu serca',
    },
    fullCaptionHistogram: {
      ua: 'Порушення ритмічної діяльності серця',
      en: 'Violation of the rhythmic activity of the heart',
      pl: 'Naruszenie rytmicznego rytmu serca',
    },
  },
  {
    graphType: [GraphType.ECG],
    histogramName: 'AtrialFibrillation',
    histogramCaption: {
      ua: 'Фібриляція передсердь',
      en: 'Atrial fibrillation',
      pl: 'Migotanie przedsionków',
    },
    fullCaptionHistogram: {
      ua: 'Фібриляція передсердь',
      en: 'Atrial fibrillation',
      pl: 'Migotanie przedsionków',
    },
  },
];
