<div *ngIf="IfspTableData">
  <h1 class="expert-system-title">
    <ng-container *ngIf="!(reloading$ | async)">{{
      IfspTableData.indicatorFullName | lang
    }}</ng-container>
  </h1>
  <div>
    <h1>
      <ng-container *ngIf="!(reloading$ | async)">{{
        IfspTableData.indicatorName | lang
      }}</ng-container>
      = {{ averageValues.IFSP }}
    </h1>
    <ng-container *ngIf="!(reloading$ | async)">{{
      descriptionIFSP | lang
    }}</ng-container>
  </div>
  <div *ngIf="IfspTableData.indicatorDescription" class="slide-wrapper">
    <div class="expert-table-wrapper">
      <app-accordion [title]="'calculation.explanation_ifvp' | translate">
        <table class="expert-table-style-2">
          <tbody>
            <tr *ngFor="let item of IfspTableData.indicatorDescription">
              <td>
                <strong>{{ item.minValue }} - {{ item.maxValue }}</strong>
              </td>
              <td>
                <ng-container *ngIf="!(reloading$ | async)">
                  {{ item.description | lang }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </app-accordion>
    </div>
  </div>
  <div class="expert-table-wrapper">
    <table class="expert-table-style-2">
      <thead>
        <ng-container *ngIf="!(reloading$ | async)">
          <tr>
            <th>{{ "calculation.levels" | translate }}</th>
            <th colspan="3">
              {{ IfspTableData.table.regulationEffect.header | lang }}
            </th>
            <th>{{ IfspTableData.table.homeostasis.header | lang }}</th>
            <th>{{ IfspTableData.table.arterialOscylogram.header | lang }}</th>
            <th>
              {{ IfspTableData.table.vascularAdaptability.header | lang }}
            </th>
            <th colspan="3">
              {{ IfspTableData.table.spectralStructure.header | lang }}
            </th>
          </tr>
        </ng-container>
      </thead>
      <tbody>
        <ng-container *ngIf="!(reloading$ | async)">
          <tr>
            <td></td>
            <td>
              {{ IfspTableData.table.regulationEffect.ChSS.header | lang }}
            </td>
            <td>
              {{ IfspTableData.table.regulationEffect.ATs.header | lang }}
            </td>
            <td>
              {{ IfspTableData.table.regulationEffect.ATd.header | lang }}
            </td>
            <td>{{ IfspTableData.table.homeostasis.IN.header | lang }}</td>
            <td>
              {{ IfspTableData.table.arterialOscylogram.parsAoi.header | lang }}
            </td>
            <td>
              {{ IfspTableData.table.vascularAdaptability.Mser.header | lang }}
            </td>

            <td>{{ IfspTableData.table.spectralStructure.VLF.header }}</td>
            <td>{{ IfspTableData.table.spectralStructure.LF.header }}</td>
            <td>{{ IfspTableData.table.spectralStructure.HF.header }}</td>
          </tr>
        </ng-container>
        <tr>
          <td></td>
          <td>
            <strong>{{ averageValues.ChSS }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.ATs }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.ATd }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.IN }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.parsAoi }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.Mser }}</strong>
          </td>

          <td>
            <strong>{{ averageValues.VLF }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.LF }}</strong>
          </td>
          <td>
            <strong>{{ averageValues.HF }}</strong>
          </td>
        </tr>

        <tr
          *ngFor="
            let row of IfspTableData.table.regulationEffect.ChSS.row;
            let i = index
          "
        >
          <ng-container *ngIf="i > 0">
            <td>{{ i }}</td>
            <td
              [ngClass]="
                IfspTableData.table.regulationEffect.ChSS.className
                  ? IfspTableData.table.regulationEffect.ChSS.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.regulationEffect.ChSS.row[i].description
              "
            ></td>

            <td
              [ngClass]="
                IfspTableData.table.regulationEffect.ATs.className
                  ? IfspTableData.table.regulationEffect.ATs.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.regulationEffect.ATs.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.regulationEffect.ATd.className
                  ? IfspTableData.table.regulationEffect.ATd.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.regulationEffect.ATd.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.homeostasis.IN.className
                  ? IfspTableData.table.homeostasis.IN.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.homeostasis.IN.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.arterialOscylogram.parsAoi.className
                  ? IfspTableData.table.arterialOscylogram.parsAoi.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.arterialOscylogram.parsAoi.row[i]
                  .description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.vascularAdaptability.Mser.className
                  ? IfspTableData.table.vascularAdaptability.Mser.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.vascularAdaptability.Mser.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.spectralStructure.VLF.className
                  ? IfspTableData.table.spectralStructure.VLF.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.spectralStructure.VLF.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.spectralStructure.LF.className
                  ? IfspTableData.table.spectralStructure.LF.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.spectralStructure.LF.row[i].description
              "
            ></td>
            <td
              [ngClass]="
                IfspTableData.table.spectralStructure.HF.className
                  ? IfspTableData.table.spectralStructure.HF.className[i]
                  : ''
              "
              [innerHTML]="
                IfspTableData.table.spectralStructure.HF.row[i].description
              "
            ></td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>
