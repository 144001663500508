export enum RoleTypeEnum {
  Doctor = 'DOCTOR',
  Consultant = 'CONSULTANT',
  Patient = 'PATIENT',
  Admin = 'ADMIN',
}

export enum Langs {
  EN = 'en',
  UA = 'ua',
  PL = 'pl',
}
