import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GenderService } from 'src/app/services/dictionaries/gender.service';

@Component({
  selector: 'app-doctor-item',
  templateUrl: './doctor-item.component.html',
  styleUrls: ['./doctor-item.component.scss'],
})
export class DoctorItemComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() doctor: any;
  @Output() setDoctor = new EventEmitter();
  @Input() selectedId: any = '';

  constructor(
    public genderDict: GenderService,
    public cd: ChangeDetectorRef,
    private auth: AuthService
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
  }

  viewPatients(id: any) {
    this.setDoctor.emit(id);
  }
}
