import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Patient } from 'src/app/models/patient';
import { PatientService } from '../services/patient.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EquipmentService } from 'src/app/services/equipment.service';
import { EquipmentName } from 'src/app/enums/equipment.enum';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-device-settings',
  templateUrl: './user-device-settings.component.html',
  styleUrls: ['./user-device-settings.component.scss'],
})
export class UserDeviceSettingsComponent
  extends OnDestroyMixin
  implements OnInit
{
  myForm: FormGroup = new FormGroup({});
  busy: boolean = false;
  patients: Patient[] = [];
  patientData;

  equipmentList: EquipmentName[] = [];
  EquipmentName = EquipmentName;
  equipmentName = EquipmentName.VAT;
  patientId;
  settings;

  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    public authService: AuthService,
    public equipmentService: EquipmentService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reactiveForm();
    for (const item of Object.keys(EquipmentName))
      this.equipmentList.push(EquipmentName[item]);
    this.patientService
      .getDoctorPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.patients = data;
        if (this.authService.isPatient()) {
          this.setPatientData(this.patients[0]);
        } else {
          this.patientService.patient$
            .pipe(untilComponentDestroyed(this))
            .subscribe((patient) => {
              this.setPatientData(patient);
            });
        }
      });
  }

  setPatientData(patient) {
    if (patient) {
      this.patientId = patient._id;
      this.equipmentName = EquipmentName.VAT;
      this.setSettings(this.patientId, this.equipmentName);
    }
  }

  setSettings(patientId, equipmentName) {
    if (patientId && equipmentName)
      this.equipmentService
        .getEquipmentPatientSetting(patientId, equipmentName)
        .subscribe((resp) => {
          this.settings = resp;
          if (resp) {
            this.myForm.patchValue(resp);
          } else {
            this.myForm.reset();
            this.reactiveForm();
          }
        });
  }

  saveSetting() {
    this.myForm.get('patientId').patchValue(this.patientId);
    this.myForm.get('EquipmentName').patchValue(this.equipmentName);
    if (!this.settings)
      this.equipmentService
        .createEquipmentPatientSetting(this.myForm.value)
        .subscribe(
          (v) => {
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_4);
              });
            this.myForm.patchValue(v);
          },
          (err: any) => {
            this.toastr.error(err.message);
          }
        );
    else
      this.equipmentService
        .updateEquipmentPatientSetting(this.settings._id, this.myForm.value)
        .subscribe(
          (v) => {
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_5);
              });
            this.myForm.patchValue(v);
          },
          (err: any) => {
            this.toastr.error(err.message);
          }
        );
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      DayStart: [],
      NightStart: [],
      DayPeriod: [],
      NightPeriod: [],
      EquipmentName: ['', Validators.required],
      patientId: ['', Validators.required],
    });
  }
}
