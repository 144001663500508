<tr>
  <td>{{ index + 1 }}</td>
  <td>
    <div class="d-flex">
      <span class="mx-2">{{ "form.from" | translate }}</span>
      <input type="text" [(ngModel)]="element.StartRangeId" />
      <span class="mx-2">-</span>
      <span class="mx-2">{{ "form.to" | translate }}</span>
      <input type="text" [(ngModel)]="element.EndRangeId" />
    </div>
  </td>
  <td>
    <input type="text" [(ngModel)]="element.PackageSize" />
  </td>
  <td>
    <input type="text" [(ngModel)]="element.InfSp" />
  </td>
  <td>
    <input type="text" [(ngModel)]="element.CuffSize" />
  </td>
  <td>
    <select [(ngModel)]="element.shouldTransmitSignal">
      <option value="0">0</option>
      <option value="1">1</option>
    </select>
  </td>
  <td>
    <button
      class="ml-1"
      (click)="updateSetting(element)"
      class="addBtn"
      matTooltipPosition="above"
      matTooltip="{{ 'form.save' | translate }}"
    >
      <i class="bi bi-sd-card"></i>
    </button>
  </td>
</tr>
