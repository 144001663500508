<ng-container
  *ngIf="
    oscillogramData?.timePoints?.length && oscillogramData?.valuePoints?.length
  "
>
  <div class="w-100 h-100" #oscContainer *ngIf="view">
    <ngx-charts-line-chart
      *ngIf="oscillogramData"
      [view]="[oscContainer.offsetWidth, oscContainer.offsetHeight]"
      [xAxis]="true"
      [yAxis]="true"
      [timeline]="false"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [tooltipDisabled]="true"
      [yAxisLabel]="'calculation.mmhg' | translate"
      [results]="rawSignalData"
      [yScaleMin]="yScaleMin"
      [yScaleMax]="yScaleMax"
      [xScaleMax]="xScaleMax"
      [yAxisTickFormatting]="formatAxisTickDateY"
    >
    </ngx-charts-line-chart>
  </div>
</ng-container>
