import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueById'
})
export class ValueByIdPipe implements PipeTransform {
  transform(value: any, array: any[], key: string): any {
    if (value) {
      if(key) {
        return array.find(item => item[key] === value);
      } else {
        return array.find(item => item.id === value).value;
      }
    } else {
      return '';
    }
  }
}
