import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, take, startWith } from 'rxjs/operators';
export enum TypeDrag {
  Move,
  Top,
  Bottom,
  Left,
  Right,
  TopRight,
  BottomRight,
  TopLeft,
  BottomLeft,
}

@Component({
  selector: 'angular-window',
  templateUrl: './angular-window.component.html',
  styleUrls: ['./angular-window.component.scss'],
})
export class AngularWindowComponent implements OnInit {
  rect: any;
  incr: number[] = [0, 0, 0, 0];
  nativeElement: any;
  typeDrag: TypeDrag;
  origin: any;
  onDrag: boolean = false;
  moveSubscription: any;
  div: any;

  classNames = [
    'cell-top',
    'cell-border-top',
    'cell-border-bottom',
    'cell-border-left',
    'cell-border-right',
    'cell-top-right',
    'cell-bottom-right',
    'cell-top-left',
    'cell-bottom-left',
  ];

  style: any = null;
  constructor(private elementRef: ElementRef) {}

  @Input() set dragHolder(value) {
    value.classList.add('cell-top');
  }
  @Input('background-color') backgroundColor = 'white';

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'mousedown')
      .pipe(
        filter((event: MouseEvent) => {
          const classs = (event.target as any).className;
          if (classs && typeof classs === 'string') {
            const className = classs.split(' ');
            return className.indexOf('cell-top') >= 0
              ? true
              : this.classNames.indexOf(classs) >= 0;
          }
          return false;
        })
      )
      .subscribe((event: MouseEvent) => {
        this.div = this.elementRef.nativeElement.childNodes[0];
        this.rect = this.div.getBoundingClientRect();
        this.origin = { x: event.screenX, y: event.screenY };

        this.onDrag = true;
        const className = (event.target as any).className.split(' ');
        this.typeDrag =
          className.indexOf('cell-top') >= 0
            ? TypeDrag.Move
            : (this.classNames.indexOf(className[0]) as TypeDrag);

        this.incr =
          this.typeDrag == TypeDrag.Move
            ? [1, 0, 1, 0]
            : this.typeDrag == TypeDrag.Top
            ? [1, -1, 0, 0]
            : this.typeDrag == TypeDrag.Bottom
            ? [0, 1, 0, 0]
            : this.typeDrag == TypeDrag.Right
            ? [0, 0, 0, 1]
            : this.typeDrag == TypeDrag.Left
            ? [0, 0, 1, -1]
            : this.typeDrag == TypeDrag.TopRight
            ? [1, -1, 0, 1]
            : this.typeDrag == TypeDrag.TopLeft
            ? [1, -1, 1, -1]
            : this.typeDrag == TypeDrag.BottomRight
            ? [0, 1, 0, 1]
            : [0, 1, 1, -1];

        this.onDrag = true;
        const modalContent = this.findModalContent(this.div.parentElement);
        if (modalContent) {
          modalContent.style.margin = 0;
        }

        fromEvent(document, 'mouseup')
          .pipe(take(1))
          .subscribe(() => {
            if (this.moveSubscription) {
              this.moveSubscription.unsubscribe();
              this.moveSubscription = undefined;
              this.onDrag = false;
            }
          });

        if (!this.moveSubscription) {
          this.moveSubscription = fromEvent(document, 'mousemove')
            .pipe(startWith({ screenY: this.origin.y, screenX: this.origin.x }))
            .subscribe((moveEvent: MouseEvent) => {
              const incrTop = moveEvent.screenY - this.origin.y;
              const incrLeft = moveEvent.screenX - this.origin.x;
              const width = this.rect.width + this.incr[3] * incrLeft;
              const heigth = this.rect.height + this.incr[1] * incrTop;
              this.style = {
                position: 'absolute',
                'z-index': 1051,
                'background-color': this.backgroundColor,
                top: this.rect.top + this.incr[0] * incrTop + 'px',
                height: (heigth < 75 ? 75 : heigth) + 'px',
                left: this.rect.left + this.incr[2] * incrLeft + 'px',
                width: (width < 50 ? 50 : width) + 'px',
              };
            });
        }
      });
  }

  findModalContent(element: HTMLElement) {
    return element.className == 'modal-dialog'
      ? element
      : element.parentElement
      ? this.findModalContent(element.parentElement)
      : null;
  }
}
