<div class="mx-3">
  <div class="d-flex align-items-center">
    <h2>{{ "measurement.upload_photo_enable_camera" | translate }}</h2>
    <button
      id="webcamButton"
      class="btn btn-primary btn-toggle"
      (click)="toggleCamera()"
    >
      <span class="mdc-button__ripple"></span>
      <span class="mdc-button__label">
        {{
          (!webcamRunning
            ? "measurement.enable_camera"
            : "measurement.upload_photo"
          ) | translate
        }}
      </span>
    </button>
  </div>
  <div class="row my-3" *ngIf="!webcamRunning">
    <div class="col-md-4">
      <mat-form-field class="col-md-12">
        <mat-label>{{ "form.select_file" | translate }}</mat-label>
        <input
          type="text"
          readonly
          matInput
          [(ngModel)]="fileName"
          (click)="f_input.click()"
        />
        <input
          type="file"
          accept="image/*"
          hidden
          #f_input
          (change)="onFileSelected($event)"
        />
        <mat-icon
          matSuffix
          svgIcon="attach"
          (click)="f_input.click()"
        ></mat-icon>
      </mat-form-field>
      <ng-container [formGroup]="form">
        <mat-form-field class="col-md-12">
          <mat-label>{{
            "measurement.measurement_type" | translate
          }}</mat-label>
          <mat-select formControlName="measurement_type">
            <ng-container *ngFor="let item of TypeMeasurementEnum | keyvalue">
              <mat-option [value]="item.value">
                {{ "measurement." + item.key | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <mat-label>{{ "measurement.video_type" | translate }}</mat-label>
          <mat-select
            formControlName="video_type"
            (selectionChange)="selectVideoType($event.value)"
          >
            <ng-container *ngFor="let item of TypeVideoEnum | keyvalue">
              <mat-option [value]="item.value">
                {{ "measurement." + item.key | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <button
        id="webcamActionRemove"
        class="btn btn-outline-primary col-md-12"
        (click)="handleClick($event)"
        [disabled]="!imageUrl"
      >
        <span class="mdc-button__ripple"></span>
        <span class="mdc-button__label">{{
          "measurement.determine_coordinates" | translate
        }}</span>
      </button>
    </div>

    <div class="detectOnClick col-md" (click)="handleClick($event)">
      <img
        id="detection_photo"
        *ngIf="imageUrl"
        [src]="imageUrl"
        alt="{{ 'measurement.selected_image' | translate }}"
        width="100%"
        crossorigin="anonymous"
        title="{{ 'measurement.click_get_detection' | translate }}!"
      />
    </div>
  </div>

  <div class="row my-3" *ngIf="webcamRunning">
    <div class="col-md">
      <form [formGroup]="form" class="row">
        <mat-form-field class="col-md-12">
          <mat-label>
            {{ "measurement.measurement_duration" | translate }}
            ({{ "measurement.in_seconds" | translate }})
          </mat-label>
          <input
            matInput
            type="number"
            min="0"
            formControlName="measurement_duration"
          />
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <mat-label>
            {{ "measurement.interval_before_starting_measurement" | translate }}
            ({{ "measurement.in_seconds" | translate }})
          </mat-label>
          <input matInput type="number" min="0" formControlName="time_before" />
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <mat-label>{{
            "measurement.measurement_type" | translate
          }}</mat-label>
          <mat-select formControlName="measurement_type">
            <ng-container *ngFor="let item of TypeMeasurementEnum | keyvalue">
              <mat-option [value]="item.value">
                {{ "measurement." + item.key | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12">
          <mat-label>{{ "measurement.video_type" | translate }}</mat-label>
          <mat-select
            formControlName="video_type"
            (selectionChange)="selectVideoType($event.value)"
          >
            <ng-container *ngFor="let item of TypeVideoEnum | keyvalue">
              <mat-option [value]="item.value">
                {{ "measurement." + item.key | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <button
          id="webcamActionRemove"
          class="btn btn-outline-primary col-md-12"
          (click)="startMeasurement()"
        >
          <span class="mdc-button__ripple"></span>
          <span class="mdc-button__label">{{
            "measurement.start_measurement" | translate
          }}</span>
        </button>
      </form>
    </div>
    <div class="col-md-auto" *ngIf="video_type.value">
      <img src="../../../../assets/img/{{ photoType }}" alt="Video position" />
    </div>
    <div id="liveView" class="videoView col-md-7">
      <app-count-down [timeBefore]="time_before.value"></app-count-down>
      <video id="webcam" class="webcam" autoplay playsinline></video>
      <canvas class="output_canvas" id="output_canvas"></canvas>
    </div>
  </div>
</div>
