<div class="main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col">
        <form [formGroup]="myForm">
          <div class="row mt-3">
            <div class="col-md h-1">
              <h2>{{ "form.working_hours" | translate }}</h2>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-4">
              <div class="row">
                <div class="col">
                  <mat-form-field class="datepicker">
                    <mat-label
                      >{{ "form.vacation_period" | translate }}
                      {{ "form.from" | translate }}</mat-label
                    >
                    <input
                      matInput
                      [matDatepicker]="vacation_date_from"
                      formControlName="vacation_date_from"
                    />
                    <mat-datepicker-toggle matSuffix [for]="vacation_date_from">
                    </mat-datepicker-toggle>
                    <mat-datepicker #vacation_date_from></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field class="datepicker">
                    <mat-label>{{ "form.to" | translate }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="vacation_date_to"
                      formControlName="vacation_date_to"
                    />
                    <mat-datepicker-toggle matSuffix [for]="vacation_date_to">
                    </mat-datepicker-toggle>
                    <mat-datepicker #vacation_date_to></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">
                      {{ "form.available_time" | translate }}
                      {{ "form.from" | translate }}</label
                    >
                    <input
                      type="time"
                      class="form-control"
                      formControlName="time_from"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">{{ "form.to" | translate }}</label>
                    <input
                      type="time"
                      class="form-control"
                      formControlName="time_to"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>
                      {{ "form.break" | translate }}
                      {{ "form.from" | translate }}
                    </label>
                    <input
                      type="time"
                      class="form-control"
                      formControlName="lunch_from"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>{{ "form.to" | translate }}</label>
                    <input
                      type="time"
                      class="form-control"
                      formControlName="lunch_to"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">{{ "form.days_of_week" | translate }}</label>
                <ng-select
                  formControlName="dayOfWeekWorkHours"
                  placeholder="-"
                  [multiple]="true"
                >
                  <ng-option
                    *ngFor="let item of weekDayDict.weekDay"
                    [value]="item.value"
                  >
                    <ng-container *ngIf="!(reloading$ | async)">
                      {{ item | lang }}
                    </ng-container>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-between">
            <div class="col-auto" *ngIf="!isProfile()">
              <button class="btn btn-primary" routerLink="/home/consultants">
                {{ "login.cancel" | translate }}
              </button>
            </div>
            <div class="col-auto">
              <button type="submit" (click)="save()" class="btn btn-primary">
                {{ "form.save" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
