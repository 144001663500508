import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { PatientService } from 'src/app/components/measurements-data/services/patient.service';
import { Biosignal } from 'src/app/models/biosignal';
import { RufierTest } from 'src/app/models/rufier';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BiosignalsService } from 'src/app/services/biosignals.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';

@Component({
  selector: 'app-rufier-test-tab',
  templateUrl: './rufier-test-tab.component.html',
  styleUrls: ['./rufier-test-tab.component.scss'],
})
export class RufierTestTabComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsValue(biosignals) {
    if (biosignals) {
      this.clearRufierData();
      this.biosignals = biosignals;
      this.getRufierData();
      biosignals.forEach((biosignal) => {
        const date = new Date(biosignal.SignalRegistrationDateTime);
        this.biosignalsFmtDates.push(this.dateFormat.format(date));
      });
    }
  }

  RufierTestData: RufierTest;
  beforeTest = null;
  afterTest = null;
  afterTwoMinutes = null;
  isDisabledAfter: boolean = false;
  isDisabledAfterTwoMinutes: boolean = false;
  biosignals: Biosignal[];
  biosignalsFmtDates: string[] = [];
  values = {
    PARS1: 0,
    PARS2: 0,
    PARS3: 0,
    PARS_R: 0,
    IFSP1: 0,
    IFSP2: 0,
    IFSP3: 0,
    IFSP_R: 0,
    AI: 0,
    P1: 0,
    P2: 0,
    P3: 0,
    P: 0,
  };
  description = {
    PARS1: '',
    PARS2: '',
    PARS3: '',
    PARS_R: '',
    IFSP1: '',
    IFSP2: '',
    IFSP3: '',
    IFSP_R: '',
    AI: '',
    RUFIER_TEST: '',
  };
  className = {
    PARS_R: '',
    IFSP_R: '',
    AI: '',
  };
  dateOptions;
  dateFormat;

  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef,
    public biosignalsService: BiosignalsService,
    public patientService: PatientService
  ) {
    super(cd);
    this.dateOptions = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    this.dateFormat = Intl.DateTimeFormat('uk-UA', this.dateOptions);
  }

  ngOnInit(): void {
    this.auth.language$.pipe(untilComponentDestroyed(this)).subscribe((v) => {
      this.changLang();
    });
  }

  selectLastTree(val: boolean) {
    if (val) {
      this.setStartVal();
      this.getRufierData();
      this.calcIndicatorsDescription();
    }
  }

  getRufierData() {
    if (localStorage.getItem('rufierTest') !== null) {
      const data = JSON.parse(localStorage.getItem('rufierTest'));
      this.beforeTest = data.before;
      this.changeBeforeTest();
      this.afterTest = data.after;
      this.changeAfterTest();
      this.afterTwoMinutes = data.afterTwoMinutes;
      this.changeAfterTwoMinutes();
    } else {
      localStorage.setItem('rufierTest', JSON.stringify({}));
      this.getRufierData();
    }
  }

  setStartVal() {
    const data = {
      before: this.biosignals?.length - 3,
      after: this.biosignals?.length - 2,
      afterTwoMinutes: this.biosignals?.length - 1,
    };
    localStorage.setItem('rufierTest', JSON.stringify(data));
  }

  clearRufierData() {
    this.beforeTest = null;
    this.afterTest = null;
    this.afterTwoMinutes = null;
    this.values.IFSP1 = 0;
    this.values.PARS1 = 0;
    this.values.IFSP2 = 0;
    this.values.PARS2 = 0;
    this.values.IFSP3 = 0;
    this.values.PARS3 = 0;
    this.values.PARS_R = 0;
    this.values.IFSP_R - 0;
    this.values.AI = 0;
    this.values.P1 = 0;
    this.values.P2 = 0;
    this.values.P3 = 0;
    this.values.P = 0;
  }

  changeBeforeTest() {
    if (this.beforeTest !== null && this.biosignals && this.biosignals.length) {
      this.isDisabledAfter = true;
      this.values.IFSP1 = this.biosignals[this.beforeTest]?.TimeAnalysis.IFSP;
      this.values.PARS1 =
        this.biosignals[this.beforeTest]?.TimeAnalysis.PARSint;
      this.values.P1 = this.biosignals[this.beforeTest]?.TimeAnalysis.HeartRate;
      this.calcIndicatorsDescription();
    }
  }

  changeAfterTest() {
    if (this.afterTest !== null && this.biosignals && this.biosignals.length) {
      this.isDisabledAfterTwoMinutes = true;
      this.values.IFSP2 = this.biosignals[this.afterTest]?.TimeAnalysis.IFSP;
      this.values.PARS2 = this.biosignals[this.afterTest]?.TimeAnalysis.PARSint;
      this.values.P2 = this.biosignals[this.afterTest]?.TimeAnalysis.HeartRate;
      this.calcIndicatorsDescription();
    }
  }

  changeAfterTwoMinutes() {
    if (
      this.afterTwoMinutes !== null &&
      this.biosignals &&
      this.biosignals.length
    ) {
      this.values.IFSP3 =
        this.biosignals[this.afterTwoMinutes]?.TimeAnalysis.IFSP;
      this.values.PARS3 =
        this.biosignals[this.afterTwoMinutes]?.TimeAnalysis.PARSint;
      this.values.P3 =
        this.biosignals[this.afterTwoMinutes]?.TimeAnalysis.HeartRate;
      this.calcIndicatorsDescription();
    }
  }

  calculateGeneralVal() {
    this.values.PARS_R =
      this.values.PARS1 + this.values.PARS2 + this.values.PARS3;
    this.values.IFSP_R =
      (this.values.IFSP1 + this.values.IFSP2 + this.values.IFSP3) / 2;
    this.values.AI = (this.values.PARS_R + this.values.IFSP_R) / 2;
    this.values.P =
      (this.values.P1 + this.values.P2 + this.values.P3 - 200) / 10;
  }

  calcIndicatorsDescription() {
    this.expertSystemService
      .getRufierTest()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data: RufierTest) => {
        this.RufierTestData = data;
        if (
          this.beforeTest !== null &&
          this.afterTest !== null &&
          this.afterTwoMinutes !== null &&
          this.RufierTestData
        ) {
          this.calculateGeneralVal();
          const data = {
            before: this.beforeTest,
            after: this.afterTest,
            afterTwoMinutes: this.afterTwoMinutes,
          };
          localStorage.setItem('rufierTest', JSON.stringify(data));

          this.description.PARS1 = this.RufierTestData.PARS_Description.find(
            (obj) =>
              +this.values.PARS1 >= obj.minValue &&
              +this.values.PARS1 <= obj.maxValue
          )?.description;

          this.description.PARS2 = this.RufierTestData.PARS_Description.find(
            (obj) =>
              +this.values.PARS2 >= obj.minValue &&
              +this.values.PARS2 <= obj.maxValue
          )?.description;

          this.description.PARS3 = this.RufierTestData.PARS_Description.find(
            (obj) =>
              +this.values.PARS3 >= obj.minValue &&
              +this.values.PARS3 <= obj.maxValue
          )?.description;

          if (+this.values.IFSP1 > 0 && +this.values.IFSP1 <= 5) {
            this.description.IFSP1 = this.RufierTestData.IFSP_Description.find(
              (obj) =>
                +this.values.IFSP1 >= obj.minValue &&
                +this.values.IFSP1 <= obj.maxValue
            )?.description;
          }

          if (+this.values.IFSP2 > 0 && +this.values.IFSP2 <= 5) {
            this.description.IFSP2 = this.RufierTestData.IFSP_Description.find(
              (obj) =>
                +this.values.IFSP2 >= obj.minValue &&
                +this.values.IFSP2 <= obj.maxValue
            )?.description;
          }

          if (+this.values.IFSP3 > 0 && +this.values.IFSP3 <= 5) {
            this.description.IFSP3 = this.RufierTestData.IFSP_Description.find(
              (obj) =>
                +this.values.IFSP3 >= obj.minValue &&
                +this.values.IFSP3 <= obj.maxValue
            )?.description;
          }

          this.description.PARS_R = this.RufierTestData.PARS.find(
            (obj) =>
              +this.values.PARS_R >= obj.minValue &&
              +this.values.PARS_R <= obj.maxValue
          )?.description;

          this.description.IFSP_R = this.RufierTestData.IFSP.find(
            (obj) =>
              +this.values.IFSP_R >= obj.minValue &&
              +this.values.IFSP_R <= obj.maxValue
          )?.description;

          this.description.AI = this.RufierTestData.AI.find(
            (obj) =>
              +this.values.AI >= obj.minValue && +this.values.AI <= obj.maxValue
          )?.description;

          this.description.RUFIER_TEST = this.RufierTestData.RUFIER_TEST.find(
            (obj) =>
              +this.values.P >= obj.minValue && +this.values.P <= obj.maxValue
          )?.description;

          this.className.PARS_R = this.RufierTestData.PARS.find(
            (obj) =>
              +this.values.PARS_R >= obj.minValue &&
              +this.values.PARS_R <= obj.maxValue
          )?.className;

          this.className.IFSP_R = this.RufierTestData.IFSP.find(
            (obj) =>
              +this.values.IFSP_R >= obj.minValue &&
              +this.values.IFSP_R <= obj.maxValue
          )?.className;

          this.className.AI = this.RufierTestData.AI.find(
            (obj) =>
              +this.values.AI >= obj.minValue && +this.values.AI <= obj.maxValue
          )?.className;
        }
      });
  }
}
