<tr>
  <td>{{ index + 1 }}</td>
  <td>{{ element.EquipmentID }}</td>
  <td>{{ element.EquipmentMacId }}</td>
  <td>{{ element.EquipmentName }}</td>
  <td>
    <input type="text" [(ngModel)]="element.options.PackageSize" />
  </td>
  <td>
    <input type="text" [(ngModel)]="element.options.InfSp" />
  </td>
  <td>
    <input type="text" [(ngModel)]="element.options.CuffSize" />
  </td>
  <td>
    <select [(ngModel)]="element.options.shouldTransmitSignal">
      <option value="0">0</option>
      <option value="1">1</option>
    </select>
  </td>
  <td>
    <input type="text" [(ngModel)]="element.identifier.telegram" />
  </td>
  <td><input type="text" [(ngModel)]="element.identifier.graph" /></td>
  <td>
    <!-- </label> -->
    <button class="ml-1" (click)="saveBiosignal(element)" class="addBtn">
      <i class="bi bi-sd-card"></i>
    </button>
    <!-- </div> -->
  </td>
</tr>
