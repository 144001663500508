import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-changes-dialog',
  templateUrl: './confirm-changes-dialog.component.html',
  styleUrls: ['./confirm-changes-dialog.component.scss']
})
export class ConfirmChangesDialogComponent implements OnInit {
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }
}
