<div class="slide-wrapper" [ngClass]="{ 'loader-section': busy }">
  <div class="loader" *ngIf="busy">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="col-md-4">
            <mat-label>
              {{ "measurement.patient" | translate }}
            </mat-label>
            <mat-select
              required
              [disabled]="authService.isPatient()"
              [(ngModel)]="patientId"
              (selectionChange)="setSettings($event.value, equipmentName)"
            >
              <mat-option
                *ngFor="let patient of patients"
                [value]="patient._id"
              >
                {{ patient.last_name }}
                {{ patient.first_name }}
                {{ patient.second_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-4">
            <mat-label>
              {{ "measurement.name_meter" | translate }}
            </mat-label>
            <mat-select
              [disabled]="false"
              [(ngModel)]="equipmentName"
              (selectionChange)="setSettings(patientId, $event.value)"
            >
              <mat-option>-</mat-option>
              <mat-option *ngFor="let item of equipmentList" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <form [formGroup]="myForm">
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-12">
              <h4>
                {{ "measurement.determination_measurement_modes" | translate }}
              </h4>
            </div>
            <mat-form-field class="col-md-6">
              <mat-label>{{ "measurement.day_begins" | translate }}</mat-label>
              <input
                formControlName="DayStart"
                matInput
                type="time"
                style="color-scheme: light"
              />
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <mat-label>{{
                "measurement.night_begins" | translate
              }}</mat-label>
              <input formControlName="NightStart" matInput type="time" />
            </mat-form-field>
          </div>
          <ng-container *ngIf="equipmentName == EquipmentName.VAT">
            <div class="col-md-6">
              <div class="col-md-12">
                <h4>
                  {{
                    "measurement.determination_measurement_frequency"
                      | translate
                  }}
                </h4>
              </div>
              <mat-form-field class="col-md-6">
                <mat-label>{{ "measurement.day" | translate }}</mat-label>
                <input formControlName="DayPeriod" matInput type="number" />
                <mat-hint>
                  {{ "measurement.frequency_in_minutes" | translate }}
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="col-md-6">
                <mat-label>{{ "measurement.night" | translate }}</mat-label>
                <input formControlName="NightPeriod" matInput type="number" />
                <mat-hint>
                  {{ "measurement.frequency_in_minutes" | translate }}
                </mat-hint>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </form>
      <div class="row mt-3">
        <div class="col">
          <div class="col">
            <button class="btn btn-primary" (click)="saveSetting()">
              {{ "form.save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
