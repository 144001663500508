import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Doctor } from 'src/app/models/doctor';
import { Schedule } from 'src/app/models/schedule';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StaffService } from 'src/app/services/staff.service';
import { ChangePasswordDialogComponent } from '../../profile/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-item-consultant',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ConsultantItemComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter();
  moreInfo: boolean = false;
  consultant: Doctor;
  schedule: Schedule;
  doctorId: string;
  user: any;

  @Input() set data(value) {
    if (value) {
      this.consultant = value.doctor;
      this.schedule = value.schedule;
    }
  }
  constructor(private authService: AuthService, public dialog: MatDialog) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    if (this.user.doctors && this.user.doctors.length) {
      this.doctorId =
        this.user.doctors.indexOf(this.consultant._id) !== -1
          ? this.consultant._id
          : null;
    }
  }

  checkConsultant() {
    this.change.emit(this.doctorId);
  }
}
