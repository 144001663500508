<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="d-flex">
    <div class="x-label">
      <div class="rotate">mV</div>
      <div #xTicks class="x-ticks">
        <!-- <span>x-ticks</span> -->
      </div>
    </div>

    <div class="scrollbar-primary scrollable-graph">
      <ngx-charts-line-chart
        #chart
        [view]="[scrollWidth, scrollHeight]"
        [hidden]="isLoading"
        [animations]="false"
        (window:resize)="onResize($event)"
        style="position: relative; float: none"
        [xAxis]="true"
        [yAxis]="true"
        [rotateXAxisTicks]="false"
        [tooltipDisabled]="false"
        [results]="rawSignalData"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
      >
        <!-- [showXAxisLabel]="false"
      [showYAxisLabel]="false" -->
        <!-- [yAxisTicks]="yAxisTicks" -->
        <!-- [xAxisTicks]="xAxisTicks" -->
        <!-- [yAxisTickFormatting]="formatAxisTickDateY" -->
        <!-- [xAxisTickFormatting]="formatAxisTickDateX" -->
        <!-- [yScaleMin]="timePointsInterval.minY" -->
        <!-- [yScaleMax]="timePointsInterval.maxY" -->

        <ng-template #tooltipTemplate let-model="model">
          <div class="chart-tooltip-container">
            <div class="content">
              <div class="text-tooltip text-center">
                sec:&nbsp;{{ model.name.toFixed(5) }}
              </div>
            </div>
            <div class="content">
              <div class="text-tooltip">
                mV:&nbsp;{{ (model.value / (10 * ecgHeight)).toFixed(5) }}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
          <div class="chart-tooltip-container">
            <div class="content" *ngFor="let item of model">
              <div class="color" [style.background-color]="item.color"></div>
              <div class="text-tooltip">
                mV:&nbsp;{{ (item.value / (10 * ecgHeight)).toFixed(5) }}
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
  <div class="x-label text-center">t, s</div>
</div>
