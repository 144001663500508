import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileComponent } from './profile.component';
import { DoctorProfileComponent } from './doctor/doctor-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfileRoutingModule } from './profile-routing.module';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { PatientComponent } from './patient/patient.component';
import { ScheduleModule } from '../global-components/schedule/schedule.module';
import { ConsultantModule } from '../global-components/consultant/consultant.module';
import { MaterialModule } from 'src/app/material.module';
import { SvgModule } from 'src/app/svg.module';
import { PatientsListModule } from '../global-components/patients-list/patients-list.module';

@NgModule({
  declarations: [
    ProfileComponent,
    DoctorProfileComponent,
    ChangePasswordDialogComponent,
    PatientComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule,
    NgSelectModule,
    NgbNavModule,
    ProfileRoutingModule,
    ConsultantModule,
    ScheduleModule,
    MaterialModule,
    SvgModule,
    PatientsListModule,
  ],
  exports: [],
})
export class ProfileModule {}
