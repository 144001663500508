import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { RoleLoginComponent } from './login/role-login/role-login.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { RoleRegistrationComponent } from './registration/role-registration/role-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthComponent } from './auth.component';
import { LangModule } from '../global-components/lang/lang.module';
import { MaterialModule } from 'src/app/material.module';
import { SvgModule } from 'src/app/svg.module';
import { RoleRegistrationPatientModule } from '../global-components/role-registration-patient/role-registration-patient.module';
import { RoleRegistrationDoctorModule } from '../global-components/role-registration-doctor/role-registration-doctor.module';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    RoleLoginComponent,
    RegistrationComponent,
    RoleRegistrationComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    PipesModule,
    LangModule,
    MaterialModule,
    SvgModule,
    RoleRegistrationPatientModule,
    RoleRegistrationDoctorModule,
  ],
  exports: [],
  entryComponents: [],
  providers: [HeaderComponent, FooterComponent],
})
export class AuthModule {}
