<div class="row">
  <mat-form-field class="col-md-4 col-sm-6 col-lg-4 col-12">
    <mat-label>Швидкість запису ЕКГ</mat-label>
    <mat-select
      [(ngModel)]="ecgWidth"
      (selectionChange)="setContainerWidth($event.value)"
    >
      <mat-option *ngFor="let item of ECGspeedObj" [value]="item.pixel">
        {{ item.mm }}&nbsp;{{ "calculation.mm_per_s" | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-md-4 col-sm-6 col-lg-4 col-12">
    <mat-label>Амплітуда запису ЕКГ</mat-label>
    <mat-select
      [(ngModel)]="ecgHeight"
      (selectionChange)="getTempValueY($event.value)"
    >
      <mat-option *ngFor="let item of ECGamplitudeObj" [value]="item.value">
        {{ item.mm }}&nbsp;{{ "calculation.mm_per_mv" | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="box-align col-md-4 col-sm-6 col-lg-4 col-12">
    <mat-form-field class="small-field">
      <mat-label>від (с)</mat-label>
      <input
        matInput
        type="number"
        [min]="timePointsInterval.minX"
        [max]="timePointsInterval.maxX - 1"
        [(ngModel)]="start_time"
        (change)="changeTimePointsInterval('start', $event.target)"
      />
    </mat-form-field>
    <div class="line"></div>
    <mat-form-field class="small-field">
      <mat-label>до (с)</mat-label>
      <input
        matInput
        type="number"
        [min]="timePointsInterval.minX + 1"
        [max]="timePointsInterval.maxX"
        [(ngModel)]="end_time"
        (change)="changeTimePointsInterval('end', $event.target)"
      />
    </mat-form-field>
  </div>
</div>
<div [ngClass]="{ 'loader-section': isLoading }">
  <div class="loader" *ngIf="isLoading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="d-flex" *ngFor="let data of oscillogramData">
    <div class="x-label">
      <div class="rotate">mV</div>
      <div #xTicks class="x-ticks">
        <!-- <span>x-ticks</span> -->
      </div>
    </div>

    <div class="scrollbar-primary scrollable-graph">

      <app-charts-line-chart
      
      [points_values]="data"
      [yMin]="timePointsInterval.minY"
      [yMax]="timePointsInterval.maxY"
      [yAxis]="yAxis"
    >
    </app-charts-line-chart>
    </div>
  </div>
  <div class="x-label text-center">t, s</div>
</div>
