import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Patient } from '../../../models/patient';
import { PatientService } from '../services/patient.service';
import { BiosignalsService } from '../../../services/biosignals.service';
import { Biosignal } from 'src/app/models/biosignal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShortDataService } from './services/short-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SortableHeader } from 'src/app/directives/sortable-header/sortable-header.directive';
import { SortEvent } from './interfaces/event.interface';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmDownloadDialogComponent } from './dialogs/confirm-download-dialog/confirm-download-dialog.component';
import { RedirectDialogComponent } from './dialogs/redirect-dialog/redirect-dialog.component';
import { AgePipe } from 'src/app/pipes/age.pipe';
import { TranslateService } from '@ngx-translate/core';
import { RecalculateDialogComponent } from './dialogs/recalculate-dialog/recalculate-dialog.component';
import { forkJoin } from 'rxjs';
import { CsvService } from 'src/app/services/csv.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { GenderService } from 'src/app/services/dictionaries/gender.service';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { AoUploadFormComponent } from './upload-forms-data/ao-upload-form/ao-upload-form.component';
import { CsvNewService } from 'src/app/services/csv-new.service';

@Component({
  selector: 'app-measurements-upload',
  templateUrl: './measurements-upload.component.html',
  styleUrls: ['./measurements-upload.component.scss'],
  providers: [AgePipe],
})
export class MeasurementsUploadComponent
  extends OnDestroyMixin
  implements OnInit
{
  @Input() patientData: Patient;
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  myForm: FormGroup;
  patients: Patient[] = [];
  files: File[] = [];
  biosignals: Biosignal[] = [];
  tableData = [];
  tableSortedData = [];
  defaultTable = [];
  showIndicatorDescription: boolean = false;
  phase: Array<any> = [];
  expandRow: boolean = false;
  isHide: boolean = false;
  formData: FormData;
  gender;
  age;
  doctor;
  diagnosis;
  busy: boolean = false;
  tableBusy: boolean = false;
  isDisabled: boolean = true;
  allCheck: boolean = false;

  typefile: GraphType = GraphType.AO;

  @ViewChild(AoUploadFormComponent) ao!: AoUploadFormComponent;

  itemsPerPage: number = 20;
  pageN: number = 1;
  constructor(
    private patientService: PatientService,
    private biosignalsService: BiosignalsService,
    public shortDataService: ShortDataService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private modalService: NgbModal,
    private agePipe: AgePipe,
    private translate: TranslateService,
    private csvService: CsvService,
    private csvNewService: CsvNewService,
    public genderService: GenderService
  ) {
    super();
  }

  get auth() {
    return this.authService;
  }

  ngOnInit(): void {
    this.reactiveForm();
    this.patientService
      .getDoctorPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        if (data) this.patients = data;
        else this.patients = null;
        if (this.authService.isPatient()) {
          this.setPatientData(this.patients ? this.patients[0] : null);
        } else {
          this.patientService.patient$
            .pipe(untilComponentDestroyed(this))
            .subscribe((patient) => {
              this.setPatientData(patient);
            });
        }
      });
  }

  setPatientData(patient) {
    if (patient) {
      this.patientData = patient;
      this.myForm.get('patientId').patchValue(patient._id);
      this.getBiosignals(patient._id);
    } else {
      this.patientData = null;
      this.myForm.get('patientId').reset();
      this.getBiosignals(null);
    }
  }

  getBiosignals(patientId?) {
    this.tableBusy = true;
    this.biosignals = [];
    let startDate = new Date('1990-08-24').toISOString();
    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    let endDate = new Date('3000-08-24').toISOString();
    this.biosignalsService
      .getMeasurementData(startDate, endDate, patientId)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        if (data && Object.values(data).length) {
          this.biosignals = data.measurementResults;
          this.updateTableData();
          this.tableBusy = false;
        } else {
          this.tableBusy = false;
          this.tableData = [];
        }
      });
  }

  yourFn($event) {
    this.typefile =
      $event.index == 0
        ? GraphType.AO
        : $event.index == 1
        ? GraphType.ECG
        : $event.index == 2
        ? GraphType.WP
        : $event.index == 3
        ? GraphType.CP
        : GraphType.AO;
  }

  getFormValue(obj: { type: GraphType; formValue }) {
    if (!(obj instanceof Event))
      Object.keys(this.myForm.controls[obj.type].value).forEach((v) => {
        this.myForm.controls[obj.type]?.get(v)?.setValue(obj.formValue[v]);
      });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      patientId: ['', Validators.required],
      ao: this.fb.group({
        EquipmentName: ['', Validators.required],
        measurementGroup: [''],
        phase: ['', Validators.required],
        signalType: [''],
        phaseType: [''],
      }),
      wp: this.fb.group({
        EquipmentName: ['', Validators.required],
      }),
      cp: this.fb.group({
        EquipmentName: ['', Validators.required],
      }),
    });
  }

  public buttonClick(event): void {
    const buttonEl = event.target;
    buttonEl.classList.toggle('button-block__itemPassive');
    buttonEl.classList.toggle('button-block__itemActive');
  }

  public selectPatient(id: string): void {
    this.patientData = this.patients.find((item) =>
      item._id === id ? item : null
    );
    if (this.patientData) this.gender = this.patientData.gender;
    this.age = this.agePipe.transform(this.patientData.birth_date);
    this.doctor = this.patientData.doctors;
    this.diagnosis = this.patientData.diagnosis;
    this.myForm.patchValue(this.patientData);
    this.myForm.get('patientId').patchValue(this.patientData._id);
    this.getBiosignals(this.patientData._id);
    this.patientService.setPatient = this.patientData;
    localStorage.setItem('selectedPatient', JSON.stringify(this.patientData));
  }

  public onSaveBtnClick(): void {
    this.busy = true;
    if (this.myForm.controls[this.typefile].valid) {
      this.formData = new FormData();

      if (this.files.length) {
        for (let file of this.files) {
          let filetype = file.name.split('.')[file.name.split('.').length - 1];
          let fileName =
            file.name
              .replace('.' + `${filetype}`, '')
              .split('.')
              .join('')
              .split('+')
              .join('') +
            ('.' + `${filetype}`);
          this.formData.append('biosignalFiles', file);
          this.formData.append(fileName, '' + file.lastModified);
        }
      }

      let temp = JSON.parse(
        JSON.stringify(this.myForm.controls[this.typefile].value)
      );

      this.toFormData(
        Object.assign(temp, {
          patientId: this.myForm.value.patientId,
          type: this.typefile,
        })
      );

      this.biosignalsService
        .uploadMeasurementFiles(this.formData)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (resp) => {
            this.busy = false;
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_7);
              });
            this.files = [];
            this.getBiosignals(this.patientData._id);
            this.ao.aoForm.reset();
            if (this.authService.isPatient()) {
              this.myForm.get('patientId').patchValue(this.patients[0]._id);
            }
            this.formData = null;
          },
          (err: any) => {
            this.busy = false;
            this.toastr.error(err.message);
          }
        );
    } else {
      this.busy = false;
      this.translate
        .get(['alerts'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((t) => {
          this.toastr.warning(t.alerts.warning_1);
        });
    }
  }

  toFormData(formValue) {
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      this.formData.append(key, value);
    }
  }

  setFiles(event) {
    this.files = Array.from(event.target.files);
  }

  deletedFile(i) {
    this.files.splice(i, 1);
  }

  updateTableData(): void {
    if (this.biosignals && this.biosignals.length) {
      this.tableData = [];
      this.biosignals.forEach((biosignal) => {
        const tempData = {
          id: biosignal._id,
          patient: '',
          PatientID: '',
          date: biosignal.SignalRegistrationDateTime,
          hospital: '',
          doctor: '',
          BloodGlucose: biosignal.BloodGlucose,
          phase: biosignal.phase,
          measurementGroup: biosignal.measurementGroup,
          signalType: biosignal.signalType,
          EquipmentName: biosignal.EquipmentName,
          name: biosignal.fileName,
          Systola: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.Systola : 0,
          Diastola: biosignal.TimeAnalysis
            ? biosignal.TimeAnalysis.Diastola
            : 0,
          HeartRate: biosignal.TimeAnalysis
            ? biosignal.TimeAnalysis.HeartRate
            : 0,
          pars: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.PARSint : 0,
          ifsp: biosignal.TimeAnalysis ? biosignal.TimeAnalysis.IFSP : 0,
          msr: biosignal.MorphologicalAnalysis
            ? biosignal.MorphologicalAnalysis.Ms
            : 0,
          // comment: biosignal.comment,
          biosignal,
          computeStatus: biosignal.ComputeStatus,
          status: biosignal.Status,
        };

        if (!this.patientData) {
          const patient = biosignal.PatientID as Patient;
          tempData.patient = `${patient.first_name} ${patient.last_name} ${
            patient.second_name ? patient.second_name : ''
          }`;
          tempData.PatientID = patient._id;
        } else {
          tempData.PatientID = biosignal.PatientID as string;
        }
        this.tableData.push(tempData);
        this.defaultTable = this.tableData;
        this.tableSortedData = this.tableData.slice();
        this.sortData({
          active: 'date',
          direction: 'desc',
        });
      });
    }
  }

  sortData(sort: any) {
    const data = this.tableData.slice();
    if (!sort.active || sort.direction === '') {
      this.tableSortedData = data;
      return;
    }
    this.tableSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare = (
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  expand() {
    this.expandRow = !this.expandRow;
  }

  download() {
    if (!this.myForm.get('patientId').value) {
      this.translate
        .get(['alerts'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((t) => {
          this.toastr.warning(t.alerts.warning_8);
        });
    } else {
      const modal = this.modalService.open(ConfirmDownloadDialogComponent);
      modal.result
        .then((result) => {
          const data = {
            format: result,
          };
          const biosignals = this.tableData
            .map((item) => (item.biosignal.checked ? item.id : null))
            .filter((data) => data);
          if (
            data.format === 'json' ||
            data.format === 'csv' ||
            data.format === 'csv_new'
          ) {
            this.getBiosignalFile(biosignals, data.format, true);
          } else if (data.format === 'txt') {
            this.getBiosignalFile(biosignals, data.format);
          }
          this.tableData.forEach((item) => {
            item.biosignal.checked = false;
          });
        })
        .catch(() => console.log('error'));
    }
  }

  getBiosignalFile(biosignals, format, isJson?) {
    if (format == 'csv' || format == 'csv_new') {
      let tempArray = [];
      biosignals.forEach((biosignal) => {
        tempArray.push(
          this.biosignalsService.downloadBiosignal(
            this.myForm.get('patientId').value,
            biosignal,
            isJson
          )
        );
      });
      forkJoin(tempArray)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (results) => {
            let temp = [];
            results.map((v: any) => {
              temp.push(JSON.parse(v));
            });
            if (format == 'csv') this.csvService.downloadFile(temp, 'data');
            if (format == 'csv_new')
              this.csvNewService.downloadFile(temp, 'data');
            this.isDisabled = true;
          },
          (err: any) => {
            this.toastr.error(err.message);
          }
        );
    } else
      biosignals.forEach((biosignal) => {
        this.biosignalsService
          .downloadBiosignal(
            this.myForm.get('patientId').value,
            biosignal,
            isJson
          )
          .pipe(untilComponentDestroyed(this))
          .subscribe(
            (resp) => {
              if (resp) {
                const blob = new Blob([resp], {
                  type: 'application/octet-stream',
                });
                let fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  window.URL.createObjectURL(blob)
                )['changingThisBreaksApplicationSecurity'];
                let fileName =
                  fileUrl.split('/')[fileUrl.split('/').length - 1];
                const downloadAncher = document.createElement('a');
                downloadAncher.style.display = 'none';
                downloadAncher.href = fileUrl;
                downloadAncher.download = fileName + '.' + format;
                downloadAncher.click();
              }
              this.isDisabled = true;
            },
            (err: any) => {
              this.toastr.error(err.message);
            }
          );
      });
  }

  selectCheckBox() {
    this.isDisabled = this.allCheck ? false : true;
    this.biosignals.map(
      (element) => (element.checked = this.allCheck ? true : false)
    );
  }

  checkIsSelected() {
    this.biosignals.forEach((element) => {
      if (element.checked) {
        this.isDisabled = false;
      }
    });
  }

  updateBiosignal(data) {
    this.getBiosignals(this.patientData._id);
  }

  onSelect(data) {
    this.isDisabled = true;
    this.checkIsSelected();
  }

  loaded(data) {
    this.tableBusy = data;
  }

  remove() {
    if (confirm('Are you sure to delete file')) {
      const biosignals = this.tableData
        .map((item) =>
          item.biosignal.checked ? { id: item.id, type: item.signalType } : null
        )
        .filter((data) => data);
      biosignals.forEach((signal) => {
        this.biosignalsService
          .removeSignal(signal.id, signal.type)
          .pipe(untilComponentDestroyed(this))
          .subscribe((resp) => {
            this.translate
              .get(['alerts'])
              .pipe(untilComponentDestroyed(this))
              .subscribe((t) => {
                this.toastr.success(t.alerts.warning_9);
              });
            this.getBiosignals(this.patientData._id);
            this.tableData.forEach((item) => {
              item.biosignal.checked = false;
            });
            this.allCheck = false;
          });
      });
    }
  }

  redirect() {
    const modal = this.modalService.open(RedirectDialogComponent);
    modal.componentInstance.patients = this.patients;
    modal.componentInstance.patientId = this.patientData._id;
    modal.result
      .then((result) => {
        const data = {
          patient: result,
        };
        const biosignals = this.tableData
          .map((item) => (item.biosignal.checked ? item.id : null))
          .filter((data) => data);
        biosignals.forEach((biosignal) => {
          this.biosignalsService
            .redirectBiosignal(biosignal, data.patient)
            .pipe(untilComponentDestroyed(this))
            .subscribe((resp) => {
              this.translate
                .get(['alerts'])
                .pipe(untilComponentDestroyed(this))
                .subscribe((t) => {
                  this.toastr.success(t.alerts.warning_10);
                });
              this.getBiosignals(this.patientData._id);
            });
        });
        this.tableData.forEach((item) => {
          item.biosignal.checked = false;
        });
      })
      .catch(() => console.log('error'));
  }

  recalculate() {
    const modal = this.modalService.open(RecalculateDialogComponent);

    const biosignals = this.tableData
      .map((item) => (item.biosignal.checked ? item.id : null))
      .filter((data) => data);
    modal.componentInstance.biosignals = biosignals;
    modal.result
      .then((result) => {
        result.forEach((biosignal) => {
          let minExtremesSet;
          let maxExtremesSet;
          let oscillogramData;
          this.biosignalsService
            .getOscillogramExtremes(biosignal)
            .pipe(untilComponentDestroyed(this))
            .subscribe((oscillogramExtremesData) => {
              oscillogramData = oscillogramExtremesData.oscillogramData;
              minExtremesSet = new Set(
                oscillogramExtremesData.extremumsData.negativeExtremums.indices
              );
              maxExtremesSet = new Set(
                oscillogramExtremesData.extremumsData.positiveExtremums.indices
              );
              let minExtremesArray = Array.from(minExtremesSet.values()).sort(
                (a: any, b: any) => a - b
              );
              let maxExtremesArray = Array.from(maxExtremesSet.values()).sort(
                (a: any, b: any) => a - b
              );

              const extremesData = {
                negativeExtremums: {
                  timePoints: [],
                  valuePoints: [],
                  indices: [],
                },
                positiveExtremums: {
                  timePoints: [],
                  valuePoints: [],
                  indices: [],
                },
              };

              for (const i of minExtremesArray) {
                let data: any = i;
                extremesData.negativeExtremums.timePoints.push(
                  oscillogramData.timePoints[data]
                );
                extremesData.negativeExtremums.valuePoints.push(
                  oscillogramData.valuePoints[data]
                );
                extremesData.negativeExtremums.indices.push(data);
              }
              for (const i of maxExtremesArray) {
                let data: any = i;
                extremesData.positiveExtremums.timePoints.push(
                  oscillogramData.timePoints[data]
                );
                extremesData.positiveExtremums.valuePoints.push(
                  oscillogramData.valuePoints[data]
                );
                extremesData.positiveExtremums.indices.push(data);
              }
              this.biosignalsService
                .changeExtremes(biosignal, extremesData)
                .pipe(untilComponentDestroyed(this))
                .subscribe((resp) => {
                  this.tableData.forEach((item) => {
                    item.biosignal.checked = false;
                    this.isDisabled = true;
                  });
                });
            });
        });
        this.translate
          .get(['alerts'])
          .pipe(untilComponentDestroyed(this))
          .subscribe((t) => {
            this.toastr.warning(t.alerts.warning_6);
          });
      })
      .catch(() => console.log('error'));
  }
}
