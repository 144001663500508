import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Оранта Артеріальна Осцилографія';
  closed:boolean=false
  constructor(
    public translate: TranslateService,
    public authService: AuthService) {
  }

  ngOnInit(): void {
    const language = this.authService.getLanguage();
    if(language) {
      this.translate.use(language);
    } else {
      this.authService.setLanguage('ua');
      this.translate.use('ua');
    }
  }
}
