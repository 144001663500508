import { SortableHeader } from '../../../directives/sortable-header/sortable-header.directive';
import { ErrorMeasurments } from '../../../models/erroneous-biosignals';
import { BiosignalsService } from '../../../services/biosignals.service';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DeviceMeasurementsErrorService } from 'src/app/services/device-measurements-error.service';
import { DeviceMeasurementErrors } from 'src/app/models/device-measurements-error';

@Component({
  selector: 'app-error-measurements',
  templateUrl: './error-measurements.component.html',
  styleUrls: ['./error-measurements.component.scss'],
})
export class ErrorMeasurementsComponent
  extends OnDestroyMixin
  implements OnInit
{
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  errorMeasurements: ErrorMeasurments[];
  sortedData: ErrorMeasurments[];

  myForm: FormGroup = new FormGroup({});

  errDeviceMeasurementsData: DeviceMeasurementErrors[] = [];
  errSortedDeviceMeasurementsData: DeviceMeasurementErrors[] = [];

  tableCsvData: any = [];

  itemsPerPage = 10;
  p2 = 1;
  p1 = 1;
  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private biosignalsService: BiosignalsService,
    public datePipe: DatePipe,
    private translate: TranslateService,
    private deviceMeasurementsErrorService: DeviceMeasurementsErrorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reactiveForm();
    this.onDateInput();
    this.deviceMeasurementsErrorService
      .getDeviceMeasurmentsErrorAll()
      .subscribe((v) => {
        v.map((val) => {
          const temp =
            val.timestamp.includes(' ') || val.timestamp.includes(':')
              ? val.timestamp
              : Number(val.timestamp);
          val.timestamp = new Date(temp);
          this.errDeviceMeasurementsData.push(val);
        });
        this.errDeviceMeasurementsData = v;
        this.errSortedDeviceMeasurementsData =
          this.errDeviceMeasurementsData.slice();
        this.sortDeviceMeasurementsErrData({
          active: 'timestamp',
          direction: 'desc',
        });
      });
  }

  reactiveForm() {
    this.myForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
    });
  }

  get fromDate(): FormControl {
    return this.myForm.get('fromDate') as FormControl;
  }

  get toDate(): FormControl {
    return this.myForm.get('toDate') as FormControl;
  }

  getErrorMeasurements() {
    this.errorMeasurements = [];
    this.biosignalsService
      .getErrorMeasurments(this.fromDate.value, this.toDate.value)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resp) => {
        this.errorMeasurements = resp;
        this.sortedData = this.errorMeasurements.slice();
        this.sortData({
          active: 'SignalRegistrationDateTime',
          direction: 'desc',
        });
      });
  }

  sortData(sort: any) {
    const data = this.errorMeasurements.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  sortDeviceMeasurementsErrData(sort: any) {
    const data = this.errDeviceMeasurementsData.slice();
    if (!sort.active || sort.direction === '') {
      this.errSortedDeviceMeasurementsData = data;
      return;
    }
    this.errSortedDeviceMeasurementsData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare = (
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  onDateInput() {
    if (this.fromDate.value)
      this.fromDate.setValue(
        new Date(
          moment(this.fromDate.value).year(),
          moment(this.fromDate.value).month(),
          moment(this.fromDate.value).date(),
          0,
          0,
          0
        )
      );
    else this.fromDate.setValue('');

    if (this.toDate.value)
      this.toDate.setValue(
        new Date(
          moment(this.toDate.value).year(),
          moment(this.toDate.value).month(),
          moment(this.toDate.value).date(),
          23,
          59,
          59
        )
      );
    else this.toDate.setValue('');
    this.getErrorMeasurements();
  }

  empty = (value) => (value ? value : '');

  generateCsv() {
    this.tableCsvData = [];
    this.sortedData.forEach((v) => {
      this.tableCsvData.push({
        doctor: `${this.empty(v?.DoctorID?.first_name)} ${this.empty(
          v?.DoctorID?.last_name
        )} ${this.empty(v?.DoctorID?.second_name)}`,
        patient: `${this.empty(v?.PatientID?.first_name)} ${this.empty(
          v?.PatientID?.last_name
        )} ${this.empty(v?.PatientID?.second_name)}`,
        uploadDateTime: `${this.datePipe.transform(
          v.uploadDateTime,
          'dd-MM-yyyy HH:mm:ss'
        )}`,
        SignalRegistrationDateTime: `${this.datePipe.transform(
          v.SignalRegistrationDateTime,
          'dd-MM-yyyy HH:mm:ss'
        )}`,
        EquipmentID: v.EquipmentID,
        EquipmentMacId: v.EquipmentMacId,
        EquipmentName: v.EquipmentName,
        Status: v.Status,
      });
    });

    let headers = [
      'doctor',
      'patient',
      'uploadDateTime',
      'SignalRegistrationDateTime',
      'EquipmentID',
      'EquipmentMacId',
      'EquipmentName',
      'Status',
    ];

    this.translate
      .get('measurement')
      .subscribe(
        (v) => (headers = headers.map((h) => v.error_measurements_header[h]))
      );

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Report ${this.datePipe.transform(
        new Date(),
        'dd-MM-yyyy hh:MM:ss'
      )}`,
      headers,
      useHeader: false,
      nullToEmptyString: true,
    };
    new AngularCsv(
      this.tableCsvData,
      `Report-${this.datePipe.transform(new Date(), 'dd-MM-yy')}`,
      options
    );
  }
}
