import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphType } from 'src/app/enums/graph_type.enum';
import { ShortDataService } from '../../services/short-data.service';

@Component({
  selector: 'app-ecg-upload-form',
  templateUrl: './ecg-upload-form.component.html',
  styleUrls: ['./ecg-upload-form.component.scss'],
})
export class EcgUploadFormComponent implements OnInit {
  ecgForm: FormGroup = new FormGroup({});
  @Output() change = new EventEmitter();
  @Input() shortDataService: ShortDataService;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.reactiveForm();
    this.ecgForm.valueChanges.subscribe((v) => {
      this.change.emit({ type: GraphType.ECG, formValue: v });
    });
  }

  reactiveForm() {
    this.ecgForm = this.fb.group({
      EquipmentName: ['', Validators.required],
    });
  }
}
