<form [formGroup]="ecgForm">
  <mat-form-field class="col-md-4 mt-3">
    <mat-label>
      {{ "measurement.type_equipment" | translate }}
    </mat-label>
    <mat-select required formControlName="EquipmentName">
      <mat-option>-</mat-option>
      <mat-option
        *ngFor="let elem of shortDataService.equipment_ecg"
        [value]="elem.value"
      >
        {{ elem | lang }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
