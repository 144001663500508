<li
  class="w3-bar scrollbar"
  (click)="viewPatients(doctor._id)"
  [ngClass]="{ 'w3-bar--active': doctor._id == selectedId }"
>
  <div>
    <div
      class="photo"
      style="width: 85px; height: 85px; border-radius: 50%; margin: 0 auto"
    >
      <app-doctor-photo [doctorId]="doctor._id"></app-doctor-photo>
    </div>
  </div>
  <div class="w3-bar-item">
    <div class="level-1">
      <div class="w3-large">
        {{ doctor.last_name }}
        {{ doctor.first_name }}
        {{ doctor.second_name }}
      </div>
    </div>

    <span class="subtitle mt-1" *ngIf="doctor.email">
      <b
        ><ng-container *ngIf="!(reloading$ | async)">{{
          doctor.gender | valueById : genderDict.gender : "value" | lang
        }}</ng-container></b
      >&nbsp;<ng-container *ngIf="doctor.gender">, </ng-container>
      {{ doctor.birth_date | age }}&nbsp;{{
        "form.years_old" | translate
      }}&nbsp;
    </span>

    <div class="subtitle mt-1">
      <ng-container
        *ngIf="doctor.medicalInstitution; else withoutMedicalInstitution"
      >
        <b>{{ doctor.medicalInstitution.name }}</b>
      </ng-container>
      <ng-template #withoutMedicalInstitution>
        <b>{{ "admin.without_medical_institution" | translate }}</b>
      </ng-template>
    </div>

    <span class="subtitle mt-1" *ngIf="doctor.email">
      <b>Email:</b>&nbsp;{{ doctor.email }};&nbsp;
    </span>
    <span class="subtitle mt-1" *ngIf="doctor.phones">
      <b>Phone:</b
      ><ng-container *ngFor="let item of doctor.phones"
        >&nbsp;{{ item.number ? item.number : "-" }} <br />
      </ng-container>
    </span>
  </div>
</li>
