import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  today = new Date();
  transform(value: any): any {
    if (value) {
      const birthDate = new Date(value);
      let age = this.today.getFullYear() - birthDate.getFullYear();
      const m = this.today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && this.today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  }
}
