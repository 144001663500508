<div class="main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col">
        <form [formGroup]="myForm">
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <div class="col">
                  <div class="photo">
                    <label class="" for="fileInput">
                      <img
                        [src]="
                          photoExist
                            ? (photoEmitter | async)
                            : '/assets/img/noavatar.png'
                        "
                      />
                      <div class="overlay" *ngIf="isEdit">
                        <i class="bi bi-camera"></i>
                        <p>{{ "form.drag_select" | translate }}</p>
                      </div>
                    </label>
                    <input
                      *ngIf="isEdit"
                      id="fileInput"
                      [readonly]="!isEdit"
                      hidden
                      type="file"
                      (change)="onSelectFiles($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center mt-3">
                <div class="col-auto">
                  <button
                    *ngIf="isEdit"
                    type="submit"
                    [disabled]="!isDisabled"
                    class="btn btn-primary"
                    (click)="photoUpdate()"
                  >
                    {{ "form.photo_update" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <div class="row">
                <div class="col-md-11">
                  <div class="row mt-4">
                    <mat-form-field class="col-md-4">
                      <mat-label>
                        {{ "form.start_work" | translate }}
                      </mat-label>
                      <input
                        matInput
                        [readonly]="!isEdit"
                        [matDatepicker]="start_date"
                        formControlName="start_date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="start_date"
                        [disabled]="!isEdit"
                      >
                      </mat-datepicker-toggle>
                      <mat-datepicker #start_date touchUi></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-md-4">
                      <mat-label>
                        {{ "form.end_work" | translate }}
                      </mat-label>
                      <input
                        matInput
                        [readonly]="!isEdit"
                        [matDatepicker]="end_date"
                        formControlName="end_date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="end_date"
                        [disabled]="!isEdit"
                      >
                      </mat-datepicker-toggle>
                      <mat-datepicker #end_date touchUi></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="col-md-4">
                      <mat-label>
                        {{ "login.login" | translate }}
                      </mat-label>
                      <input
                        matInput
                        [readonly]="!isEdit"
                        formControlName="login"
                        type="text"
                        required
                      />
                    </mat-form-field>

                    <mat-form-field class="col-md-4">
                      <mat-label>
                        {{ "login.password" | translate }}
                      </mat-label>
                      <input
                        matInput
                        readonly
                        formControlName="password"
                        type="text"
                        required
                      />
                    </mat-form-field>

                    <div
                      class="col-md-4 d-flex align-items-center"
                      *ngIf="isEdit"
                    >
                      <button
                        class="btn btn-outline-primary btn-sm"
                        (click)="changePassword()"
                      >
                        {{ "form.change_password" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <mat-form-field class="col-md-4">
                      <mat-label>
                        {{ "registration.medOrganization" | translate }}
                      </mat-label>
                      <mat-select
                        [disabled]="!isEdit"
                        formControlName="medicalInstitution"
                      >
                        <mat-option>-</mat-option>
                        <mat-option
                          *ngFor="let item of medicalInstitutions"
                          [value]="item._id"
                        >
                          {{ item.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label> &nbsp; </label>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addMedicalInstitution()"
                          class="addBtn"
                          ngbTooltip="{{
                            'form.add_medical_institutions' | translate
                          }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md">
                      <button
                        class="btn btn-outline-primary btn-sm"
                        (click)="openDialog()"
                      >
                        <mat-icon svgIcon="telegram"></mat-icon>
                        Отримувати сповіщення в телеграм бот
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 mt-4" *ngIf="!isAdmin">
                  <span
                    class="addBtn"
                    (click)="edit()"
                    *ngIf="isStaff"
                    ngbTooltip="{{ 'form.edit' | translate }}"
                    placement="top"
                  >
                    <i class="bi bi-pencil"></i>
                  </span>
                </div>
              </div>

              <hr />
              <!-- ------------------------------------------------------------------------- -->
              <!-- -----------------------------PERSONAL INFO-------------------------------- -->
              <div class="row mt-5">
                <div class="col h-1">
                  <app-accordion [title]="'form.personal_info' | translate">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.surname" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              required
                              type="text"
                              formControlName="last_name"
                            />
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.name" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              required
                              type="text"
                              formControlName="first_name"
                            />
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.secondName" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="second_name"
                            />
                          </mat-form-field>
                        </div>

                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.birthDate" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              required
                              [matDatepicker]="birth_date"
                              formControlName="birth_date"
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="birth_date"
                              [disabled]="!isEdit"
                            >
                            </mat-datepicker-toggle>
                            <mat-datepicker
                              #birth_date
                              touchUi
                            ></mat-datepicker>
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.sex" | translate }}
                            </mat-label>
                            <mat-select
                              [disabled]="!isEdit"
                              required
                              formControlName="gender"
                            >
                              <mat-option>-</mat-option>
                              <mat-option
                                *ngFor="let item of genderService.gender"
                                [value]="item.value"
                              >
                                <ng-container *ngIf="!(reloading$ | async)">
                                  {{ item | lang }}
                                </ng-container>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.address" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="address"
                            />
                          </mat-form-field>
                        </div>

                        <div formArrayName="phones">
                          <div
                            class="row mt-3"
                            *ngFor="
                              let item of phones.controls;
                              let phoneIndex = index
                            "
                            [formGroupName]="phoneIndex"
                          >
                            <mat-form-field class="col-md-4">
                              <mat-label>
                                {{ "form.phone_type" | translate }}
                              </mat-label>
                              <mat-select
                                [disabled]="!isEdit"
                                formControlName="type"
                              >
                                <mat-option>-</mat-option>
                                <mat-option
                                  *ngFor="let item of phoneService.phones_type"
                                  [value]="item.value"
                                >
                                  <ng-container *ngIf="!(reloading$ | async)">
                                    {{ item | lang }}
                                  </ng-container>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="col-md-4">
                              <mat-label>
                                {{ "form.phone" | translate }}
                              </mat-label>
                              <input
                                matInput
                                [readonly]="!isEdit"
                                type="text"
                                formControlName="number"
                                placeholder="+38097 77 77 777"
                              />
                            </mat-form-field>

                            <div class="col-md-4 mt-3">
                              <button
                                *ngIf="displayDelBtn(phones) && isEdit"
                                (click)="deletedItem(phones, phoneIndex)"
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{ 'form.del-phone' | translate }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                          <button
                            *ngIf="isEdit"
                            class="ml-1"
                            (click)="addPhone(phones)"
                            class="addBtn"
                            ngbTooltip="{{ 'form.add-phone' | translate }}"
                            placement="top"
                          >
                            <i class="bi bi-plus"></i>
                          </button>
                        </div>

                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.working_experience" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="working_experience"
                            />
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.about_myself" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="about_myself"
                            />
                          </mat-form-field>

                          <div class="col-md-4"></div>
                        </div>

                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.name_insurance_policy" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="insurance_policy_name"
                            />
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.valid-to" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              [matDatepicker]="insurance_policy_date"
                              formControlName="insurance_policy_date"
                            />
                            <mat-datepicker-toggle
                              [disabled]="!isEdit"
                              matSuffix
                              [for]="insurance_policy_date"
                            >
                            </mat-datepicker-toggle>
                            <mat-datepicker
                              touchUi
                              #insurance_policy_date
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>

                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "registration.email" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="email"
                              placeholder="pat@example.com.ua"
                            />
                          </mat-form-field>
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.itn" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="number"
                              formControlName="tax_id"
                            />
                          </mat-form-field>

                          <div class="col-md-4 mt-4"></div>
                        </div>

                        <div class="row mt-3">
                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.type-doc" | translate }}
                            </mat-label>
                            <mat-select
                              [disabled]="!isEdit"
                              formControlName="type_doc"
                            >
                              <mat-option>-</mat-option>
                              <mat-option
                                *ngFor="
                                  let item of documentService.document_type
                                "
                                [value]="item.value"
                              >
                                <ng-container *ngIf="!(reloading$ | async)">
                                  {{ item | lang }}
                                </ng-container>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.number-doc" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="number_doc"
                            />
                          </mat-form-field>

                          <mat-form-field class="col-md-4">
                            <mat-label>
                              {{ "form.issued-by" | translate }}
                            </mat-label>
                            <input
                              matInput
                              [readonly]="!isEdit"
                              type="text"
                              formControlName="issued_by"
                            />
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="col-md-1"></div>
                    </div>
                    <button
                      *ngIf="hideGroups && isEdit"
                      class="buttonClick mt-1"
                      (click)="hideGroups = false"
                    >
                      Заповнити всі поля
                    </button>
                  </app-accordion>
                  <hr />
                </div>
              </div>

              <!-- ------------------------------------------------------------------------------ -->
              <!-- ------------------------EDUCATIONS-------------------------------------------- -->
              <div *ngIf="!hideGroups">
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion [title]="'form.education' | translate">
                      <div formArrayName="educations">
                        <div
                          *ngFor="
                            let item of educations.controls;
                            let educationsIndex = index
                          "
                          [formGroupName]="educationsIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{
                                      "form.name_education_institution"
                                        | translate
                                    }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="institution_name"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.diploma_number" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="diploma_number"
                                  />
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.issued-date" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="issued_date"
                                    formControlName="issued_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="issued_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #issued_date
                                    touchUi
                                  ></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.country" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="country"
                                  />
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.degree" | translate }}
                                  </mat-label>
                                  <mat-select
                                    [disabled]="!isEdit"
                                    formControlName="degree"
                                  >
                                    <mat-option>-</mat-option>
                                    <mat-option
                                      *ngFor="
                                        let item of specializationLevelsService.levelsSpecialises
                                      "
                                      [value]="item.value"
                                    >
                                      <ng-container
                                        *ngIf="!(reloading$ | async)"
                                      >
                                        {{ item | lang }}
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.speciality" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="speciality"
                                  />
                                </mat-form-field>
                              </div>
                              <div class="row mt-4">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.city" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="city"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(educations) && isEdit"
                                (click)="
                                  deletedItem(educations, educationsIndex)
                                "
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{
                                  'form.add_education' | translate
                                }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addEducation()"
                          class="addBtn"
                          ngbTooltip="Додати освіту."
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <hr />
                <!-- ------------------------------------------------------------------------- -->
                <!-- ------------------------------QUALIFICATIONS------------------------------- -->
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion [title]="'form.qualification' | translate">
                      <div formArrayName="qualifications">
                        <div
                          *ngFor="
                            let item of qualifications.controls;
                            let qualificationsIndex = index
                          "
                          [formGroupName]="qualificationsIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.type" | translate }}
                                  </mat-label>

                                  <mat-select
                                    [disabled]="!isEdit"
                                    formControlName="type"
                                  >
                                    <mat-option>-</mat-option>
                                    <mat-option
                                      *ngFor="
                                        let item of qualificationTypeService.qualification_type
                                      "
                                      [value]="item.value"
                                    >
                                      <ng-container
                                        *ngIf="!(reloading$ | async)"
                                      >
                                        {{ item | lang }}
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{
                                      "form.name_education_institution"
                                        | translate
                                    }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="institution_name"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.speciality" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="speciality"
                                  />
                                </mat-form-field>
                              </div>

                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.issued-date" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="issued_date"
                                    formControlName="issued_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="issued_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #issued_date
                                    touchUi
                                  ></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.certificate_number" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="certificate_number"
                                  />
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.valid-to" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="valid_to"
                                    formControlName="valid_to"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="valid_to"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #valid_to
                                    touchUi
                                  ></mat-datepicker>
                                </mat-form-field>
                              </div>

                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.additional_info" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="additional_info"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(qualifications) && isEdit"
                                (click)="
                                  deletedItem(
                                    qualifications,
                                    qualificationsIndex
                                  )
                                "
                                class="mt-4 delBtn"
                                placement="top"
                                ngbTooltip="{{
                                  'form.del_qualification' | translate
                                }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addQualification()"
                          class="addBtn"
                          ngbTooltip="{{
                            'form.add_qualification' | translate
                          }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <hr />
                <!-- ----------------------------------------------------------------------------- -->
                <!-- --------------------------SPECIALISATION------------------------------ -->
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion [title]="'form.specialization' | translate">
                      <div formArrayName="specialities">
                        <div
                          *ngFor="
                            let item of specialities.controls;
                            let specialitiesIndex = index
                          "
                          [formGroupName]="specialitiesIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.speciality" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="speciality"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.level" | translate }}
                                  </mat-label>
                                  <mat-select
                                    [disabled]="!isEdit"
                                    formControlName="speciality"
                                  >
                                    <mat-option
                                      *ngFor="
                                        let item of specialtyLevelService.specialty_level
                                      "
                                      [value]="item.value"
                                    >
                                      <ng-container
                                        *ngIf="!(reloading$ | async)"
                                      >
                                        {{ item | lang }}
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.qualification_type" | translate }}
                                  </mat-label>
                                  <mat-select
                                    [disabled]="!isEdit"
                                    formControlName="qualification_type"
                                  >
                                    <mat-option
                                      *ngFor="
                                        let item of qualificationTypeService.qualification_type
                                      "
                                      [value]="item.value"
                                    >
                                      <ng-container
                                        *ngIf="!(reloading$ | async)"
                                      >
                                        {{ item | lang }}
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.attestation_name" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="attestation_name"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.attestation_date" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="attestation_date"
                                    formControlName="attestation_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="attestation_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #attestation_date
                                    touchUi
                                  ></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.valid-to" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="valid_to_date"
                                    formControlName="valid_to_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="valid_to_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #valid_to_date
                                    touchUi
                                  ></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.certificate_number" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="certificate_number"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(specialities) && isEdit"
                                (click)="
                                  deletedItem(specialities, specialitiesIndex)
                                "
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{
                                  'form.del_specialization' | translate
                                }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addSpecialities()"
                          class="addBtn"
                          ngbTooltip="{{
                            'form.add_specialization' | translate
                          }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <hr />
                <!-- ------------------------------------------------------------------------------------- -->
                <!-- ---------------------------------------SCINCE DEGREE---------------------------------------------- -->
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion [title]="'form.degree' | translate">
                      <div formArrayName="science_degree">
                        <div
                          *ngFor="
                            let item of science_degree.controls;
                            let scienceDegreeIndex = index
                          "
                          [formGroupName]="scienceDegreeIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{
                                      "form.name_education_institution"
                                        | translate
                                    }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="institution_name"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.degree" | translate }}
                                  </mat-label>
                                  <mat-select
                                    [disabled]="!isEdit"
                                    formControlName="degree"
                                  >
                                    <mat-option
                                      *ngFor="let item of degreeService.degree"
                                      [value]="item.value"
                                    >
                                      <ng-container
                                        *ngIf="!(reloading$ | async)"
                                      >
                                        {{ item | lang }}
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.country" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="country"
                                  />
                                </mat-form-field>
                              </div>
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.city" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="city"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.speciality" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="speciality"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.diploma_number" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="diploma_number"
                                  />
                                </mat-form-field>
                              </div>
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.date_recived" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="issued_date"
                                    formControlName="issued_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="issued_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #issued_date></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(science_degree) && isEdit"
                                (click)="
                                  deletedItem(
                                    science_degree,
                                    scienceDegreeIndex
                                  )
                                "
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{ 'form.del_degree' | translate }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addScinceDegree()"
                          class="addBtn"
                          ngbTooltip="{{ 'form.add_degree' | translate }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <hr />
                <!-- --------------------------------------scientific_title--------------------------------------- -->
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion
                      [title]="'form.scientific_title' | translate"
                    >
                      <div formArrayName="scientifics">
                        <div
                          *ngFor="
                            let item of scientifics.controls;
                            let scientificsIndex = index
                          "
                          [formGroupName]="scientificsIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.scientific_title" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="scientific"
                                  />
                                </mat-form-field>
                                <mat-form-field class="datecol-md-4picker">
                                  <mat-label>
                                    {{ "form.date_recived" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="scientific_date"
                                    formControlName="scientific_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="scientific_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker
                                    #scientific_date
                                  ></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(scientifics) && isEdit"
                                (click)="
                                  deletedItem(scientifics, scientificsIndex)
                                "
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{
                                  'form.del_scientific_title' | translate
                                }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addScientific()"
                          class="addBtn"
                          ngbTooltip="{{
                            'form.add_scientific_title' | translate
                          }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <hr />
                <!-- ----------------------------------------------------------------------------------------- -->
                <!-- -------------------------------------------COURSES--------------------------------------- -->
                <div class="row mt-5">
                  <div class="col h-1">
                    <app-accordion
                      [title]="'form.training_courses' | translate"
                    >
                      <div formArrayName="courses">
                        <div
                          *ngFor="
                            let item of courses.controls;
                            let coursesIndex = index
                          "
                          [formGroupName]="coursesIndex"
                        >
                          <div class="row">
                            <div class="col-md-11">
                              <div class="row mt-3">
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.name_course" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="text"
                                    formControlName="name_course"
                                  />
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.date_passing" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    [matDatepicker]="course_date"
                                    formControlName="course_date"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [disabled]="!isEdit"
                                    [for]="course_date"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #course_date></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>
                                    {{ "form.points" | translate }}
                                  </mat-label>
                                  <input
                                    matInput
                                    [readonly]="!isEdit"
                                    type="number"
                                    formControlName="points"
                                    min="0"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-1 pt-3">
                              <button
                                *ngIf="displayDelBtn(courses) && isEdit"
                                (click)="deletedItem(courses, coursesIndex)"
                                class="delBtn"
                                placement="top"
                                ngbTooltip="{{
                                  'form.del_training_courses' | translate
                                }}"
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="isEdit"
                          class="ml-1"
                          (click)="addCourses()"
                          class="addBtn"
                          ngbTooltip="{{
                            'form.add_training_courses' | translate
                          }}"
                          placement="top"
                        >
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </app-accordion>
                  </div>
                </div>
                <!-- ----------------------------------------------------------------------------------------- -->
                <hr />
              </div>

              <div class="row" *ngIf="!isProfile()">
                <div class="col-md-11">
                  <div class="row mt-5">
                    <div class="col">
                      <div class="form-group">
                        <label for="consent">
                          <input
                            type="checkbox"
                            class="mr-2"
                            id="consent"
                            formControlName="consent_text"
                          />
                          {{ "form.consent1" | translate }}
                          <span class="mark">*</span>
                        </label>
                      </div>
                      <div class="form-group mt-4">
                        <label for="consent2" class="ml-1">
                          <input
                            type="checkbox"
                            class="mr-2"
                            id="consent2"
                            formControlName="consent"
                          />
                          {{ "form.consent2" | translate }}
                          <span class="mark">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>
              </div>
              <!-- ------------------------------------------------------------------------- -->
            </div>
          </div>
          <div class="row">
            <div class="col">
              <small>
                <span class="mark">*</span>
                {{ "registration.mandatory" | translate }}
              </small>
            </div>
          </div>
          <div class="row d-flex justify-content-between" *ngIf="isEdit">
            <div class="col-auto" *ngIf="!isProfile()">
              <button class="btn btn-primary" routerLink="/home/consultants">
                {{ "login.cancel" | translate }}
              </button>
            </div>
            <div class="col-auto">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="saveStaff()"
              >
                {{ "form.save" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
