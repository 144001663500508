import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from 'src/app/services/validators/password-strength.validators';
import { Doctor } from 'src/app/models/doctor';
import { StaffService } from 'src/app/services/staff.service';
import { ToastrService } from 'ngx-toastr';
import { QualificationTypeService } from 'src/app/services/dictionaries/qualification-type.service';
import { DegreeService } from 'src/app/services/dictionaries/degree.service';
import { SpecialityLevelsService } from 'src/app/services/dictionaries/specialty-level.service';
import { RoleTypeEnum } from '../../auth/model/enums/role.enum';

@Component({
  selector: 'app-role-registration-doctor',
  templateUrl: './role-registration-doctor.component.html',
  styleUrls: ['./role-registration-doctor.component.scss'],
})
export class RoleRegistrationDoctorComponent implements OnInit {
  myForm: FormGroup = this.initForm();
  medOrganisationInfoNoAvailable: boolean = false;
  isVisiblePassword: boolean = false;
  consultants: Doctor[] = [];
  doctors: Doctor[] = [];

  @Output('registration') registration = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private staffService: StaffService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public qualificationTypeService: QualificationTypeService,
    public degreeService: DegreeService,
    public specialtyLevelService: SpecialityLevelsService
  ) {}

  ngOnInit(): void {}

  onSubmitForm(): void {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.translate.get(['alerts']).subscribe((t) => {
        this.toastr.warning(t.alerts.warning_1);
      });
      return;
    }
    if (this.myForm.get('birth_date').value) {
      this.myForm
        .get('birth_date')
        .patchValue(
          new Date(this.myForm.get('birth_date').value).toISOString()
        );
    }
    this.myForm.get('userType').patchValue(RoleTypeEnum.Doctor);
    this.myForm
      .get('language')
      .patchValue(localStorage.getItem('language') || 'ua');
    if (!this.myForm.get('medicalInstitution').value) {
      this.myForm.get('medicalInstitution').patchValue(undefined);
    }

    delete this.myForm.value['consultantId'];
    delete this.myForm.value['doctorId'];
    this.registration.emit(this.myForm);
  }

  selectMedOrganization(id?: any) {
    if (
      (id && !this.medOrganisationInfoNoAvailable) ||
      (!id && this.medOrganisationInfoNoAvailable)
    )
      this.staffService.getDoctors().subscribe((data) => {
        if (!this.medOrganisationInfoNoAvailable) {
          this.consultants = data.filter(
            (i) => i.isConsultant && i.medicalInstitution?._id == id
          );
          this.doctors = data.filter(
            (i) => !i.isConsultant && i.medicalInstitution?._id == id
          );
        } else {
          this.consultants = data.filter(
            (i) => i.isConsultant && !i.medicalInstitution
          );
          this.doctors = data.filter(
            (i) => !i.isConsultant && !i.medicalInstitution
          );
        }
      });
    else {
      this.doctors = [];
      this.consultants = [];
    }
  }

  setGender(gender: 'FEMALE' | 'MALE') {
    this.myForm.get('gender').setValue(gender);
  }

  initForm() {
    return (this.myForm = this.fb.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      second_name: '',
      birth_date: ['', Validators.required],
      gender: '',
      email: [''],
      login: ['', Validators.required],
      password: ['', [Validators.required, PasswordStrengthValidator]],
      medicalInstitution: '',
      userType: [''],
      language: [''],
      phones: this.fb.array([this.createPhoneFormGroup()]),
      educations: this.fb.array([this.createEducationsFormGroup()]),
      qualifications: this.fb.array([this.createQualificationsFormGroup()]),
      specialities: this.fb.array([this.createSpecialitiesFormGroup()]),
      science_degree: this.fb.array([this.createScienceDegreeFormGroup()]),
    }));
  }

  get phones(): FormArray {
    return (<FormArray>this.myForm.get('phones')) as FormArray;
  }

  get qualifications(): FormArray {
    return (<FormArray>this.myForm.get('qualifications')) as FormArray;
  }

  get specialities(): FormArray {
    return (<FormArray>this.myForm.get('specialities')) as FormArray;
  }

  get educations(): FormArray {
    return (<FormArray>this.myForm.get('educations')) as FormArray;
  }

  get science_degree(): FormArray {
    return (<FormArray>this.myForm.get('science_degree')) as FormArray;
  }

  createEducationsFormGroup() {
    return this.fb.group({
      institution_name: [''],
    });
  }

  createPhoneFormGroup() {
    return this.fb.group({
      number: [''],
    });
  }

  createScienceDegreeFormGroup() {
    return this.fb.group({
      degree: [''],
    });
  }

  createSpecialitiesFormGroup() {
    return this.fb.group({
      speciality: [''],
    });
  }

  createQualificationsFormGroup() {
    return this.fb.group({
      type: [''],
    });
  }
}
