<div class="block-1__info-0">
  <div class="measurement-container">
    <div class="measurement-block">
      <p class="title">{{ "calculation.chss" | translate }}</p>
      <p class="text">
        {{ bpm | number : "1.0-0" }}
      </p>
    </div>
  </div>
</div>
<div
  [ngStyle]="{ 'background-color': PARS ? PARS.color : '' }"
  class="block-1__info-1"
  *ngIf="PARS"
>
  <div class="card-title">
    <h1
      placement="left"
      ngbTooltip="{{ 'calculation.pars_tooltip' | translate }}"
    >
      {{ "calculation.pars_ao" | translate }}
      <span></span>
    </h1>
  </div>
  <div class="card-info">
    {{ PARS ? PARS.value : 0 }}.
    {{ ("PARS_MESSAGES" | translate)[PARS.message] }}
  </div>
</div>
<div
  [ngStyle]="{ 'background-color': IFSP ? IFSP.color : '' }"
  class="block-1__info-2"
  *ngIf="IFSP"
>
  <div class="card-title">
    <h1
      placement="left"
      ngbTooltip="{{ 'calculation.IFVP_tooltip' | translate }}"
    >
      {{ "calculation.IFVP" | translate }}
      <span></span>
    </h1>
  </div>
  <div class="card-info">
    {{ IFSP ? IFSP.value : 0 }}.
    {{ ("IFSP_MESSAGES" | translate)[IFSP.message] }}
  </div>
</div>
