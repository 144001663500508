import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffPatientsGuard } from 'src/app/guards/staff-patients.guard';
import { ConsultantComponent } from '../global-components/consultant/consultant.component';
import { PatientsListComponent } from '../global-components/patients-list/patients-list.component';
import { ScheduleComponent } from '../global-components/schedule/schedule.component';
import { DoctorProfileComponent } from './doctor/doctor-profile.component';
import { PatientComponent } from './patient/patient.component';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,

    children: [
      { path: 'patient', component: PatientComponent },
      { path: 'patient/:id', component: PatientComponent },
      {
        path: 'staff',
        component: DoctorProfileComponent,
        children: [
          { path: '', redirectTo: 'patients', pathMatch: 'full' },
          { path: 'view', component: ConsultantComponent },
          { path: 'schedule', component: ScheduleComponent },
          {
            path: 'patients',
            component: PatientsListComponent,
            canDeactivate: [StaffPatientsGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
