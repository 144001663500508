import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { Biosignal } from 'src/app/models/biosignal';
import { Ifsp } from 'src/app/models/ifsp';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExpertSystemService } from 'src/app/services/expert-system.service';

@Component({
  selector: 'app-ifsp',
  templateUrl: './ifsp.component.html',
  styleUrls: ['./ifsp.component.scss'],
})
export class IfspComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    if (biosignals) {
      this.biosignals = biosignals.rangeBiosignals;
      if (this.IfspTableData) {
        this.setAverageValuesAndInterpretations();
      }
    }
  }
  IfspTableData: Ifsp;
  biosignals: Biosignal[];
  averageValues: any = {
    IFSP: '0',

    parsAoi: '0',
    VLF: '0',
    LF: '0',
    HF: '0',
    IN: '0',
    ATd: '0',
    ATs: '0',
    ChSS: '0',
    Mser: '0',
  };
  descriptionIFSP: string = '';

  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }
  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.getIfspData();
  }

  getIfspData() {
    this.expertSystemService.getIfsp().subscribe((data) => {
      this.IfspTableData = data;
      if (this.biosignals && this.biosignals.length) {
        this.calculateClassInterpretation(this.IfspTableData.table);
        this.setAverageValuesAndInterpretations();
      }
    });
  }

  setAverageValuesAndInterpretations(): void {
    this.averageValues.IFSP = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'IFSP'
    );
    this.expertSystemService.ifsp = this.averageValues.IFSP;

    this.averageValues.parsAoi = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'PARSint'
    ).slice(0, -3);
    this.averageValues.VLF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perVLF'
    );
    this.averageValues.LF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perLF'
    );
    this.averageValues.HF = this.calculateBiosignalsAverageValue(
      'FFT',
      'perHF'
    );
    this.averageValues.IN = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'INpos'
    );
    this.averageValues.ATd = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Diastola'
    );
    this.averageValues.ATs = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'Systola'
    );

    this.averageValues.ChSS = this.calculateBiosignalsAverageValue(
      'TimeAnalysis',
      'HeartRate'
    );
    this.averageValues.Mser = this.calculateBiosignalsAverageValue(
      'MorphologicalAnalysis',
      'Ms'
    );
    if (+this.averageValues.IFSP > 0 && +this.averageValues.IFSP <= 5) {
      this.descriptionIFSP = this.IfspTableData.indicatorDescription.find(
        (obj) =>
          +this.averageValues.IFSP >= obj.minValue &&
          +this.averageValues.IFSP <= obj.maxValue
      )['description'];
    } else {
      this.descriptionIFSP = '';
    }
    if (this.IfspTableData) {
      this.calculateClassInterpretation(this.IfspTableData.table);
    }
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    let sum = 0,
      count = 0;
    this.biosignals.forEach((biosignal) => {
      if (biosignal[object]) {
        sum += biosignal[object][property];
        count += 1;
      }
    });
    return sum ? (sum / count).toFixed(2) : '0';
  }

  calculateClassInterpretation(table) {
    Object.entries(table).forEach((values) => {
      Object.entries(values[1]).forEach((val) => {
        if (val[1]['row']) {
          let averageValue = this.averageValues[val[0]];
          val[1]['className'] = [];
          this.setActiveClass(val[1]['row'], val[1]['className'], averageValue);
        }
      });
    });
  }

  setActiveClass(cols, colsClasses, averageValue) {
    cols.forEach((col, i) => {
      let isEquals: any = [];
      if (i > 0) {
        colsClasses[i] = '';
        isEquals = this.calculateInterpretation(col, averageValue) || [];
        if (isEquals && isEquals.length) {
          switch (i) {
            case 1: {
              if (
                !colsClasses[2] &&
                !colsClasses[3] &&
                !colsClasses[4] &&
                !colsClasses[5]
              ) {
                colsClasses[i] = 'success';
              }
              break;
            }
            case 2:
            case 3: {
              if (!colsClasses[4] && !colsClasses[5]) {
                colsClasses[1] = '';
                colsClasses[i] = 'warning';
              }
              break;
            }
            case 4:
              colsClasses[1] = '';
              colsClasses[2] = '';
              colsClasses[3] = '';
              colsClasses[i] = 'error';
            case 5:
              colsClasses[4] = '';
              colsClasses[1] = '';
              colsClasses[2] = '';
              colsClasses[3] = '';
              colsClasses[i] = 'error';
              break;
          }
        }
      }
    });
  }

  calculateInterpretation(values: any, comparisonValue: any): any {
    if (typeof values.maxValue === 'number') {
      const minValue = values.minValue;
      const maxValue = values.maxValue;
      let idx = [];
      if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
        idx.push(+comparisonValue);
        return idx;
      }
    } else if (Array.isArray(values.maxValue)) {
      if (Array.isArray(comparisonValue)) {
        let idx = [];
        comparisonValue.forEach((item) => {
          const [keys] = Object.entries(item);
          const minValue = values.minValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          const maxValue = values.maxValue.find((val) =>
            val[keys[0]] ? val[keys[0]] : null
          );
          if (minValue && maxValue) {
            if (
              +item[keys[0]] >= +minValue[keys[0]] &&
              +item[keys[0]] <= +maxValue[keys[0]]
            ) {
              idx.push(item[keys[0]]);
            }
          }
        });
        return idx;
      } else {
        let idx = [];
        values.minValue.forEach((el, i) => {
          const minValue = Object.values(values.minValue[i])[0];
          const maxValue = Object.values(values.maxValue[i])[0];
          if (+comparisonValue >= +minValue && +comparisonValue <= +maxValue) {
            idx.push(+comparisonValue);
          }
        });

        return idx;
      }
    }
  }
}
