import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ifsp-tab',
  templateUrl: './ifsp-tab.component.html',
  styleUrls: ['./ifsp-tab.component.scss'],
})
export class IfspTabComponent implements OnInit {
  @Input() biosignalsData = [];

  constructor() {}

  ngOnInit(): void {}
}
