<tr [formGroup]="myForm">
  <td>
    <span *ngIf="page == 1">{{ index ? index + 1 : 1 }}</span>
    <span *ngIf="page > 1">{{
      (page - 1) * itemsPerPage + (index ? index + 1 : 1)
    }}</span>
  </td>
  <td *ngIf="element.patient">
    <a
      (click)="selectPatient(element.PatientID)"
      [routerLink]="['/home/calculations']"
    >
      {{ element.patient }}
    </a>
  </td>
  <td>{{ element.date | date : "dd:MM:YYYY HH:mm:ss" }}</td>
  <td>
    {{ element.measurementGroup == "null" ? "" : element.measurementGroup }}
  </td>
  <td *ngIf="expand">{{ element.hospital }}</td>
  <td *ngIf="expand">{{ element.doctor }}</td>
  <td>{{ CheckSpaces(element.EquipmentName) }}</td>
  <td *ngIf="expand">{{ element.computeStatus }}</td>
  <td *ngIf="expand">{{ element.status }}</td>
  <td>{{ element.phase }}</td>
  <td>
    <ng-container *ngIf="!isEdit">{{ element.Systola }}</ng-container>
    <ng-container *ngIf="isEdit">
      <input type="number" class="form-control" formControlName="Systola" />
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!isEdit">{{ element.Diastola }}</ng-container>
    <ng-container *ngIf="isEdit">
      <input type="number" class="form-control" formControlName="Diastola" />
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!isEdit">{{ element.HeartRate }}</ng-container>
    <ng-container *ngIf="isEdit">
      <input type="number" class="form-control" formControlName="HeartRate" />
    </ng-container>
  </td>
  <td>
    <ng-container>{{ element.pars }}</ng-container>
  </td>
  <td>
    <ng-container>{{ element.ifsp }}</ng-container>
  </td>
  <td>
    <ng-container>{{ element.msr }}</ng-container>
  </td>
  <!-- <td>
    <ng-container>{{ element.msr }}</ng-container>
  </td> -->
  <td>
    <ng-container *ngIf="!isEdit">{{ element?.BloodGlucose }}</ng-container>
    <ng-container *ngIf="isEdit">
      <input
        type="number"
        class="form-control"
        formControlName="BloodGlucose"
      />
    </ng-container>
  </td>
  <!-- blood glucose -->
  <td>
    <div class="btn-wrap">
      <label>
        <input
          (change)="selectCheckBox($event, element.id)"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="element.biosignal.checked"
          class="customCheckBox"
          type="checkbox"
        />
      </label>
      <button
        class="ml-1"
        *ngIf="!isEdit"
        (click)="editBiosignal()"
        class="addBtn"
        ngbTooltip="{{ 'form.edit' | translate }}"
        placement="top"
      >
        <i class="bi bi-pencil"></i>
      </button>
      <button
        class="ml-1"
        *ngIf="isEdit"
        (click)="saveBiosignal()"
        class="addBtn"
        ngbTooltip="{{ 'form.save' | translate }}"
        placement="top"
      >
        <i class="bi bi-sd-card"></i>
      </button>
    </div>
  </td>
</tr>
