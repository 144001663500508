import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentService } from '../services/equipment.service';

@Pipe({
  name: 'equipmentIdentifier',
})
export class EquipmentIdentifierPipe implements PipeTransform {
  constructor(private equipmentService: EquipmentService) {}
  transform(equipmentName: any, equipmentId: any): any {
    let idetifier = this.equipmentService.equipments.find(
      (e) => equipmentName == e?.EquipmentName && equipmentId == e?.EquipmentID
    )?.identifier?.graph;
    return `${equipmentName} ${equipmentId ? '‧ ' + equipmentId : ''} ${
      idetifier ? '‧ ' + idetifier : ''
    }`;
  }
}
