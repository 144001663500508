<form [formGroup]="myForm" class="col-md-12">
  <div class="col-md-12 mt-3">
    <h4>{{ "measurement.determination_measurement_modes" | translate }}</h4>
  </div>
  <mat-form-field class="col-md-3">
    <mat-label>{{ "measurement.day_begins" | translate }}</mat-label>
    <input
      formControlName="DayStart"
      matInput
      type="time"
      style="color-scheme: light"
    />
  </mat-form-field>
  <mat-form-field class="col-md-3">
    <mat-label>{{ "measurement.night_begins" | translate }}</mat-label>
    <input formControlName="NightStart" matInput type="time" />
  </mat-form-field>
  <div class="col-md-12">
    <h4>{{ "admin.ao" | translate }}</h4>
  </div>
  <mat-form-field class="col-md-3">
    <mat-label>{{ "measurement.start_of_measurement" | translate }}</mat-label>
    <input formControlName="StartRegistr" matInput type="number" />
    <mat-hint>
      {{ "measurement.in_seconds" | translate }} ({{
        "measurement.after_turning_on_start_button" | translate
      }})
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="col-md-3">
    <mat-label>{{ "measurement.finishing_measurement" | translate }}</mat-label>
    <input formControlName="FinishRegistr" matInput type="number" />
    <mat-hint>
      {{ "measurement.in_seconds" | translate }} ({{
        "measurement.after_measurement_completed" | translate
      }})
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="col-md-3">
    <mat-label>{{ "measurement.frequency_of_requests" | translate }}</mat-label>
    <input formControlName="RequestFreqOsc" matInput type="number" />
    <mat-hint>{{ "measurement.in_seconds" | translate }}</mat-hint>
  </mat-form-field>
  <mat-form-field class="col-md-3">
    <mat-label>{{
      "measurement.signal_registration_frequency" | translate
    }}</mat-label>
    <input formControlName="SampleFreqRealTimeOsc" matInput type="number" />
    <mat-hint>{{ "measurement.in_Hz" | translate }} (real time)</mat-hint>
  </mat-form-field>
  <ng-container *ngIf="equipmentName == EquipmentName['ВР2']">
    <div class="col-md-12 mt-3">
      <h4>{{ "admin.ecg" | translate }}</h4>
    </div>
    <mat-form-field class="col-md-3">
      <mat-label>{{
        "measurement.measurement_duration" | translate
      }}</mat-label>
      <input formControlName="MesurDuration" matInput type="number" />
      <mat-hint>
        {{ "measurement.in_seconds" | translate }} (30, 60, 90, 120)
      </mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-3">
      <mat-label>{{
        "measurement.frequency_of_requests" | translate
      }}</mat-label>
      <input formControlName="RequestFreqECG" matInput type="number" />
      <mat-hint>{{ "measurement.in_seconds" | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-3">
      <mat-label>{{
        "measurement.signal_registration_frequency" | translate
      }}</mat-label>
      <input formControlName="SampleFreqRealTimeECG" matInput type="number" />
      <mat-hint>{{ "measurement.in_Hz" | translate }} (real time)</mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-3">
      <mat-label>{{
        "measurement.signal_registration_frequency" | translate
      }}</mat-label>
      <input formControlName="SampleFreqMemoryECG" matInput type="number" />
      <mat-hint>{{ "measurement.in_Hz" | translate }} (memory)</mat-hint>
    </mat-form-field>
  </ng-container>
</form>
