<h1 mat-dialog-title>
  <a href="https://t.me/OrantaAoReportBot" target="_blank">
    Перейти в телеграм
    <mat-icon svgIcon="telegram" style="height: 40px"> </mat-icon
  ></a>
</h1>
<div mat-dialog-content>
  <p>
    Для отримування сповіщень про нові вимірювання пацієнтів перейдіть у
    телеграм бот та ідентифікуйте себе як лікар за допомогою пошти, що вказана
    при реєстрації.
  </p>
  <div class="d-flex align-items-cente">
    <div class="font-weight-bold" #userinput>{{ data.email }}</div>
    <button
      class="btnAdd button ml-2"
      (click)="copyInputMessage(userinput)"
      value="click to copy"
    >
      <mat-icon svgIcon="copy"></mat-icon>
      {{ buttonText }}
    </button>
  </div>
</div>
