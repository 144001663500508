import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PhonesTypeService {
  phones_type = [
    {
      value: 'MOBILE',
      ua: 'Мобільний',
      en: 'Mobile',
      pl: 'Mobilny',
    },
    {
      value: 'LAND_LINE',
      ua: 'Стаціонарний',
      en: 'Landline',
      pl: 'Stacjonarny',
    },
  ];
}
  