export const TABS_DATA = [
  {
    id: 1,
    name: {
      ua: 'ПАРС-АОІ',
      en: 'PARS-AOI',
      pl: 'WASR-AO',
    },
  },
  {
    id: 2,
    name: {
      ua: 'ІФСП',
      en: 'IFVP',
      pl: 'ZFPN',
    },
  },
  {
    id: 3,
    name: {
      ua: 'AНС',
      en: 'ANS',
      pl: 'AUN',
    },
  },
  {
    id: 4,
    name: {
      ua: 'Серце',
      en: 'Heart',
      pl: 'Serce',
    },
  },
  {
    id: 5,
    name: {
      ua: 'Судини',
      en: 'Vessels',
      pl: 'Naczynia',
    },
  },
  {
    id: 6,
    name: {
      ua: 'Нервова система',
      en: 'Nervous System',
      pl: 'System nerwowy',
    },
  },
  {
    id: 7,
    name: {
      ua: 'Проба Руф’є',
      en: 'Rufier`s test',
      pl: 'Proces Ruffiera',
    },
  },
  {
    id: 8,
    name: {
      ua: 'Експертна система',
      en: 'Expert system',
      pl: 'System ekspercki',
    },
  },
  {
    id: 9,
    name: {
      ua: 'Мартіне-Кушелевського',
      en: 'Martine-Kushelevsky',
      pl: 'Martine-Kushelevsky',
    },
  },
];
