import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Biosignal } from 'src/app/models/biosignal';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataTypeService } from 'src/app/services/data-type.service';
import { EXPERT_SYSTEM_THRESHOLDS } from '../../../constants';
import {
  Diastola,
  Systola,
} from 'src/app/services/dictionaries/constants/time-analize-data';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { MeasurementsDataUpdateService } from 'src/app/services/measurements-data-update.service';

@Component({
  selector: 'app-disease-chance-table-ecg',
  templateUrl: './disease-chance-table-ecg.component.html',
  styleUrls: ['./disease-chance-table-ecg.component.scss'],
})
export class DiseaseChanceTableEcgComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  biosignals: Biosignal[];

  averageValues: any = {
    ArrhythmiaDegree: 0,
    ATs: 0,
    ATd: 0,
  };
  pressureClass: any = '';
  pressureDescription: any = '';
  AFib_marker;
  AFib_marker_1;

  leadId: string;
  @Input() set biosignalsRange(v) {
    if (v?.length) {
      this.biosignals = v;
      // let temp = this.biosignals[0]['ExpertSystem']['AFib_marker'][0]
      //   ?.toLowerCase()
      //   ?.split(' ');
      // if (temp) this.AFib_marker = temp[temp?.length - 1];
      // this.AFib_marker_1 = this.biosignals[0]['ExpertSystem']['AFib_marker'][1];
      this.setAverageValuesAndInterpretations();
    }
  }

  constructor(
    public measurementsDataUpdateService: MeasurementsDataUpdateService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
    this.measurementsDataUpdateService.selectedEcgLead$.subscribe((id) => {
      this.leadId = id;
    });
  }

  setAverageValuesAndInterpretations(): void {
    // this.averageValues.ArrhythmiaDegree = this.calculateBiosignalsAverageValue(
    //   'ExpertSystem',
    //   'ArrhythmiaDegree'
    // );
    // this.averageValues.AFib_marker = this.calculateBiosignalsAverageValue(
    //   'ExpertSystem',
    //   'AFib_marker'
    // );
  }

  calculateBiosignalsAverageValue(object: string, property: string): string {
    let sum = 0,
      count = 0;
    this.biosignals.forEach((biosignal) => {
      if (biosignal[this.leadId][object]) {
        if (property == 'AFib_marker') {
          sum += biosignal[this.leadId][object][property][2];
        } else sum += biosignal[this.leadId][object][property];
        count += 1;
      }
    });
    return sum ? (sum / count).toFixed(2) : '0';
  }

  displayRiskColor(diseaseChance): string {
    return diseaseChance >= EXPERT_SYSTEM_THRESHOLDS.critical
      ? 'redClass'
      : diseaseChance >= EXPERT_SYSTEM_THRESHOLDS.medium
      ? 'yellowClass'
      : 'whiteClass';
  }

  calculateInterpretation(values: any, comparisonValue: any): any {
    let idx = [];
    values.forEach((el) => {
      const minValue = el.minValue;
      const maxValue = el.maxValue;
      const descr = el.description;
      if (+comparisonValue >= minValue && +comparisonValue <= maxValue) {
        idx.push(descr);
      } else {
        idx.push('');
      }
    });
    return idx;
  }
}
