<div class="row">
  <div class="col-md-12 mt-3">
    <mat-form-field class="col-md-4">
      <mat-label>
        {{ "measurement.name_meter" | translate }}
      </mat-label>
      <mat-select
        [(ngModel)]="equipmentName"
        (selectionChange)="selectEquipment($event.value)"
      >
        <mat-option>-</mat-option>
        <mat-option *ngFor="let item of equipmentList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-vat41-setting
    class="w-100"
    *ngIf="equipmentName == EquipmentName.VAT"
    [setting]="equipmentGeneralSetting"
    (changeSetting)="getDeviceSettingForm($event)"
  ></app-vat41-setting>
  <app-duo-ek-setting
    class="w-100"
    *ngIf="equipmentName == EquipmentName.DuoEK"
    [setting]="equipmentGeneralSetting"
    (changeSetting)="getDeviceSettingForm($event)"
  ></app-duo-ek-setting>
  <app-bp2-bp2a-setting
    class="w-100"
    *ngIf="
      equipmentName == EquipmentName['ВР2'] ||
      equipmentName == EquipmentName['ВР2А']
    "
    [equipment]="equipmentName"
    [setting]="equipmentGeneralSetting"
    (changeSetting)="getDeviceSettingForm($event)"
  ></app-bp2-bp2a-setting>
  <app-er1-er2-visual-beat-setting
    class="w-100"
    *ngIf="
      equipmentName == EquipmentName['ER1'] ||
      equipmentName == EquipmentName['ER2'] ||
      equipmentName == EquipmentName['Visual_Beat']
    "
    [setting]="equipmentGeneralSetting"
    (changeSetting)="getDeviceSettingForm($event)"
  >
  </app-er1-er2-visual-beat-setting>
  <div class="col-md-12 mt-3">
    <div class="col">
      <button class="btn btn-primary" (click)="saveSetting()">
        {{ "form.save" | translate }}
      </button>
    </div>
  </div>
</div>
