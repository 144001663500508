import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { MonitoringProgramService } from 'src/app/services/monitoring-program.service';

@Component({
  selector: 'app-measurements-setting',
  templateUrl: './measurements-setting.component.html',
  styleUrls: ['./measurements-setting.component.scss'],
})
export class MeasurementsSettingComponent
  extends OnDestroyMixin
  implements OnInit
{
  monitoringPrograms!: Array<any>;
  @ViewChildren('trInTable') trInTable: QueryList<any>;

  busy: boolean = false;
  tempId;
  blockFollowingUncheck: boolean = false;
  constructor(private monitoringProgramService: MonitoringProgramService) {
    super();
  }

  ngOnInit(): void {
    this.monitoringProgramService
      .getAllMonitoringProgram()
      .pipe(untilComponentDestroyed(this))
      .subscribe((v) => {
        this.monitoringPrograms = v;
      });
  }

  selectCheckBox(event, id): void {
    // const isChecked: boolean = event.target.checked;
    // const checkBoxOutput = {id, isChecked};
    // this.measurementsDataUpdateService.selectIdCheckBox(checkBoxOutput);
    const checkedCount = this.monitoringPrograms.reduce(
      (counter, biosignal) => (biosignal.checked ? counter + 1 : counter),
      0
    );
    // this.blockFollowingUncheck = checkedCount == 1;

    if (checkedCount == this.monitoringPrograms.length) {
    }

    const index = this.monitoringPrograms.findIndex(
      (biosignal) => biosignal._id == id
    );
    // if (event.target.checked && index == this.biosignalsRange.beginIndex - 1) {
    //   this.biosignalsRange.beginIndex = index;
    //   this.measurementsDataUpdateService.changeMeasurementsRange(
    //     'MeasurementTableComponent',
    //     this.biosignals,
    //     index,
    //     this.biosignalsRange.endIndex,
    //     this.biosignalsRange.excludedBiosignalsIds
    //   );
    // } else if (
    //   event.target.checked &&
    //   index == this.biosignalsRange.endIndex + 1
    // ) {
    //   this.biosignalsRange.endIndex = index;
    //   this.measurementsDataUpdateService.changeMeasurementsRange(
    //     'MeasurementTableComponent',
    //     this.biosignals,
    //     this.biosignalsRange.beginIndex,
    //     index,
    //     this.biosignalsRange.excludedBiosignalsIds
    //   );
    // } else {
    //   for (let i = 0; i < this.biosignals.length; i++) {
    //     if (this.biosignals[i].checked) {
    //       this.biosignalsRange.beginIndex = i;
    //       break;
    //     }
    //   }
    //   for (let i = this.biosignals.length - 1; i >= 0; i--) {
    //     if (this.biosignals[i].checked) {
    //       this.biosignalsRange.endIndex = i;
    //       break;
    //     }
    //   }
    //   this.biosignalsRange.excludedBiosignalsIds = new Set<string>();
    //   for (
    //     let i = this.biosignalsRange.beginIndex + 1;
    //     i <= this.biosignalsRange.endIndex - 1;
    //     i++
    //   ) {
    //     if (!this.biosignals[i].checked) {
    //       this.biosignalsRange.excludedBiosignalsIds.add(
    //         this.biosignals[i]._id
    //       );
    //     }
    //   }
    //   this.measurementsDataUpdateService.changeMeasurementsRange(
    //     'MeasurementTableComponent',
    //     this.biosignals,
    //     this.biosignalsRange.beginIndex,
    //     this.biosignalsRange.endIndex,
    //     this.biosignalsRange.excludedBiosignalsIds
    //   );
    // }
  }

  selectRowByTable(event, id): void {
    const trEl = event.target;
    if (trEl.localName === 'td') {
      if (trEl.parentNode.classList.contains('checkedTR')) {
      } else {
        this.tempId = id;
        // this.measurementsDataUpdateService.changeSelectedMeasurement(
        //   this.biosignals,
        //   id
        // );
        // this.measurementsDataUpdateService.changeSelectedOscillogram(id);
        // this.changeUrlStart(id);
        const trList = trEl.parentNode.parentNode.children;
        for (const tr of trList) {
          tr.classList.remove('checkedTR');
        }
      }
      trEl.parentNode.classList.toggle('checkedTR');
    }
  }

  sortTable(n): void {
    let table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById('myTable');
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName('TR');
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];
        if (dir == 'asc') {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == 'desc') {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  }

  onBlurMethod(comment, id): void {
    const commentDB = { comment };
    // this.measurementsDataUpdateService.setComment(commentDB, id).subscribe();
  }

  changeConditionCheckbox(event): void {
    const isChecked = event.target.checked;
    this.trInTable.forEach((tr: ElementRef) => {
      const checkbox = tr.nativeElement.children[
        tr.nativeElement.children.length - 1
      ].children
        .item(0)
        .children.item(0);
      if (checkbox.checked !== isChecked) {
        checkbox.click();
      }
    });
  }
}
