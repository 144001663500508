import {
  Component,
  ElementRef,
  KeyValueDiffers,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
} from '@mediapipe/tasks-vision';
import { CountDownComponent } from './count-down/count-down.component';
import { Marks, TypeMeasurementEnum, TypeVideoEnum } from './mediapipe.enum';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-mediapipe',
  templateUrl: './mediapipe.component.html',
  styleUrls: ['./mediapipe.component.scss'],
})
export class MediapipeComponent implements OnInit {
  form: FormGroup = this.reactiveForm();
  TypeMeasurementEnum = TypeMeasurementEnum;
  TypeVideoEnum = TypeVideoEnum;
  poseLandmarker: PoseLandmarker | undefined;
  runningMode: any = 'IMAGE';
  enableWebcamButton: HTMLButtonElement | null;
  webcamRunning = false;
  video: HTMLVideoElement | null;
  canvasElement: HTMLCanvasElement | null;

  canvasCtx;
  drawingUtils;

  @ViewChild(CountDownComponent) countDownComponent!: CountDownComponent;

  countOfMeasurement: number = 50;
  iterations: number = 0;
  photoType: string = '';

  showCounter: boolean = false;
  arrGeneral = [];

  imageUrl: string | ArrayBuffer | null = null;
  file: File;
  fileName: string = '';

  validMarks = Object.entries(Marks)
    .filter(([key, value]) => typeof value === 'number')
    .map(([key, value]) => ({ key, value }));

  constructor(
    public differs: KeyValueDiffers,
    private _elementRef: ElementRef,
    private fb: FormBuilder
  ) {
    this.measurement_duration.valueChanges.subscribe((v) => {
      this.countOfMeasurement = v * 10;
    });
  }

  async ngOnInit() {
    const vision = await FilesetResolver.forVisionTasks(
      'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm'
    );
    this.poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
        delegate: 'GPU',
      },
      runningMode: this.runningMode,
      numPoses: 1,
    });
  }

  reactiveForm() {
    return this.fb.group({
      measurement_type: ['', Validators.required],
      video_type: ['', Validators.required],
      time_before: [0],
      measurement_duration: [5],
    });
  }

  public get video_type(): FormControl {
    return this.form.get('video_type') as FormControl;
  }

  public get measurement_type(): FormControl {
    return this.form.get('measurement_type') as FormControl;
  }

  public get time_before(): FormControl {
    return this.form.get('time_before') as FormControl;
  }

  public get measurement_duration(): FormControl {
    return this.form.get('measurement_duration') as FormControl;
  }

  selectVideoType(item: TypeVideoEnum) {
    console.log(item);
    if (item == TypeVideoEnum.front_video) {
      this.photoType = 'front_video.png';
    } else if (item == TypeVideoEnum.side_video) {
      this.photoType = 'side_video.png';
    }
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    if (this.file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.file);
      const allCanvas =
        this._elementRef.nativeElement?.querySelectorAll('.canvas');
      for (let i = allCanvas.length - 1; i >= 0; i--) {
        const n = allCanvas[i];
        n.parentNode!.removeChild(n);
      }
    }
  }

  async handleClick(event: MouseEvent) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (!this.poseLandmarker) {
      console.log('Wait for poseLandmarker to load before clicking!');
      return;
    }

    if (this.runningMode === 'VIDEO') {
      this.runningMode = 'IMAGE';
      await this.poseLandmarker.setOptions({ runningMode: 'IMAGE' });
    }

    const allCanvas = (
      event.target as HTMLElement
    ).parentNode?.querySelectorAll('.canvas');
    for (let i = allCanvas.length - 1; i >= 0; i--) {
      const n = allCanvas[i];
      n.parentNode!.removeChild(n);
    }

    const detection_photo = (this._elementRef.nativeElement?.querySelectorAll(
      '#detection_photo'
    ))[0] as HTMLImageElement;

    this.poseLandmarker.detect(detection_photo, (result) => {
      this.addResult(result);
      this.downloadJSON({
        Info: {
          HospitalID: 'xxxxxxxxx',
          DoctorID: 'xxxxxxxxx',
          PatientID: 'xxxxxxxxx',
          MeasurementID: 'xxxxxxxxx',
        },
        ExerciseType: this.measurement_type.value,
        DataSource: this.video_type.value,
        DataType: 'Landmark Coordinates',
        Data: this.arrGeneral,
        AuthToken: 'xxxxxxxxx',
      });
      const canvas = document.createElement('canvas');
      canvas.setAttribute('class', 'canvas');
      canvas.setAttribute('width', detection_photo.naturalWidth + 'px');
      canvas.setAttribute('height', detection_photo.naturalHeight + 'px');
      canvas.style.cssText =
        'position: absolute;' +
        'left: 18px;' +
        'top: 0px;' +
        'width: ' +
        detection_photo.width +
        'px;' +
        'height: ' +
        detection_photo.height +
        'px;';

      detection_photo.parentNode!.appendChild(canvas);
      const canvasCtx = canvas.getContext('2d')!;
      const drawingUtils = new DrawingUtils(canvasCtx);

      for (const landmark of result.landmarks) {
        drawingUtils.drawLandmarks(landmark, {
          radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 5, 1),
        });
        drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
      }
    });
  }

  toggleCamera() {
    this.webcamRunning ? this.removePoints() : this.loadPoints();
  }

  loadPoints() {
    if (!this.webcamRunning) {
      this.webcamRunning = true;
      const constraints = {
        video: true,
      };
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        this.video = document.getElementById('webcam') as HTMLVideoElement;
        this.video.srcObject = stream;
        this.video.addEventListener(
          'loadeddata',
          this.predictWebcam.bind(this)
        );
      });
    }
  }

  removePoints() {
    if (this.webcamRunning) {
      this.webcamRunning = false;
      if (this.video && this.video.srcObject) {
        const stream = this.video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        this.video.srcObject = null;
      }
    }
  }

  removePredictWebcamListener() {
    if (this.video) {
      this.video.removeEventListener(
        'loadeddata',
        this.predictWebcam.bind(this)
      );
    }
  }

  async predictWebcam() {
    this.canvasElement = document.getElementById(
      'output_canvas'
    ) as HTMLCanvasElement;
    this.canvasCtx = this.canvasElement?.getContext('2d')!;
    this.drawingUtils = new DrawingUtils(this.canvasCtx);

    if (this.runningMode === 'IMAGE') {
      this.runningMode = 'VIDEO';
      await this.poseLandmarker!.setOptions({ runningMode: 'VIDEO' });
    }

    if (this.webcamRunning === true) {
      window.requestAnimationFrame(this.predictWebcam.bind(this));
    }
  }

  checkPoints() {
    let lastVideoTime = -1;
    const currentTime = this.video.currentTime;
    if (currentTime === lastVideoTime) return;
    lastVideoTime = currentTime;
    const startTimeMs = performance.now();
    this.poseLandmarker.detectForVideo(this.video, startTimeMs, (result) => {
      this.addResult(result);
      if (this.iterations === this.countOfMeasurement - 1) {
        this.downloadJSON({
          Info: {
            HospitalID: 'xxxxxxxxx',
            DoctorID: 'xxxxxxxxx',
            PatientID: 'xxxxxxxxx',
            MeasurementID: 'xxxxxxxxx',
          },
          ExerciseType: this.measurement_type.value,
          DataSource: this.video_type.value,
          DataType: 'Landmark Coordinates',
          Data: this.arrGeneral,
          AuthToken: 'xxxxxxxxx',
        });
      }

      this.drawLandmarks(result.landmarks);
    });
  }

  addResult(result) {
    const arr = this.validMarks.map(({ key, value }) => [
      this.iterations,
      key,
      result.landmarks[0][value].x,
      result.landmarks[0][value].y,
      result.landmarks[0][value].z,
    ]);
    this.arrGeneral.push(arr);
  }

  drawLandmarks(landmarks) {
    this.canvasCtx.save();
    this.canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    for (const landmark of landmarks) {
      this.drawingUtils.drawLandmarks(landmark, {
        radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 3, 0.5),
      });
      this.drawingUtils.drawConnectors(
        landmark,
        PoseLandmarker.POSE_CONNECTIONS
      );
    }
    this.canvasCtx.restore();
  }

  startMeasurement() {
    this.iterations = 0;
    this.countDownComponent.startCountDown();

    setTimeout(() => {
      const intervalId = setInterval(() => {
        this.checkPoints();
        this.iterations++;
        this.showCounter = true;
        setTimeout(() => {
          this.showCounter = false;
        }, 2000);
        if (this.iterations >= this.countOfMeasurement) {
          clearInterval(intervalId);
        }
      }, 100);
    }, this.time_before.value * 1000);
  }

  downloadJSON(data: any): void {
    const jsonStr = JSON.stringify(data);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
