<div class="main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col">
        <ul class="w3-ul" *ngIf="patients.length">
          <h1 class="title-role">
            {{ "admin.patients" | translate }}
          </h1>
          <app-patients-list-item
            *ngFor="let patient of patients; let i = index"
            [patient]="patient"
            [mode]="mode"
            [isChosen]="(onPatient | async)?._id === patient._id"
            (getPatients)="getPatients($event)"
            (chosenPatient)="onPatient.emit($event)"
          ></app-patients-list-item>
        </ul>
        <h2 *ngIf="!patients.length" style="color: darkgray">
          {{ "admin.empty" | translate }}
        </h2>
      </div>
    </div>
  </div>
</div>
