import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ExpertSystemService } from '../../../../../services/expert-system.service';
import { NervousSystem } from '../../../../../models/nervous-system/nervous-system';
import { ChangeLangDetectorComponent } from 'src/app/components/global-components/change-lang-detector/change-lang-detector.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-nervous',
  templateUrl: './nervous.component.html',
  styleUrls: ['./nervous.component.scss'],
})
export class NervousComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  @Input() set biosignalsData(biosignals) {
    this.biosignals = biosignals;
  }
  firstIndicatorName = '';
  secondIndicatorName = '';
  biosignals;

  constructor(
    private expertSystemService: ExpertSystemService,
    public auth: AuthService,
    public cd: ChangeDetectorRef
  ) {
    super(cd);
    this.expertSystemService
      .getNervousSystem()
      .subscribe((nervousSystem: NervousSystem) => {
        this.firstIndicatorName = nervousSystem.firstIndicatorName;
        this.secondIndicatorName = nervousSystem.secondIndicatorName;
      });
  }

  ngOnInit(): void {
    this.auth.language$.subscribe((v) => {
      this.changLang();
    });
  }
}
