import { RouterModule, Routes } from '@angular/router';
import { CalculationsComponent } from './components/calculations/calculations.component';
import { AuthGuard } from './services/auth/auth.guard';
import { calculationsRoutes } from './components/calculations/calculations.module';
import { HomeComponent } from './components/home/home.component';
import { MeasurementsDataComponent } from './components/measurements-data/measurements-data.component';
import { W_MEASUREMENTS_ROUTES } from './components/measurements-data/measurements-data.module';
import { ConsultantsModule } from './components/consultants/consultants.module';

import { AdminModule } from './components/admin/admin.module';
import { ProfileModule } from './components/profile/profile.module';
import { AuthModule } from './components/auth/auth.module';
import { NgModule } from '@angular/core';

const itemRoutes: Routes = [
  { path: '', redirectTo: '/home/calculations', pathMatch: 'full' },
  {
    path: 'calculations',
    component: CalculationsComponent,
    children: calculationsRoutes,
  },
  {
    path: 'measurements',
    component: MeasurementsDataComponent,
    children: W_MEASUREMENTS_ROUTES,
  },

  { path: 'consultants', loadChildren: () => ConsultantsModule },
  { path: 'admin', loadChildren: () => AdminModule },
  { path: 'profile', loadChildren: () => ProfileModule },
];

export const appRoutes: Routes = [
  { path: 'auth', loadChildren: () => AuthModule },
  { path: '', redirectTo: '/home/calculations', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: itemRoutes,
    data: { title: 'Оранта Артеріальна Осцилографія' },
  },
  { path: '**', redirectTo: '/home/calculations' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
