import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-telegram-message-box',
  templateUrl: './telegram-message-box.component.html',
  styleUrls: ['./telegram-message-box.component.scss'],
  animations: [
    trigger('fadeOutUp', [
      state('false', style({ opacity: 0, transform: 'translate3d(0, 0, 0)' })),
      state('true', style({ opacity: 1 })),
      transition('false <=> true', animate('500ms ease-in')),
    ]),
  ],
})
export class TelegramMessageBoxComponent {
  fadeOut = true;
  buttonText = 'Сopy';

  @Output() copyButtonClicked: EventEmitter<boolean> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    public dialogRef: MatDialogRef<TelegramMessageBoxComponent>,
    private clipboard: Clipboard
  ) {}

  copyInputMessage(element) {
    const text: string = element.textContent || '';
    const successful = this.clipboard.copy(text);
    this.fadeOut = false;
    this.buttonText = 'Сopied';
    this.copyButtonClicked.emit(true);
  }
}
