import { Injectable } from '@angular/core';
import { GraphType } from '../enums/graph_type.enum';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  rights;
  rightsFiltered;

  constructor() {}

  setRightsAll(data): void {
    this.rightsFiltered = data;
  }

  setRights(type: GraphType) {
    this.rights = this.rightsFiltered[type];
  }

  isHasRight(code: any): void {
    if (this.rights && this.rights.length) {
      return this.rights.find((data) => data.code === code);
    }
  }
}
