import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-add-medical-institution-dialog',
  templateUrl: './add-medical-institution-dialog.component.html',
  styleUrls: ['./add-medical-institution-dialog.component.scss'],
})
export class AddMedicalInstitutionDialogComponent implements OnInit {
  myForm: FormGroup;
  photoStaffLink;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private staffService: StaffService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      region: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      telephone: [''],
      site: [''],
    });
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.myForm.markAllAsTouched();
      this.translate.get(['alerts']).subscribe((t) => {
        this.toastr.warning(t.alerts.warning_1);
      });
      return;
    }
    this.staffService
      .postMedicalInstitution(this.myForm.value)
      .subscribe((resp) => {
        if (resp) {
          this.modal.close();
        }
      });
  }

  onClose() {
    this.modal.close();
  }
}
