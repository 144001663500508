import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAV_MENU_EQUIPMENT_SETTINGS } from 'src/app/services/dictionaries/constants/nav-menu';
import { ChangeLangDetectorComponent } from '../../global-components/change-lang-detector/change-lang-detector.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss'],
})
export class DeviceSettingsComponent
  extends ChangeLangDetectorComponent
  implements OnInit
{
  busy: boolean = false;
  active: number = 0;
  showNav: boolean = false;
  navMenu: Array<any> = [];

  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    public authService: AuthService
  ) {
    super(cd);
    this.navMenu = NAV_MENU_EQUIPMENT_SETTINGS;
  }

  ngOnInit(): void {
    this.authService.language$.subscribe((v) => {
      this.changLang();
    });
    const url = this.router.url;
    const fragment = url.split('/');
    switch (fragment[fragment.length - 1]) {
      case 'global':
        this.active = 0;
        break;
      case 'current':
        this.active = 1;
        break;
      case 'range':
        this.active = 2;
        break;
    }
  }
}
