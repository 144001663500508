import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-heart-tab',
  templateUrl: './heart-tab.component.html',
  styleUrls: ['./heart-tab.component.scss'],
})
export class HeartTabComponent implements OnInit {
  @Input() biosignalsData = [];
  constructor() {}

  ngOnInit(): void {}
}
