import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleRegistrationDoctorComponent } from './role-registration-doctor.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { SvgModule } from 'src/app/svg.module';

@NgModule({
  declarations: [RoleRegistrationDoctorComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SvgModule,
    MaterialModule,
  ],
  exports: [RoleRegistrationDoctorComponent],
})
export class RoleRegistrationDoctorModule {}
