import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastrMessageService {
  password_change_success = {
    ua: 'Пароль успішно змінено',
    en: 'Password changed successfully',
    pl: 'Hasło zostało zmienione pomyślnie',
  };

  incorrect_data = {
    ua: 'Дані введено неправильно',
    en: 'Data entered incorrectly',
    pl: 'Dane wprowadzone nieprawidłowo',
  };
}
