import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { PatientService } from '../measurements-data/services/patient.service';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  isRegistration: boolean = false;
  constructor(
    private router: Router,
    private roleService: RoleService,
    private cd: ChangeDetectorRef,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    const url = this.router.url;
    if (url.includes('login')) {
      this.isRegistration = false;
    } else if (url.includes('registration')) {
      this.isRegistration = true;
    }
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd || e instanceof NavigationStart) {
        if (e.url.includes('login')) {
          this.isRegistration = false;
        } else if (e.url.includes('registration')) {
          this.isRegistration = true;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.patientService.setPatient = null;
  }

  registration(page) {
    this.roleService.setRole = null;
    this.router.navigate(['/auth/', page]);
  }
}
