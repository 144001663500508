import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  gender = [
    {
      value: 'MALE',
      ua: 'Чоловік',
      en: 'Male',
      pl: 'Mężczyzna',
    },
    {
      value: 'FEMALE',
      ua: 'Жінка',
      en: 'Female',
      pl: 'Kobieta',
    },
  ];
}
