import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EquipmentWithOptions,
  EquipmentGeneralSetting,
  EquipmentRangeSetting,
} from '../models/equipment';
import { environment } from 'src/environments/environment';
import { EquipmentName } from '../enums/equipment.enum';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  equipments: any[] = [];
  constructor(private http: HttpClient) {
    this.setEquipment();
  }

  setEquipment() {
    this.getEquipments(EquipmentName.VAT).subscribe((v) => {
      this.equipments = v;
    });
  }

  createEquipmentSetting(setting): Observable<EquipmentGeneralSetting[]> {
    return this.http.post<EquipmentGeneralSetting[]>(
      `${environment.apiUrl}/equipments`,
      setting
    );
  }

  getEquipments(name): Observable<EquipmentWithOptions[]> {
    let params = new HttpParams();
    if (name) params = params.append(`equipmentName`, `${name}`);
    return this.http.get<EquipmentWithOptions[]>(
      `${environment.apiUrl}/equipments`,
      { params }
    );
  }

  getEquipmentGeneralSetting(
    equipmentName
  ): Observable<EquipmentGeneralSetting> {
    return this.http.get<EquipmentGeneralSetting>(
      `${environment.apiUrl}/equipments/setting/${equipmentName}`
    );
  }

  createEquipmentGeneralSetting(
    setting
  ): Observable<EquipmentGeneralSetting[]> {
    return this.http.post<EquipmentGeneralSetting[]>(
      `${environment.apiUrl}/equipments/setting`,
      setting
    );
  }

  updateEquipmentGeneralSetting(
    id,
    setting
  ): Observable<EquipmentGeneralSetting[]> {
    return this.http.patch<EquipmentGeneralSetting[]>(
      `${environment.apiUrl}/equipments/setting/${id}`,
      setting
    );
  }

  //
  getEquipmentPatientSetting(
    patientId,
    equipmentName
  ): Observable<EquipmentGeneralSetting> {
    return this.http.get<EquipmentGeneralSetting>(
      `${environment.apiUrl}/equipments/setting-patient/${patientId}/${equipmentName}`
    );
  }

  createEquipmentPatientSetting(
    setting
  ): Observable<EquipmentGeneralSetting[]> {
    return this.http.post<EquipmentGeneralSetting[]>(
      `${environment.apiUrl}/equipments/setting-patient`,
      setting
    );
  }

  updateEquipmentPatientSetting(
    id,
    setting
  ): Observable<EquipmentGeneralSetting[]> {
    return this.http.patch<EquipmentGeneralSetting[]>(
      `${environment.apiUrl}/equipments/setting-patient/${id}`,
      setting
    );
  }

  //
  getEquipmentRangeSettingAll(
    equipmentName
  ): Observable<EquipmentRangeSetting> {
    return this.http.get<EquipmentRangeSetting>(
      `${environment.apiUrl}/equipments/setting-range/${equipmentName}`
    );
  }

  createEquipmentRangeSetting(setting): Observable<EquipmentRangeSetting[]> {
    return this.http.post<EquipmentRangeSetting[]>(
      `${environment.apiUrl}/equipments/setting-range`,
      setting
    );
  }

  updateEquipmentRangeSetting(
    id,
    setting
  ): Observable<EquipmentRangeSetting[]> {
    return this.http.patch<EquipmentRangeSetting[]>(
      `${environment.apiUrl}/equipments/setting-range/${id}`,
      setting
    );
  }
}
