import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  protected subjects: any = {};
  constructor() {
  }
  protected getSubject(id): BehaviorSubject<any> {
    this.subjects[id] = (this.subjects[id] || new BehaviorSubject<any>(null));
    return this.subjects[id];
  }

  protected getSubject$(id) {
    return this.getSubject(id).asObservable();
  }
}
