import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLangDetectorComponent } from './change-lang-detector.component';

@NgModule({
  declarations: [ChangeLangDetectorComponent],
  imports: [CommonModule],
  exports: [ChangeLangDetectorComponent],
})
export class ChangeLangDetectorModule {}
